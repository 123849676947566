import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from '../../services/apollo/mutations';
import { StateInterface } from 'jesco-web';
import { FaTrash } from 'react-icons/fa';

export interface ClientFormValues {
  id: number | null;
  name: string;
  address: string;
  email: string;
  city: string;
  stateId: number;
  postCode: string;
  clientContact: string;
  phone: string;
  mobile: string;
}

export interface ClientDrawerProps {
  isOpen: boolean;
  onComplete: () => void;
  onClose: () => void;
  states: StateInterface[];
  initialValues: ClientFormValues;
}

export const ClientDrawer = ({
  isOpen,
  onComplete,
  onClose,
  states,
  initialValues,
}: ClientDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });

  const [addClient, { loading: addClientLoading }] = useMutation(
    CREATE_CLIENT,
    {
      onCompleted(d) {
        if (!d || !d.createClient || !d.createClient.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Client created',
          description: `${d.createClient.name} created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateClient, { loading: updateClientLoading }] = useMutation(
    UPDATE_CLIENT,
    {
      onCompleted(d) {
        if (!d || !d.updateClient || !d.updateClient.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Client updated',
          description: `${d.updateClient.name} updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteClient, { loading: delteClientLoading }] = useMutation(
    DELETE_CLIENT,
    {
      onCompleted(d) {
        if (!d || !d.deleteClient || !d.deleteClient.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Client deleted',
          description: `${d.deleteClient.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      address: Yup.string(),
      city: Yup.string(),
      clientContact: Yup.string(),
      email: Yup.string(),
      mobile: Yup.string(),
      name: Yup.string().required(),
      phone: Yup.string(),
      postCode: Yup.string(),
      stateId: Yup.number(),
    }),
    onSubmit: (values) => {
      if (!values.id) {
        addClient({
          variables: {
            ...values,
            stateId: Number(values.stateId),
          },
        });
      } else {
        updateClient({
          variables: {
            ...values,
            stateId: Number(values.stateId),
          },
        });
      }
    },
  });

  const isLoading =
    addClientLoading || updateClientLoading || delteClientLoading;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md" data-testid="client-drawer">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">
              {formik.values.name ? `${formik.values.name}` : 'New client'}
            </Text>
            {formik.values && formik.values.id && (
              <IconButton
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete ${formik.values.name}?`,
                    )
                  ) {
                    deleteClient({
                      variables: {
                        id: formik.values.id,
                      },
                    });
                  }
                }}
                aria-label="delete-client-button"
                data-testid="delete-client-button"
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody bg="white">
            <FormControl
              id="name"
              isInvalid={Boolean(formik.errors.name)}
              isRequired
            >
              <FormLabel>Name</FormLabel>
              <Input
                data-testid="client-form-name"
                autoFocus
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl id="email" isInvalid={Boolean(formik.errors.email)}>
              <FormLabel>Email</FormLabel>
              <Input
                data-testid="client-form-email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl id="address">
              <FormLabel>Address</FormLabel>
              <Input
                data-testid="client-form-address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
            </FormControl>

            <FormControl id="city" isInvalid={Boolean(formik.errors.city)}>
              <FormLabel>City</FormLabel>
              <Input
                data-testid="client-form-city"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
            </FormControl>

            <FormControl id="state">
              <FormLabel>State</FormLabel>
              <Select
                data-testid="client-form-state"
                name="stateId"
                placeholder="States"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stateId}
              >
                {states.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl
              id="post-code"
              isInvalid={Boolean(formik.errors.postCode)}
            >
              <FormLabel>Post code</FormLabel>
              <Input
                data-testid="client-form-post-code"
                name="postCode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
              />
            </FormControl>

            <FormControl
              id="client-contact"
              isInvalid={Boolean(formik.errors.clientContact)}
            >
              <FormLabel>Client contact</FormLabel>
              <Input
                data-testid="client-form-client-contact"
                name="clientContact"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.clientContact}
              />
            </FormControl>

            <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <NumberInput value={formik.values.phone}>
                <NumberInputField
                  data-testid="client-form-phone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </NumberInput>
            </FormControl>

            <FormControl id="mobile">
              <FormLabel>Mobile</FormLabel>
              <NumberInput value={formik.values.mobile}>
                <NumberInputField
                  data-testid="client-form-mobile"
                  name="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </NumberInput>
            </FormControl>

            {formik.values.id && (
              <Input
                data-testid="client-form-id"
                name="id"
                value={formik.values.id}
                type="hidden"
              />
            )}
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-evenly"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid || !formik.dirty}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
