import React from 'react';
import { Text, Box, Flex, HStack, Image, Stack } from '@chakra-ui/react';
import {
  FaSuitcase,
  FaUsers,
  FaFile,
  FaTrash,
  FaCalendar,
} from 'react-icons/fa';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SidebarButton } from './SidebarButton';
import logo from '../../lib/images/logo.png';

export const DashboardSidebar = withRouter(
  ({ history: { push }, location: { pathname } }: RouteComponentProps) => {
    return (
      <Box height="100%" width="100%" data-testid="dashboard-sidebar" px="3px">
        <Flex width="100%" justify="center" py="20px">
          <Image height={['50px', '50px', '50px', '55px']} src={logo} />
        </Flex>

        <Stack spacing="5px">
          <SidebarButton
            onClick={() => push('/dashboard/calendar')}
            isActive={pathname.includes('/dashboard/calendar')}
            leftIcon={<FaCalendar />}
          >
            <HStack>
              <Text>Calendar</Text>
            </HStack>
          </SidebarButton>

          <SidebarButton
            isActive={pathname.includes('/dashboard/removal-project')}
            onClick={() => push('/dashboard/removal-projects')}
            leftIcon={<FaSuitcase />}
          >
            Removal projects
          </SidebarButton>

          <SidebarButton
            onClick={() => push('/dashboard/people/clients')}
            isActive={pathname.includes('/dashboard/people')}
            leftIcon={<FaUsers />}
          >
            People
          </SidebarButton>

          <SidebarButton
            onClick={() => push('/dashboard/waste-disposals')}
            isActive={pathname.includes('/dashboard/waste-disposal')}
            leftIcon={<FaTrash />}
          >
            Waste disposals
          </SidebarButton>

          <SidebarButton
            onClick={() => push('/dashboard/files')}
            isActive={pathname.includes('/dashboard/files')}
            leftIcon={<FaFile />}
          >
            Files
          </SidebarButton>
        </Stack>
      </Box>
    );
  },
);
