import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  Text,
  Stack,
  HStack,
  FormControl,
  Input,
  IconButton,
  DrawerFooter,
  Skeleton,
} from '@chakra-ui/react';
import { CalendarEventTypeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { CREATE_CALENDAR_EVENT_TYPE } from '../../services/apollo/mutations';
import { CalendarEventTypeEditRow } from './CalendarEventTypeEditRow';
import { ColorPickerButton } from '../ColorPickerButton';

export interface CalendarEventTypeDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onComplete?(): void;
  calendarEventTypes: CalendarEventTypeInterface[];
  isGetEventTypesLoading: boolean;
}

export const CalendarEventTypeDrawer = ({
  isOpen,
  onClose,
  onComplete,
  calendarEventTypes,
  isGetEventTypesLoading,
}: CalendarEventTypeDrawerProps) => {
  const [showNewEventTypeForm, setShowNewEventTypeForm] = useState(false);
  const toast = useToast({ position: 'bottom-left' });

  const [createCalendarEventType, { loading: isCreateLoading }] = useMutation(
    CREATE_CALENDAR_EVENT_TYPE,
    {
      onCompleted(d) {
        if (!d || !d.createCalendarEventType || !d.createCalendarEventType.id)
          return;

        formik.resetForm();
        setShowNewEventTypeForm(false);

        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Calendar event type created',
          description: `Calendar event type created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      title: '',
      color: '#ccc',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      color: Yup.string().required(),
    }),
    onSubmit: (values) => {
      createCalendarEventType({
        variables: {
          title: values.title,
          color: values.color,
        },
      });
    },
  });

  const isLoading = isCreateLoading;

  const handleClose = () => {
    setShowNewEventTypeForm(false);
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent bg="dark" rounded="md">
        <DrawerHeader
          pl="40px"
          pr="20px"
          display="flex"
          fontSize="md"
          color="white"
          bg="blue.500"
          justifyContent="space-between"
          alignItems="center"
        >
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <Text fontSize="xl">Edit calendar event types</Text>
        </DrawerHeader>
        <DrawerBody bg="white">
          <Box>
            {isGetEventTypesLoading && (
              <Stack>
                <Skeleton height="35px" />
                <Skeleton height="35px" />
                <Skeleton height="35px" />
                <Skeleton height="35px" />
                <Skeleton height="35px" />
              </Stack>
            )}
            {!isGetEventTypesLoading && (
              <Stack>
                <HStack justify="flex-end" py="15px">
                  {!showNewEventTypeForm && (
                    <HStack justify="flex-end">
                      <Button
                        variant="link"
                        colorScheme="green"
                        onClick={() => setShowNewEventTypeForm(true)}
                      >
                        Add new event type
                      </Button>
                    </HStack>
                  )}

                  {showNewEventTypeForm && (
                    <form onSubmit={formik.handleSubmit}>
                      <FormControl
                        id="title"
                        isInvalid={Boolean(formik.errors.title)}
                        isRequired
                      >
                        <HStack>
                          <HStack align="center" spacing={5}>
                            <ColorPickerButton
                              color={formik.values.color}
                              onChange={(color) => {
                                formik.setFieldValue('color', color.hex);
                              }}
                            />

                            <Input
                              data-testid="calendar-event-type-create-form-title"
                              name="title"
                              placeholder="Event type name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                              autoFocus
                            />
                          </HStack>

                          <HStack>
                            <IconButton
                              variant="outline"
                              colorScheme="green"
                              data-testid="submit-calendar-event-type-create-ok"
                              aria-label="submit-calendar-event-type-create-ok"
                              type="submit"
                              disabled={!formik.isValid}
                              isLoading={isLoading}
                              icon={<FaCheck />}
                            />
                            <IconButton
                              variant="outline"
                              colorScheme="gray"
                              data-testid="submit-calendar-event-type-create-cancel"
                              aria-label="submit-calendar-event-type-create-cancel"
                              onClick={() => setShowNewEventTypeForm(false)}
                              isLoading={isLoading}
                              icon={<FaTimes />}
                            />
                          </HStack>
                        </HStack>
                      </FormControl>
                    </form>
                  )}
                </HStack>

                {calendarEventTypes.map((cet) => (
                  <Box key={cet.id} data-testid="calendar-event-types">
                    <CalendarEventTypeEditRow
                      calendarEventType={cet}
                      onComplete={onComplete}
                    />
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
        </DrawerBody>
        <DrawerFooter
          bg="white"
          justifyContent="center"
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <HStack align="flex-end">
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Finished
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
