import React from 'react';
import { SignatureInterface } from 'jesco-web';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Image,
} from '@chakra-ui/react';

export interface SignaturesTableProps {
  signatures: SignatureInterface[];
}

export const SwmsSignaturePrintTable = ({
  signatures,
}: SignaturesTableProps) => {
  return (
    <Table colorScheme="gray">
      {!signatures.length && (
        <TableCaption fontWeight="bold">No signatures</TableCaption>
      )}
      <Thead>
        <Tr>
          <Th p={0} width="25%">
            Name of Worker Signing SWMS
          </Th>
          <Th p={0} width="15%">
            Organization
          </Th>
          <Th p={0} width="25%">
            Signature
          </Th>
          <Th p={0} width="15%">
            Date Signed SWMS
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {signatures.map((signature) => (
          <Tr data-testid="swms-signature-row">
            <Td p={0}>{signature.name}</Td>
            <Td p={0}>{signature.organization}</Td>
            <Td p={0}>
              <Image
                height="60px"
                src={signature.path}
                alt={`${signature.name} signature`}
              />
            </Td>
            <Td p={0}>{signature.date}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
