import * as React from 'react';
import {
  InMemoryCache,
  ApolloClient,
  ApolloProvider as Provider,
} from '@apollo/client';
import { useAuthContext } from '../../providers/Auth.Provider';
import { createUploadLink } from 'apollo-upload-client';

export const ApolloProvider = (props: any) => {
  const { token } = useAuthContext();

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: `${process.env.REACT_APP_API_ENDPOINT}`,
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    }),
  });

  return <Provider client={client} {...props} />;
};
