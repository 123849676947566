import React from 'react';
import { RemovalAreaInterface } from 'jesco-web';
import { IconButton, Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';
import { FaTimes } from 'react-icons/fa';

export interface RemovalAreaRowInterface extends TableRowPropsInterface {
  removalArea: RemovalAreaInterface;
  onRemoveClick?(removalArea: RemovalAreaInterface): void;
  isLoading: boolean;
  canDelete: boolean;
}

export const RemovalAreaTableRow = ({
  removalArea,
  onRemoveClick,
  isLoading,
  canDelete,
  ...props
}: RemovalAreaRowInterface) => {
  const handleRemoveClick = () => {
    if (onRemoveClick) {
      onRemoveClick(removalArea);
    }
  };

  return (
    <TableRow
      borderTop="1px solid"
      borderColor="gray.300"
      data-testid="removal-area-table-row"
      {...props}
    >
      <TableDiv px="5px" py="5px" data-testid="removal-area-td">
        <Text fontSize="sm" wordBreak="break-word">
          {removalArea.Area.name}
        </Text>
      </TableDiv>

      <TableDiv px="5px" py="5px" data-testid="removal-location-td">
        <Text fontSize="sm" wordBreak="break-word">
          {removalArea.Location.name}
        </Text>
      </TableDiv>

      <TableDiv px="5px" py="5px" data-testid="removal-material-td">
        <Text fontSize="sm" wordBreak="break-word">
          {removalArea.Material.name}
        </Text>
      </TableDiv>

      <TableDiv px="5px" py="5px" data-testid="removal-type-td">
        <Text fontSize="sm" wordBreak="break-word">
          {removalArea.Type.name}
        </Text>
      </TableDiv>

      <TableDiv px="5px" py="5px" data-testid="removal-condition-td">
        <Text fontSize="sm" wordBreak="break-word">
          {removalArea.Condition.name}
        </Text>
      </TableDiv>

      {Boolean(onRemoveClick) && (
        <TableDiv px="5px" py="10px">
          <IconButton
            aria-label="remove-area"
            data-testid="delete-removal-area"
            variant="outline"
            isDisabled={!canDelete}
            icon={<FaTimes />}
            isLoading={isLoading}
            size="sm"
            onClick={handleRemoveClick}
          />
        </TableDiv>
      )}
    </TableRow>
  );
};
