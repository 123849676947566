import React, { useState } from 'react';
import { UserInterface } from 'jesco-web';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
} from '@chakra-ui/react';
import { UPDATE_PERMISSIONS } from '../../services/apollo/mutations';
import { useMutation } from '@apollo/client';

export interface UserPermissionsFormProps {
  user: UserInterface;
  onComplete(): void;
}

export const UserPermissionsForm = ({
  user,
  onComplete,
}: UserPermissionsFormProps) => {
  const [yesToAll, setYesToAll] = useState(
    user.Permissions &&
      user.Permissions.can_edit_clients &&
      user.Permissions.can_edit_users &&
      user.Permissions.can_edit_supervisors &&
      user.Permissions.can_edit_employees &&
      user.Permissions.can_edit_company_files &&
      user.Permissions.can_view_company_files &&
      user.Permissions.can_edit_removal_projects &&
      user.Permissions.can_edit_waste_disposals &&
      user.Permissions.can_edit_quotes,
  );

  const toast = useToast({ position: 'bottom-left' });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: Number(user.id),
      canEditClients: user.Permissions
        ? user.Permissions.can_edit_clients
        : false,
      canEditUsers: user.Permissions ? user.Permissions.can_edit_users : false,
      canEditSupervisors: user.Permissions
        ? user.Permissions.can_edit_supervisors
        : false,
      canEditEmployees: user.Permissions
        ? user.Permissions.can_edit_employees
        : false,
      canEditCompanyFiles: user.Permissions
        ? user.Permissions.can_edit_company_files
        : false,
      canViewCompanyFiles: user.Permissions
        ? user.Permissions.can_view_company_files
        : false,
      canEditRemovalProjects: user.Permissions
        ? user.Permissions.can_edit_removal_projects
        : false,
      canEditWasteDisposals: user.Permissions
        ? user.Permissions.can_edit_waste_disposals
        : false,
      canEditQuotes: user.Permissions
        ? user.Permissions.can_edit_quotes
        : false,
    },
    validationSchema: Yup.object().shape({
      userId: Yup.number().required(),
      canEditClients: Yup.boolean().required(),
      canEditUsers: Yup.boolean().required(),
      canEditSupervisors: Yup.boolean().required(),
      canEditEmployees: Yup.boolean().required(),
      canEditCompanyFiles: Yup.boolean().required(),
      canViewCompanyFiles: Yup.boolean().required(),
      canEditRemovalProjects: Yup.boolean().required(),
      canEditWasteDisposals: Yup.boolean().required(),
      canEditQuotes: Yup.boolean().required(),
    }),
    onSubmit: (values) => {
      updatePermissions({
        variables: {
          userId: Number(values.userId),
          canEditClients: values.canEditClients,
          canEditUsers: values.canEditUsers,
          canEditSupervisors: values.canEditSupervisors,
          canEditEmployees: values.canEditEmployees,
          canEditCompanyFiles: values.canEditCompanyFiles,
          canViewCompanyFiles: values.canViewCompanyFiles,
          canEditRemovalProjects: values.canEditRemovalProjects,
          canEditWasteDisposals: values.canEditWasteDisposals,
          canEditQuotes: values.canEditQuotes,
        },
      });
    },
  });

  const [updatePermissions, { loading }] = useMutation(UPDATE_PERMISSIONS, {
    onCompleted(d) {
      if (!d || !d.updatePermissions || !d.updatePermissions.status) return;

      onComplete();

      formik.resetForm();

      toast({
        title: 'Permissions updated',
        description: `Permissions updated`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack spacing={2} bg="white">
        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
        >
          <FormLabel htmlFor="canEditClients" mb="0">
            Allow this user to create and edit clients?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditClients"
            name="canEditClients"
            data-testid="permissions-form-canEditClients"
            isChecked={formik.values.canEditClients}
            onChange={(e) => {
              if (!formik.values.canEditClients) {
                formik.setFieldValue('canEditClients', true);
              } else {
                formik.setFieldValue('canEditClients', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bg="gray.50"
        >
          <FormLabel htmlFor="canEditSupervisors" mb="0">
            Allow this user to create and edit supervisors?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditSupervisors"
            name="canEditSupervisors"
            data-testid="permissions-form-canEditSupervisors"
            isChecked={formik.values.canEditSupervisors}
            onChange={(e) => {
              if (!formik.values.canEditSupervisors) {
                formik.setFieldValue('canEditSupervisors', true);
              } else {
                formik.setFieldValue('canEditSupervisors', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
        >
          <FormLabel htmlFor="canEditUsers" mb="0">
            Allow this user to create and edit users?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditUsers"
            name="canEditUsers"
            data-testid="permissions-form-canEditUsers"
            isChecked={formik.values.canEditUsers}
            onChange={(e) => {
              if (!formik.values.canEditUsers) {
                formik.setFieldValue('canEditUsers', true);
              } else {
                formik.setFieldValue('canEditUsers', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bg="gray.50"
        >
          <FormLabel htmlFor="canEditEmployees" mb="0">
            Allow this user to create and edit employees?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditEmployees"
            name="canEditEmployees"
            data-testid="permissions-form-canEditEmployees"
            isChecked={formik.values.canEditEmployees}
            onChange={(e) => {
              if (!formik.values.canEditEmployees) {
                formik.setFieldValue('canEditEmployees', true);
              } else {
                formik.setFieldValue('canEditEmployees', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
        >
          <FormLabel htmlFor="canEditCompanyFiles" mb="0">
            Allow this user to create and edit company files?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditCompanyFiles"
            name="canEditCompanyFiles"
            data-testid="permissions-form-canEditCompanyFiles"
            isChecked={formik.values.canEditCompanyFiles}
            onChange={(e) => {
              if (!formik.values.canEditCompanyFiles) {
                formik.setFieldValue('canEditCompanyFiles', true);
              } else {
                formik.setFieldValue('canEditCompanyFiles', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bg="gray.50"
        >
          <FormLabel htmlFor="canViewCompanyFiles" mb="0">
            Allow this user to view company files?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canViewCompanyFiles"
            name="canViewCompanyFiles"
            data-testid="permissions-form-canViewCompanyFiles"
            isChecked={formik.values.canViewCompanyFiles}
            onChange={(e) => {
              if (!formik.values.canViewCompanyFiles) {
                formik.setFieldValue('canViewCompanyFiles', true);
              } else {
                formik.setFieldValue('canViewCompanyFiles', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
        >
          <FormLabel htmlFor="canEditRemovalProjects" mb="0">
            Allow this user to create and edit removal projects?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditRemovalProjects"
            name="canEditRemovalProjects"
            data-testid="permissions-form-canEditRemovalProjects"
            isChecked={formik.values.canEditRemovalProjects}
            onChange={(e) => {
              if (!formik.values.canEditRemovalProjects) {
                formik.setFieldValue('canEditRemovalProjects', true);
              } else {
                formik.setFieldValue('canEditRemovalProjects', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bg="gray.50"
        >
          <FormLabel htmlFor="canEditWasteDisposals" mb="0">
            Allow this user to create and edit waste disposals?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditWasteDisposals"
            name="canEditWasteDisposals"
            data-testid="permissions-form-canEditWasteDisposals"
            isChecked={formik.values.canEditWasteDisposals}
            onChange={(e) => {
              if (!formik.values.canEditWasteDisposals) {
                formik.setFieldValue('canEditWasteDisposals', true);
              } else {
                formik.setFieldValue('canEditWasteDisposals', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bg="gray.50"
        >
          <FormLabel htmlFor="canEditQuotes" mb="0">
            Allow this user to view and edit quotes?
          </FormLabel>
          <Switch
            colorScheme="green"
            id="canEditQuotes"
            name="canEditQuotes"
            data-testid="permissions-form-canEditQuotes"
            isChecked={formik.values.canEditQuotes}
            onChange={(e) => {
              if (!formik.values.canEditQuotes) {
                formik.setFieldValue('canEditQuotes', true);
              } else {
                formik.setFieldValue('canEditQuotes', false);
                setYesToAll(false);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
        >
          <FormLabel mb="0"></FormLabel>
          <Switch
            colorScheme="green"
            id="yes-to-all"
            data-testid="user-permissions-form-yes-to-all"
            isChecked={yesToAll}
            onChange={(e) => {
              if (yesToAll) {
                setYesToAll(false);
                formik.setFieldValue('canEditClients', false);
                formik.setFieldValue('canEditSupervisors', false);
                formik.setFieldValue('canEditUsers', false);
                formik.setFieldValue('canEditEmployees', false);
                formik.setFieldValue('canEditCompanyFiles', false);
                formik.setFieldValue('canViewCompanyFiles', false);
                formik.setFieldValue('canEditRemovalProjects', false);
                formik.setFieldValue('canEditWasteDisposals', false);
                formik.setFieldValue('canEditQuotes', false);
              } else {
                setYesToAll(true);
                formik.setFieldValue('canEditClients', true);
                formik.setFieldValue('canEditSupervisors', true);
                formik.setFieldValue('canEditUsers', true);
                formik.setFieldValue('canEditEmployees', true);
                formik.setFieldValue('canEditCompanyFiles', true);
                formik.setFieldValue('canViewCompanyFiles', true);
                formik.setFieldValue('canEditRemovalProjects', true);
                formik.setFieldValue('canEditQuotes', true);
              }
            }}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        {formik.values.userId && (
          <Input
            data-testid="user-form-id"
            name="userId"
            value={formik.values.userId}
            type="hidden"
          />
        )}

        <Box>
          <HStack spacing={5} mt={5} justify="flex-end">
            <Button
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
              isLoading={loading}
            >
              Update permissions
            </Button>
          </HStack>
        </Box>
      </Stack>
    </form>
  );
};
