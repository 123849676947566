import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker } from 'antd';
import { FaChevronDown, FaTrash } from 'react-icons/fa';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CalendarEventInterface, CalendarEventTypeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import {
  CREATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT,
} from '../../services/apollo/mutations';
import { CalendarBubble } from './CalendarBubble';
import { TimePicker } from '../TimePicker';

export interface CalendarEventDrawerProps {
  isOpen: boolean;
  onClose(): void;
  calendarEvent?: CalendarEventInterface;
  onComplete?(): void;
  calendarEventTypes: CalendarEventTypeInterface[];
  date: Date;
}

export interface CalendarEventFormValues {
  id?: number;
  endDate: Date;
  startDate: Date;
  title: string;
  location: string;
  description: string;
  isAllDay: boolean;
  calendarEventTypeId: number | null;
  attendeeIds: number[];
}

export const CalendarEventDrawer = ({
  isOpen,
  onClose,
  calendarEvent,
  onComplete,
  calendarEventTypes,
  date,
}: CalendarEventDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [createCalendarEvent, { loading: isCreateLoading }] = useMutation(
    CREATE_CALENDAR_EVENT,
    {
      onCompleted(d) {
        if (!d || !d.createCalendarEvent || !d.createCalendarEvent.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }
        formik.resetForm();

        toast({
          title: 'Calendar event created',
          description: `Calendar event created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateCalendarEvent, { loading: isUpdateLoading }] = useMutation(
    UPDATE_CALENDAR_EVENT,
    {
      onCompleted(d) {
        if (!d || !d.updateCalendarEvent || !d.updateCalendarEvent.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }
        formik.resetForm();

        toast({
          title: 'Calendar event updated',
          description: `Calendar event updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteCalendarEvent, { loading: isDeleteLoading }] = useMutation(
    DELETE_CALENDAR_EVENT,
    {
      onCompleted(d) {
        if (!d || !d.deleteCalendarEvent || !d.deleteCalendarEvent.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }
        formik.resetForm();

        toast({
          title: 'Calendar event deleted',
          description: `Calendar event deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {
      id: calendarEvent ? calendarEvent.id : undefined,
      startDate: calendarEvent
        ? moment(calendarEvent?.start_date_formatted, 'LLL').toDate()
        : moment(date).set('hour', 9).set('minute', 0).toDate(),
      endDate: calendarEvent
        ? moment(calendarEvent?.end_date_formatted, 'LLL').toDate()
        : moment(date).set('hour', 17).set('minute', 0).toDate(),
      isAllDay: calendarEvent ? calendarEvent.is_all_day : false,
      title: calendarEvent ? calendarEvent.title : '',
      location: calendarEvent ? calendarEvent.location : '',
      description: calendarEvent ? calendarEvent.description : '',
      calendarEventTypeId: calendarEvent
        ? Number(calendarEvent.calendar_event_type_id)
        : null,
      attendeeIds: calendarEvent
        ? calendarEvent.Employees.map((e) => Number(e.id))
        : [],
    },
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      calendarEventTypeId: Yup.number().nullable(),
      description: Yup.string().nullable(),
      endDate: Yup.date().required(),
      isAllDay: Yup.boolean().required(),
      location: Yup.string().nullable(),
      startDate: Yup.date().required(),
      title: Yup.string().min(1).required(),
    }),
    onSubmit: (values) => {
      if (!values.id) {
        createCalendarEvent({
          variables: {
            startDate: moment(values.startDate).format('YYYY-MM-DD HH:mm'),
            endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm'),
            isAllDay: Boolean(values.isAllDay),
            calendarEventTypeId: Number(values.calendarEventTypeId),
            attendeeIds: values.attendeeIds.map((aId) => Number(aId)),
            title: values.title,
            location: values.location,
            description: values.description,
          },
        });
      } else {
        updateCalendarEvent({
          variables: {
            id: values.id,
            startDate: moment(values.startDate).format('YYYY-MM-DD HH:mm'),
            endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm'),
            isAllDay: Boolean(values.isAllDay),
            calendarEventTypeId: Number(values.calendarEventTypeId),
            attendeeIds: values.attendeeIds.map((aId) => Number(aId)),
            title: values.title,
            location: values.location,
            description: values.description,
          },
        });
      }
    },
  });

  const activeEventType = calendarEventTypes.find(
    (cet) => cet.id === formik.values.calendarEventTypeId,
  );

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent bg="dark" rounded="md">
          <DrawerHeader
            pl="40px"
            pr="20px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <DrawerCloseButton
              left="0"
              top="5px"
              data-testid="drawer-close-button"
              onClick={handleClose}
              color="white"
            />
            <Text fontSize="xl">
              {calendarEvent ? `Edit event` : 'New event'}
            </Text>

            {calendarEvent && calendarEvent.id && (
              <IconButton
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    deleteCalendarEvent({
                      variables: {
                        id: calendarEvent.id,
                      },
                    });
                  }
                }}
                data-testid="delete-event-button"
                aria-label="delete-event"
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerBody p={0} bg="white">
            <Box p="20px">
              <Stack spacing="20px">
                <HStack>
                  <FormControl isRequired>
                    <FormLabel>From</FormLabel>
                    <HStack>
                      <Box width="160px">
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={moment(formik.values.startDate).clone()}
                          onChange={(d) => {
                            formik.setFieldValue('startDate', d?.toDate());
                          }}
                        />
                      </Box>
                      {!formik.values.isAllDay && (
                        <Box width="160px">
                          <TimePicker
                            date={moment(formik.values.startDate)}
                            onHourChange={(hour) => {
                              const currentDate = moment(
                                formik.values.startDate,
                              );
                              currentDate.set('hour', hour);
                              formik.setFieldValue(
                                'startDate',
                                currentDate.toDate(),
                              );
                            }}
                            onMinuteChange={(minute) => {
                              const currentDate = moment(
                                formik.values.startDate,
                              );
                              currentDate.set('minute', minute);
                              formik.setFieldValue(
                                'startDate',
                                currentDate.toDate(),
                              );
                            }}
                          />
                        </Box>
                      )}
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <FormLabel textAlign="right">All day?</FormLabel>
                    <HStack justify="flex-end">
                      <Switch
                        data-testid="event-form-all-day"
                        colorScheme="green"
                        isChecked={formik.values.isAllDay}
                        onChange={() => {
                          formik.setFieldValue(
                            'isAllDay',
                            !formik.values.isAllDay,
                          );
                        }}
                      />
                    </HStack>
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl isRequired>
                    <FormLabel>To</FormLabel>
                    <HStack>
                      <Box width="160px">
                        <DatePicker
                          format="DD/MM/YYYY"
                          disabledDate={(d) =>
                            d.isBefore(
                              moment(formik.values.startDate).startOf('day'),
                            )
                          }
                          value={moment(formik.values.endDate)}
                          onChange={(d) => {
                            formik.setFieldValue('endDate', d?.toDate());
                          }}
                        />
                      </Box>
                      {!formik.values.isAllDay && (
                        <Box width="160px">
                          <TimePicker
                            date={moment(formik.values.endDate)}
                            onHourChange={(hour) => {
                              const currentDate = moment(formik.values.endDate);
                              currentDate.set('hour', hour);
                              formik.setFieldValue(
                                'endDate',
                                currentDate.toDate(),
                              );
                            }}
                            onMinuteChange={(minute) => {
                              const currentDate = moment(formik.values.endDate);
                              currentDate.set('minute', minute);
                              formik.setFieldValue(
                                'endDate',
                                currentDate.toDate(),
                              );
                            }}
                          />
                        </Box>
                      )}
                    </HStack>
                  </FormControl>
                </HStack>
                <FormControl id="title" isRequired>
                  <FormLabel>Event name</FormLabel>
                  <Input
                    data-testid="calendar-event-form-title"
                    name="title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                  />
                </FormControl>
                <FormControl id="location">
                  <FormLabel>Where</FormLabel>
                  <Input
                    data-testid="calendar-event-form-location"
                    name="location"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                  />
                </FormControl>
                <FormControl id="description">
                  <FormLabel>Description</FormLabel>
                  <Input
                    height="200px"
                    py="10px"
                    as="textarea"
                    data-testid="calendar-event-form-description"
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  />
                </FormControl>
                <FormControl>
                  <HStack>
                    {/* <Menu closeOnSelect={false}>
                      <MenuButton
                        data-testid="calendar-event-add-employees-button"
                        as={Button}
                        rightIcon={<FaChevronDown />}
                      >
                        {!formik.values.attendeeIds.length
                          ? 'Add employees'
                          : `${formik.values.attendeeIds.length} ${pluralize(
                              'Employee',
                              formik.values.attendeeIds.length,
                            )} selected`}
                      </MenuButton>
                      <MenuList data-testid="calendar-event-add-employees-menu">
                        {employees.map((e) => {
                          const isSelected = formik.values.attendeeIds.find(
                            (aid) => aid === e.id,
                          );

                          return (
                            <MenuItem
                              key={e.id}
                              onClick={() => {
                                if (isSelected) {
                                  formik.setFieldValue(
                                    'attendeeIds',
                                    formik.values.attendeeIds.filter(
                                      (aid) => aid !== e.id,
                                    ),
                                  );
                                } else {
                                  formik.setFieldValue('attendeeIds', [
                                    ...formik.values.attendeeIds,
                                    e.id,
                                  ]);
                                }
                              }}
                            >
                              <HStack>
                                {isSelected && <FaCheck color="green.500" />}
                                <Text>
                                  {e.first} {e.last}
                                </Text>
                              </HStack>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu> */}

                    <Menu>
                      <MenuButton
                        data-testid="calendar-event-type-button"
                        as={Button}
                        rightIcon={<FaChevronDown />}
                      >
                        {!activeEventType && (
                          <HStack>
                            <CalendarBubble color="gray.400" />
                            <Text>Type not specified (default)</Text>
                          </HStack>
                        )}

                        {activeEventType && (
                          <HStack>
                            <CalendarBubble color={activeEventType.color} />
                            <Text>{activeEventType.title}</Text>
                          </HStack>
                        )}
                      </MenuButton>
                      <MenuList
                        data-testid="calendar-event-type-menu"
                        height="300px"
                        overflowY="scroll"
                      >
                        <MenuItem
                          onClick={() => {
                            formik.setFieldValue(
                              'calendarEventTypeId',
                              undefined,
                            );
                          }}
                        >
                          <HStack>
                            <CalendarBubble color="gray.400" />
                            <Text>Type not specified (default)</Text>
                          </HStack>
                        </MenuItem>
                        {calendarEventTypes.map((cet) => {
                          return (
                            <MenuItem
                              key={cet.id}
                              onClick={() => {
                                if (
                                  cet.id === formik.values.calendarEventTypeId
                                ) {
                                  formik.setFieldValue(
                                    'calendarEventTypeId',
                                    undefined,
                                  );
                                } else {
                                  formik.setFieldValue(
                                    'calendarEventTypeId',
                                    cet.id,
                                  );
                                }
                              }}
                            >
                              <HStack>
                                <CalendarBubble color={cet.color} />
                                <Text>{cet.title}</Text>
                              </HStack>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </HStack>
                </FormControl>
              </Stack>
              {formik.values.id && (
                <Input
                  data-testid="calendar-event-form-id"
                  name="id"
                  value={formik.values.id}
                  type="hidden"
                />
              )}
            </Box>
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-evenly"
            bg="white"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              isDisabled={!formik.isValid || formik.isSubmitting}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
