import React, { useState } from 'react';
import * as Yup from 'yup';
import { DatePicker } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  CREATE_TOOLBOX_TALK,
  UPDATE_TOOLBOX_TALK,
  DELETE_TOOLBOX_TALK,
} from '../../services/apollo/mutations';
import { weatherOptions } from '../../services/appData';
import { FaTrash } from 'react-icons/fa';

export interface ToolboxTalkFormValues {
  id: number | null;
  removalProjectId: number;
  date: Date;
  weatherId: number;
  plannedActivities: string;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  topicsToCover: string;
  notes: string;
  otherItems: string;
}

export interface ToolboxTalkDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onComplete(): void;
  initialValues: ToolboxTalkFormValues;
}

export const ToolboxTalkDrawer = ({
  isOpen,
  onClose,
  initialValues,
}: ToolboxTalkDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [yesToAll, setYesToAll] = useState(
    initialValues.q1 === 1 &&
      initialValues.q2 === 1 &&
      initialValues.q3 === 1 &&
      initialValues.q4 === 1 &&
      initialValues.q5 === 1 &&
      initialValues.q6 === 1 &&
      initialValues.q7 === 1,
  );

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      removalProjectId: Yup.number().required(),
      date: Yup.date().required(),
      weatherId: Yup.number().required(),
      plannedActivities: Yup.string(),
      q1: Yup.number(),
      q2: Yup.number(),
      q3: Yup.number(),
      q4: Yup.number(),
      q5: Yup.number(),
      q6: Yup.number(),
      q7: Yup.number(),
      topicsToCover: Yup.string(),
      notes: Yup.string(),
      otherItems: Yup.string(),
    }),
    onSubmit: (values: ToolboxTalkFormValues) => {
      if (!values.id) {
        createToolboxTalk({
          variables: {
            ...values,
            weatherId: Number(values.weatherId),
          },
        });
      } else {
        updateToolboxTalk({
          variables: {
            ...values,
            weatherId: Number(values.weatherId),
          },
        });
      }
    },
  });

  const [createToolboxTalk, { loading: isCreateLoading }] = useMutation(
    CREATE_TOOLBOX_TALK,
    {
      onCompleted(d) {
        if (!d || !d.createToolboxTalk || !d.createToolboxTalk.id) return;

        onClose();

        toast({
          title: 'Toolbox talk created',
          description: `Toolbox talk created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateToolboxTalk, { loading: isUpdateLoading }] = useMutation(
    UPDATE_TOOLBOX_TALK,
    {
      onCompleted(d) {
        if (!d || !d.updateToolboxTalk || !d.updateToolboxTalk.id) return;

        onClose();

        toast({
          title: 'Toolbox talk updated',
          description: `Toolbox talk updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteToolboxTalk, { loading: isDeleteLoading }] = useMutation(
    DELETE_TOOLBOX_TALK,
    {
      onCompleted(d) {
        if (!d || !d.deleteToolboxTalk || !d.deleteToolboxTalk.id) return;

        onClose();

        toast({
          title: 'Toolbox talk deleted',
          description: `Toolbox talk deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            pl="40px"
            pr="20px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <DrawerCloseButton
              left="0"
              top="5px"
              data-testid="drawer-close-button"
              onClick={handleClose}
              color="white"
            />
            <Text fontSize="xl">
              {formik.values.id ? `Edit Toolbox Talk` : 'New Toolbox Talk'}
            </Text>

            {formik.values.id && formik.values.id && (
              <IconButton
                onClick={() =>
                  deleteToolboxTalk({
                    variables: {
                      id: formik.values.id,
                    },
                  })
                }
                data-testid="delete-assessor-button"
                aria-label="delete-assessor"
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={onClose}
            color="white"
          />
          <DrawerBody p="20px">
            <Stack spacing={5}>
              <FormControl id="date" data-testid="toolbox-talk-form-date">
                <FormLabel>
                  <HStack justifyContent="space-between">
                    <Text fontSize="lg" fontWeight="bold">
                      Date
                    </Text>
                    <Text>
                      {moment(formik.values.date).format('D MMMM, YYYY')}
                    </Text>
                  </HStack>
                </FormLabel>

                <Box width="300px">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={moment(formik.values.date).clone()}
                    onChange={(d) => {
                      formik.setFieldValue('date', d?.toDate());
                    }}
                  />
                </Box>
              </FormControl>

              <FormControl id="weather">
                <FormLabel>Weather</FormLabel>
                <Select
                  data-testid="toolbox-talk-form-weather"
                  name="weatherId"
                  placeholder="Select weather type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.weatherId}
                >
                  {weatherOptions.map((t) => (
                    <option key={t.label} value={t.value}>
                      {t.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="plannedActivities">
                <FormLabel>Planned activities</FormLabel>
                <Input
                  minHeight="150px"
                  as="textarea"
                  data-testid="toolbox-talk-form-planned-activities"
                  name="plannedActivities"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.plannedActivities}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="q1" mb="0">
                  Were the previous day's activities issue free?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q1"
                  name="q1"
                  data-testid="toolbox-talk-form-q1"
                  isChecked={formik.values.q1 === 1}
                  onChange={(e) => {
                    if (formik.values.q1 === 0) {
                      formik.setFieldValue('q1', 1);
                    } else {
                      formik.setFieldValue('q1', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <FormLabel htmlFor="q2" mb="0">
                  Assessment of asbestos work area and the asbestos removal site
                  complete?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q2"
                  name="q2"
                  data-testid="toolbox-talk-form-q2"
                  isChecked={formik.values.q2 === 1}
                  onChange={(e) => {
                    if (formik.values.q2 === 0) {
                      formik.setFieldValue('q2', 1);
                    } else {
                      formik.setFieldValue('q2', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="q3" mb="0">
                  Work area safe to commence works?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q3"
                  name="q3"
                  data-testid="toolbox-talk-form-q3"
                  isChecked={formik.values.q3 === 1}
                  onChange={(e) => {
                    if (formik.values.q3 === 0) {
                      formik.setFieldValue('q3', 1);
                    } else {
                      formik.setFieldValue('q3', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <FormLabel htmlFor="q4" mb="0">
                  Workers adequately trained to complete tasks
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q4"
                  name="q4"
                  data-testid="toolbox-talk-form-q4"
                  isChecked={formik.values.q4 === 1}
                  onChange={(e) => {
                    if (formik.values.q4 === 0) {
                      formik.setFieldValue('q4', 1);
                    } else {
                      formik.setFieldValue('q4', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="q5" mb="0">
                  All workers have suitable PPE for all tasks to be performed?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q5"
                  name="q5"
                  data-testid="toolbox-talk-form-q5"
                  isChecked={formik.values.q5 === 1}
                  onChange={(e) => {
                    if (formik.values.q5 === 0) {
                      formik.setFieldValue('q5', 1);
                    } else {
                      formik.setFieldValue('q5', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <FormLabel htmlFor="q6" mb="0">
                  Weather conditions appropriate for commencement of works?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q6"
                  name="q6"
                  data-testid="toolbox-talk-form-q6"
                  isChecked={formik.values.q6 === 1}
                  onChange={(e) => {
                    if (formik.values.q6 === 0) {
                      formik.setFieldValue('q6', 1);
                    } else {
                      formik.setFieldValue('q6', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="q7" mb="0">
                  All workers instructed in relation to today's work
                  requirements?
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="q7"
                  name="q7"
                  data-testid="toolbox-talk-form-q7"
                  isChecked={formik.values.q7 === 1}
                  onChange={(e) => {
                    if (formik.values.q7 === 0) {
                      formik.setFieldValue('q7', 1);
                    } else {
                      formik.setFieldValue('q7', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <FormLabel htmlFor="q7" mb="0"></FormLabel>
                <Switch
                  colorScheme="green"
                  id="yes-to-all"
                  data-testid="toolbox-talk-form-yes-to-all"
                  isChecked={yesToAll}
                  onChange={(e) => {
                    if (yesToAll) {
                      setYesToAll(false);
                      formik.setFieldValue('q1', 0);
                      formik.setFieldValue('q2', 0);
                      formik.setFieldValue('q3', 0);
                      formik.setFieldValue('q4', 0);
                      formik.setFieldValue('q5', 0);
                      formik.setFieldValue('q6', 0);
                      formik.setFieldValue('q7', 0);
                    } else {
                      setYesToAll(true);
                      formik.setFieldValue('q1', 1);
                      formik.setFieldValue('q2', 1);
                      formik.setFieldValue('q3', 1);
                      formik.setFieldValue('q4', 1);
                      formik.setFieldValue('q5', 1);
                      formik.setFieldValue('q6', 1);
                      formik.setFieldValue('q7', 1);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl id="topicsToCover">
                <FormLabel>Topics to cover</FormLabel>
                <Input
                  minHeight="150px"
                  as="textarea"
                  data-testid="toolbox-talk-form-topics-to-cover"
                  name="topicsToCover"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.topicsToCover}
                />
              </FormControl>

              <FormControl id="notes">
                <FormLabel>Notes</FormLabel>
                <Input
                  minHeight="150px"
                  as="textarea"
                  data-testid="toolbox-talk-form-notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                />
              </FormControl>

              <FormControl id="otherItems">
                <FormLabel>Other itmes</FormLabel>
                <Input
                  minHeight="150px"
                  as="textarea"
                  data-testid="toolbox-talk-form-other-items"
                  name="otherItems"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherItems}
                />
              </FormControl>

              {formik.values.id && (
                <Input
                  data-testid="toolbox-talk-form-id"
                  name="id"
                  value={formik.values.id}
                  type="hidden"
                />
              )}

              <Input
                data-testid="toolbox-talk-form-removal-project-id"
                name="id"
                value={formik.values.removalProjectId}
                type="hidden"
              />
            </Stack>
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-evenly"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={
                !formik.isValid ||
                formik.isSubmitting ||
                (Boolean(formik.values.id) && !formik.dirty)
              }
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
