import React, { useState } from 'react';
import numeral from 'numeral';
import {
  Box,
  Button,
  Stack,
  useDisclosure,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { SupervisorInterface } from 'jesco-web';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';

import { GET_SUPERVISORS_QUERY } from '../services/apollo/queries';
import { SupervisorTableRow } from '../containers/Supervisor/SupervisorTableRow';
import { useAuthContext } from '../providers/Auth.Provider';
import { SupervisorDrawer } from '../containers/Supervisor/SupervisorDrawer';

export const SupervisorsPage = () => {
  const { me } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [supervisor, setSupervisor] = useState<SupervisorInterface | null>(
    null,
  );
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch, loading } = useQuery<{
    supervisors: {
      items: SupervisorInterface[];
      count: number;
    };
  }>(GET_SUPERVISORS_QUERY, {
    variables: filter,
  });

  let supervisors: SupervisorInterface[] = [];
  let count = 0;

  if (data) {
    const {
      supervisors: { count: total, items },
    } = data;

    count = total;
    supervisors = items;
  }

  const renderRow = ({
    item,
    index,
  }: {
    item: SupervisorInterface;
    index: number;
  }) => {
    return (
      <SupervisorTableRow
        canClick={Boolean(me?.Permissions?.can_edit_supervisors)}
        key={String(item.id)}
        isStriped={index % 2 === 1}
        supervisor={item}
        onRowClick={() => {
          onOpen();
          setSupervisor(item);
        }}
      />
    );
  };

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  return (
    <>
      <Box width="100%" pt="10px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary">
            Supervisors
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>
            {Boolean(me?.Permissions?.can_edit_supervisors) && (
              <Button
                colorScheme="green"
                variant="ghost"
                color="green.500"
                data-testid="add-supervisor-button"
                onClick={() => {
                  setSupervisor(null);
                  onOpen();
                }}
                size="md"
              >
                Add supervisor
              </Button>
            )}
          </Stack>
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <Table
          isLoading={loading}
          dataTestId="supervisors-table"
          onChangeFilter={(newFilter) =>
            setFilter({
              ...filter,
              ...newFilter,
            })
          }
          headers={[
            {
              slug: 'name',
              title: 'Name',
              width: '35%',
              'data-testid': 'supervisor-name-header',
            },
            {
              title: 'Phone',
              width: '35%',
            },
          ]}
          data={supervisors || []}
          renderItem={renderRow}
          filter={filter}
        />
      </Box>

      <SupervisorDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        onComplete={() => {
          refetch();
        }}
        initialValues={{
          id: supervisor ? Number(supervisor.id) : null,
          name: supervisor ? supervisor.name : '',
          phone: supervisor ? String(supervisor.phone) : '',
        }}
      />
    </>
  );
};
