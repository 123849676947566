import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export interface CalendarBubbleProps extends BoxProps {
  color: string;
}

export const CalendarBubble = ({ color, ...props }: CalendarBubbleProps) => {
  return (
    <Box height="10px" width="10px" rounded="100%" bg={color} {...props} />
  );
};
