export const weatherOptions = [
  {
    label: 'Clear',
    value: 1,
  },
  {
    label: 'Cold',
    value: 2,
  },
  {
    label: 'Hail',
    value: 3,
  },
  {
    label: 'Hot',
    value: 4,
  },
  {
    label: 'Overcast',
    value: 5,
  },
  {
    label: 'Rain',
    value: 6,
  },
  {
    label: 'Snow',
    value: 7,
  },
];
