import React, { useState } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import {
  Box,
  Button,
  Text,
  Stack,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { RemovalProjectInterface, StateInterface } from 'jesco-web';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import { GET_REMOVAL_PROJECTS_QUERY } from '../services/apollo/queries';
import { RemovalProjectTableRow } from '../containers/RemovalProject/RemovalProjectTableRow';

import { RemovalProjectDrawer } from '../containers/RemovalProject/RemovalProjectDrawer';
import { useAuthContext } from '../providers/Auth.Provider';

export interface RemovalProjectsPageProps extends RouteComponentProps {
  states: StateInterface[];
}

export const RemovalProjectsPage = withRouter(
  ({ history: { push }, states }: RemovalProjectsPageProps) => {
    const { me } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filter, setFilter] = useState<TableFilterInterface>({
      orderBy: 'id',
      orderByDirection: 'desc',
      search: '',
      page: 1,
      perPage: 40,
    });
    const { data, refetch, loading } = useQuery<{
      removalProjects: {
        count: number;
        items: RemovalProjectInterface[];
      };
    }>(GET_REMOVAL_PROJECTS_QUERY, {
      variables: filter,
      fetchPolicy: 'network-only',
    });

    let removalProjects: RemovalProjectInterface[] = [];
    let count = 0;

    if (data) {
      const {
        removalProjects: { count: total, items: projects },
      } = data;
      count = total;
      removalProjects = projects;
    }

    const renderRow = ({
      item,
      index,
    }: {
      item: RemovalProjectInterface;
      index: number;
    }) => {
      return (
        <RemovalProjectTableRow
          key={String(item.id)}
          isStriped={index % 2 === 1}
          removalProject={item}
          onRowClick={() => push(`/dashboard/removal-project/${item.id}`)}
        />
      );
    };

    const handleRefetch = (newFilter: TableFilterInterface) => {
      setFilter(newFilter);
      refetch(newFilter);
    };

    return (
      <>
        <Box p={['5px', '10px', '20px']} maxWidth="1400px">
          <Stack
            mb="20px"
            direction={['column', 'row', 'row']}
            justify={['center', 'space-between', 'space-between']}
            align="center"
          >
            <Header fontSize="3xl" color="textSecondary" mb="10px">
              Removal projects
            </Header>

            <Stack
              direction={['column-reverse', 'row', 'row']}
              justify="center"
              width={['100%', 'initial', 'initial']}
            >
              <Box width={['100%', '200px', '250px']}>
                <SearchInput
                  placeholder="Address search"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      search: e.target.value || '',
                    });
                  }}
                  onClear={() => {
                    setFilter({
                      ...filter,
                      search: '',
                    });
                  }}
                  value={filter.search}
                />
              </Box>

              {Boolean(me?.Permissions?.can_edit_removal_projects) && (
                <Button
                  data-testid="new-project-button"
                  colorScheme="green"
                  variant="ghost"
                  color="green.500"
                  size="md"
                  onClick={onOpen}
                >
                  New project
                </Button>
              )}
            </Stack>
          </Stack>

          <HStack mb="20px" justify="space-between">
            <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
              {`${numeral(count).format('0,0')} results`}
            </Text>

            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          </HStack>

          <Table
            isLoading={loading}
            dataTestId="removal-projects-table"
            onChangeFilter={(newFilter) =>
              handleRefetch({
                ...filter,
                ...newFilter,
                page: 1,
              })
            }
            headers={[
              {
                title: 'Site address',
                width: '40%',
              },
              {
                slug: 'client',
                title: 'Client',
                width: '25%',
                'data-testid': 'client-header',
              },
              {
                slug: 'startDate',
                title: 'Commencement',
                width: '20%',
                'data-testid': 'commencement-header',
              },
              {
                slug: 'endDate',
                title: 'Completion',
                width: '20%',
                'data-testid': 'completion-header',
              },
            ]}
            data={removalProjects || []}
            renderItem={renderRow}
            filter={filter}
          />
        </Box>

        <RemovalProjectDrawer
          isOpen={isOpen}
          onClose={onClose}
          states={states}
          initialValues={{
            assessorId: undefined,
            address: '',
            city: '',
            clientId: undefined,
            stateId: undefined,
            notes: undefined,
            endDate: moment().toDate(),
            quantity: 1,
            startDate: moment().toDate(),
            supervisorId: undefined,
            worksafeNo: '',
            id: undefined,
          }}
        />
      </>
    );
  },
);
