import React from 'react';
import { FileInterface } from 'jesco-web';
import { Text, IconButton } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';
import { FaTrash } from 'react-icons/fa';

export interface FileRowInterface extends TableRowPropsInterface {
  file: FileInterface;
  onRowClick(file: FileInterface): void;
  onDeleteFileClick(file: FileInterface): void;
  canClick: boolean;
  canDelete: boolean;
  isLoading: boolean;
}

export const FileTableRow = ({
  file,
  onRowClick,
  onDeleteFileClick,
  canDelete,
  canClick,
  isLoading,
  ...props
}: FileRowInterface) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(file);
    }
  };

  const handleDeleteFile = () => {
    if (onDeleteFileClick) {
      onDeleteFileClick(file);
    }
  };

  return (
    <TableRow
      data-testid="file-table-row"
      className={canClick ? 'clickable' : 'not-clickable'}
      onClick={canClick ? handleClick : undefined}
      {...props}
    >
      <TableDiv p="5px" data-testid="file-name-td">
        <Text wordBreak="break-word">{file.name}</Text>
      </TableDiv>

      <TableDiv p="5px" data-testid="file-file-type-td">
        <Text wordBreak="break-word">{file.type}</Text>
      </TableDiv>

      <TableDiv p="5px">
        <IconButton
          aria-label="delete-file"
          data-testid="delete-file"
          isLoading={isLoading}
          icon={<FaTrash />}
          size="sm"
          isDisabled={!canDelete}
          onClick={(e) => {
            e.stopPropagation();

            handleDeleteFile();
          }}
          variant="ghost"
          colorScheme="red"
        />
      </TableDiv>
    </TableRow>
  );
};
