import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from '../providers/Auth.Provider';

export interface AuthenticationGuardInterface {
  children: React.ReactElement;
}

export const AuthenticationGuard = ({
  children,
}: AuthenticationGuardInterface) => {
  const { token } = useAuthContext();

  if (!token) {
    return <Redirect to="/home" />;
  }

  return children;
};
