import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker, Divider } from 'antd';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  CREATE_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
} from '../../services/apollo/mutations';
import { QuoteInterface, StateInterface } from 'jesco-web';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { TimePicker } from '../../containers/TimePicker';

export interface QuoteFormValues {
  id?: number;
  date: Date;
  endDate: Date;
  street: string;
  city: string;
  zip: string;
  name: string;
  notes: string;
  stateId: number | undefined;
}

export interface QuoteDrawerProps extends RouteComponentProps {
  initialValues: QuoteFormValues;
  isOpen: boolean;
  onClose(): void;
  states: StateInterface[];
  quote?: QuoteInterface;
  onComplete?(): void;
}

export const QuoteDrawer = withRouter(
  ({
    history: { push },
    initialValues,
    isOpen,
    onClose,
    states,
    onComplete,
  }: QuoteDrawerProps) => {
    const toast = useToast({ position: 'bottom-left' });
    const [createQuote, { loading: isCreateLoading }] = useMutation<{
      createQuote: { id: number };
    }>(CREATE_QUOTE, {
      onCompleted(d) {
        if (!d || !d.createQuote || !Boolean(d.createQuote.id)) return;

        onClose();
        toast({
          title: 'Quote create',
          description: `Quote created for ${createQuote.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        push(`/dashboard/quote/${d.createQuote.id}`);
      },
    });

    const [updateQuote, { loading: isUpdateLoading }] = useMutation<{
      updateQuote: { status: boolean };
    }>(UPDATE_QUOTE, {
      onCompleted(d) {
        if (!d || !d.updateQuote || !d.updateQuote.status) return;

        onClose();
        if (onComplete) {
          onComplete();
        }

        formik.resetForm();

        toast({
          title: 'Quote updated',
          description: `Quote updated for ${updateQuote.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    });

    const [deleteQuote, { loading: isDeleteLoading }] = useMutation(
      DELETE_QUOTE,
      {
        onCompleted(d) {
          if (!d || !d.deleteQuote || !d.deleteQuote.status) return;

          onClose();

          toast({
            title: 'Quote deleted',
            description: `Quote deleted`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          push(`/dashboard/quotes`);
        },
      },
    );

    const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

    const formik = useFormik({
      isInitialValid: false,
      enableReinitialize: true,
      initialValues,
      validationSchema: Yup.object().shape({
        date: Yup.date().required(),
        endDate: Yup.date().required(),
        street: Yup.string().min(1).required('Address is required'),
        city: Yup.string().min(1).required('City is required'),
        zip: Yup.string().nullable(),
        notes: Yup.string().nullable(),
        name: Yup.string().required('Client name is required'),
        stateId: Yup.string().required(),
      }),
      onSubmit: (values) => {
        if (!values.id) {
          createQuote({
            variables: {
              ...values,
              date: moment(values.date).format('YYYY-MM-DD HH:mm'),
              endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm'),
              street: values.street,
              city: values.city,
              zip: Number(values.zip),
              name: values.name,
              notes: values.notes,
              stateId: Number(values.stateId),
            },
          });
        } else {
          updateQuote({
            variables: {
              ...values,
              date: moment(values.date).format('YYYY-MM-DD'),
              endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm'),
              street: values.street,
              city: values.city,
              zip: Number(values.zip),
              name: values.name,
              notes: values.notes,
              stateId: Number(values.stateId),
            },
          });
        }
      },
    });

    const handleClose = () => {
      onClose();
      formik.resetForm();
    };

    return (
      <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <form onSubmit={formik.handleSubmit} noValidate>
          <DrawerContent rounded="md">
            <DrawerHeader
              pl="40px"
              pr="20px"
              display="flex"
              fontSize="md"
              color="white"
              bg="blue.500"
              justifyContent="space-between"
              alignItems="center"
            >
              <DrawerCloseButton
                left="0"
                top="5px"
                data-testid="drawer-close-button"
                onClick={handleClose}
                color="white"
              />
              <Text fontSize="xl">
                {formik.values.id ? `Edit Quote` : 'New Quote'}
              </Text>

              {formik.values.id && formik.values.id && (
                <IconButton
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      deleteQuote({
                        variables: {
                          id: formik.values.id,
                        },
                      });
                    }
                  }}
                  data-testid="delete-quote -button"
                  aria-label="delete-quote"
                  colorScheme="red"
                  size="sm"
                  icon={<FaTrash />}
                />
              )}
            </DrawerHeader>

            <DrawerBody bg="white" p="20px">
              <Stack spacing={15}>
                <FormControl
                  id="name"
                  isInvalid={Boolean(formik.errors.name)}
                  isRequired
                >
                  <FormLabel>Client name</FormLabel>
                  <Input
                    data-testid="quote-form-name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                <Divider />

                <FormControl
                  id="address"
                  isInvalid={Boolean(formik.errors.street)}
                  isRequired
                >
                  <FormLabel>Street address</FormLabel>
                  <Input
                    data-testid="quote-form-street"
                    name="street"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                  />
                  <FormErrorMessage>{formik.errors.street}</FormErrorMessage>
                </FormControl>

                <Stack
                  justify="space-between"
                  direction={['column', 'column', 'row']}
                >
                  <FormControl
                    id="city"
                    isInvalid={Boolean(formik.errors.city)}
                    isRequired
                  >
                    <FormLabel>City</FormLabel>
                    <Input
                      data-testid="quote-form-city"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                    <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    id="state"
                    isInvalid={Boolean(formik.errors.stateId)}
                    isRequired
                  >
                    <FormLabel>State</FormLabel>
                    <Select
                      data-testid="quote-form-state"
                      name="stateId"
                      placeholder="Select a state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.stateId}
                    >
                      {states.map((s) => (
                        <option key={s.id} value={s.id}>
                          {s.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <FormControl id="date" data-testid="quote-form-date">
                  <FormLabel>
                    <HStack justifyContent="space-between">
                      <Text fontSize="lg">Date</Text>
                    </HStack>
                  </FormLabel>

                  <HStack spacing="15px">
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={moment(formik.values.date).clone()}
                      onChange={(d) => {
                        formik.setFieldValue('date', d?.toDate());
                        formik.setFieldValue('endDate', d?.toDate());
                      }}
                    />
                    <TimePicker
                      date={moment(formik.values.date)}
                      onHourChange={(hour) => {
                        const currentDate = moment(formik.values.date);
                        currentDate.set('hour', hour);
                        formik.setFieldValue('date', currentDate.toDate());
                      }}
                      onMinuteChange={(minute) => {
                        const currentDate = moment(formik.values.date);
                        currentDate.set('minute', minute);
                        formik.setFieldValue('date', currentDate.toDate());
                      }}
                    />

                    <Text>To</Text>

                    <TimePicker
                      date={moment(formik.values.endDate)}
                      onHourChange={(hour) => {
                        const currentDate = moment(formik.values.endDate);
                        currentDate.set('hour', hour);
                        formik.setFieldValue('endDate', currentDate.toDate());
                      }}
                      onMinuteChange={(minute) => {
                        const currentDate = moment(formik.values.endDate);
                        currentDate.set('minute', minute);
                        formik.setFieldValue('endDate', currentDate.toDate());
                      }}
                    />
                  </HStack>
                </FormControl>
                <FormControl id="notes">
                  <FormLabel>Notes</FormLabel>
                  <Input
                    height="150px"
                    py="10px"
                    as="textarea"
                    data-testid="quote-form-notes"
                    name="notes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                </FormControl>
              </Stack>
            </DrawerBody>

            <DrawerFooter
              justifyContent="space-evenly"
              borderTop="1px solid"
              borderColor="gray.300"
            >
              <Button width="200px" size="md" mr="5px" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                width="200px"
                ml="5px"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={!formik.isValid || formik.isSubmitting}
                isLoading={isLoading}
              >
                {formik.values.id ? 'Update' : 'Create'}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    );
  },
);
