import * as React from 'react';
import { SomethingWentWrongMessage } from './SomethingWentWrongMessage';

export interface ErrorBoundaryInterface {
  children: any;
  logError: any;
}

export interface ErrorBoundaryState {
  hasError: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryInterface,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const { logError } = this.props;

    logError(error, errorInfo);

    this.setState({
      hasError: true,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <SomethingWentWrongMessage />;
    }

    return children;
  }
}
