import React from 'react';
import moment, { Moment } from 'moment';
import {
  Box,
  Stack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  HStack,
  Text,
} from '@chakra-ui/react';

export interface TimePickerProps {
  date: Moment;
  onHourChange(hour: number): void;
  onMinuteChange(minute: number): void;
}
export const TimePicker = ({
  date,
  onHourChange,
  onMinuteChange,
}: TimePickerProps) => {
  const hour = date.get('hour');

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button>{moment(date).format('hh:mm A')}</Button>
      </PopoverTrigger>
      <PopoverContent width="375px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Select a time</PopoverHeader>
        <PopoverBody>
          <Stack>
            <Box>
              <Text fontWeight="bold">Hour</Text>
              <HStack spacing="0">
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(12);
                    } else {
                      onHourChange(0);
                    }
                  }}
                  isActive={hour === 12 || hour === 0}
                >
                  12
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(13);
                    } else {
                      onHourChange(1);
                    }
                  }}
                  isActive={hour === 1 || hour === 13}
                >
                  1
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(14);
                    } else {
                      onHourChange(2);
                    }
                  }}
                  isActive={hour === 2 || hour === 14}
                >
                  2
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(15);
                    } else {
                      onHourChange(3);
                    }
                  }}
                  isActive={hour === 3 || hour === 15}
                >
                  3
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(16);
                    } else {
                      onHourChange(4);
                    }
                  }}
                  isActive={hour === 4 || hour === 16}
                >
                  4
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(17);
                    } else {
                      onHourChange(5);
                    }
                  }}
                  isActive={hour === 5 || hour === 17}
                >
                  5
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(18);
                    } else {
                      onHourChange(6);
                    }
                  }}
                  isActive={hour === 6 || hour === 18}
                >
                  6
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(19);
                    } else {
                      onHourChange(7);
                    }
                  }}
                  isActive={hour === 7 || hour === 19}
                >
                  7
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(20);
                    } else {
                      onHourChange(8);
                    }
                  }}
                  isActive={hour === 8 || hour === 20}
                >
                  8
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(21);
                    } else {
                      onHourChange(9);
                    }
                  }}
                  isActive={hour === 9 || hour === 21}
                >
                  9
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(22);
                    } else {
                      onHourChange(10);
                    }
                  }}
                  isActive={hour === 10 || hour === 22}
                >
                  10
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => {
                    if (hour >= 12) {
                      onHourChange(23);
                    } else {
                      onHourChange(11);
                    }
                  }}
                  isActive={hour === 11 || hour === 23}
                >
                  11
                </Button>
              </HStack>
            </Box>
            <Stack>
              <Text fontWeight="bold">Minute</Text>
              <HStack spacing="0">
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(0)}
                  isActive={date.get('minutes') === 0}
                >
                  0
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(5)}
                  isActive={date.get('minutes') === 5}
                >
                  5
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(10)}
                  isActive={date.get('minutes') === 10}
                >
                  10
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(15)}
                  isActive={date.get('minutes') === 15}
                >
                  15
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(20)}
                  isActive={date.get('minutes') === 20}
                >
                  20
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(25)}
                  isActive={date.get('minutes') === 25}
                >
                  25
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(30)}
                  isActive={date.get('minutes') === 30}
                >
                  30
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(35)}
                  isActive={date.get('minutes') === 35}
                >
                  35
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(40)}
                  isActive={date.get('minutes') === 40}
                >
                  40
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(45)}
                  isActive={date.get('minutes') === 45}
                >
                  45
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(50)}
                  isActive={date.get('minutes') === 50}
                >
                  50
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  rounded="100%"
                  size="sm"
                  onClick={() => onMinuteChange(55)}
                  isActive={date.get('minutes') === 55}
                >
                  55
                </Button>
              </HStack>
            </Stack>

            <HStack justify="center">
              <Button
                variant="ghost"
                colorScheme="green"
                rounded="100%"
                size="sm"
                onClick={() => {
                  if (hour >= 12) {
                    onHourChange(hour - 12);
                  } else {
                    onHourChange(hour);
                  }
                }}
                isActive={hour < 12}
              >
                AM
              </Button>
              <Button
                variant="ghost"
                colorScheme="green"
                rounded="100%"
                size="sm"
                onClick={() => {
                  if (hour < 12) {
                    onHourChange(hour + 12);
                  } else {
                    onHourChange(hour);
                  }
                }}
                isActive={hour >= 12}
              >
                PM
              </Button>
            </HStack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
