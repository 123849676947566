import React, { useState } from 'react';
import {
  Button,
  Stack,
  IconButton,
  Text,
  HStack,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  useDisclosure,
  DrawerFooter,
} from '@chakra-ui/react';
import { RemovalProjectInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import { Header } from '../../containers/Text/Header';
import { Section } from '../../containers/Section';
import {
  DELETE_FILE,
  GET_SIGNED_URL,
  UPLOAD_REMOVAL_PROJECT_FILE,
} from '../../services/apollo/mutations';
import { FaEye, FaTimes, FaPlus } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { useAuthContext } from '../../providers/Auth.Provider';
import axios from 'axios';

export interface RemovalProjectUploadsProps {
  onComplete(): void;
  removalProject: RemovalProjectInterface;
}

export const RemovalProjectUploads = ({
  onComplete,
  removalProject,
}: RemovalProjectUploadsProps) => {
  const { me } = useAuthContext();
  const toast = useToast({ position: 'bottom-left' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<File>();
  const [deleteFile, { loading: deleteFileLoading }] = useMutation(
    DELETE_FILE,
    {
      onCompleted(d) {
        if (!d || !d.deleteFile || !d.deleteFile.id) return;

        onClose();
        onComplete();

        toast({
          title: 'File deleted',
          description: `${d.deleteFile.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [uploadRemovalProjectFile, { loading }] = useMutation(
    UPLOAD_REMOVAL_PROJECT_FILE,
    {
      onCompleted(d) {
        if (!d || !d.uploadRemovalProjectFile || !d.uploadRemovalProjectFile.id)
          return;

        setFile(undefined);
        onClose();
        onComplete();

        toast({
          title: 'File uploaded',
          description: `File uploaded`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const handleSubmit = () => {
    if (!file) return;

    getSignedUrl({
      variables: {
        file: file.name,
        type: file.type,
        folder: 'uploads',
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setFile(acceptedFiles[0]);
      }
    },
    maxFiles: 1,
  });

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onCompleted: async (data) => {
      if (data.getSignedUrl && file) {
        const signedUrl = data.getSignedUrl;
        await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        toast({
          title: 'File uploaded',
          description: 'File successfully uploaded',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // drop the query params from the signed URL
        const path = signedUrl.split('?')[0];

        await uploadRemovalProjectFile({
          variables: {
            path,
            name: file.name,
            mimetype: file.type,
            removalProjectId: removalProject.id,
          },
        });
      }
    },
    onError: (error) => {
      toast({
        title: 'Upload failed',
        description: 'The file could not be uploaded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Failed to upload file:', error);
    },
  });

  return (
    <>
      <Section
        alignSelf="flex-start"
        maxWidth={['auto', 'auto', '500px']}
        data-testid="removal-project-upload-section"
      >
        <HStack justify="space-between" mb="10px">
          <Header fontSize="lg" color="textSecondary">
            Uploads
          </Header>
          <IconButton
            data-testid="add-removal-project-upload"
            aria-label="add-removal-project-upload"
            size="sm"
            variant="outline"
            onClick={onOpen}
            icon={<FaPlus />}
          />
        </HStack>
        {!removalProject.Uploads.length && (
          <Section>
            <Text fontSize="md" textAlign="center">
              No uploads
            </Text>
          </Section>
        )}

        {removalProject.Uploads.map((td) => (
          <React.Fragment key={td.id}>
            <HStack
              justify="space-between"
              data-testid="removal-project-upload-row"
            >
              <Stack spacing={1}>
                <Text fontSize="md">{`${td.name}`}</Text>
                <Text>{td.type}</Text>
              </Stack>
              <HStack>
                <IconButton
                  isDisabled={
                    !Boolean(me?.Permissions?.can_edit_removal_projects)
                  }
                  isLoading={deleteFileLoading}
                  data-testid="remove-upload-button"
                  aria-label="remove-project"
                  variant="outline"
                  icon={<FaTimes />}
                  size="sm"
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      deleteFile({
                        variables: {
                          id: td.id,
                        },
                      });
                    }
                  }}
                />
                <IconButton
                  aria-label="remove-project-upload"
                  isDisabled={
                    !Boolean(me?.Permissions?.can_edit_removal_projects)
                  }
                  isLoading={deleteFileLoading}
                  variant="outline"
                  icon={<FaEye />}
                  size="sm"
                  onClick={() => {
                    window.open(td.path, '_blank');
                  }}
                />
              </HStack>
            </HStack>
            <Divider />
          </React.Fragment>
        ))}
      </Section>

      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent rounded="md">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
          >
            Upload file
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={onClose}
            color="white"
          />
          <DrawerBody p="20px" data-testid="drawer">
            <Stack>
              {!file && (
                <Stack
                  {...getRootProps()}
                  bg="#eee"
                  height="150px"
                  rounded="md"
                  alignItems="center"
                  justifyContent="center"
                >
                  <input
                    data-testid="removal-project-upload"
                    {...getInputProps()}
                  />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>Drag and drop a file here, or click to select files</p>
                  )}
                </Stack>
              )}

              {file && (
                <HStack bg="gray.50" p="10px" justify="space-between">
                  <Text>
                    <Text fontWeight="bold" as="span">
                      {file.name}
                    </Text>
                    <Text as="span" ml="10px">
                      {file.type}
                    </Text>
                  </Text>
                  <IconButton
                    aria-label="cancel-upload"
                    icon={<FaTimes />}
                    size="sm"
                    onClick={() => setFile(undefined)}
                    variant="outline"
                    colorScheme="gray"
                  />
                </HStack>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-evenly"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button
              width="200px"
              size="md"
              mr="5px"
              onClick={onClose}
              isLoading={loading}
            >
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              onClick={handleSubmit}
              variant="solid"
              colorScheme="blue"
              disabled={!Boolean(file)}
              isLoading={loading}
            >
              Upload
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
