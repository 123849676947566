import React from 'react';
import * as Yup from 'yup';
import {
  Button,
  useDisclosure,
  useToast,
  FormControl,
  Text,
  Input,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { QuoteInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import { UPDATE_QUOTE_NOTES } from '../../services/apollo/mutations';

export interface QuoteNotesProps {
  quote: QuoteInterface;
  onComplete(): void;
}

export const QuoteNotes = ({ quote, onComplete }: QuoteNotesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ position: 'bottom-left' });

  const [updateQuoteNotes, { loading: isLoading }] = useMutation(
    UPDATE_QUOTE_NOTES,
    {
      onCompleted(d) {
        if (!d || !d.updateQuoteNotes || !d.updateQuoteNotes.status) return;

        onClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Notes updated',
          description: `Notes updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues: {
      id: quote.id,
      notes: quote.notes,
    },
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      updateQuoteNotes({
        variables: values,
      });
    },
  });

  return (
    <Stack>
      {!Boolean(quote.notes) && !isOpen && (
        <Button
          variant="link"
          onClick={onOpen}
          alignSelf="flex-start"
          colorScheme="green"
          isDisabled={isLoading}
        >
          Add notes
        </Button>
      )}

      {(Boolean(quote.notes) || isOpen) && (
        <HStack>
          <Button
            fontSize="md"
            variant="link"
            colorScheme="green"
            onClick={onOpen}
            isDisabled={isLoading}
          >
            Notes
          </Button>
        </HStack>
      )}

      {Boolean(quote.notes) && !isOpen && (
        <Stack spacing="5px">
          {quote.notes.split('\n').map((line) => (
            <Text key={line} fontSize="md">
              {line}
            </Text>
          ))}
        </Stack>
      )}

      {isOpen && (
        <form onSubmit={formik.handleSubmit}>
          <FormControl id="location">
            <Input
              autoFocus
              height="150px"
              py="10px"
              as="textarea"
              data-testid="quote-notes-form-notes"
              name="notes"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
            />
          </FormControl>
          <HStack justify="flex-end">
            <Button
              isLoading={isLoading}
              onClick={() => {
                formik.resetForm();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" colorScheme="green" isLoading={isLoading}>
              Update
            </Button>
          </HStack>
        </form>
      )}
    </Stack>
  );
};
