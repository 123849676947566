import React from 'react';
import { SupervisorInterface } from 'jesco-web';
import { Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';

export interface SupervisorRowInterface extends TableRowPropsInterface {
  supervisor: SupervisorInterface;
  onRowClick(supervisor: SupervisorInterface): void;
  canClick: boolean;
}

export const SupervisorTableRow = ({
  supervisor,
  onRowClick,
  canClick,
  ...props
}: SupervisorRowInterface) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(supervisor);
    }
  };

  return (
    <TableRow
      data-testid="supervisor-table-row"
      onClick={canClick ? handleClick : undefined}
      {...props}
    >
      <TableDiv px="5px" py="10px" data-testid="supervisor-name-td">
        <Text wordBreak="break-word">{supervisor.name}</Text>
      </TableDiv>

      <TableDiv px="5px" py="10px" data-testid="supervisor-email-td">
        <Text wordBreak="break-word">{supervisor.phone}</Text>
      </TableDiv>
    </TableRow>
  );
};
