import React from 'react';
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Divider,
  useDisclosure,
  Box,
  Stack,
} from '@chakra-ui/react';
import { UserInterface } from 'jesco-web';
import { FaCog } from 'react-icons/fa';
import { RemovalOptionPanel } from './RemovalOptionPanel';

export interface RemovalOptionEditButtonProps {
  me: UserInterface;
  onClose?: () => void;
}

export const RemovalOptionEditButton = ({
  me,
  onClose,
}: RemovalOptionEditButtonProps) => {
  const { isOpen, onOpen, onClose: closeDrawer } = useDisclosure();

  const handleClose = () => {
    closeDrawer();
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <IconButton
        isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
        onClick={onOpen}
        variant="transparent"
        data-testid="removal-areas-edit"
        aria-label="removal-areas-edit"
        colorScheme="gray"
        color="gray.500"
        size="md"
        icon={<FaCog />}
      />

      <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent bg="dark" rounded="md">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
          >
            Edit removal options
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody p={0}>
            <Box bg="white" width="100%" p="20px" data-testid="drawer">
              <Stack spacing={10}>
                <RemovalOptionPanel type="area" />
                <Divider />
                <RemovalOptionPanel type="location" />
                <Divider />
                <RemovalOptionPanel type="material" />
                <Divider />
                <RemovalOptionPanel type="type" />
                <Divider />
                <RemovalOptionPanel type="condition" />
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
