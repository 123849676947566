import React from 'react';
import moment from 'moment';
import { SignatureInterface } from 'jesco-web';
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Image,
} from '@chakra-ui/react';

export interface SignaturesTableProps {
  signatures: SignatureInterface[];
}

export const SignaturePrintTable = ({ signatures }: SignaturesTableProps) => {
  return (
    <Table colorScheme="gray">
      {!signatures.length && (
        <TableCaption fontWeight="bold">No signatures</TableCaption>
      )}
      <Thead>
        <Tr>
          <Th width="50%">Name</Th>
          <Th width="15%">Date</Th>
          <Th textAlign="right">Signature</Th>
        </Tr>
      </Thead>
      <Tbody>
        {signatures.map((signature) => (
          <Tr>
            <Td>{signature.name}</Td>
            <Td>
              {moment(signature.date, 'YYYY-MM-DD').format('D MMMM, YYYY')}
            </Td>
            <Td>
              <Flex justify="flex-end">
                <Image
                  height="60px"
                  src={signature.path}
                  alt={`${signature.name} signature`}
                />
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
