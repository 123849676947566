import * as React from 'react';
import { UserInterface } from 'jesco-web';
import { appEnvironments } from '../enums';
import { useErrorLoggingContext } from './ErrorLogging.Provider';

export interface AuthProviderContextInterface {
  me?: UserInterface;
  token?: string;
  logout(): void;
  setAuthToken(token: string): void;
  setAuthUser(user: UserInterface): void;
  checkAuth(): void;
}

export const AuthContext = React.createContext<AuthProviderContextInterface>({
  me: undefined,
  token: undefined,
  logout: () => null,
  setAuthToken: () => undefined,
  setAuthUser: () => undefined,
  checkAuth: () => null,
});

export const useAuthContext = () => React.useContext(AuthContext);

const getDefaultToken = () => {
  if (process.env.REACT_APP_ENV === appEnvironments.test) {
    return String(process.env.REACT_APP_TEST_AUTH_TOKEN);
  }

  return String(localStorage.getItem('app-token'));
};

const getDefaultUser = () => {
  const user = localStorage.getItem('app-user');

  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};

export interface AuthProviderInterface {
  children: React.ReactElement;
}

export const AuthProvider = ({ children }: AuthProviderInterface) => {
  const [me, setMe] = React.useState<UserInterface | undefined>(
    getDefaultUser(),
  );
  const [token, setToken] = React.useState<string | undefined>(
    getDefaultToken(),
  );
  const { setLoggingUser } = useErrorLoggingContext();

  const logout = () => {
    setToken(undefined);
    setMe(undefined);
    localStorage.removeItem('app-token');
  };

  const setAuthUser = (user: UserInterface) => {
    setLoggingUser(user);
    setMe(user);
  };

  const setAuthToken = (authToken: string) => {
    localStorage.setItem('app-token', authToken);
    setToken(authToken);
  };

  const checkAuth = () => {
    const tokenFromLocalStorage = localStorage.getItem('app-token');

    if (tokenFromLocalStorage) {
      setToken(tokenFromLocalStorage);
    } else {
      logout();
    }
  };

  React.useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logout,
        token,
        me,
        setAuthToken,
        setAuthUser,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
