import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export type SectionPropsInterface = BoxProps;

export const Section = ({ children, ...props }: SectionPropsInterface) => {
  return (
    <Box
      bg="white"
      rounded="sm"
      border="1px"
      borderColor="gray.300"
      p="20px"
      {...props}
    >
      {children}
    </Box>
  );
};
