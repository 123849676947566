import React from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import {
  useDisclosure,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

export interface ColorPickerButtonProps {
  color: string;
  onChange?: ColorChangeHandler | undefined;
}

export const ColorPickerButton = ({
  color,
  onChange,
}: ColorPickerButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <Button rounded="100%" bg={color} onClick={onOpen} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton onClick={onClose} />
        <PopoverHeader>Pick a color</PopoverHeader>
        <PopoverBody>
          <SketchPicker
            color={color}
            onChange={(p, e) => {
              onClose();
              if (onChange) {
                onChange(p, e);
              }
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
