import React from 'react';
import Headroom from 'react-headroom';
import { Redirect, Switch, Route } from 'react-router-dom';
import { StateInterface, EmployeeInterface } from 'jesco-web';
import { Box, Flex, Progress } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { GET_ME_QUERY } from '../services/apollo';
import { Navbar } from '../containers/Navbar';
import { useAuthContext } from '../providers/Auth.Provider';
import { RemovalProjectsPage } from './RemovalProjectsPage';
import { DashboardSidebar } from '../containers/Dashboard/DashboardSidebar';
import { RemovalProjectPage } from './RemovalProjectPage';
import { WasteDisposalsPage } from './WasteDisposalsPage';
import { WasteDisposalPage } from './WasteDisposalPage';
import { FilesPage } from './FilesPage';
import { GET_APPLICATION_DETAILS_QUERY } from '../services/apollo/queries';
import { PeoplePage } from './PeoplePage';
import { CalendarPage } from './CalendarPage';
import { QuotesPage } from './QuotesPage';
import { QuotePage } from './QuotePage';

export const DashboardLandingPage = () => {
  const { setAuthUser, me } = useAuthContext();

  const { loading } = useQuery(GET_ME_QUERY, {
    skip: Boolean(me),
    onCompleted(d) {
      setAuthUser(d.me);
    },
  });

  const { data } = useQuery<{
    applicationDetails: {
      states: StateInterface[];
      employees: EmployeeInterface[];
    };
  }>(GET_APPLICATION_DETAILS_QUERY);

  let states: StateInterface[] = [];
  let employees: EmployeeInterface[] = [];
  if (data) {
    const {
      applicationDetails: { states: apiStates, employees: apiEmployees },
    } = data;

    states = apiStates;
    employees = apiEmployees;
  }

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".2rem"
          isIndeterminate
        />
      </Box>
    );
  }

  return (
    <Box bg="base" h="100%">
      <Flex h="100%" direction="column">
        <Headroom>
          <Navbar />
        </Headroom>

        <Box h="100%" overflow="hidden">
          <Flex h="100%">
            <Box
              display={['none', 'none', 'initial']}
              bg="gray.50"
              height="100%"
              width="100%"
              maxWidth="180px"
            >
              <DashboardSidebar />
            </Box>

            <Box h="100%" w="100%" overflowY="scroll">
              <Switch>
                <Route
                  exact
                  path={`/dashboard/calendar/:eventId?`}
                  render={() => <CalendarPage />}
                />

                <Route
                  exact
                  path={`/dashboard/quote/:quoteId`}
                  render={({ match: { params } }) => (
                    <QuotePage
                      quoteId={Number(params.quoteId)}
                      states={states}
                    />
                  )}
                />

                <Route
                  exact
                  path={`/dashboard/quotes`}
                  render={(routeProps) => (
                    <QuotesPage states={states} {...routeProps} />
                  )}
                />

                <Route
                  exact
                  path={`/dashboard/removal-projects`}
                  render={(routeProps) => (
                    <RemovalProjectsPage states={states} {...routeProps} />
                  )}
                />

                <Route
                  exact
                  path={`/dashboard/removal-project/:removalProjectId`}
                  render={({ match: { params } }) => (
                    <RemovalProjectPage
                      states={states}
                      employees={employees}
                      removalProjectId={Number(params.removalProjectId)}
                    />
                  )}
                />

                <Route
                  path={`/dashboard/people`}
                  render={(props) => <PeoplePage states={states} {...props} />}
                />

                <Route
                  exact
                  path={`/dashboard/files`}
                  render={() => <FilesPage />}
                />

                <Route
                  exact
                  path={`/dashboard/waste-disposals`}
                  render={() => <WasteDisposalsPage />}
                />

                <Route
                  exact
                  path={`/dashboard/waste-disposal/:wasteDisposalId`}
                  render={({ match: { params } }) => (
                    <WasteDisposalPage
                      wasteDisposalId={Number(params.wasteDisposalId)}
                    />
                  )}
                />

                <Redirect to={`/dashboard/calendar`} />
              </Switch>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
