import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CalendarEventTypeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import {
  UPDATE_CALENDAR_EVENT_TYPE,
  DELETE_CALENDAR_EVENT_TYPE,
} from '../../services/apollo/mutations';
import {
  FormControl,
  Input,
  IconButton,
  useToast,
  Text,
  HStack,
} from '@chakra-ui/react';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import { CalendarBubble } from './CalendarBubble';
import { ColorPickerButton } from '../ColorPickerButton';

export interface CalendarEventTypeEditRowProps {
  calendarEventType: CalendarEventTypeInterface;
  onComplete?: () => void;
}

export const CalendarEventTypeEditRow = ({
  calendarEventType,
  onComplete,
}: CalendarEventTypeEditRowProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [isEditing, setIsEditing] = useState(false);
  const [updateCalendarEventType, { loading: isUpdateLoading }] = useMutation(
    UPDATE_CALENDAR_EVENT_TYPE,
    {
      onCompleted(d) {
        if (!d || !d.updateCalendarEventType || !d.updateCalendarEventType.id)
          return;

        if (onComplete) {
          onComplete();
        }

        formik.resetForm();
        setIsEditing(false);

        toast({
          title: 'Calendar event type updated',
          description: `Calendar event type updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteCalendarEventType, { loading: isDeleteLoading }] = useMutation(
    DELETE_CALENDAR_EVENT_TYPE,
    {
      onCompleted(d) {
        if (!d || !d.deleteCalendarEventType || !d.deleteCalendarEventType.id)
          return;

        if (onComplete) {
          onComplete();
        }
        formik.resetForm();

        toast({
          title: 'Calendar event type deleted',
          description: `Calendar event type deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );
  const formik = useFormik({
    initialValues: {
      id: calendarEventType.id,
      title: calendarEventType.title,
      color: calendarEventType.color,
    },
    validationSchema: Yup.object().shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
      color: Yup.string().required(),
    }),
    onSubmit: (values) => {
      updateCalendarEventType({
        variables: {
          id: Number(values.id),
          title: values.title,
          color: values.color,
        },
      });
    },
  });

  const isLoading = isDeleteLoading || isUpdateLoading;

  return (
    <HStack
      width="100%"
      borderTop="1px solid"
      borderColor="gray.300"
      py="5px"
      justify="flex-end"
      data-testid="calendar-event-type-row"
    >
      {!isEditing && (
        <HStack justifyContent="space-between" width="100%">
          <HStack align="center">
            <HStack>
              <CalendarBubble color={String(calendarEventType?.color)} />
              <Text fontSize="lg">{String(calendarEventType?.title)}</Text>
            </HStack>
          </HStack>

          <HStack>
            <IconButton
              variant="ghost"
              colorScheme="green"
              data-testid="event-form-type-edit"
              aria-label="event-form-type-edit"
              isLoading={isLoading}
              icon={<FaPen />}
              onClick={() => {
                setIsEditing(true);
              }}
            />

            <IconButton
              variant="ghost"
              colorScheme="gray"
              data-testid="event-form-type-delete"
              aria-label="event-form-type-delete"
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  deleteCalendarEventType({
                    variables: {
                      id: calendarEventType.id,
                    },
                  });
                }
              }}
              isLoading={isLoading}
              icon={<FaTrash />}
            />
          </HStack>
        </HStack>
      )}

      {isEditing && (
        <form
          onSubmit={formik.handleSubmit}
          data-testid="calendar-event-type-form"
        >
          <FormControl
            id="title"
            isInvalid={Boolean(formik.errors.title)}
            isRequired
          >
            <HStack justifyContent="space-between">
              <HStack spacing={5}>
                <ColorPickerButton
                  color={formik.values.color}
                  onChange={(color) => {
                    formik.setFieldValue('color', color.hex);
                  }}
                />

                <Input
                  data-testid="calendar-event-type-form-title"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  autoFocus
                />
              </HStack>

              <HStack>
                <IconButton
                  variant="outline"
                  colorScheme="green"
                  data-testid="submit-event-type-ok"
                  aria-label="submit-event-type-ok"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                  isLoading={isLoading}
                  icon={<FaCheck />}
                />

                <IconButton
                  variant="outline"
                  colorScheme="gray"
                  data-testid="submit-event-type-cancel"
                  aria-label="submit-event-type-cancel"
                  onClick={() => {
                    formik.resetForm();
                    setIsEditing(false);
                  }}
                  isLoading={isLoading}
                  icon={<FaTimes />}
                />
              </HStack>
            </HStack>
          </FormControl>
        </form>
      )}
    </HStack>
  );
};
