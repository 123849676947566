import React from 'react';
import { RemovalAreaInterface } from 'jesco-web';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

export interface RemovalAreasTableProps {
  removalAreas: RemovalAreaInterface[];
}

export const RemovalAreaPrintTable = ({
  removalAreas,
}: RemovalAreasTableProps) => {
  return (
    <Table
      variant="striped"
      colorScheme="gray"
      data-testid="removal-areas-table"
    >
      {!removalAreas.length && (
        <TableCaption fontWeight="bold">No items to be removed</TableCaption>
      )}

      <Thead>
        <Tr>
          <Th p="5px">Area</Th>
          <Th p="5px">Location</Th>
          <Th p="5px">Material</Th>
          <Th p="5px">Type</Th>
          <Th p="5px">Condition</Th>
        </Tr>
      </Thead>
      <Tbody>
        {removalAreas.map((removalArea) => (
          <Tr>
            <Td p="5px">{removalArea.Area.name}</Td>
            <Td p="5px">{removalArea.Location.name}</Td>
            <Td p="5px">{removalArea.Material.name}</Td>
            <Td p="5px">{removalArea.Type.name}</Td>
            <Td p="5px">{removalArea.Condition.name}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
