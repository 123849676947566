import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { ErrorMessage } from './ErrorMessage';

export interface SignInFormValues {
  email: string;
  password: string;
}

export interface SignInFormInterface {
  onSubmit(values: SignInFormValues): void;
  initialValues: SignInFormValues;
  errorMessage: string;
  isLoading: boolean;
}

export const SignInForm = ({
  initialValues,
  onSubmit,
  errorMessage,
  isLoading,
}: SignInFormInterface) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      email: Yup.string().required(),
      password: Yup.string().required(),
    }),
    onSubmit,
  });

  return (
    <form data-testid="form-signin" onSubmit={formik.handleSubmit}>
      <Box px="15px">
        <Text fontWeight="bold" mb="20px" fontSize="xl" color="textSecondary">
          Sign in
        </Text>

        <Stack spacing="8px">
          <FormControl id="username">
            <FormLabel>Username</FormLabel>
            <Input
              autoFocus
              data-testid="signin-email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              data-testid="signin-password"
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </FormControl>
        </Stack>

        {Boolean(errorMessage) && (
          <ErrorMessage color="red.500" my="15px" data-testid="signin-message">
            {errorMessage}
          </ErrorMessage>
        )}

        {/* Footer */}
        <Flex justify="flex-end" mt="20px">
          <Button
            size="md"
            type="submit"
            variant="outline"
            colorScheme="green"
            data-testid="submit-button"
            isLoading={isLoading || formik.isSubmitting}
            disabled={!formik.isValid}
          >
            Sign in
          </Button>
        </Flex>
      </Box>
    </form>
  );
};
