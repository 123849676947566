import React from 'react';
import moment from 'moment';
import { WasteDisposalInterface, RemovalProjectInterface } from 'jesco-web';
import { Stack, Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';

export interface WasteDisposalRowInterface extends TableRowPropsInterface {
  wasteDisposal: WasteDisposalInterface;
  onRowClick(wasteDisposal: WasteDisposalInterface): void;
  onProjectClick(removalProject: RemovalProjectInterface): void;
}

export const WasteDisposalTableRow = ({
  wasteDisposal,
  onProjectClick,
  onRowClick,
  ...props
}: WasteDisposalRowInterface) => {
  return (
    <TableRow
      onClick={() => onRowClick(wasteDisposal)}
      data-testid="wasteDisposal-table-row"
      {...props}
    >
      <TableDiv>
        <Text>
          {wasteDisposal.RemovalProjects.map((removalProject) => (
            <Stack key={removalProject.id} spacing={3}>
              <Text>
                {`${removalProject.address}, ${removalProject.city}, ${removalProject.State?.abbrev}`}
              </Text>
            </Stack>
          ))}
        </Text>
      </TableDiv>

      <TableDiv>
        <Text>
          {moment(wasteDisposal.date, 'YYYY-MM-DD').format('D MMMM, YYYY')}
        </Text>
      </TableDiv>
    </TableRow>
  );
};
