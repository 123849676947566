import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export const SomethingWentWrongMessage = () => (
  <Flex p="10px" justifyContent="center">
    <Flex
      maxWidth="500px"
      flex="1"
      borderRadius="6px"
      bg="white"
      p="20px"
      flexDirection="column"
      alignItems="center"
    >
      <Text mb="10px" textAlign="center" fontFamily="body" fontSize="3">
        Something went wrong!
      </Text>
      <Text color="medium" mb="10px" textAlign="center" fontFamily="body">
        We have been notified of the issue are working on a fix
      </Text>

      <Text color="medium" textAlign="center" fontFamily="body" fontSize="2">
        Thank you for your patience!
      </Text>
    </Flex>
  </Flex>
);
