import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

export const QuoteFooter = () => {
  return (
    <Stack spacing={0}>
      <Box bg="blue.500" height="3px" width="200px" mb="5px" />
      <Stack pl="2px" spacing={0}>
        <Text>admin@jescoasbestos.com.au</Text>
        <Text>0411 067 700</Text>
        <Text fontSize="10px">17 Capital Terrace</Text>
        <Text fontSize="10px">Queanbeyan NSW 2620</Text>
        <Text fontSize="9px">Lic. ARA00003</Text>
        <Box>
          <Text mt="15px" color="blue.500" textTransform="uppercase">
            Without a Trace
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};
