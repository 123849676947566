import React from 'react';
import moment from 'moment';
import {
  HStack,
  Box,
  Stack,
  Progress,
  Text,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Header } from '../containers/Text/Header';
import { GET_TOOLBOX_TALK_QUERY } from '../services/apollo/queries';
import { ToolboxTalkInterface } from 'jesco-web';
import logo from '../lib/images/logo.png';
import { weatherOptions } from '../services/appData';
import { SignaturePrintTable } from 'src/containers/Signature/SignaturePrintTable';
import A4 from 'src/containers/Print/A4';

export interface ToolboxTalkPrintPageProps {
  toolboxTalkId: number;
}

export const ToolboxTalkPrintPage = ({
  toolboxTalkId,
}: ToolboxTalkPrintPageProps) => {
  const [isPrinting] = useMediaQuery('print');
  const { loading, data } = useQuery<{
    toolboxTalk: ToolboxTalkInterface;
  }>(GET_TOOLBOX_TALK_QUERY, {
    variables: {
      id: toolboxTalkId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const toolboxTalk = data.toolboxTalk;

  const weather = weatherOptions.find(
    (w) => w.value === toolboxTalk.weather_id,
  );

  return (
    <Box
      bg={isPrinting ? '#fff' : '#ccc'}
      width="100%"
      py={isPrinting ? 0 : '20px'}
      color="black"
      borderColor="black"
      margin="0 auto"
    >
      <A4 minHeight="297mm">
        <Box borderBottom="1px solid" borderColor="gray.300">
          <HStack mb="5px" justify="space-between">
            <Image height="70px" src={logo} alt="Jesco logo" />
            <Stack align="flex-end" justifyContent="flex-end" spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="right">
                Jesco Asbestos Removal Pty Ltd
              </Header>
              <Text fontSize="sm" as="span">
                17 Capital Terrace
              </Text>
              <Text fontSize="sm" as="span">
                Queanbeyan East, NSW 2620
              </Text>
              <Text fontSize="sm" as="span">
                Tel 0411 067 700
              </Text>
              <Text fontSize="sm" as="span">
                ABN 64660657572
              </Text>
            </Stack>
          </HStack>
        </Box>
        <Header mt="20px" fontSize="xl" textAlign="center">
          Pre-Start Toolbox Talk
        </Header>
        <Header mb="10px" fontSize="xl" textAlign="center">
          <Stack isInline mb="10px" align="center" justify="center">
            <Text fontSize="xl" color="textSecondary" as="span">
              {`${toolboxTalk.RemovalProject.address},`}
            </Text>
            <Text fontSize="lg" color="textSecondary" as="span">
              {`${toolboxTalk.RemovalProject.city},`}
            </Text>
            <Text fontSize="lg" color="textSecondary" as="span">
              {toolboxTalk.RemovalProject.State?.abbrev}
            </Text>
          </Stack>
        </Header>
        <Box mb="20px">
          <HStack width="100%" justifyContent="space-between">
            <Stack flex="1" spacing="0">
              <HStack p="5px" bg="gray.50" justify="space-between">
                <Text fontSize="sm">Date</Text>
                <Text fontSize="sm">
                  {moment(toolboxTalk.date, 'YYYY/MM/DD').format(
                    'D MMMM, YYYY',
                  ) || '-'}
                </Text>
              </HStack>
            </Stack>
            <Stack flex="1" spacing="0">
              <HStack p="5px" bg="gray.50" justify="space-between">
                <Text fontSize="sm">Weather</Text>
                <Text fontSize="sm">{weather ? weather.label : '-'}</Text>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        <Box mb="30px">
          <Header mb="10px" fontSize="md" fontWeight="bold">
            General items / Activity checklist
          </Header>
          <Box>
            <HStack
              justify="space-between"
              p="3px"
              bg="gray.50"
              px="10px"
              data-testid="toolbox-talk-q1"
            >
              <Text>Were the previous day's activities issue free?</Text>
              {toolboxTalk.q_1 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_1 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              px="10px"
              data-testid="toolbox-talk-q2"
            >
              <Text>
                Assessment of asbestos work area and the asbestos removal site
                complete?
              </Text>
              {toolboxTalk.q_2 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_2 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              bg="gray.50"
              px="10px"
              data-testid="toolbox-talk-q3"
            >
              <Text>Work area safe to commence works?</Text>
              {toolboxTalk.q_3 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_3 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              px="10px"
              data-testid="toolbox-talk-q4"
            >
              <Text>Workers adequately trained to complete tasks</Text>
              {toolboxTalk.q_4 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_4 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              bg="gray.50"
              px="10px"
              data-testid="toolbox-talk-q5"
            >
              <Text>
                All workers have suitable PPE for all tasks to be performed?
              </Text>
              {toolboxTalk.q_5 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_5 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              px="10px"
              data-testid="toolbox-talk-q6"
            >
              <Text>
                Weather conditions appropriate for commencement of works?
              </Text>
              {toolboxTalk.q_6 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_6 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <HStack
              justify="space-between"
              p="3px"
              bg="gray.50"
              px="10px"
              data-testid="toolbox-talk-q7"
            >
              <Text>
                All workers instructed in relation to today's work requirements?
              </Text>
              {toolboxTalk.q_7 === 1 && (
                <Text fontSize="md" fontWeight="bold">
                  Yes
                </Text>
              )}
              {toolboxTalk.q_7 !== 1 && (
                <Text fontSize="md" fontWeight="bold">
                  No
                </Text>
              )}
            </HStack>

            <Box my="10px">
              <Header fontSize="md" fontWeight="bold">
                Topics to cover
              </Header>
              <Text p="10px">
                {toolboxTalk.topics_to_cover
                  ? toolboxTalk.topics_to_cover
                  : 'No topics to cover'}
              </Text>
            </Box>

            <Box my="10px">
              <Header fontSize="md" fontWeight="bold">
                Notes
              </Header>
              <Text p="10px">
                {toolboxTalk.notes ? toolboxTalk.notes : 'No notes'}
              </Text>
            </Box>

            <Box my="10px">
              <Header fontSize="md" fontWeight="bold">
                Other items
              </Header>
              <Text p="10px">
                {toolboxTalk.other_items
                  ? toolboxTalk.other_items
                  : 'No other items'}
              </Text>
            </Box>

            <Header fontSize="md" fontWeight="bold">
              Signatures
            </Header>
            <Box>
              <SignaturePrintTable signatures={toolboxTalk.Signatures} />
            </Box>
          </Box>
        </Box>
      </A4>
    </Box>
  );
};
