import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
} from '../../services/apollo/mutations';
import { useMutation } from '@apollo/client';
import { FaTrash } from 'react-icons/fa';

export interface UserFormValues {
  id?: number;
  name: string;
  username: string;
  email: string;
  password?: string;
}

export interface ClientDrawerProps {
  isOpen: boolean;
  onComplete: () => void;
  onClose: () => void;
  initialValues: UserFormValues;
}

export const UserDrawer = ({
  isOpen,
  onComplete,
  onClose,
  initialValues,
}: ClientDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });

  const [createUser, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onCompleted(d) {
        if (!d || !d.createUser || !d.createUser.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'User created',
          description: `${d.createUser.name} created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted(d) {
        if (!d || !d.updateUser || !d.updateUser.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'User updated',
          description: `${d.updateUser.name} updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      onCompleted(d) {
        if (!d || !d.deleteUser || !d.deleteUser.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'User deleted',
          description: `${d.deleteUser.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      name: Yup.string().min(1).required('Name is required'),
      username: Yup.string().min(1).required('Username is required'),
      email: Yup.string().min(1).required('Email is required'),
      password: Yup.string().when('id', {
        is: !undefined,
        then: Yup.string().min(1).required('Password is required'),
      }),
    }),
    onSubmit: (values) => {
      if (!values.id) {
        createUser({
          variables: {
            name: values.name,
            username: values.username,
            email: values.email,
            password: values.password,
          },
        });
      } else {
        updateUser({
          variables: {
            id: values.id,
            name: values.name,
            username: values.username,
            email: values.email,
          },
        });
      }
    },
  });

  const isLoading = createUserLoading || updateUserLoading || deleteUserLoading;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">
              {formik.values.id ? `${formik.values.name}` : 'New user'}
            </Text>
            {formik.values.id && formik.values.id && (
              <IconButton
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    deleteUser({
                      variables: {
                        id: formik.values.id,
                      },
                    });
                  }
                }}
                data-testid="delete-user-button"
                aria-label="delete-user"
                colorScheme="red"
                isLoading={isLoading}
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={onClose}
            color="white"
          />
          <DrawerBody data-testid="drawer">
            <Stack spacing={5}>
              <FormControl
                id="name"
                isInvalid={Boolean(formik.errors.name)}
                isRequired
              >
                <FormLabel>Name</FormLabel>
                <Input
                  data-testid="user-form-name"
                  autoFocus
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>

              <FormControl
                id="email"
                isInvalid={Boolean(formik.errors.email)}
                isRequired
              >
                <FormLabel>Email</FormLabel>
                <Input
                  data-testid="user-form-email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                id="username"
                isInvalid={Boolean(formik.errors.username)}
                isRequired
              >
                <FormLabel>Username</FormLabel>
                <Input
                  data-testid="user-form-username"
                  name="username"
                  type="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
              </FormControl>

              {!formik.values.id && (
                <FormControl
                  id="password"
                  isInvalid={Boolean(formik.errors.password)}
                  isRequired
                >
                  <FormLabel>Password</FormLabel>
                  <Input
                    data-testid="user-form-password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>
              )}

              {formik.values.id && (
                <Input
                  data-testid="user-form-id"
                  name="id"
                  value={formik.values.id}
                  type="hidden"
                />
              )}
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTop="1px solid" borderColor="gray.300">
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid || formik.isSubmitting}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
