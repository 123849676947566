export interface PaginatorPageInterface {
  type: string;
  number: number;
}

export interface GetPaginatorPagesPropsInterface {
  totalResults: number;
  currentPage: number;
  perPage: number;
}

export const getPaginatorPages = (
  { totalResults, currentPage, perPage }: GetPaginatorPagesPropsInterface = {
    totalResults: 20,
    currentPage: 1,
    perPage: 20,
  },
) => {
  const maxPageNumber = Math.ceil(totalResults / perPage);
  let pages: PaginatorPageInterface[] = [];

  if (maxPageNumber < 5 || currentPage > maxPageNumber) {
    for (let i = 1; i <= maxPageNumber; i += 1) {
      pages.push({
        type: 'page',
        number: i,
      });
    }
  } else if (currentPage > maxPageNumber - 3) {
    pages = [
      {
        type: 'page',
        number: 1,
      },
      {
        type: 'elipsis',
        number: currentPage,
      },
      {
        type: 'page',
        number: maxPageNumber - 2,
      },
      {
        type: 'page',
        number: maxPageNumber - 1,
      },
      {
        type: 'page',
        number: maxPageNumber,
      },
    ];
  } else if (currentPage < 4) {
    pages = [
      {
        type: 'page',
        number: 1,
      },
      {
        type: 'page',
        number: 2,
      },
      {
        type: 'page',
        number: 3,
      },
      {
        type: 'elipsis',
        number: maxPageNumber,
      },
      {
        type: 'page',
        number: maxPageNumber,
      },
    ];
  } else if (currentPage <= maxPageNumber - 3) {
    pages = [
      {
        type: 'page',
        number: 1,
      },
      {
        type: 'elipsis',
        number: currentPage,
      },
      {
        type: 'page',
        number: currentPage - 1,
      },
      {
        type: 'page',
        number: currentPage,
      },
      {
        type: 'page',
        number: currentPage + 1,
      },
      {
        type: 'elipsis',
        number: maxPageNumber,
      },
      {
        type: 'page',
        number: maxPageNumber,
      },
    ];
  }

  return pages;
};
