import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  Text,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Switch,
  HStack,
  Input,
  Image,
  DrawerFooter,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_SWMS } from '../../services/apollo/mutations';
import swmsRisksDecontaminationImg from '../../lib/images/PPE/swms-risks-decontamination.png';
import swmsRisksEarProtectionImg from '../../lib/images/PPE/swms-risks-ear-protection.png';
import swmsRisksEyeProtectionImg from '../../lib/images/PPE/swms-risks-eye-protection.png';
import swmsRisksFootProtectionImg from '../../lib/images/PPE/swms-risks-foot-protection.png';
import swmsRisksHandProtectionImg from '../../lib/images/PPE/swms-risks-hand-protection.png';
import swmsRisksHardHatImg from '../../lib/images/PPE/swms-risks-hard-hat.png';
import swmsRisksLungProtectionImg from '../../lib/images/PPE/swms-risks-lung-protection.png';
import swmsRisksProtectiveClothingImg from '../../lib/images/PPE/swms-risks-protective-clothing.png';
import swmsRisksSafetyHarnessImg from '../../lib/images/PPE/swms-risks-safety-harness.png';
import swmsRisksVisibilityImg from '../../lib/images/PPE/swms-risks-visibility.png';

export interface SWMSFormProps {
  removalProjectId: number;
  workActivityRisk1: number;
  workActivityRisk2: number;
  workActivityRisk3: number;
  workActivityRisk4: number;
  workActivityRisk5: number;
  workActivityRisk6: number;
  workActivityRisk7: number;
  workActivityRisk8: number;
  workActivityRisk9: number;
  workActivityRisk10: number;
  workActivityRisk11: number;
  workActivityRisk12: number;
  workActivityRisk13: number;
  workActivityRisk14: number;
  workActivityRisk15: number;
  workActivityRisk16: number;
  workActivityRisk17: number;
  workActivityRisk18: number;
  workActivityRisk19: number;
  workActivityRisk20: number;
  workActivityRisk21: number;
  workActivityRisk22: number;
  workActivityRisk23: number;
  workActivityRisk24: number;
  swmsProtectionHardHat: number;
  swmsProtectionEye: number;
  swmsProtectionVisibleClothing: number;
  swmsProtectionFoot: number;
  swmsProtectionLung: number;
  swmsProtectionHand: number;
  swmsProtectionEar: number;
  swmsProtectionDecontamination: number;
  swmsProtectionProtectiveClothing: number;
  swmsProtectionSafetyHarness: number;
}

export interface SWMSDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onComplete(): void;
  initialValues: SWMSFormProps;
}

export const SWMSDrawer = ({
  isOpen,
  onClose,
  onComplete,
  initialValues,
}: SWMSDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });

  const [updateSWMS, { loading: isUpdateLoading }] = useMutation(UPDATE_SWMS, {
    onCompleted(d) {
      if (!d || !d.updateSWMS) return;

      onClose();
      onComplete();

      toast({
        title: 'SWMS updated',
        description: `SWMS updated`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const isLoading = isUpdateLoading;
  const [yesToAll, setYesToAll] = useState(
    initialValues.workActivityRisk1 === 1 &&
      initialValues.workActivityRisk2 === 1 &&
      initialValues.workActivityRisk3 === 1 &&
      initialValues.workActivityRisk4 === 1 &&
      initialValues.workActivityRisk5 === 1 &&
      initialValues.workActivityRisk6 === 1 &&
      initialValues.workActivityRisk7 === 1 &&
      initialValues.workActivityRisk8 === 1 &&
      initialValues.workActivityRisk9 === 1 &&
      initialValues.workActivityRisk10 === 1 &&
      initialValues.workActivityRisk11 === 1 &&
      initialValues.workActivityRisk12 === 1 &&
      initialValues.workActivityRisk13 === 1 &&
      initialValues.workActivityRisk14 === 1 &&
      initialValues.workActivityRisk15 === 1 &&
      initialValues.workActivityRisk16 === 1 &&
      initialValues.workActivityRisk17 === 1 &&
      initialValues.workActivityRisk18 === 1 &&
      initialValues.workActivityRisk19 === 1 &&
      initialValues.workActivityRisk20 === 1 &&
      initialValues.workActivityRisk21 === 1 &&
      initialValues.workActivityRisk22 === 1 &&
      initialValues.workActivityRisk23 === 1 &&
      initialValues.workActivityRisk24 === 1,
  );

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      removalProjectId: Yup.number().required(),
      workActivityRisk1: Yup.number(),
      workActivityRisk2: Yup.number(),
      workActivityRisk3: Yup.number(),
      workActivityRisk4: Yup.number(),
      workActivityRisk5: Yup.number(),
      workActivityRisk6: Yup.number(),
      workActivityRisk7: Yup.number(),
      workActivityRisk8: Yup.number(),
      workActivityRisk9: Yup.number(),
      workActivityRisk10: Yup.number(),
      workActivityRisk11: Yup.number(),
      workActivityRisk12: Yup.number(),
      workActivityRisk13: Yup.number(),
      workActivityRisk14: Yup.number(),
      workActivityRisk15: Yup.number(),
      workActivityRisk16: Yup.number(),
      workActivityRisk17: Yup.number(),
      workActivityRisk18: Yup.number(),
      workActivityRisk19: Yup.number(),
      workActivityRisk20: Yup.number(),
      workActivityRisk21: Yup.number(),
      workActivityRisk22: Yup.number(),
      workActivityRisk23: Yup.number(),
      workActivityRisk24: Yup.number(),
      swmsProtectionHardHat: Yup.number(),
      swmsProtectionEye: Yup.number(),
      swmsProtectionVisibleClothing: Yup.number(),
      swmsProtectionFoot: Yup.number(),
      swmsProtectionLung: Yup.number(),
      swmsProtectionHand: Yup.number(),
      swmsProtectionEar: Yup.number(),
      swmsProtectionDecontamination: Yup.number(),
      swmsProtectionProtectiveClothing: Yup.number(),
      swmsProtectionSafetyHarness: Yup.number(),
    }),
    onSubmit: (values) => {
      console.log(values);
      updateSWMS({
        variables: {
          ...values,
        },
      });
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            pl="40px"
            pr="20px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <DrawerCloseButton
              left="0"
              top="5px"
              data-testid="drawer-close-button"
              onClick={handleClose}
              color="white"
            />
            <Text fontSize="xl">Edit SWMS</Text>
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody p="20px">
            <Stack spacing={4} bg="white">
              <Text mb="10px" fontSize="2xl" fontWeight="bold">
                Work Activity Risks
              </Text>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk1" mb="0">
                  Involves a risk of a person falling more than 2 metres
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk1"
                  name="workActivityRisk1"
                  data-testid="swms-form-workActivityRisk1"
                  isChecked={formik.values.workActivityRisk1 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk1 === 0) {
                      formik.setFieldValue('workActivityRisk1', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk1', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk20" mb="0">
                  Involves working off ladder
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk20"
                  name="workActivityRisk20"
                  data-testid="swms-form-workActivityRisk20"
                  isChecked={formik.values.workActivityRisk20 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk20 === 0) {
                      formik.setFieldValue('workActivityRisk20', 1);
                      formik.setFieldValue('workActivityRisk1', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk20', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk21" mb="0">
                  Involves use of scaffold/mobile scaffold
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk21"
                  name="workActivityRisk21"
                  data-testid="swms-form-workActivityRisk21"
                  isChecked={formik.values.workActivityRisk21 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk21 === 0) {
                      formik.setFieldValue('workActivityRisk21', 1);
                      formik.setFieldValue('workActivityRisk1', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk21', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk22" mb="0">
                  Involves use of elevated work platform
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk22"
                  name="workActivityRisk22"
                  data-testid="swms-form-workActivityRisk22"
                  isChecked={formik.values.workActivityRisk22 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk22 === 0) {
                      formik.setFieldValue('workActivityRisk22', 1);
                      formik.setFieldValue('workActivityRisk1', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk22', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk23" mb="0">
                  Involves use of fall-arrest system
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk23"
                  name="workActivityRisk23"
                  data-testid="swms-form-workActivityRisk23"
                  isChecked={formik.values.workActivityRisk23 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk23 === 0) {
                      formik.setFieldValue('workActivityRisk23', 1);
                      formik.setFieldValue('workActivityRisk1', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk23', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk2" mb="0">
                  Is carried out on a telecommunication tower
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk2"
                  name="workActivityRisk2"
                  data-testid="swms-form-workActivityRisk2"
                  isChecked={formik.values.workActivityRisk2 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk2 === 0) {
                      formik.setFieldValue('workActivityRisk2', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk2', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk3" mb="0">
                  Involves demolition of an element of a structure that is
                  load-bearing
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk3"
                  name="workActivityRisk3"
                  data-testid="swms-form-workActivityRisk3"
                  isChecked={formik.values.workActivityRisk3 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk3 === 0) {
                      formik.setFieldValue('workActivityRisk3', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk3', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk4" mb="0">
                  Involves demolition of an element of a structure that is
                  related to the physical integrity of the structure
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk4"
                  name="workActivityRisk4"
                  data-testid="swms-form-workActivityRisk4"
                  isChecked={formik.values.workActivityRisk4 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk4 === 0) {
                      formik.setFieldValue('workActivityRisk4', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk4', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk5" mb="0">
                  Involves, or is likely to involve, disturbing asbestos
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk5"
                  name="workActivityRisk5"
                  data-testid="swms-form-workActivityRisk5"
                  isChecked={formik.values.workActivityRisk5 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk5 === 0) {
                      formik.setFieldValue('workActivityRisk5', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk5', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk6" mb="0">
                  Involves structural alteration or repair that requires
                  temporary support to prevent collapse
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk6"
                  name="workActivityRisk6"
                  data-testid="swms-form-workActivityRisk6"
                  isChecked={formik.values.workActivityRisk6 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk6 === 0) {
                      formik.setFieldValue('workActivityRisk6', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk6', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk7" mb="0">
                  Is carried out in or near a confined space
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk7"
                  name="workActivityRisk7"
                  data-testid="swms-form-workActivityRisk7"
                  isChecked={formik.values.workActivityRisk7 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk7 === 0) {
                      formik.setFieldValue('workActivityRisk7', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk7', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk8" mb="0">
                  Is carried out in or near a shaft or trench deeper than 1.5
                  metres or a tunnel
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk8"
                  name="workActivityRisk8"
                  data-testid="swms-form-workActivityRisk8"
                  isChecked={formik.values.workActivityRisk8 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk8 === 0) {
                      formik.setFieldValue('workActivityRisk8', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk8', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk9" mb="0">
                  Involves the use of explosives
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk9"
                  name="workActivityRisk9"
                  data-testid="swms-form-workActivityRisk9"
                  isChecked={formik.values.workActivityRisk9 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk9 === 0) {
                      formik.setFieldValue('workActivityRisk9', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk9', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk10" mb="0">
                  Is carried out on or near pressurised gas mains or piping
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk10"
                  name="workActivityRisk10"
                  data-testid="swms-form-workActivityRisk10"
                  isChecked={formik.values.workActivityRisk10 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk10 === 0) {
                      formik.setFieldValue('workActivityRisk10', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk10', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk11" mb="0">
                  Is carried out on or near chemical, fuel or refrigerant lines
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk11"
                  name="workActivityRisk11"
                  data-testid="swms-form-workActivityRisk11"
                  isChecked={formik.values.workActivityRisk11 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk11 === 0) {
                      formik.setFieldValue('workActivityRisk11', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk11', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk12" mb="0">
                  Is carried out on or near energised electrical installations
                  or services
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk12"
                  name="workActivityRisk12"
                  data-testid="swms-form-workActivityRisk12"
                  isChecked={formik.values.workActivityRisk12 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk12 === 0) {
                      formik.setFieldValue('workActivityRisk12', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk12', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk13" mb="0">
                  Is carried out in an area that may have a contaminated or
                  flammable atmosphere
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk13"
                  name="workActivityRisk13"
                  data-testid="swms-form-workActivityRisk13"
                  isChecked={formik.values.workActivityRisk13 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk13 === 0) {
                      formik.setFieldValue('workActivityRisk13', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk13', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk14" mb="0">
                  Involves tilt-up or precast concrete
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk14"
                  name="workActivityRisk14"
                  data-testid="swms-form-workActivityRisk14"
                  isChecked={formik.values.workActivityRisk14 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk14 === 0) {
                      formik.setFieldValue('workActivityRisk14', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk14', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk15" mb="0">
                  Is carried out on, in or adjacent to a road, railway, shipping
                  lane or other traffic corridor in use by traffic other than
                  pedestrians
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk15"
                  name="workActivityRisk15"
                  data-testid="swms-form-workActivityRisk15"
                  isChecked={formik.values.workActivityRisk15 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk15 === 0) {
                      formik.setFieldValue('workActivityRisk15', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk15', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk16" mb="0">
                  Is carried out in an area of a workplace where there is any
                  movement of powered mobile plant
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk16"
                  name="workActivityRisk16"
                  data-testid="swms-form-workActivityRisk16"
                  isChecked={formik.values.workActivityRisk16 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk16 === 0) {
                      formik.setFieldValue('workActivityRisk16', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk16', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk17" mb="0">
                  Is carried out in areas with artificial extremes of
                  temperature
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk17"
                  name="workActivityRisk17"
                  data-testid="swms-form-workActivityRisk17"
                  isChecked={formik.values.workActivityRisk17 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk17 === 0) {
                      formik.setFieldValue('workActivityRisk17', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk17', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk18" mb="0">
                  Is carried out in or near water or other liquid that involves
                  a risk of drowning
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk18"
                  name="workActivityRisk18"
                  data-testid="swms-form-workActivityRisk18"
                  isChecked={formik.values.workActivityRisk18 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk18 === 0) {
                      formik.setFieldValue('workActivityRisk18', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk18', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk19" mb="0">
                  Involves diving work
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk19"
                  name="workActivityRisk19"
                  data-testid="swms-form-workActivityRisk19"
                  isChecked={formik.values.workActivityRisk19 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk19 === 0) {
                      formik.setFieldValue('workActivityRisk19', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk19', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                bg="gray.50"
              >
                <FormLabel htmlFor="workActivityRisk24" mb="0">
                  Involves the cutting of crystalline silica material using a
                  power tool or another mechanical process
                </FormLabel>
                <Switch
                  colorScheme="green"
                  id="workActivityRisk24"
                  name="workActivityRisk24"
                  data-testid="swms-form-workActivityRisk24"
                  isChecked={formik.values.workActivityRisk24 === 1}
                  onChange={(e) => {
                    if (formik.values.workActivityRisk24 === 0) {
                      formik.setFieldValue('workActivityRisk24', 1);
                    } else {
                      formik.setFieldValue('workActivityRisk24', 0);
                      setYesToAll(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <FormLabel htmlFor="q7" mb="0"></FormLabel>
                <Switch
                  colorScheme="green"
                  id="yes-to-all"
                  data-testid="swms-form-yes-to-all"
                  isChecked={yesToAll}
                  onChange={(e) => {
                    if (yesToAll) {
                      setYesToAll(false);
                      formik.setFieldValue('workActivityRisk1', 0);
                      formik.setFieldValue('workActivityRisk2', 0);
                      formik.setFieldValue('workActivityRisk3', 0);
                      formik.setFieldValue('workActivityRisk4', 0);
                      formik.setFieldValue('workActivityRisk5', 0);
                      formik.setFieldValue('workActivityRisk6', 0);
                      formik.setFieldValue('workActivityRisk7', 0);
                      formik.setFieldValue('workActivityRisk8', 0);
                      formik.setFieldValue('workActivityRisk9', 0);
                      formik.setFieldValue('workActivityRisk10', 0);
                      formik.setFieldValue('workActivityRisk11', 0);
                      formik.setFieldValue('workActivityRisk12', 0);
                      formik.setFieldValue('workActivityRisk13', 0);
                      formik.setFieldValue('workActivityRisk14', 0);
                      formik.setFieldValue('workActivityRisk15', 0);
                      formik.setFieldValue('workActivityRisk16', 0);
                      formik.setFieldValue('workActivityRisk17', 0);
                      formik.setFieldValue('workActivityRisk18', 0);
                      formik.setFieldValue('workActivityRisk19', 0);
                      formik.setFieldValue('workActivityRisk20', 0);
                      formik.setFieldValue('workActivityRisk21', 0);
                      formik.setFieldValue('workActivityRisk22', 0);
                      formik.setFieldValue('workActivityRisk23', 0);
                      formik.setFieldValue('workActivityRisk24', 0);
                    } else {
                      setYesToAll(true);
                      formik.setFieldValue('workActivityRisk1', 1);
                      formik.setFieldValue('workActivityRisk2', 1);
                      formik.setFieldValue('workActivityRisk3', 1);
                      formik.setFieldValue('workActivityRisk4', 1);
                      formik.setFieldValue('workActivityRisk5', 1);
                      formik.setFieldValue('workActivityRisk6', 1);
                      formik.setFieldValue('workActivityRisk7', 1);
                      formik.setFieldValue('workActivityRisk8', 1);
                      formik.setFieldValue('workActivityRisk9', 1);
                      formik.setFieldValue('workActivityRisk10', 1);
                      formik.setFieldValue('workActivityRisk11', 1);
                      formik.setFieldValue('workActivityRisk12', 1);
                      formik.setFieldValue('workActivityRisk13', 1);
                      formik.setFieldValue('workActivityRisk14', 1);
                      formik.setFieldValue('workActivityRisk15', 1);
                      formik.setFieldValue('workActivityRisk16', 1);
                      formik.setFieldValue('workActivityRisk17', 1);
                      formik.setFieldValue('workActivityRisk18', 1);
                      formik.setFieldValue('workActivityRisk19', 1);
                      formik.setFieldValue('workActivityRisk20', 1);
                      formik.setFieldValue('workActivityRisk21', 1);
                      formik.setFieldValue('workActivityRisk22', 1);
                      formik.setFieldValue('workActivityRisk23', 1);
                      formik.setFieldValue('workActivityRisk24', 1);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <Text mb="10px" fontSize="2xl" fontWeight="bold">
                REQUIRED PERSONAL PROTECTIVE EQUIPMENT (PPE)
              </Text>
              swmsProtectionEye swmsProtectionVisibleClothing swmsProtectionFoot
              swmsProtectionLung swmsProtectionHand swmsProtectionEar
              swmsProtectionDecontamination swmsProtectionProtectiveClothing
              swmsProtectionSafetyHarness
              <Stack>
                <HStack>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionDecontamination === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionDecontamination === 1) {
                        formik.setFieldValue(
                          'swmsProtectionDecontamination',
                          0,
                        );
                      } else {
                        formik.setFieldValue(
                          'swmsProtectionDecontamination',
                          1,
                        );
                      }
                    }}
                    data-testid="swms-risk-swmsRisksDecontamination"
                  >
                    <Image
                      src={swmsRisksDecontaminationImg}
                      alt="swms-risk-swmsRisksDecontaminationImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionEar === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionEar === 1) {
                        formik.setFieldValue('swmsProtectionEar', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionEar', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksEarProtection"
                  >
                    <Image
                      src={swmsRisksEarProtectionImg}
                      alt="swms-risk-swmsRisksEarProtectionImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionEye === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionEye === 1) {
                        formik.setFieldValue('swmsProtectionEye', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionEye', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksEyeProtection"
                  >
                    <Image
                      src={swmsRisksEyeProtectionImg}
                      alt="swms-risk-swmsRisksEyeProtectionImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionFoot === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionFoot === 1) {
                        formik.setFieldValue('swmsProtectionFoot', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionFoot', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksFootProtection"
                  >
                    <Image
                      src={swmsRisksFootProtectionImg}
                      alt="swms-risk-swmsRisksFootProtectionImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionHand === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionHand === 1) {
                        formik.setFieldValue('swmsProtectionHand', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionHand', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksHandProtection"
                  >
                    <Image
                      src={swmsRisksHandProtectionImg}
                      alt="swms-risk-swmsRisksHandProtectionImg"
                      height="80px"
                    />
                  </Button>
                </HStack>
                <HStack>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionHardHat === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionHardHat === 1) {
                        formik.setFieldValue('swmsProtectionHardHat', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionHardHat', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksHardHat"
                  >
                    <Image
                      src={swmsRisksHardHatImg}
                      alt="swms-risk-swmsRisksHardHatImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionLung === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionLung === 1) {
                        formik.setFieldValue('swmsProtectionLung', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionLung', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksLungProtection"
                  >
                    <Image
                      src={swmsRisksLungProtectionImg}
                      alt="swms-risk-swmsRisksLungProtectionImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={
                      formik.values.swmsProtectionProtectiveClothing === 1
                    }
                    onClick={() => {
                      if (
                        formik.values.swmsProtectionProtectiveClothing === 1
                      ) {
                        formik.setFieldValue(
                          'swmsProtectionProtectiveClothing',
                          0,
                        );
                      } else {
                        formik.setFieldValue(
                          'swmsProtectionProtectiveClothing',
                          1,
                        );
                      }
                    }}
                    data-testid="swms-risk-swmsProtectionProtectiveClothing"
                  >
                    <Image
                      src={swmsRisksProtectiveClothingImg}
                      alt="swms-risk-swmsProtectionProtectiveClothingImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionSafetyHarness === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionSafetyHarness === 1) {
                        formik.setFieldValue('swmsProtectionSafetyHarness', 0);
                      } else {
                        formik.setFieldValue('swmsProtectionSafetyHarness', 1);
                      }
                    }}
                    data-testid="swms-risk-swmsRisksSafetyHarness"
                  >
                    <Image
                      src={swmsRisksSafetyHarnessImg}
                      alt="swms-risk-swmsRisksSafetyHarnessImg"
                      height="80px"
                    />
                  </Button>
                  <Button
                    colorScheme="blue"
                    height="auto"
                    variant="outline"
                    p="15px"
                    isActive={formik.values.swmsProtectionVisibleClothing === 1}
                    onClick={() => {
                      if (formik.values.swmsProtectionVisibleClothing === 1) {
                        formik.setFieldValue(
                          'swmsProtectionVisibleClothing',
                          0,
                        );
                      } else {
                        formik.setFieldValue(
                          'swmsProtectionVisibleClothing',
                          1,
                        );
                      }
                    }}
                    data-testid="swms-risk-swmsRisksVisibility"
                  >
                    <Image
                      src={swmsRisksVisibilityImg}
                      alt="swms-risk-swmsRisksVisibilityImg"
                      height="80px"
                    />
                  </Button>
                </HStack>
              </Stack>
              <Input
                data-testid="swms-form-removal-project-id"
                name="id"
                value={formik.values.removalProjectId}
                type="hidden"
              />
            </Stack>
          </DrawerBody>

          <DrawerFooter
            justifyContent="space-evenly"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid || formik.isSubmitting}
              isLoading={isLoading}
            >
              Update
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
