import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SidebarButton = ({ isActive, ...props }: ButtonProps) => (
  <Button
    width="100%"
    variant="ghost"
    colorScheme="blue"
    rounded="none"
    justifyContent="flex-start"
    textAlign="left"
    fontSize="lg"
    fontWeight="normal"
    height="4rem"
    color={isActive ? 'green.600' : 'auto'}
    {...props}
  />
);
