import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { BrowserRouter as Router } from 'react-router-dom';
// import { styles } from './global-styles';
import { theme } from './theme';
import { ErrorLoggingProvider } from './providers';
import { App } from './App';
import { AuthProvider } from './providers/Auth.Provider';
import { ApolloProvider } from './services/apollo/Apollo.Provider';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'antd/dist/antd.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const Root = () => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <ApolloProvider>
        <ErrorLoggingProvider>
          <Router>
            <App />
          </Router>
        </ErrorLoggingProvider>
      </ApolloProvider>
    </AuthProvider>
  </ChakraProvider>
);
