import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  Button,
  Stack,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { WasteDisposalInterface } from 'jesco-web';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { Header } from '../containers/Text/Header';
import { GET_WASTE_DISPOSALS_QUERY } from '../services/apollo/queries';
import { WasteDisposalTableRow } from '../containers/WasteDisposal/WasteDisposalTableRow';
import { WasteDisposalDrawer } from 'src/containers/WasteDisposal/WasteDisposalDrawer';
import { useAuthContext } from '../providers/Auth.Provider';

export const WasteDisposalsPage = withRouter(
  ({ history: { push } }: RouteComponentProps) => {
    const { me } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filter, setFilter] = useState<TableFilterInterface>({
      orderBy: 'name',
      orderByDirection: 'desc',
      search: '',
      page: 1,
      perPage: 40,
    });

    const { loading, data, refetch } = useQuery<{
      wasteDisposals: {
        items: WasteDisposalInterface[];
        count: number;
      };
    }>(GET_WASTE_DISPOSALS_QUERY, {
      variables: filter,
      fetchPolicy: 'network-only',
    });

    let wasteDisposals: WasteDisposalInterface[] = [];
    let count = 0;

    if (data) {
      const {
        wasteDisposals: { count: total, items: projects },
      } = data;
      count = total;
      wasteDisposals = projects;
    }

    const renderRow = ({
      item,
      index,
    }: {
      item: WasteDisposalInterface;
      index: number;
    }) => {
      return (
        <WasteDisposalTableRow
          key={String(item.id)}
          isStriped={index % 2 === 1}
          wasteDisposal={item}
          onRowClick={() => push(`/dashboard/waste-disposal/${item.id}`)}
          onProjectClick={(removalProject) =>
            push(`/dashboard/removal-project/${removalProject.id}`)
          }
        />
      );
    };

    const handleRefetch = (newFilter: TableFilterInterface) => {
      setFilter(newFilter);
      refetch(newFilter);
    };

    return (
      <Box p="20px" maxWidth="1400px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary" mb="10px">
            Waste disposals
          </Header>

          {Boolean(me?.Permissions?.can_edit_waste_disposals) && (
            <Button
              colorScheme="green"
              variant="ghost"
              color="green.500"
              size="md"
              data-testid="add-waste-disposal-button"
              onClick={onOpen}
            >
              Add waste disposal
            </Button>
          )}
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          <Paginator
            onChangePage={(pageNumber) =>
              handleRefetch({
                ...filter,
                page: pageNumber,
              })
            }
            perPage={filter.perPage || 20}
            currentPage={filter.page || 1}
            totalResults={count}
          />
        </HStack>

        <Table
          isLoading={loading}
          dataTestId="waste-disposals-table"
          headers={[
            {
              title: 'Removal Projects',
            },
            {
              title: 'Drop Off',
            },
          ]}
          data={wasteDisposals || []}
          renderItem={renderRow}
          filter={filter}
        />

        <WasteDisposalDrawer
          isOpen={isOpen}
          onClose={onClose}
          initialValues={{
            id: undefined,
            date: moment().toDate(),
            signatureDate: moment().toDate(),
          }}
        />
      </Box>
    );
  },
);
