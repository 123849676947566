import React from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { formatValue } from 'react-currency-input-field';
import draftToHtml from 'draftjs-to-html';
import logo from '../lib/images/logo.png';
import {
  Progress,
  Box,
  Stack,
  Image,
  Text,
  HStack,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import jillSignature from '../lib/images/jill-signature.png';
import { QuoteInterface } from 'jesco-web';
import { useQuery } from '@apollo/client';
import { GET_QUOTE_QUERY } from '../services/apollo/queries';
import { RemovalAreaTable } from '../containers/RemovalProject/RemovalAreaTable';

import A4 from 'src/containers/Print/A4';

export interface QuotePrintPageProps {
  quoteId: number;
}

export const QuotePrintPage = ({ quoteId }: QuotePrintPageProps) => {
  const [isPrinting] = useMediaQuery('print');
  const { loading, data } = useQuery<{
    getQuote: QuoteInterface;
  }>(GET_QUOTE_QUERY, {
    variables: {
      id: quoteId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const quote = data.getQuote;

  const body = EditorState.createWithContent(
    convertFromRaw(JSON.parse(quote.body)),
  );

  return (
    <Box
      bg={isPrinting ? '#fff' : '#ccc'}
      width="100%"
      py={isPrinting ? 0 : '20px'}
      color="black"
      borderColor="black"
      margin="0 auto"
    >
      <A4 minHeight="297mm">
        <HStack justify="space-between">
          <Text color="blue.500">Jesco Asbestos Removal</Text>
          <Image src={logo} height="50px" />
        </HStack>
        <Box mt="15px">
          <Text>
            {moment(quote.date_formatted, 'LLL').format('D MMMM YYYY')}
          </Text>
        </Box>
        <Stack spacing={0} mt="15px">
          <Text>{quote.name}</Text>
          <Text>{quote.street}</Text>
          <Text>
            {quote.city} {quote.State.abbrev} {quote.zip ? quote.zip : ''}
          </Text>
        </Stack>
        <Box mt="15px">
          <Box className="quote-body">
            {ReactHtmlParser(
              draftToHtml(convertToRaw(body.getCurrentContent())),
            )}
          </Box>
        </Box>

        <HStack justify="space-between" mt="15px">
          <Text fontSize="sm" fontWeight="bold">
            The removal areas include:
          </Text>
        </HStack>

        <Box mt="15px">
          <RemovalAreaTable
            isLoading={false}
            removalAreas={quote.RemovalAreas}
            emptyMessage="No removal areas yet"
          />
        </Box>

        <HStack justify="space-between" mt="15px">
          <Text fontSize="sm" fontWeight="bold" textDecoration="underline">
            Pricing
          </Text>
        </HStack>

        <Stack spacing="8px" mt="10px">
          {quote.QuoteItems.map((pricingItem) => (
            <HStack
              key={pricingItem.id}
              justifyContent="space-between"
              px="5px"
              borderBottom="1px solid"
              borderColor="gray.300"
              pb="5px"
            >
              <Text>{pricingItem.description}</Text>

              <Box
                minWidth="100px"
                borderLeft="1px solid"
                pl="20px"
                borderColor="gray.300"
                textAlign="right"
              >
                <Text>
                  {formatValue({
                    value: (pricingItem.price / 100).toFixed(2),
                    groupSeparator: ',',
                    decimalSeparator: '.',
                    prefix: '$',
                  })}
                </Text>
              </Box>
            </HStack>
          ))}
        </Stack>

        <HStack justify="flex-end" mt="20px" fontSize="md">
          <Text fontWeight="bold">Total</Text>
          <Text fontWeight="bold">
            {formatValue({
              value: quote.QuoteItems.length
                ? quote.QuoteItems.reduce((state, p) => {
                    return (state += Number(p.price / 100));
                  }, 0).toFixed(2)
                : '0.00',
              groupSeparator: ',',
              decimalSeparator: '.',
              prefix: '$',
            })}{' '}
            + GST
          </Text>
        </HStack>
        <Stack mt="20px" spacing="15px">
          <Text>Please give us a call if you have any questions.</Text>

          <Text>I look forward to hearing from you.</Text>

          <Stack>
            <Text>Regards,</Text>
            <Image height="50px" width="100px" src={jillSignature} />
            <Text>Jill Krogh</Text>
          </Stack>
        </Stack>

        <Flex justify="flex-end">
          <Stack spacing={0}>
            <Box bg="blue.500" height="3px" width="200px" mb="5px" />
            <Stack pl="2px" spacing={0}>
              <Text>admin@jescoasbestos.com.au</Text>
              <Text>0411 067 700</Text>
              <Text fontSize="10px">17 Capital Terrace</Text>
              <Text fontSize="10px">Queanbeyan NSW 2620</Text>
              <Text fontSize="9px">Lic. ARA00003</Text>
              <Box>
                <Text mt="15px" color="blue.500" textTransform="uppercase">
                  Without a Trace
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Flex>
      </A4>
    </Box>
  );
};
