import React, { useState } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { formatValue } from 'react-currency-input-field';
import draftToHtml from 'draftjs-to-html';
import logo from '../lib/images/logo.png';
import {
  Progress,
  Box,
  Button,
  Stack,
  Image,
  Text,
  useDisclosure,
  HStack,
  Select,
  IconButton,
  Input,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputLeftElement,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
} from '@chakra-ui/react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import jillSignature from '../lib/images/jill-signature.png';
import {
  QuoteInterface,
  StateInterface,
  RemovalOptionInterface,
} from 'jesco-web';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_ALL_REMOVAL_OPTIONS,
  GET_QUOTE_QUERY,
} from '../services/apollo/queries';
import { Section } from '../containers/Section';
import { useAuthContext } from '../providers/Auth.Provider';
import { QuoteDrawer } from 'src/containers/Quote/QuoteDrawer';
import { v4 } from 'uuid';
import { FaEllipsisH, FaTimes } from 'react-icons/fa';
import {
  UPDATE_QUOTE_DETAILS,
  UPDATE_QUOTE_STATUS,
} from '../services/apollo/mutations';
import { RemovalOptionEditButton } from 'src/containers/RemovalProject/RemovalOptionEditButton';
import { RemovalAreaTable } from '../containers/RemovalProject/RemovalAreaTable';
import { QuoteFooter } from '../containers/Quote/QuoteFooter';
import { QuoteNotes } from 'src/containers/Quote/QuoteNotes';

export interface QuotePageProps {
  states: StateInterface[];
  quoteId: number;
}

interface RemovalAreaRow {
  id: number | string;
  area_id: number | undefined;
  location_id: number | undefined;
  material_id: number | undefined;
  type_id: number | undefined;
  condition_id: number | undefined;
  quote_price: string | undefined;
}

interface PricingRow {
  id: number | string;
  description: string;
  price: string;
}

export const QuotePage = ({ quoteId, states }: QuotePageProps) => {
  const { me } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [body, setBody] = useState(EditorState.createEmpty());
  const [removalAreas, setRemovalAreas] = useState<RemovalAreaRow[]>([]);
  const [pricing, setPricing] = useState<PricingRow[]>([]);
  const { loading, data, refetch } = useQuery<{
    getQuote: QuoteInterface;
  }>(GET_QUOTE_QUERY, {
    variables: {
      id: quoteId,
    },
    fetchPolicy: 'network-only',
    onCompleted(d) {
      setPricing(
        d.getQuote.QuoteItems.map((pi) => ({
          ...pi,
          price: (pi.price / 100).toFixed(2),
        })),
      );
      setRemovalAreas(
        d.getQuote.RemovalAreas.map((ra) => ({
          ...ra,
          quote_price: (ra.quote_price / 100).toFixed(2),
        })),
      );
      setBody(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(d.getQuote.body)),
        ),
      );
    },
  });

  React.useEffect(() => {
    setPricing(
      pricing.map((p, i) => {
        if (i === 0) {
          return {
            ...p,
            price: removalAreas
              .reduce((state, item) => {
                return (state += Number(item.quote_price));
              }, 0)
              .toFixed(2),
          };
        }

        return p;
      }),
    );
  }, [removalAreas, pricing]);

  const {
    loading: removalAreasLoading,
    data: removalAreasData,
    refetch: refetchRemovalOptions,
  } = useQuery<{
    allRemovalOptions: {
      areas: RemovalOptionInterface[];
      locations: RemovalOptionInterface[];
      materials: RemovalOptionInterface[];
      types: RemovalOptionInterface[];
      conditions: RemovalOptionInterface[];
    };
  }>(GET_ALL_REMOVAL_OPTIONS);

  const [updateQuoteDetails, { loading: updateDetailsLoading }] = useMutation<{
    updateQuoteDetails: { status: boolean };
  }>(UPDATE_QUOTE_DETAILS, {
    onCompleted(d) {
      refetch();
      setIsEditing(false);
    },
  });

  const [updateQuoteStatus, { loading: updateStatusLoading }] = useMutation<{
    updateQuoteStatus: { status: boolean };
  }>(UPDATE_QUOTE_STATUS, {
    onCompleted(d) {
      refetch();
      setIsEditing(false);
    },
  });

  if (!data || !removalAreasData) {
    return null;
  }

  const quote = data.getQuote;
  const removalOptions = removalAreasData.allRemovalOptions;

  const handleAddRemovalArea = () => {
    setRemovalAreas([
      ...removalAreas,
      {
        id: v4(),
        area_id: undefined,
        material_id: undefined,
        location_id: undefined,
        condition_id: undefined,
        type_id: undefined,
        quote_price: '0.00',
      },
    ]);
  };

  const handleAddPricing = () => {
    setPricing([
      ...pricing,
      {
        id: v4(),
        description: '',
        price: '0.00',
      },
    ]);
  };

  const handleSave = async () => {
    return updateQuoteDetails({
      variables: {
        id: quote.id,
        body: JSON.stringify(convertToRaw(body.getCurrentContent())),
        removalAreas: removalAreas.map((ra) => ({
          areaId: ra.area_id,
          locationId: ra.location_id,
          materialId: ra.material_id,
          typeId: ra.type_id,
          conditionId: ra.condition_id,
          price: Number(ra.quote_price) * 100,
        })),
        pricing: pricing.map((p) => {
          return {
            description: p.description,
            price: Number(p.price) * 100,
          };
        }),
      },
    });
  };

  const handleMarkPre = async () => {
    handleSave();

    updateQuoteStatus({
      variables: {
        id: quote.id,
        status: 'pre',
      },
    });
  };

  const handleMarkApproved = async () => {
    handleSave();

    updateQuoteStatus({
      variables: {
        id: quote.id,
        status: 'approved',
      },
    });
  };

  const isSaveDisabled = Boolean(
    removalAreas.filter(
      (ra) =>
        ra.area_id === undefined ||
        ra.condition_id === undefined ||
        ra.location_id === undefined ||
        ra.material_id === undefined ||
        ra.type_id === undefined,
    ).length,
  );

  return (
    <>
      {(loading || removalAreasLoading || updateDetailsLoading) && (
        <Box width="100%" position="relative">
          <Progress
            position="absolute"
            top="0"
            left="0"
            right="0"
            height=".15rem"
            isIndeterminate
          />
        </Box>
      )}

      <Box
        bg="white"
        width="100%"
        p={['5px', '10px', '20px']}
        maxWidth="1000px"
      >
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Stack
            direction="column"
            justify={['center', 'space-between', 'space-between']}
            align={['center', 'flex-start', 'flex-start']}
          >
            <Button
              fontSize="3xl"
              mb="10px"
              data-testid="edit-removal-project-button"
              aria-label="edit-removal-project-button"
              variant="link"
              isDisabled={!Boolean(me?.Permissions?.can_edit_quotes)}
              colorScheme="green"
              color="green.500"
              onClick={onOpen}
            >
              <Text maxWidth="500px" isTruncated>
                {quote.name}
              </Text>
            </Button>
            <Stack isInline mb="10px" align="center">
              <Text fontSize="xl" color="textSecondary" as="span">
                {`${quote.street}, ${quote.city} ${quote.State?.abbrev}`}
              </Text>
            </Stack>
          </Stack>
          <Stack align="flex-end">
            <Text color="gray.500" fontSize="lg">
              {quote.statusString}
            </Text>
            <HStack>
              {isEditing && (
                <Button
                  colorScheme="green"
                  isLoading={updateDetailsLoading}
                  onClick={handleSave}
                  isDisabled={isSaveDisabled}
                >
                  Save
                </Button>
              )}
              {isEditing && (
                <Button
                  isLoading={updateDetailsLoading}
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              )}

              <Menu>
                <MenuButton
                  isLoading={updateDetailsLoading || updateStatusLoading}
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisH />}
                  size="md"
                  colorScheme="green"
                  rounded="md"
                />
                <MenuList>
                  <MenuItem
                    isDisabled={isEditing}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    Edit
                  </MenuItem>
                  {quote.status === 'pre' && (
                    <MenuItem
                      isLoading={updateStatusLoading}
                      onClick={handleMarkApproved}
                    >
                      Mark as "Approved"
                    </MenuItem>
                  )}
                  {quote.status === 'approved' && (
                    <MenuItem
                      isLoading={updateStatusLoading}
                      onClick={handleMarkPre}
                    >
                      Mark as "Pre"
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      window.open(`/quote/${quote.id}`, '_blank');
                    }}
                  >
                    Preview
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Stack>
        </Stack>

        <Box pb="15px">
          <QuoteNotes quote={quote} onComplete={refetch} />
        </Box>

        <Section
          width="100%"
          minWidth="300px"
          maxWidth={['auto', 'auto', '1000px']}
          alignSelf="flex-start"
        >
          <HStack justify="space-between">
            <Text color="blue.500">Jesco Asbestos Removal</Text>
            <Image src={logo} height="50px" />
          </HStack>
          <Box mt="15px">
            <Text>
              {moment(quote.date_formatted, 'LLL').format('D MMMM YYYY')}
            </Text>
          </Box>
          <Stack spacing={0} mt="15px">
            <Text>{quote.name}</Text>
            <Text>{quote.street}</Text>
            <Text>
              {quote.city} {quote.State.abbrev} {quote.zip ? quote.zip : ''}
            </Text>
          </Stack>
          <Box mt="15px">
            {isEditing && (
              <Editor
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'emoji',
                  ],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                  },
                  list: {
                    options: ['unordered', 'ordered'],
                  },
                }}
                editorState={body}
                onEditorStateChange={setBody}
              />
            )}

            {!isEditing && (
              <Box className="quote-body">
                {ReactHtmlParser(
                  draftToHtml(convertToRaw(body.getCurrentContent())),
                )}
              </Box>
            )}
          </Box>

          <HStack justify="space-between" mt="15px">
            <Text fontSize="sm" fontWeight="bold">
              The removal areas include:
            </Text>
            {isEditing && (
              <Button
                variant="link"
                colorScheme="green"
                onClick={handleAddRemovalArea}
              >
                Add new removal area
              </Button>
            )}
          </HStack>

          {isEditing && (
            <Stack mt="15px" spacing="8px">
              {removalAreas.map((removalArea) => (
                <HStack key={removalArea.id} mt="10px">
                  <Select
                    data-testid="removal-area-form-area"
                    name="areaId"
                    placeholder="Select an area"
                    value={removalArea.area_id}
                    onChange={(e) => {
                      setRemovalAreas(
                        removalAreas.map((ra) => {
                          if (ra.id === removalArea.id) {
                            return {
                              ...ra,
                              area_id: Number(e.target.value),
                            };
                          }

                          return ra;
                        }),
                      );
                    }}
                  >
                    {removalOptions.areas.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    data-testid="removal-area-form-location"
                    name="locationId"
                    placeholder="Select a location"
                    value={removalArea.location_id}
                    onChange={(e) => {
                      setRemovalAreas(
                        removalAreas.map((ra) => {
                          if (ra.id === removalArea.id) {
                            return {
                              ...ra,
                              location_id: Number(e.target.value),
                            };
                          }

                          return ra;
                        }),
                      );
                    }}
                  >
                    {removalOptions.locations.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    data-testid="removal-area-form-material"
                    name="materialId"
                    placeholder="Select a material"
                    value={removalArea.material_id}
                    onChange={(e) => {
                      setRemovalAreas(
                        removalAreas.map((ra) => {
                          if (ra.id === removalArea.id) {
                            return {
                              ...ra,
                              material_id: Number(e.target.value),
                            };
                          }

                          return ra;
                        }),
                      );
                    }}
                  >
                    {removalOptions.materials.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    data-testid="removal-area-form-type"
                    name="typeId"
                    placeholder="Select a type"
                    value={removalArea.type_id}
                    onChange={(e) => {
                      setRemovalAreas(
                        removalAreas.map((ra) => {
                          if (ra.id === removalArea.id) {
                            return {
                              ...ra,
                              type_id: Number(e.target.value),
                            };
                          }

                          return ra;
                        }),
                      );
                    }}
                  >
                    {removalOptions.types.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    data-testid="removal-area-form-condition"
                    name="conditionId"
                    placeholder="Select a condition"
                    value={removalArea.condition_id}
                    onChange={(e) => {
                      setRemovalAreas(
                        removalAreas.map((ra) => {
                          if (ra.id === removalArea.id) {
                            return {
                              ...ra,
                              condition_id: Number(e.target.value),
                            };
                          }

                          return ra;
                        }),
                      );
                    }}
                  >
                    {removalOptions.conditions.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>
                  <Box>
                    <NumberInput
                      width="100px"
                      min={0}
                      precision={2}
                      value={removalArea.quote_price}
                      step={0.2}
                      onChange={(e) => {
                        setRemovalAreas(
                          removalAreas.map((ra) => {
                            if (ra.id === removalArea.id) {
                              return {
                                ...ra,
                                quote_price: e,
                              };
                            }

                            return ra;
                          }),
                        );
                      }}
                    >
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                          children="$"
                        />
                        <NumberInputField pl="30px" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </InputGroup>
                    </NumberInput>
                  </Box>
                  <IconButton
                    aria-label="remove-removal-area-row"
                    variant="ghost"
                    colorScheme="green"
                    icon={<FaTimes />}
                    onClick={() => {
                      setRemovalAreas(
                        removalAreas.filter((ra) => ra.id !== removalArea.id),
                      );
                    }}
                  />
                </HStack>
              ))}

              <HStack justify="flex-end">
                {me && (
                  <RemovalOptionEditButton
                    me={me}
                    onClose={refetchRemovalOptions}
                  />
                )}
              </HStack>
            </Stack>
          )}

          {!isEditing && (
            <Box mt="15px">
              <RemovalAreaTable
                isLoading={false}
                removalAreas={quote.RemovalAreas}
                emptyMessage="No removal areas yet"
              />
            </Box>
          )}

          <HStack justify="space-between" mt="15px">
            <Text fontSize="sm" fontWeight="bold">
              Pricing
            </Text>
            {isEditing && (
              <Button
                variant="link"
                colorScheme="green"
                onClick={handleAddPricing}
              >
                Add new pricing item
              </Button>
            )}
          </HStack>

          {isEditing && (
            <Stack spacing="8px" mt="10px">
              {pricing.map((pricingItem, index) => {
                return (
                  <FormControl ey={pricingItem.id}>
                    <HStack>
                      <Input
                        as="textarea"
                        fontSize="sm"
                        minHeight="50px"
                        py="5px"
                        value={pricingItem.description}
                        placeholder="Description"
                        onChange={(e) => {
                          setPricing(
                            pricing.map((p) => {
                              if (p.id === pricingItem.id) {
                                return {
                                  ...p,
                                  description: e.target.value,
                                };
                              }

                              return p;
                            }),
                          );
                        }}
                      />

                      <NumberInput
                        width="150px"
                        min={0}
                        precision={2}
                        value={pricingItem.price}
                        isDisabled={index === 0}
                        step={0.2}
                        onChange={(e) => {
                          setPricing(
                            pricing.map((p) => {
                              if (p.id === pricingItem.id) {
                                return {
                                  ...p,
                                  price: e,
                                };
                              }

                              return p;
                            }),
                          );
                        }}
                      >
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            color="gray.300"
                            fontSize="1.2em"
                            children="$"
                          />
                          <NumberInputField pl="30px" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </InputGroup>
                      </NumberInput>

                      <IconButton
                        isDisabled={index === 0}
                        aria-label="pricing-row-remove"
                        variant="ghost"
                        colorScheme="green"
                        icon={<FaTimes />}
                        onClick={() => {
                          setPricing(
                            pricing.filter((p) => p.id !== pricingItem.id),
                          );
                        }}
                      />
                    </HStack>
                  </FormControl>
                );
              })}
            </Stack>
          )}

          {!isEditing && (
            <Stack spacing="8px" mt="10px">
              {pricing.map((pricingItem) => (
                <HStack
                  key={pricingItem.id}
                  justifyContent="space-between"
                  px="5px"
                  borderBottom="1px solid"
                  borderColor="gray.300"
                  pb="5px"
                >
                  <Text>{pricingItem.description}</Text>

                  <Box
                    minWidth="100px"
                    borderLeft="1px solid"
                    pl="20px"
                    borderColor="gray.300"
                    textAlign="right"
                  >
                    <Text>
                      {formatValue({
                        value: pricingItem.price,
                        groupSeparator: ',',
                        decimalSeparator: '.',
                        prefix: '$',
                      })}
                    </Text>
                  </Box>
                </HStack>
              ))}
            </Stack>
          )}
          <HStack justify="flex-end" mt="20px" fontSize="md">
            <Text fontWeight="bold">Total</Text>
            <Text fontWeight="bold">
              {formatValue({
                value: pricing.length
                  ? pricing
                      .reduce((state, p) => {
                        return (state += Number(p.price));
                      }, 0)
                      .toFixed(2)
                  : '0.00',
                groupSeparator: ',',
                decimalSeparator: '.',
                prefix: '$',
              })}{' '}
              + GST
            </Text>
          </HStack>
          <Stack mt="20px" spacing="15px">
            <Text>Please give us a call if you have any questions.</Text>

            <Text>I look forward to hearing from you.</Text>

            <Stack>
              <Text>Regards,</Text>
              <Image height="50px" width="100px" src={jillSignature} />
              <Text>Jill Krogh</Text>
            </Stack>
          </Stack>

          <Flex justify="flex-end">
            <QuoteFooter />
          </Flex>
        </Section>

        <QuoteDrawer
          isOpen={isOpen}
          onClose={onClose}
          onComplete={refetch}
          states={states}
          initialValues={{
            id: quote.id,
            street: quote.street,
            city: quote.city,
            zip: String(quote.zip),
            stateId: quote.state_id,
            name: quote.name,
            notes: quote.notes,
            date: moment(quote.date_formatted, 'LLL').toDate(),
            endDate: moment(quote.end_date_formatted, 'LLL').toDate(),
          }}
        />
      </Box>
    </>
  );
};
