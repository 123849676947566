import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { DatePicker } from 'antd';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
} from '../../services/apollo/mutations';
import { FaTrash } from 'react-icons/fa';
import {
  IconButton,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Button,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  useToast,
} from '@chakra-ui/react';

export interface EmployeeFormValues {
  id: number | null;
  first: string;
  last: string;
  start_date: Date;
}

export interface EmployeeDrawerProps {
  isOpen: boolean;
  onComplete: () => void;
  onClose: () => void;
  initialValues: EmployeeFormValues;
}

export const EmployeeDrawer = ({
  isOpen,
  onComplete,
  onClose,
  initialValues,
}: EmployeeDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });

  const [createEmployee, { loading: createEmployeeLoading }] = useMutation(
    CREATE_EMPLOYEE,
    {
      onCompleted(d) {
        if (!d || !d.createEmployee || !d.createEmployee.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Employee created',
          description: `Employee created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateEmployee, { loading: updateEmployeeLoading }] = useMutation(
    UPDATE_EMPLOYEE,
    {
      onCompleted(d) {
        if (!d || !d.updateEmployee || !d.updateEmployee.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Employee updated',
          description: `Employee updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteEmployee, { loading: deleteEmployeeLoading }] = useMutation(
    DELETE_EMPLOYEE,
    {
      onCompleted(d) {
        if (!d || !d.deleteEmployee || !d.deleteEmployee.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Employee deleted',
          description: `Employee deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      first: Yup.string().min(1).required(),
      last: Yup.string().min(1).required(),
      start_date: Yup.string().required(),
    }),
    onSubmit: (values: EmployeeFormValues) => {
      if (!values.id) {
        createEmployee({
          variables: {
            ...values,
            startDate: moment(values.start_date).format('YYYY-MM-DD'),
          },
        });
      } else {
        updateEmployee({
          variables: {
            ...values,
            startDate: moment(values.start_date).format('YYYY-MM-DD'),
          },
        });
      }
    },
  });

  const isLoading =
    createEmployeeLoading || updateEmployeeLoading || deleteEmployeeLoading;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">
              {formik.values.id
                ? `${formik.values.first} ${formik.values.last}`
                : 'New employee'}
            </Text>
            {formik.values && formik.values.id && (
              <IconButton
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    deleteEmployee({
                      variables: {
                        id: formik.values.id,
                      },
                    });
                  }
                }}
                data-testid="delete-employee-button"
                aria-label="delete-employee"
                colorScheme="red"
                isLoading={isLoading}
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody>
            <Stack spacing={5} mt="20px">
              <HStack alignItems="flex-start">
                <FormControl
                  id="first"
                  isInvalid={
                    Boolean(formik.errors.first) && formik.touched.first
                  }
                  isRequired
                >
                  <FormLabel>First</FormLabel>
                  <Input
                    data-testid="employee-form-first"
                    autoFocus
                    name="first"
                    isRequired
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first}
                  />
                  <FormErrorMessage>{formik.errors.first}</FormErrorMessage>
                </FormControl>

                <FormControl
                  id="last"
                  isInvalid={Boolean(formik.errors.last) && formik.touched.last}
                  isRequired
                >
                  <FormLabel>Last</FormLabel>
                  <Input
                    data-testid="employee-form-last"
                    name="last"
                    isRequired
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last}
                  />
                  <FormErrorMessage>{formik.errors.last}</FormErrorMessage>
                </FormControl>
              </HStack>

              <FormControl
                id="start_date"
                data-testid="employee-form-start-date"
              >
                <FormLabel>
                  <HStack justifyContent="space-between">
                    <Text>Start date</Text>
                    <Text>
                      {moment(formik.values.start_date).format('D MMMM, YYYY')}
                    </Text>
                  </HStack>
                </FormLabel>

                <DatePicker
                  format="DD/MM/YYYY"
                  value={moment(formik.values.start_date).clone()}
                  onChange={(d) => {
                    formik.setFieldValue('start_date', d?.toDate());
                  }}
                />
              </FormControl>

              {formik.values.id && (
                <Input
                  data-testid="employee-form-id"
                  name="id"
                  value={Number(formik.values.id)}
                  type="hidden"
                />
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTop="1px solid" borderColor="gray.300">
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              isDisabled={!formik.isValid || !formik.dirty}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
