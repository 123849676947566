import React, { useState, useRef } from 'react';
import {
  Button,
  Box,
  Stack,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  Image,
  DrawerFooter,
} from '@chakra-ui/react';
import { EmployeeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import {
  GET_SIGNED_URL,
  UPLOAD_EMPLOYEE_SIGNATURE,
} from '../../services/apollo/mutations';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';

export interface EmployeeSignatureProps {
  onComplete(): void;
  employee?: EmployeeInterface;
  isOpen: boolean;
  onClose(): void;
}

export const EmployeeSignatureDrawer = ({
  onComplete,
  employee,
  isOpen,
  onClose,
}: EmployeeSignatureProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const sigRef = useRef<SignatureCanvas>(null);
  const [signature, setSignature] = useState<string>();

  const onClearSignature = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  };

  const onSaveSignature = () => {
    if (sigRef.current) {
      setSignature(sigRef.current.getCanvas().toDataURL('image/png'));
    }
  };

  const [uploadEmployeeSignature, { loading }] = useMutation(
    UPLOAD_EMPLOYEE_SIGNATURE,
    {
      onCompleted(d) {
        if (
          !d ||
          !d.uploadEmployeeSignature ||
          !d.uploadEmployeeSignature.status
        )
          return;

        onClose();
        onComplete();
        onClearSignature();
        setSignature(undefined);

        toast({
          title: 'Signature uploaded',
          description: `Signature uploaded`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onCompleted: async (data) => {
      if (data.getSignedUrl && signature && employee) {
        const signedUrl = data.getSignedUrl;
        const base64Response = await fetch(signature);
        const blob = await base64Response.blob();
        await axios.put(signedUrl, blob, {
          headers: {
            'Content-Type': 'image/png',
          },
        });

        toast({
          title: 'File uploaded',
          description: 'File successfully uploaded',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // drop the query params from the signed URL
        const path = signedUrl.split('?')[0];

        await uploadEmployeeSignature({
          variables: {
            path,
            name: `${employee?.first}-${employee?.last}-signature.png`,
            employeeId: Number(employee.id),
          },
        });
      }
    },
    onError: (error) => {
      toast({
        title: 'Upload failed',
        description: 'The file could not be uploaded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Failed to upload file:', error);
    },
  });

  const handleSubmit = () => {
    if (!employee) return;

    getSignedUrl({
      variables: {
        file: `${employee.first}-${employee.last}-signature.png`,
        type: 'image/png',
        folder: 'employee-signatures',
      },
    });
  };

  const handleClose = () => {
    setSignature(undefined);
    onClose();
  };

  if (!employee) return null;

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent rounded="md">
        <DrawerHeader
          px="40px"
          display="flex"
          fontSize="md"
          color="white"
          bg="blue.500"
        >
          {`${employee.first} ${employee.last} signature`}
        </DrawerHeader>
        <DrawerCloseButton
          left="0"
          top="5px"
          data-testid="drawer-close-button"
          onClick={handleClose}
          color="white"
        />
        <DrawerBody p="20px">
          <Stack>
            {signature && (
              <Stack>
                <Box
                  border="1px solid"
                  borderColor="gray.300"
                  rounded="md"
                  width="400px"
                >
                  <Image width={400} height={200} src={signature} />
                </Box>

                <HStack>
                  <Button onClick={() => setSignature(undefined)}>Clear</Button>
                </HStack>
              </Stack>
            )}

            {!signature && (
              <Stack>
                <Box
                  border="1px solid"
                  borderColor="gray.300"
                  rounded="md"
                  width="400px"
                  data-testid="signature-container"
                >
                  <SignatureCanvas
                    ref={sigRef}
                    penColor="black"
                    canvasProps={{
                      height: 200,
                      width: 400,
                      className: 'sigCanvas',
                    }}
                  />
                </Box>
                <HStack>
                  <Button onClick={onClearSignature}>Clear</Button>
                  <Button onClick={onSaveSignature}>Save</Button>
                </HStack>
              </Stack>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter
          justifyContent="space-evenly"
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <Button width="200px" size="md" mr="5px" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            width="200px"
            ml="5px"
            onClick={handleSubmit}
            variant="solid"
            colorScheme="blue"
            isLoading={loading}
            disabled={!Boolean(signature)}
          >
            Upload
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
