import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Flex,
  FlexProps,
  Image,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Stack,
  DrawerCloseButton,
  DrawerHeader,
  Text,
  Link,
  Tag,
  DrawerFooter,
  DrawerBody,
  HStack,
} from '@chakra-ui/react';
import { useAuthContext } from '../providers';
import logo from '../lib/images/logo.png';
import whiteLogo from '../lib/images/home-logo-white.png';
import { FaBars, FaSearch } from 'react-icons/fa';
import { SearchDrawer } from './SearchDrawer';

export interface NavbarPropsInterface extends RouteComponentProps, FlexProps {}

export const Navbar = withRouter(
  ({
    history: { push },
    location: { pathname },
    ...props
  }: RouteComponentProps) => {
    const { me, logout } = useAuthContext();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const {
      isOpen: isSearchDrawerOpen,
      onClose: onSearchDrawerClose,
      onOpen: onSearchDrawerOpen,
    } = useDisclosure();

    return (
      <>
        <Flex
          as="nav"
          p="1rem"
          width="100%"
          position="relative"
          zIndex={3}
          paddingX={['1rem', '1rem', '2rem']}
          bg="white"
          borderBottom="1px solid"
          borderColor="lightGray"
          {...props}
        >
          <Box
            width="100%"
            maxWidth={['1400px', '1400px', '1625px']}
            margin={pathname.includes('/dashboard') ? 'none' : '0 auto'}
          >
            <Flex width="100%" align="center" justify="space-between">
              <Button
                variant="solid"
                height="auto"
                colorScheme="white"
                backgroundColor="white"
                onClick={() => push('/home')}
              >
                <Image height="40px" src={logo} />
              </Button>

              <Box display={['initial', 'initial', 'none']}>
                <IconButton
                  colorScheme="green"
                  variant="ghost"
                  aria-label="hamburger-menu"
                  fontSize="18px"
                  icon={<FaBars />}
                  onClick={onOpen}
                />
              </Box>

              <HStack spacing="20px" display={['none', 'none', 'initial']}>
                {me && pathname.includes('dashboard') && (
                  <>
                    <IconButton
                      rounded="100%"
                      colorScheme="gray"
                      color="green.500"
                      aria-label="search-button"
                      icon={<FaSearch />}
                      onClick={onSearchDrawerOpen}
                    />
                  </>
                )}

                {me && !pathname.includes('dashboard') && (
                  <Button
                    variant="link"
                    size="md"
                    colorScheme="green"
                    onClick={() => push('/dashboard')}
                    bg="transparent"
                  >
                    Dashboard
                  </Button>
                )}

                {me && (
                  <Button
                    variant="ghost"
                    size="md"
                    colorScheme="green"
                    onClick={logout}
                  >
                    Sign out
                  </Button>
                )}
              </HStack>
            </Flex>
          </Box>
        </Flex>

        <Drawer size="full" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />

          <DrawerContent bg="rgba(45, 55, 72,1)">
            <DrawerCloseButton color="white" />
            <DrawerHeader>
              <Image height="60px" src={whiteLogo} alt="Mobile navbar logo" />
            </DrawerHeader>
            <DrawerBody justifyContent="center">
              <Stack align="center" spacing="10px">
                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  bg="transparent"
                  color={
                    pathname.includes('/dashboard/calendar')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  onClick={() => {
                    onClose();
                    push('/dashboard/calendar');
                  }}
                >
                  <HStack>
                    <Text>Calendar</Text>
                  </HStack>
                </Button>

                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  bg="transparent"
                  color={
                    pathname.includes('/dashboard/quotes')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  onClick={() => {
                    onClose();
                    push('/dashboard/quotes');
                  }}
                >
                  <HStack>
                    <Text>Quotes</Text>
                    <Tag colorScheme="teal" size="lg">
                      Beta
                    </Tag>
                  </HStack>
                </Button>
                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  color={
                    pathname.includes('/dashboard/removal-project')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  bg="transparent"
                  onClick={() => {
                    onClose();
                    push('/dashboard/removal-projects');
                  }}
                >
                  Removal projects
                </Button>

                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  color={
                    pathname.includes('/dashboard/people')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  bg="transparent"
                  onClick={() => {
                    onClose();
                    push('/dashboard/people');
                  }}
                >
                  People
                </Button>

                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  color={
                    pathname.includes('/dashboard/waste-disposal')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  bg="transparent"
                  onClick={() => {
                    onClose();
                    push('/dashboard/waste-disposals');
                  }}
                >
                  Waste disposals
                </Button>

                <Button
                  p="20px"
                  variant="link"
                  size="md"
                  color={
                    pathname.includes('/dashboard/files')
                      ? 'green.400'
                      : '#D8D8D8'
                  }
                  bg="transparent"
                  onClick={() => {
                    onClose();
                    push('/dashboard/files');
                  }}
                >
                  Files
                </Button>
              </Stack>
            </DrawerBody>
            <DrawerFooter justifyContent="center">
              <Stack align="center">
                <Link
                  rel="noopener noreferrer"
                  href="https://maps.google.com/maps?q=17 Capital Terrace Queanbeyan NSW, 2620"
                  fontWeight={300}
                  color="#CEE7F2"
                  target="_blank"
                >
                  17 Capital Terrace Queanbeyan NSW, 2620
                </Link>
                <Text fontWeight={300} color="#CEE7F2">
                  ABN: 64660657572
                </Text>
                <Link
                  color="#CEE7F2"
                  fontWeight={400}
                  href="mailto: admin@jescoasbestos.com.au"
                >
                  admin@jescoasbestos.com.au
                </Link>
              </Stack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <SearchDrawer
          isOpen={isSearchDrawerOpen}
          onClose={onSearchDrawerClose}
        />
      </>
    );
  },
);
