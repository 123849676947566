import React, { useState } from 'react';
import moment from 'moment';
import {
  Progress,
  Box,
  Button,
  Stack,
  IconButton,
  SimpleGrid,
  Text,
  HStack,
  Divider,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { RemovalProjectInterface, WasteDisposalInterface } from 'jesco-web';
import { SearchInput } from '../containers/Form/SearchInput';
import { useQuery, useMutation } from '@apollo/client';
import { Header } from '../containers/Text/Header';
import { TableFilterInterface } from '../containers/Table';
import {
  GET_WASTE_DISPOSAL_QUERY,
  GET_REMOVAL_PROJECTS_QUERY,
} from '../services/apollo/queries';
import { Section } from '../containers/Section';
import {
  ADD_REMOVAL_PROJECT_TO_WASTE_DISPOSAL,
  DELETE_FILE,
  REMOVE_REMOVAL_PROJECT_FROM_WASTE_DISPOSAL,
} from '../services/apollo/mutations';
import { FaPrint, FaTimes, FaPlus, FaCalendar, FaEye } from 'react-icons/fa';
import { WasteDisposalDrawer } from '../containers/WasteDisposal/WasteDisposalDrawer';
import { Link } from 'react-router-dom';
import { TipDocketDrawer } from 'src/containers/WasteDisposal/TipDocketDrawer';
import { useAuthContext } from '../providers/Auth.Provider';

export interface WasteDisposalPageProps {
  wasteDisposalId: number;
}

export const WasteDisposalPage = ({
  wasteDisposalId,
}: WasteDisposalPageProps) => {
  const { me } = useAuthContext();
  const toast = useToast({ position: 'bottom-left' });
  const {
    isOpen: isTipDocketDrawerOpen,
    onOpen: onOpenTipDocketDrawer,
    onClose: onCloseTipDocketDrawer,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 10,
  });

  const { data, refetch, loading } = useQuery<{
    wasteDisposal: WasteDisposalInterface;
  }>(GET_WASTE_DISPOSAL_QUERY, {
    variables: {
      id: wasteDisposalId,
    },
  });

  const { data: removalProjectsData } = useQuery<{
    removalProjects: {
      count: number;
      items: RemovalProjectInterface[];
    };
  }>(GET_REMOVAL_PROJECTS_QUERY, {
    variables: filter,
  });

  const [
    addRemovalProjectToWasteDisposal,
    { loading: isAddProjectLoading },
  ] = useMutation(ADD_REMOVAL_PROJECT_TO_WASTE_DISPOSAL, {
    onCompleted(d) {
      if (
        !d ||
        !d.addRemovalProjectToWasteDisposal ||
        !d.addRemovalProjectToWasteDisposal.success
      )
        return;

      refetch();
    },
  });

  const [
    removeRemovalProjectFromWasteDisposal,
    { loading: isRemoveProjectLoading },
  ] = useMutation(REMOVE_REMOVAL_PROJECT_FROM_WASTE_DISPOSAL, {
    onCompleted(d) {
      if (
        !d ||
        !d.removeRemovalProjectFromWasteDisposal ||
        !d.removeRemovalProjectFromWasteDisposal.success
      )
        return;

      refetch();
    },
  });

  const [deleteFile] = useMutation(DELETE_FILE, {
    onCompleted(d) {
      if (!d || !d.deleteFile || !d.deleteFile.id) return;

      onClose();
      refetch();

      toast({
        title: 'Tip docket deleted',
        description: `${d.deleteFile.name} deleted`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  let removalProjects: RemovalProjectInterface[] = [];
  if (removalProjectsData) {
    removalProjects = removalProjectsData.removalProjects.items;
  }

  const wasteDisposal = data.wasteDisposal;

  const isMutationLoading = isAddProjectLoading || isRemoveProjectLoading;
  const isLoading =
    isRemoveProjectLoading ||
    isAddProjectLoading ||
    isAddProjectLoading ||
    isRemoveProjectLoading;

  return (
    <Box
      bg="white"
      width="100%"
      p={['5px', '10px', '20px']}
      position="relative"
      maxWidth="1400px"
      pb="150px"
    >
      {isLoading && (
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      )}
      <Stack
        mb="20px"
        direction={['column', 'row', 'row']}
        justify={['center', 'space-between', 'space-between']}
        align="center"
      >
        <Stack>
          <Stack>
            <Button
              fontSize="3xl"
              data-testid="edit-waste-disposal-button"
              aria-label="edit-waste-disposal"
              variant="link"
              isDisabled={!Boolean(me?.Permissions?.can_edit_waste_disposals)}
              colorScheme="green"
              color="green.500"
              onClick={onOpen}
            >
              Waste disposal
            </Button>
            <HStack>
              <Box>
                <FaCalendar fontSize="18px" />
              </Box>
              <Text fontSize="xl" color="textSecondary">
                {`${moment(wasteDisposal.date, 'YYYY-MM-DD').format(
                  'D MMMM, YYYY',
                )}`}
              </Text>
            </HStack>
          </Stack>
        </Stack>
        <HStack>
          <IconButton
            as={Link}
            to={`/waste-disposal/${wasteDisposal.id}`}
            target="_blank"
            data-testid="print-waste-disposal-button"
            aria-label="print-waste-disposal"
            colorScheme="green"
            variant="outline"
            icon={<FaPrint />}
          />
        </HStack>
      </Stack>

      <SimpleGrid spacing="10px" columns={[1, 1, 2]}>
        <Section maxWidth={['auto', 'auto', '600px']}>
          <Stack mb="50px">
            <Header fontSize="xl" color="textSecondary" mb="10px">
              Removal projects
            </Header>
            {!wasteDisposal.RemovalProjects.length && (
              <Section>
                <Text fontSize="md" textAlign="center">
                  No removal projects added
                </Text>
              </Section>
            )}

            {wasteDisposal.RemovalProjects.map((removalProject) => (
              <React.Fragment key={removalProject.id}>
                <HStack
                  justify="space-between"
                  data-testid="waste-disposal-project-row"
                >
                  <Stack spacing={1}>
                    <Text fontSize="md">
                      {`${removalProject.address}, ${removalProject.city}, ${removalProject.State?.abbrev}`}
                    </Text>
                    <Text>
                      {removalProject.Client ? removalProject.Client.name : '-'}
                    </Text>
                  </Stack>
                  <IconButton
                    aria-label="remove-project"
                    data-testid="removal-project-button"
                    isDisabled={
                      !Boolean(me?.Permissions?.can_edit_waste_disposals)
                    }
                    variant="outline"
                    isLoading={isMutationLoading}
                    icon={<FaTimes />}
                    size="sm"
                    onClick={() => {
                      removeRemovalProjectFromWasteDisposal({
                        variables: {
                          removalProjectId: Number(removalProject.id),
                          wasteDisposalId: Number(wasteDisposal.id),
                        },
                      });
                    }}
                  />
                </HStack>
                <Divider />
              </React.Fragment>
            ))}
          </Stack>

          <Divider />

          <Stack mt="20px">
            <HStack justify="space-between">
              <Header fontSize="xl" color="gray.500" mb="10px">
                Recent projects
              </Header>
              <Box>
                <SearchInput
                  placeholder="Search by address"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      search: e.target.value || '',
                    });
                  }}
                  onClear={() => {
                    setFilter({
                      ...filter,
                      search: '',
                    });
                  }}
                  value={filter.search}
                />
              </Box>
            </HStack>

            <Divider />
            {removalProjects.map((removalProject) => {
              const isIncluded = wasteDisposal.RemovalProjects.find(
                (rp) => rp.id === removalProject.id,
              );

              return (
                <React.Fragment key={removalProject.id}>
                  <HStack
                    justify="space-between"
                    data-testid="recent-project-row"
                  >
                    <Stack spacing={1}>
                      <Text fontSize="md">
                        {`${removalProject.address}, ${removalProject.city}, ${removalProject.State?.abbrev}`}
                      </Text>
                      <Text>
                        {removalProject.Client
                          ? removalProject.Client.name
                          : '-'}
                      </Text>
                    </Stack>
                    {isIncluded && (
                      <IconButton
                        isDisabled={
                          !Boolean(me?.Permissions?.can_edit_waste_disposals)
                        }
                        data-testid="remove-project-button"
                        aria-label="remove-project"
                        variant="outline"
                        isLoading={isMutationLoading}
                        icon={<FaTimes />}
                        size="sm"
                        onClick={() => {
                          removeRemovalProjectFromWasteDisposal({
                            variables: {
                              removalProjectId: Number(removalProject.id),
                              wasteDisposalId: Number(wasteDisposal.id),
                            },
                          });
                        }}
                      />
                    )}

                    {!isIncluded && (
                      <IconButton
                        isDisabled={
                          !Boolean(me?.Permissions?.can_edit_waste_disposals)
                        }
                        data-testid="add-project-button"
                        aria-label="add-project"
                        size="sm"
                        colorScheme="gray"
                        icon={<FaPlus />}
                        isLoading={isMutationLoading}
                        onClick={() => {
                          addRemovalProjectToWasteDisposal({
                            variables: {
                              removalProjectId: Number(removalProject.id),
                              wasteDisposalId: Number(wasteDisposal.id),
                            },
                          });
                        }}
                      />
                    )}
                  </HStack>
                  <Divider />
                </React.Fragment>
              );
            })}
          </Stack>
        </Section>
        <Section alignSelf="flex-start" maxWidth={['auto', 'auto', '500px']}>
          <HStack justify="space-between" mb="10px">
            <Header fontSize="xl" color="textSecondary">
              Tip dockets
            </Header>
            <Button
              variant="link"
              size="sm"
              data-testid="add-tip-docket"
              onClick={onOpenTipDocketDrawer}
              isDisabled={!Boolean(me?.Permissions?.can_edit_waste_disposals)}
            >
              Add tip docket
            </Button>
          </HStack>
          {!wasteDisposal.TipDockets.length && (
            <Section>
              <Text fontSize="md" textAlign="center">
                No tip dockets
              </Text>
            </Section>
          )}

          {wasteDisposal.TipDockets.map((td) => (
            <React.Fragment key={td.id}>
              <HStack
                justify="space-between"
                mt="10px"
                data-testid="tip-docket-row"
              >
                <Stack spacing={1}>
                  <Text fontSize="md">{`${td.name}`}</Text>
                  <Text>{td.type}</Text>
                </Stack>
                <HStack>
                  <IconButton
                    data-testid="remove-tip-docket-button"
                    aria-label="remove-tip-docket-button"
                    variant="outline"
                    isLoading={isMutationLoading}
                    icon={<FaTimes />}
                    size="sm"
                    isDisabled={
                      !Boolean(me?.Permissions?.can_edit_waste_disposals)
                    }
                    onClick={() => {
                      if (window.confirm('Are you sure?')) {
                        deleteFile({
                          variables: {
                            id: td.id,
                          },
                        });
                      }
                    }}
                  />
                  <IconButton
                    data-testid="view-tip-docket"
                    aria-label="view-tip-docket"
                    variant="outline"
                    isLoading={isMutationLoading}
                    icon={<FaEye />}
                    size="sm"
                    onClick={() => {
                      window.open(td.path, '_blank');
                    }}
                  />
                </HStack>
              </HStack>
              <Divider />
            </React.Fragment>
          ))}
        </Section>
      </SimpleGrid>
      <WasteDisposalDrawer
        isOpen={isOpen}
        onClose={onClose}
        onComplete={refetch}
        wasteDisposal={wasteDisposal}
        initialValues={{
          id: wasteDisposal ? wasteDisposal.id : undefined,
          date: wasteDisposal
            ? moment(wasteDisposal.date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
          signatureDate: wasteDisposal
            ? moment(wasteDisposal.signature_date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
        }}
      />
      <TipDocketDrawer
        wasteDisposalId={wasteDisposalId}
        isOpen={isTipDocketDrawerOpen}
        onComplete={refetch}
        onClose={onCloseTipDocketDrawer}
      />
    </Box>
  );
};
