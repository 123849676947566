import React, { useState } from 'react';
import moment from 'moment';
import {
  Progress,
  Box,
  Button,
  Stack,
  SimpleGrid,
  Text,
  useDisclosure,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import {
  RemovalProjectInterface,
  StateInterface,
  ToolboxTalkInterface,
  EmployeeInterface,
} from 'jesco-web';
import { useQuery } from '@apollo/client';
import { FaChevronRight, FaPen, FaPlus } from 'react-icons/fa';
import { Details } from '../containers/Details';
import { GET_REMOVAL_PROJECT_QUERY } from '../services/apollo/queries';
import { Section } from '../containers/Section';
import { ToolboxTalkDrawer } from 'src/containers/ToolboxTalk/ToolboxTalkDrawer';
import { RemovalProjectDrawer } from 'src/containers/RemovalProject/RemovalProjectDrawer';
import { SWMSDrawer } from 'src/containers/SWMS/SWMSDrawer';
import { RemovalProjectUploads } from 'src/containers/RemovalProject/RemovalProjectUploads';
import { RemovalProjectSignatures } from 'src/containers/RemovalProject/RemovalProjectSignatures';
import { RemovalProjectRemovalAreas } from 'src/containers/RemovalProject/RemovalProjectRemovalAreas';
import arcpBg from '../lib/images/arcp-bg.png';
import swmsBg from '../lib/images/swms-bg.png';
import toolboxTalkBg from '../lib/images/toolbox-talk-bg.png';
import { useAuthContext } from '../providers/Auth.Provider';

export interface RemovalProjectPageProps {
  removalProjectId: number;
  states: StateInterface[];
  employees: EmployeeInterface[];
}

export const RemovalProjectPage = ({
  removalProjectId,
  states,
  employees,
}: RemovalProjectPageProps) => {
  const { me } = useAuthContext();
  const {
    isOpen: isToolboxTalkDrawerOpen,
    onOpen: onOpenToolboxTalkDrawer,
    onClose: onCloseToolboxTalkDrawer,
  } = useDisclosure();
  const {
    isOpen: isSWMSDrawerOpen,
    onOpen: onOpenSWMSDrawer,
    onClose: onCloseSWMSDrawer,
  } = useDisclosure();
  const {
    isOpen: isRemovalProjectDrawerOpen,
    onOpen: onOpenRemovalProjectDrawer,
    onClose: onCloseRemovalProjectDrawer,
  } = useDisclosure();
  const [activeToolboxTalk, setActiveToolboxTalk] =
    useState<ToolboxTalkInterface | null>(null);

  const { loading, data, refetch } = useQuery<{
    removalProject: RemovalProjectInterface;
  }>(GET_REMOVAL_PROJECT_QUERY, {
    variables: {
      id: removalProjectId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const removalProject = data.removalProject;

  return (
    <Box bg="white" width="100%" p={['5px', '10px', '20px']} maxWidth="1400px">
      <Stack
        mb="20px"
        direction={['column', 'row', 'row']}
        justify={['center', 'space-between', 'space-between']}
        align="center"
      >
        <Stack
          direction="column"
          justify={['center', 'space-between', 'space-between']}
          align={['center', 'flex-start', 'flex-start']}
        >
          <Button
            fontSize="3xl"
            mb="10px"
            data-testid="edit-removal-project-button"
            aria-label="edit-removal-project-button"
            variant="link"
            isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
            colorScheme="green"
            color="green.500"
            onClick={(e) => {
              onOpenRemovalProjectDrawer();
            }}
          >
            {data.removalProject.Client
              ? data.removalProject.Client.name
              : 'No client'}
          </Button>
          <Stack isInline mb="10px" align="center">
            <Text fontSize="xl" color="textSecondary" as="span">
              {`${data.removalProject.address}, ${data.removalProject.city}, ${data.removalProject.State?.abbrev}`}
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <SimpleGrid spacing="20px" columns={[1, 1, 2]} maxWidth="1200px">
        <Section
          width="100%"
          minWidth="300px"
          maxWidth={['auto', 'auto', '550px']}
          alignSelf="flex-start"
        >
          <Stack>
            <Text color="header" fontSize="lg">
              Project details
            </Text>
            <Details
              label="Commencement"
              body={moment(data.removalProject.start_date, 'YYYY-MM-DD').format(
                'D MMMM, YYYY',
              )}
            />

            <Details
              label="Completion"
              body={
                moment(data.removalProject.end_date, 'YYYY-MM-DD').isSame(
                  moment(data.removalProject.start_date, 'YYYY-MM-DD'),
                )
                  ? 'Same day'
                  : moment(data.removalProject.end_date, 'YYYY-MM-DD').format(
                      'D MMMM, YYYY',
                    )
              }
            />
            <Details
              label="Quantity"
              body={data.removalProject.quantityString}
            />
            <Details
              label="Assessor"
              body={
                data.removalProject.Assessor
                  ? data.removalProject.Assessor.name
                  : '-'
              }
            />
            <Details
              label="Nominated Supervisor / Emergency Contact"
              body={
                data.removalProject.Supervisor
                  ? data.removalProject.Supervisor.name
                  : '-'
              }
            />

            <Details
              label="Regulatory Notification"
              body={String(data.removalProject.worksafe_no)}
            />
            {data.removalProject.notes && (
              <Details
                direction="column"
                label="Notes"
                align="flex-start"
                body={data.removalProject.notes}
              />
            )}
          </Stack>
        </Section>

        <RemovalProjectRemovalAreas
          onComplete={refetch}
          removalProject={data.removalProject}
        />

        <RemovalProjectUploads
          removalProject={data.removalProject}
          onComplete={refetch}
        />

        <RemovalProjectSignatures
          employees={employees}
          removalProject={data.removalProject}
          onComplete={refetch}
        />
      </SimpleGrid>

      <Box mt="10px" overflowX={['auto', 'auto', 'initial']}>
        <Box minWidth="fit-content" width="100%">
          <Text color="textSecondary" fontSize="lg">
            Forms
          </Text>
          <HStack my="20px" align="center">
            <Button
              border="1px solid"
              borderColor="gray.200"
              height="200px"
              width="170px"
              rounded="md"
              variant="ghost"
              colorScheme="transparent"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
              mr="10px"
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundImage={`url(${arcpBg})`}
              onClick={() => {
                window.open(`/arcp/${data.removalProject.id}`, '_blank');
              }}
            >
              <Text backgroundColor="white" p="15px" rounded="md">
                ARCP
              </Text>
            </Button>

            <Button
              border="1px solid"
              borderColor="gray.200"
              height="200px"
              width="170px"
              rounded="md"
              variant="ghost"
              colorScheme="transparent"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundImage={`url(${swmsBg})`}
              onClick={() => {
                window.open(`/swms/${data.removalProject.id}`, '_blank');
              }}
            >
              <Text backgroundColor="white" p="15px" rounded="md">
                SWMS
              </Text>
              <HStack
                spacing="5px"
                position="absolute"
                bottom="0"
                right="0"
                p="5px"
              >
                <IconButton
                  data-testid="swms-edit-button"
                  aria-label="swms-edit-button"
                  isDisabled={
                    !Boolean(me?.Permissions?.can_edit_removal_projects)
                  }
                  icon={<FaPen />}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenSWMSDrawer();
                  }}
                  variant="outline"
                  colorScheme="yellow"
                />
              </HStack>
            </Button>

            <Box>
              <Box mx="20px" color="gray.200" as={FaChevronRight} />
            </Box>

            <Button
              borderColor="green.500"
              height="200px"
              width="170px"
              rounded="md"
              variant="ghost"
              colorScheme="transparent"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="1px dashed"
              onClick={onOpenToolboxTalkDrawer}
              data-testid="new-toolbox-talk-button"
              isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
            >
              <Box mr="5px" color="green.200" as={FaPlus} />
              Toolbox Talk
            </Button>

            {data.removalProject.ToolboxTalks.map((toolboxTalk) => (
              <Button
                key={toolboxTalk.id}
                border="1px solid"
                borderColor="gray.200"
                height="200px"
                width="170px"
                rounded="md"
                variant="ghost"
                colorScheme="transparent"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                data-testid="toolbox-talk-button"
                backgroundImage={`url(${toolboxTalkBg})`}
                onClick={() => {
                  window.open(`/toolbox-talk/${toolboxTalk.id}`, '_blank');
                }}
              >
                <Stack alignItems="center" bg="white" p="5px" rounded="md">
                  <Text fontSize="lg">ToolboxTalk</Text>
                  <Text fontWeight="300">
                    {moment(toolboxTalk.date, 'YYYY-MM-DD').format(
                      'D MMMM, YYYY',
                    )}
                  </Text>
                </Stack>
                <HStack
                  spacing="5px"
                  position="absolute"
                  bottom="0"
                  right="0"
                  p="5px"
                >
                  <IconButton
                    isDisabled={
                      !Boolean(me?.Permissions?.can_edit_removal_projects)
                    }
                    data-testid="toolbox-talk-edit-button"
                    aria-label="toolbox-talk-edit-button"
                    icon={<FaPen />}
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveToolboxTalk(toolboxTalk);
                      onOpenToolboxTalkDrawer();
                    }}
                    variant="outline"
                    colorScheme="yellow"
                  />
                </HStack>
                <Box hidden data-testid="toolbox-talk-id">
                  {toolboxTalk.id}
                </Box>
              </Button>
            ))}
          </HStack>
        </Box>
        <Box hidden data-testid="removal-project-id">
          {data.removalProject.id}
        </Box>
      </Box>

      <ToolboxTalkDrawer
        onClose={() => {
          refetch();
          onCloseToolboxTalkDrawer();
          setActiveToolboxTalk(null);
        }}
        onComplete={refetch}
        isOpen={isToolboxTalkDrawerOpen}
        initialValues={{
          id: activeToolboxTalk ? Number(activeToolboxTalk.id) : null,
          removalProjectId: activeToolboxTalk
            ? Number(activeToolboxTalk.removal_project_id)
            : removalProjectId,
          date: activeToolboxTalk
            ? moment(activeToolboxTalk.date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
          weatherId: activeToolboxTalk ? activeToolboxTalk.weather_id : 1,
          plannedActivities: activeToolboxTalk
            ? activeToolboxTalk.planned_activities
            : '',
          q1: activeToolboxTalk ? activeToolboxTalk.q_1 : 0,
          q2: activeToolboxTalk ? activeToolboxTalk.q_2 : 0,
          q3: activeToolboxTalk ? activeToolboxTalk.q_3 : 0,
          q4: activeToolboxTalk ? activeToolboxTalk.q_4 : 0,
          q5: activeToolboxTalk ? activeToolboxTalk.q_5 : 0,
          q6: activeToolboxTalk ? activeToolboxTalk.q_6 : 0,
          q7: activeToolboxTalk ? activeToolboxTalk.q_7 : 0,
          topicsToCover: activeToolboxTalk
            ? activeToolboxTalk.topics_to_cover
            : '',
          notes: activeToolboxTalk ? activeToolboxTalk.notes : '',
          otherItems: activeToolboxTalk ? activeToolboxTalk.other_items : '',
        }}
      />

      <RemovalProjectDrawer
        isOpen={isRemovalProjectDrawerOpen}
        onClose={onCloseRemovalProjectDrawer}
        states={states}
        onComplete={() => {
          refetch();
        }}
        initialValues={{
          assessorId: removalProject ? removalProject.assessor_id : undefined,
          address: removalProject ? removalProject.address : '',
          city: removalProject ? removalProject.city : '',
          clientId: removalProject ? removalProject.client_id : undefined,
          stateId: removalProject ? removalProject.state_id : undefined,
          notes: removalProject ? removalProject.notes : undefined,
          endDate: removalProject
            ? moment(removalProject.end_date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
          quantity: removalProject ? removalProject.quantity : 1,
          startDate: removalProject
            ? moment(removalProject.start_date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
          supervisorId: removalProject
            ? removalProject.supervisor_id
            : undefined,
          worksafeNo: removalProject ? removalProject.worksafe_no : '',
          id: removalProject ? removalProject.id : undefined,
        }}
      />

      <SWMSDrawer
        initialValues={{
          removalProjectId: data.removalProject.id,
          workActivityRisk1: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_1,
          ),
          workActivityRisk2: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_2,
          ),
          workActivityRisk3: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_3,
          ),
          workActivityRisk4: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_4,
          ),
          workActivityRisk5: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_5,
          ),
          workActivityRisk6: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_6,
          ),
          workActivityRisk7: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_7,
          ),
          workActivityRisk8: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_8,
          ),
          workActivityRisk9: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_9,
          ),
          workActivityRisk10: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_10,
          ),
          workActivityRisk11: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_11,
          ),
          workActivityRisk12: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_12,
          ),
          workActivityRisk13: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_13,
          ),
          workActivityRisk14: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_14,
          ),
          workActivityRisk15: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_15,
          ),
          workActivityRisk16: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_16,
          ),
          workActivityRisk17: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_17,
          ),
          workActivityRisk18: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_18,
          ),
          workActivityRisk19: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_19,
          ),
          workActivityRisk20: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_20,
          ),
          workActivityRisk21: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_21,
          ),
          workActivityRisk22: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_22,
          ),
          workActivityRisk23: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_23,
          ),
          workActivityRisk24: Number(
            removalProject.SWMSWorkActivityRisk.work_activity_risk_24,
          ),
          swmsProtectionHardHat: Number(
            removalProject.SWMSProtection.swms_protection_hard_hat,
          ),
          swmsProtectionEye: Number(
            removalProject.SWMSProtection.swms_protection_eye,
          ),
          swmsProtectionVisibleClothing: Number(
            removalProject.SWMSProtection.swms_protection_visible_clothing,
          ),
          swmsProtectionFoot: Number(
            removalProject.SWMSProtection.swms_protection_foot,
          ),
          swmsProtectionLung: Number(
            removalProject.SWMSProtection.swms_protection_lung,
          ),
          swmsProtectionHand: Number(
            removalProject.SWMSProtection.swms_protection_hand,
          ),
          swmsProtectionEar: Number(
            removalProject.SWMSProtection.swms_protection_ear,
          ),
          swmsProtectionDecontamination: Number(
            removalProject.SWMSProtection.swms_protection_decontamination,
          ),
          swmsProtectionProtectiveClothing: Number(
            removalProject.SWMSProtection.swms_protection_protective_clothing,
          ),
          swmsProtectionSafetyHarness: Number(
            removalProject.SWMSProtection.swms_protection_safety_harness,
          ),
        }}
        isOpen={isSWMSDrawerOpen}
        onClose={onCloseSWMSDrawer}
        onComplete={() => {
          refetch();
        }}
      />
    </Box>
  );
};
