import React from 'react';
import { AssessorInterface } from 'jesco-web';
import { Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';

export interface AssessorRowInterface extends TableRowPropsInterface {
  assessor: AssessorInterface;
  onRowClick(assessor: AssessorInterface): void;
}

export const AssessorTableRow = ({
  assessor,
  onRowClick,
  ...props
}: AssessorRowInterface) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(assessor);
    }
  };

  return (
    <TableRow data-testid="assessor-table-row" onClick={handleClick} {...props}>
      <TableDiv px="5px" py="10px" data-testid="assessor-name-td">
        <Text wordBreak="break-word">{assessor.name}</Text>
      </TableDiv>

      <TableDiv px="5px" py="10px" data-testid="assessor-company-td">
        <Text wordBreak="break-word">{assessor.company}</Text>
      </TableDiv>

      <TableDiv px="5px" py="10px">
        {assessor.phone && <Text wordBreak="break-word">{assessor.phone}</Text>}
      </TableDiv>
    </TableRow>
  );
};
