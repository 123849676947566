import { gql } from '@apollo/client';

export const SIGN_IN_MUTATION = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      me {
        id
        email
        name
        status
        username
        Permissions {
          can_edit_clients
          can_edit_users
          can_edit_supervisors
          can_edit_employees
          can_edit_company_files
          can_view_company_files
          can_edit_removal_projects
          can_edit_waste_disposals
          can_edit_quotes
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CREATE_REMOVAL_PROJECT = gql`
  mutation createRemovalProject(
    $clientId: Int!
    $address: String!
    $assessorId: Int!
    $city: String!
    $endDate: String!
    $notes: String
    $quantity: Int!
    $startDate: String!
    $stateId: ID!
    $supervisorId: Int!
    $worksafeNo: String
  ) {
    createRemovalProject(
      clientId: $clientId
      assessorId: $assessorId
      address: $address
      city: $city
      endDate: $endDate
      notes: $notes
      quantity: $quantity
      startDate: $startDate
      stateId: $stateId
      supervisorId: $supervisorId
      worksafeNo: $worksafeNo
    ) {
      id
      client_id
      client_contact
      assessor_id
      supervisor_id
      address
      city
      state_id
      worksafe_no
      start_date
      end_date
      active
      status
      type
      quantity
      notes
      created_at
      updated_at
    }
  }
`;

export const UPDATE_REMOVAL_PROJECT = gql`
  mutation updateRemovalProject(
    $id: ID!
    $clientId: Int!
    $address: String!
    $assessorId: Int!
    $city: String!
    $endDate: String!
    $notes: String
    $quantity: Int!
    $startDate: String!
    $stateId: ID!
    $supervisorId: Int!
    $worksafeNo: String
  ) {
    updateRemovalProject(
      id: $id
      clientId: $clientId
      assessorId: $assessorId
      address: $address
      city: $city
      endDate: $endDate
      notes: $notes
      quantity: $quantity
      startDate: $startDate
      stateId: $stateId
      supervisorId: $supervisorId
      worksafeNo: $worksafeNo
    ) {
      id
      client_id
      client_contact
      assessor_id
      supervisor_id
      address
      city
      state_id
      worksafe_no
      start_date
      end_date
      active
      status
      statusString
      type
      typeString
      quantity
      quantityString
      notes
      created_at
      updated_at
      Client {
        id
        name
        address
        city
        state_id
        post_code
        email
        client_contact
        phone
        mobile
        active
        created_at
        updated_at
      }
      Assessor {
        id
        name
        company
        phone
        state_id
        license_no
        license_expires
        active
        created_at
        updated_at
      }
      Supervisor {
        id
        name
        phone
        active
        created_at
        updated_at
      }
      ToolboxTalks {
        id
        removal_project_id
        date
        weather_id
        planned_activities
        q_1
        q_2
        q_3
        q_4
        q_5
        q_6
        q_7
        q_8
        topics_to_cover
        notes
        other_items
        active
        created_at
        updated_at
      }
      RemovalAreas {
        Area {
          id
          name
        }
        Location {
          id
          name
        }
        Material {
          id
          name
        }
        Type {
          id
          name
        }
        Condition {
          id
          name
        }
        has_asbestos
      }
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const DELETE_REMOVAL_PROJECT = gql`
  mutation deleteRemovalProject($id: ID!) {
    deleteRemovalProject(id: $id) {
      id
      client_id
      client_contact
      assessor_id
      supervisor_id
      address
      city
      state_id
      worksafe_no
      start_date
      end_date
      active
      status
      statusString
      type
      typeString
      quantity
      notes
      created_at
      updated_at
      Client {
        id
        name
        address
        city
        state_id
        post_code
        email
        client_contact
        phone
        mobile
        active
        created_at
        updated_at
      }
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const CREATE_TOOLBOX_TALK = gql`
  mutation createToolboxTalk(
    $removalProjectId: ID!
    $date: String!
    $weatherId: Int
    $plannedActivities: String
    $q1: Int!
    $q2: Int!
    $q3: Int!
    $q4: Int!
    $q5: Int!
    $q6: Int!
    $q7: Int!
    $topicsToCover: String
    $notes: String
    $otherItems: String
  ) {
    createToolboxTalk(
      removalProjectId: $removalProjectId
      date: $date
      weatherId: $weatherId
      plannedActivities: $plannedActivities
      q1: $q1
      q2: $q2
      q3: $q3
      q4: $q4
      q5: $q5
      q6: $q6
      q7: $q7
      topicsToCover: $topicsToCover
      notes: $notes
      otherItems: $otherItems
    ) {
      id
      removal_project_id
      date
      weather_id
      planned_activities
      q_1
      q_2
      q_3
      q_4
      q_5
      q_6
      q_7
      q_8
      topics_to_cover
      notes
      other_items
      active
      created_at
      updated_at
    }
  }
`;

export const UPDATE_TOOLBOX_TALK = gql`
  mutation updateToolboxTalk(
    $id: ID!
    $removalProjectId: ID!
    $date: String!
    $weatherId: Int
    $plannedActivities: String
    $q1: Int!
    $q2: Int!
    $q3: Int!
    $q4: Int!
    $q5: Int!
    $q6: Int!
    $q7: Int!
    $topicsToCover: String
    $notes: String
    $otherItems: String
  ) {
    updateToolboxTalk(
      id: $id
      removalProjectId: $removalProjectId
      date: $date
      weatherId: $weatherId
      plannedActivities: $plannedActivities
      q1: $q1
      q2: $q2
      q3: $q3
      q4: $q4
      q5: $q5
      q6: $q6
      q7: $q7
      topicsToCover: $topicsToCover
      notes: $notes
      otherItems: $otherItems
    ) {
      id
      removal_project_id
      date
      weather_id
      planned_activities
      q_1
      q_2
      q_3
      q_4
      q_5
      q_6
      q_7
      q_8
      topics_to_cover
      notes
      other_items
      active
      created_at
      updated_at
    }
  }
`;

export const DELETE_TOOLBOX_TALK = gql`
  mutation delteToolboxTalk($id: ID!) {
    deleteToolboxTalk(id: $id) {
      id
      removal_project_id
      date
      weather_id
      planned_activities
      q_1
      q_2
      q_3
      q_4
      q_5
      q_6
      q_7
      q_8
      topics_to_cover
      notes
      other_items
      active
      created_at
      updated_at
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient(
    $address: String
    $city: String
    $clientContact: String
    $email: String
    $mobile: String
    $name: String
    $phone: String
    $postCode: String
    $stateId: ID!
  ) {
    createClient(
      address: $address
      city: $city
      clientContact: $clientContact
      email: $email
      mobile: $mobile
      name: $name
      phone: $phone
      postCode: $postCode
      stateId: $stateId
    ) {
      id
      name
      address
      city
      state_id
      post_code
      email
      client_contact
      phone
      mobile
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: ID!
    $name: String!
    $address: String
    $email: String
    $city: String
    $stateId: ID!
    $postCode: String
    $clientContact: String
    $phone: String
    $mobile: String
  ) {
    updateClient(
      id: $id
      name: $name
      address: $address
      email: $email
      city: $city
      stateId: $stateId
      postCode: $postCode
      clientContact: $clientContact
      phone: $phone
      mobile: $mobile
    ) {
      id
      name
      address
      city
      state_id
      post_code
      email
      client_contact
      phone
      mobile
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation updateClient($id: ID!) {
    deleteClient(id: $id) {
      id
      name
      address
      city
      state_id
      post_code
      email
      client_contact
      phone
      mobile
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const CREATE_ASSESSOR = gql`
  mutation createAssessor(
    $name: String!
    $phone: String
    $company: String
    $stateId: ID!
    $licenseNo: String
    $licenseExpires: String
  ) {
    createAssessor(
      name: $name
      phone: $phone
      company: $company
      stateId: $stateId
      licenseNo: $licenseNo
      licenseExpires: $licenseExpires
    ) {
      id
      name
      company
      phone
      state_id
      license_no
      license_expires
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const UPDATE_ASSESSOR = gql`
  mutation updateAssessor(
    $id: ID!
    $name: String!
    $phone: String
    $company: String
    $stateId: ID!
    $licenseNo: String
    $licenseExpires: String
  ) {
    updateAssessor(
      id: $id
      name: $name
      phone: $phone
      company: $company
      stateId: $stateId
      licenseNo: $licenseNo
      licenseExpires: $licenseExpires
    ) {
      id
      name
      company
      phone
      state_id
      license_no
      license_expires
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const DELETE_ASSESSOR = gql`
  mutation updateAssessor($id: ID!) {
    deleteAssessor(id: $id) {
      id
      name
      company
      phone
      state_id
      license_no
      license_expires
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const CREATE_SUPERVISOR = gql`
  mutation createSupervisor($name: String!, $phone: String) {
    createSupervisor(name: $name, phone: $phone) {
      id
      name
      phone
      active
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SUPERVISOR = gql`
  mutation updateSupervisor($id: ID!, $name: String!, $phone: String) {
    updateSupervisor(id: $id, name: $name, phone: $phone) {
      id
      name
      phone
      active
      created_at
      updated_at
    }
  }
`;

export const DELETE_SUPERVISOR = gql`
  mutation deleteSupervisor($id: ID!) {
    deleteSupervisor(id: $id) {
      id
      name
      phone
      active
      created_at
      updated_at
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $first: String!
    $last: String!
    $startDate: String!
  ) {
    createEmployee(first: $first, last: $last, startDate: $startDate) {
      id
      first
      last
      active
      start_date
      termination_date
      created_at
      updated_at
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $id: ID!
    $first: String!
    $last: String!
    $startDate: String!
  ) {
    updateEmployee(id: $id, first: $first, last: $last, startDate: $startDate) {
      id
      first
      last
      active
      start_date
      termination_date
      created_at
      updated_at
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      id
      first
      last
      active
      start_date
      termination_date
      created_at
      updated_at
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $username: String!
    $email: String!
    $password: String!
  ) {
    createUser(
      name: $name
      username: $username
      email: $email
      password: $password
    ) {
      id
      name
      username
      email
      created_at
      updated_at
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String!
    $username: String!
    $email: String!
  ) {
    updateUser(id: $id, name: $name, username: $username, email: $email) {
      id
      name
      username
      email
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($userId: ID!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      status
    }
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updatePermissions(
    $userId: ID!
    $canEditClients: Boolean!
    $canEditUsers: Boolean!
    $canEditSupervisors: Boolean!
    $canEditEmployees: Boolean!
    $canEditCompanyFiles: Boolean!
    $canViewCompanyFiles: Boolean!
    $canEditRemovalProjects: Boolean!
    $canEditWasteDisposals: Boolean!
    $canEditQuotes: Boolean!
  ) {
    updatePermissions(
      userId: $userId
      canEditClients: $canEditClients
      canEditUsers: $canEditUsers
      canEditSupervisors: $canEditSupervisors
      canEditEmployees: $canEditEmployees
      canEditCompanyFiles: $canEditCompanyFiles
      canViewCompanyFiles: $canViewCompanyFiles
      canEditRemovalProjects: $canEditRemovalProjects
      canEditWasteDisposals: $canEditWasteDisposals
      canEditQuotes: $canEditQuotes
    ) {
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      name
      username
      email
      created_at
      updated_at
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile(
    $path: String!
    $name: String!
    $mimetype: String!
    $type: String!
  ) {
    uploadFile(path: $path, name: $name, mimetype: $mimetype, type: $type) {
      id
      name
      path
      size
      type
      uploadable_type
      uploadable_id
      created_at
      updated_at
    }
  }
`;

export const UPLOAD_EMPLOYEE_SIGNATURE = gql`
  mutation uploadEmployeeSignature(
    $path: String!
    $name: String!
    $employeeId: ID!
  ) {
    uploadEmployeeSignature(path: $path, name: $name, employeeId: $employeeId) {
      status
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
      name
      path
      size
      type
      uploadable_type
      uploadable_id
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SWMS = gql`
  mutation updateSWMS(
    $removalProjectId: ID!
    $workActivityRisk1: Int!
    $workActivityRisk2: Int!
    $workActivityRisk3: Int!
    $workActivityRisk4: Int!
    $workActivityRisk5: Int!
    $workActivityRisk6: Int!
    $workActivityRisk7: Int!
    $workActivityRisk8: Int!
    $workActivityRisk9: Int!
    $workActivityRisk10: Int!
    $workActivityRisk11: Int!
    $workActivityRisk12: Int!
    $workActivityRisk13: Int!
    $workActivityRisk14: Int!
    $workActivityRisk15: Int!
    $workActivityRisk16: Int!
    $workActivityRisk17: Int!
    $workActivityRisk18: Int!
    $workActivityRisk19: Int!
    $workActivityRisk20: Int!
    $workActivityRisk21: Int!
    $workActivityRisk22: Int!
    $workActivityRisk23: Int!
    $workActivityRisk24: Int!
    $swmsProtectionHardHat: Int!
    $swmsProtectionEye: Int!
    $swmsProtectionVisibleClothing: Int!
    $swmsProtectionFoot: Int!
    $swmsProtectionLung: Int!
    $swmsProtectionHand: Int!
    $swmsProtectionEar: Int!
    $swmsProtectionDecontamination: Int!
    $swmsProtectionProtectiveClothing: Int!
    $swmsProtectionSafetyHarness: Int!
  ) {
    updateSWMS(
      removalProjectId: $removalProjectId
      workActivityRisk1: $workActivityRisk1
      workActivityRisk2: $workActivityRisk2
      workActivityRisk3: $workActivityRisk3
      workActivityRisk4: $workActivityRisk4
      workActivityRisk5: $workActivityRisk5
      workActivityRisk6: $workActivityRisk6
      workActivityRisk7: $workActivityRisk7
      workActivityRisk8: $workActivityRisk8
      workActivityRisk9: $workActivityRisk9
      workActivityRisk10: $workActivityRisk10
      workActivityRisk11: $workActivityRisk11
      workActivityRisk12: $workActivityRisk12
      workActivityRisk13: $workActivityRisk13
      workActivityRisk14: $workActivityRisk14
      workActivityRisk15: $workActivityRisk15
      workActivityRisk16: $workActivityRisk16
      workActivityRisk17: $workActivityRisk17
      workActivityRisk18: $workActivityRisk18
      workActivityRisk19: $workActivityRisk19
      workActivityRisk20: $workActivityRisk20
      workActivityRisk21: $workActivityRisk21
      workActivityRisk22: $workActivityRisk22
      workActivityRisk23: $workActivityRisk23
      workActivityRisk24: $workActivityRisk24
      swmsProtectionHardHat: $swmsProtectionHardHat
      swmsProtectionEye: $swmsProtectionEye
      swmsProtectionVisibleClothing: $swmsProtectionVisibleClothing
      swmsProtectionFoot: $swmsProtectionFoot
      swmsProtectionLung: $swmsProtectionLung
      swmsProtectionHand: $swmsProtectionHand
      swmsProtectionEar: $swmsProtectionEar
      swmsProtectionDecontamination: $swmsProtectionDecontamination
      swmsProtectionProtectiveClothing: $swmsProtectionProtectiveClothing
      swmsProtectionSafetyHarness: $swmsProtectionSafetyHarness
    ) {
      SWMSProtection {
        id
        swms_protection_hard_hat
        swms_protection_eye
        swms_protection_visible_clothing
        swms_protection_foot
        swms_protection_lung
        swms_protection_hand
        swms_protection_ear
        swms_protection_decontamination
        swms_protection_protective_clothing
        swms_protection_safety_harness
        created_at
        updated_at
      }
      SWMSWorkActivityRisk {
        id
        work_activity_risk_1
        work_activity_risk_2
        work_activity_risk_3
        work_activity_risk_4
        work_activity_risk_5
        work_activity_risk_6
        work_activity_risk_7
        work_activity_risk_8
        work_activity_risk_9
        work_activity_risk_10
        work_activity_risk_11
        work_activity_risk_12
        work_activity_risk_13
        work_activity_risk_14
        work_activity_risk_15
        work_activity_risk_16
        work_activity_risk_17
        work_activity_risk_18
        work_activity_risk_19
        work_activity_risk_20
        work_activity_risk_21
        work_activity_risk_22
        work_activity_risk_23
        work_activity_risk_24
        created_at
        updated_at
      }
    }
  }
`;

export const CREATE_WASTE_DISPOSAL = gql`
  mutation createWasteDisposal($date: String!, $signatureDate: String!) {
    createWasteDisposal(date: $date, signatureDate: $signatureDate) {
      id
      signature_date
      date
      active
      created_at
      updated_at
    }
  }
`;

export const UPDATE_WASTE_DISPOSAL = gql`
  mutation updateWasteDisposal(
    $id: ID!
    $date: String!
    $signatureDate: String!
  ) {
    updateWasteDisposal(id: $id, date: $date, signatureDate: $signatureDate) {
      id
      signature_date
      date
      active
      created_at
      updated_at
    }
  }
`;

export const DELETE_WASTE_DISPOSAL = gql`
  mutation deleteWasteDisposal($id: ID!) {
    deleteWasteDisposal(id: $id) {
      id
      signature_date
      date
      active
      created_at
      updated_at
    }
  }
`;

export const ADD_REMOVAL_PROJECT_TO_WASTE_DISPOSAL = gql`
  mutation addRemovalProjectToWasteDisposal(
    $wasteDisposalId: Int!
    $removalProjectId: Int!
  ) {
    addRemovalProjectToWasteDisposal(
      wasteDisposalId: $wasteDisposalId
      removalProjectId: $removalProjectId
    ) {
      success
    }
  }
`;

export const REMOVE_REMOVAL_PROJECT_FROM_WASTE_DISPOSAL = gql`
  mutation removeRemovalProjectFromWasteDisposal(
    $wasteDisposalId: Int!
    $removalProjectId: Int!
  ) {
    removeRemovalProjectFromWasteDisposal(
      wasteDisposalId: $wasteDisposalId
      removalProjectId: $removalProjectId
    ) {
      success
    }
  }
`;

export const UPLOAD_TIP_DOCKET = gql`
  mutation uploadTipDocket(
    $path: String!
    $name: String!
    $mimetype: String!
    $wasteDisposalId: ID!
  ) {
    uploadTipDocket(
      path: $path
      name: $name
      mimetype: $mimetype
      wasteDisposalId: $wasteDisposalId
    ) {
      id
      name
      path
      size
      type
      uploadable_type
      uploadable_id
      created_at
      updated_at
    }
  }
`;

export const UPLOAD_REMOVAL_PROJECT_FILE = gql`
  mutation uploadRemovalProjectFile(
    $path: String!
    $name: String!
    $mimetype: String!
    $removalProjectId: ID!
  ) {
    uploadRemovalProjectFile(
      path: $path
      name: $name
      mimetype: $mimetype
      removalProjectId: $removalProjectId
    ) {
      id
      name
      path
      size
      type
      uploadable_type
      uploadable_id
      created_at
      updated_at
    }
  }
`;

export const UPLOAD_EMPLOYEE_FILE = gql`
  mutation uploadEmployeeFile(
    $path: String!
    $name: String!
    $mimetype: String!
    $employeeId: ID!
  ) {
    uploadEmployeeFile(
      path: $path
      name: $name
      mimetype: $mimetype
      employeeId: $employeeId
    ) {
      id
      name
      path
      size
      type
      uploadable_type
      uploadable_id
      created_at
      updated_at
    }
  }
`;

export const DELETE_SIGNATURE = gql`
  mutation deleteSignature($id: ID!) {
    deleteSignature(id: $id) {
      status
    }
  }
`;

export const UPLOAD_SIGNATURE = gql`
  mutation uploadSignature(
    $path: String
    $name: String!
    $supervisor: String
    $organization: String
    $date: String!
    $employeeId: Int
    $signatures: [SignatureType!]
  ) {
    uploadSignature(
      path: $path
      name: $name
      supervisor: $supervisor
      organization: $organization
      date: $date
      employeeId: $employeeId
      signatures: $signatures
    ) {
      status
    }
  }
`;

export const DELETE_REMOVAL_OPTION = gql`
  mutation deleteRemovalOption($id: ID!) {
    deleteRemovalOption(id: $id) {
      status
    }
  }
`;

export const CREATE_REMOVAL_OPTION = gql`
  mutation createRemovalOption($type: String!, $name: String!) {
    createRemovalOption(type: $type, name: $name) {
      id
      name
    }
  }
`;

export const ADD_AREA_TO_REMOVAL_PROJECT = gql`
  mutation addAreaToRemovalProject(
    $removalProjectId: ID!
    $areaId: ID!
    $locationId: ID!
    $materialId: ID!
    $typeId: ID!
    $conditionId: ID!
  ) {
    addAreaToRemovalProject(
      removalProjectId: $removalProjectId
      areaId: $areaId
      locationId: $locationId
      materialId: $materialId
      typeId: $typeId
      conditionId: $conditionId
    ) {
      status
    }
  }
`;

export const REMOVE_AREA_FROM_REMOVAL_PROJECT = gql`
  mutation removeAreaFromRemovalProject($id: ID!) {
    removeAreaFromRemovalProject(id: $id) {
      status
    }
  }
`;

export const CREATE_CALENDAR_EVENT = gql`
  mutation createCalendarEvent(
    $startDate: String!
    $endDate: String!
    $isAllDay: Boolean!
    $calendarEventTypeId: ID
    $attendeeIds: [ID!]
    $title: String!
    $location: String
    $description: String
  ) {
    createCalendarEvent(
      startDate: $startDate
      endDate: $endDate
      isAllDay: $isAllDay
      calendarEventTypeId: $calendarEventTypeId
      attendeeIds: $attendeeIds
      title: $title
      location: $location
      description: $description
    ) {
      id
      calendar_event_type_id
      start_date
      end_date
      is_all_day
      title
      location
    }
  }
`;

export const UPDATE_CALENDAR_EVENT = gql`
  mutation updateCalendarEvent(
    $id: ID!
    $startDate: String!
    $endDate: String!
    $isAllDay: Boolean!
    $calendarEventTypeId: ID
    $attendeeIds: [ID!]
    $title: String!
    $location: String
    $description: String
  ) {
    updateCalendarEvent(
      id: $id
      startDate: $startDate
      endDate: $endDate
      isAllDay: $isAllDay
      calendarEventTypeId: $calendarEventTypeId
      attendeeIds: $attendeeIds
      title: $title
      location: $location
      description: $description
    ) {
      id
      calendar_event_type_id
      start_date
      end_date
      is_all_day
      title
      location
    }
  }
`;

export const DELETE_CALENDAR_EVENT = gql`
  mutation deleteCalendarEvent($id: ID!) {
    deleteCalendarEvent(id: $id) {
      id
      calendar_event_type_id
      start_date
      end_date
      is_all_day
      title
      location
    }
  }
`;

export const CREATE_CALENDAR_EVENT_TYPE = gql`
  mutation createCalendarEventType($title: String!, $color: String!) {
    createCalendarEventType(title: $title, color: $color) {
      id
      title
      color
    }
  }
`;

export const UPDATE_CALENDAR_EVENT_TYPE = gql`
  mutation updateCalendarEventType($id: ID!, $title: String!, $color: String!) {
    updateCalendarEventType(id: $id, title: $title, color: $color) {
      id
      title
      color
    }
  }
`;

export const DELETE_CALENDAR_EVENT_TYPE = gql`
  mutation deleteCalendarEventType($id: ID!) {
    deleteCalendarEventType(id: $id) {
      id
      title
      color
    }
  }
`;

export const CREATE_QUOTE = gql`
  mutation createQuote(
    $date: String!
    $endDate: String!
    $street: String!
    $city: String!
    $zip: Int
    $name: String!
    $notes: String
    $stateId: ID!
  ) {
    createQuote(
      date: $date
      endDate: $endDate
      street: $street
      city: $city
      zip: $zip
      name: $name
      notes: $notes
      stateId: $stateId
    ) {
      id
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote(
    $id: ID!
    $date: String!
    $endDate: String!
    $street: String!
    $city: String!
    $zip: Int
    $name: String!
    $notes: String
    $stateId: ID!
  ) {
    updateQuote(
      id: $id
      date: $date
      endDate: $endDate
      street: $street
      city: $city
      zip: $zip
      name: $name
      notes: $notes
      stateId: $stateId
    ) {
      status
    }
  }
`;

export const UPDATE_QUOTE_DETAILS = gql`
  mutation updateQuoteDetails(
    $id: ID!
    $body: String!
    $removalAreas: [QuoteRemovalArea!]
    $pricing: [QuotePricing!]
  ) {
    updateQuoteDetails(
      id: $id
      body: $body
      removalAreas: $removalAreas
      pricing: $pricing
    ) {
      status
    }
  }
`;

export const UPDATE_QUOTE_NOTES = gql`
  mutation updateQuoteNotes($id: ID!, $notes: String!) {
    updateQuoteNotes(id: $id, notes: $notes) {
      status
    }
  }
`;

export const UPDATE_QUOTE_STATUS = gql`
  mutation updateQuoteStatus($id: ID!, $status: String!) {
    updateQuoteStatus(id: $id, status: $status) {
      status
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation deleteQuote($id: ID!) {
    deleteQuote(id: $id) {
      status
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation getSignedUrl($file: String!, $type: String!, $folder: String!) {
    getSignedUrl(file: $file, type: $type, folder: $folder)
  }
`;
