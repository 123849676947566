import React from 'react';
import { Text, BoxProps } from '@chakra-ui/react';

export type HeaderPropsInterface = BoxProps;

export const Header = ({ children, ...props }: HeaderPropsInterface) => {
  return (
    <Text fontSize="2xl" color="textSecondary" {...props}>
      {children}
    </Text>
  );
};
