import React from 'react';
import moment from 'moment';
import {
  HStack,
  Flex,
  Box,
  Stack,
  Progress,
  Text,
  Image,
  List,
  ListItem,
  useMediaQuery,
  ListIcon,
  UnorderedList,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Header } from '../containers/Text/Header';
import { GET_REMOVAL_PROJECT_QUERY } from '../services/apollo/queries';
import { RemovalProjectInterface } from 'jesco-web';
import logo from '../lib/images/logo.png';
import { RemovalAreaPrintTable } from '../containers/RemovalProject/RemovalAreaPrintTable';
import { FaCheckCircle } from 'react-icons/fa';
import { SwmsSignaturePrintTable } from '../containers/Signature/SwmsSignaturePrintTable';
import swmsRisksDecontaminationImg from '../lib/images/PPE/swms-risks-decontamination.png';
import swmsRisksEarProtectionImg from '../lib/images/PPE/swms-risks-ear-protection.png';
import swmsRisksEyeProtectionImg from '../lib/images/PPE/swms-risks-eye-protection.png';
import swmsRisksFootProtectionImg from '../lib/images/PPE/swms-risks-foot-protection.png';
import swmsRisksHandProtectionImg from '../lib/images/PPE/swms-risks-hand-protection.png';
import swmsRisksHardHatImg from '../lib/images/PPE/swms-risks-hard-hat.png';
import swmsRisksLungProtectionImg from '../lib/images/PPE/swms-risks-lung-protection.png';
import swmsRisksProtectiveClothingImg from '../lib/images/PPE/swms-risks-protective-clothing.png';
import swmsRisksSafetyHarnessImg from '../lib/images/PPE/swms-risks-safety-harness.png';
import swmsRisksVisibilityImg from '../lib/images/PPE/swms-risks-visibility.png';

export interface SWMSPrintPageProps {
  removalProjectId: number;
}

export const SWMSPrintPage = ({ removalProjectId }: SWMSPrintPageProps) => {
  const [isPrinting] = useMediaQuery('print');
  const { loading, data } = useQuery<{
    removalProject: RemovalProjectInterface;
  }>(GET_REMOVAL_PROJECT_QUERY, {
    variables: {
      id: removalProjectId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const removalProject = data.removalProject;

  const highRiskOptions = [
    {
      label: 'Involves a risk of a person falling more than 2 metres',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_1,
    },
    {
      label: 'Is carried out on a telecommunication tower',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_2,
    },
    {
      label:
        'Involves demolition of an element of a structure that is load-bearing',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_3,
    },

    {
      label:
        'Involves demolition of an element of a structure that is related to the physical integrity of the structure',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_4,
    },
    {
      label: 'Involves, or is likely to involve, disturbing asbestos',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_5,
    },
    {
      label:
        'Involves structural alteration or repair that requires temporary support to prevent collapse',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_6,
    },
    {
      label: 'Is carried out in or near a confined space',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_7,
    },
    {
      label:
        'Is carried out in or near a shaft or trench deeper than 1.5 metres or a tunnel',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_8,
    },

    {
      label: 'Involves the use of explosives',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_9,
    },
    {
      label: 'Is carried out on or near pressurised gas mains or piping',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_10,
    },
    {
      label: 'Is carried out on or near chemical, fuel or refrigerant lines',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_11,
    },
    {
      label:
        'Is carried out on or near energised electrical installations or services',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_12,
    },
    {
      label:
        'Is carried out in an area that may have a contaminated or flammable atmosphere',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_13,
    },
    {
      label: 'Involves tilt-up or precast concrete',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_14,
    },
    {
      label:
        'Is carried out on, in or adjacent to a road, railway, shipping lane or other traffic corridor in use by traffic other than pedestrians',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_15,
    },
    {
      label:
        'Is carried out in an area of a workplace where there is any movement of powered mobile plant',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_16,
    },
    {
      label: 'Is carried out in areas with artificial extremes of temperature',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_17,
    },
    {
      label:
        'Is carried out in or near water or other liquid that involves a risk of drowning',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_18,
    },
    {
      label: 'Involves diving work',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_19,
    },
    {
      label:
        'Involves the cutting of crystalline silica material using a power tool or another mechanical process',
      isActive: removalProject.SWMSWorkActivityRisk.work_activity_risk_14,
    },
  ];

  const supervisorSignature = removalProject.SWMSSignatures.find(
    (signature) => signature.name === removalProject.Supervisor?.name,
  );

  const workerSignatures = removalProject.SWMSSignatures.filter(
    (signature) => signature.name !== removalProject.Supervisor?.name,
  );

  const containsFriable = removalProject.RemovalAreas.find(
    (ra) => ra.Type.name === 'Friable',
  );

  return (
    <Box
      bg={isPrinting ? '#fff' : '#ccc'}
      width="100%"
      py={isPrinting ? 0 : '20px'}
      fontSize="sm"
    >
      <Flex justify="center">
        <Box width="337mm" bg="white" rounded="md" p={isPrinting ? 0 : '40px'}>
          <Box borderBottom="1px solid">
            <HStack mb="5px" justify="space-between">
              <Image height="70px" src={logo} alt="Jesco logo" />
              <Stack align="flex-end" justifyContent="flex-end" spacing={0}>
                <Header fontWeight="bold" fontSize="lg" textAlign="right">
                  Jesco Asbestos Removal Pty Ltd
                </Header>
                <Text fontSize="sm" as="span">
                  17 Capital Terrace
                </Text>
                <Text fontSize="sm" as="span">
                  Queanbeyan East, NSW 2620
                </Text>
                <Text fontSize="sm" as="span">
                  Tel 0411 067 700
                </Text>
                <Text fontSize="sm" as="span">
                  ABN 64660657572
                </Text>
              </Stack>
            </HStack>
          </Box>
          <Header mt="15px" mb="0px" fontSize="xl" textAlign="center">
            High Risk Safe Work Method Statement (SWMS)
          </Header>
          <Header mb="10px" fontSize="md" fontWeight="bold">
            Project Details
          </Header>
          <Box>
            <HStack
              width="100%"
              justifyContent="space-between"
              align="flex-start"
            >
              <Stack flex="1" spacing="0">
                <HStack
                  p="3px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="site-address"
                >
                  <Text>Site Address</Text>
                  <Text>{`${data.removalProject.address}, ${data.removalProject.city}, ${data.removalProject.State?.abbrev}`}</Text>
                </HStack>
                <HStack p="3px" justify="space-between" data-testid="client">
                  <Text>Client</Text>
                  <Text>{removalProject.Client?.name || '-'}</Text>
                </HStack>
                <HStack
                  p="3px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="client-contact"
                >
                  <Text>Client Contact</Text>
                  <Text>{removalProject.Client?.client_contact || '-'}</Text>
                </HStack>
                <HStack
                  p="3px"
                  justify="space-between"
                  data-testid="client-phone"
                >
                  <Text>Client Phone</Text>
                  <Stack>
                    {removalProject.Client && removalProject.Client.mobile && (
                      <Text>{removalProject.Client.mobile}</Text>
                    )}
                    {removalProject.Client && removalProject.Client.phone && (
                      <Text>{removalProject.Client.phone}</Text>
                    )}
                  </Stack>
                </HStack>
              </Stack>
              <Stack flex="1" spacing="0">
                <HStack
                  p="3px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="start-date"
                >
                  <Text>Expected Commencement Date</Text>
                  <Text>
                    {moment(removalProject.start_date, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    ) || '-'}
                  </Text>
                </HStack>
                <HStack p="3px" justify="space-between" data-testid="end-date">
                  <Text>Expected Completion Date</Text>
                  <Text>
                    {moment(removalProject.end_date, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    ) || '-'}
                  </Text>
                </HStack>
                <HStack
                  p="3px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="quantity"
                >
                  <Text>Quantity</Text>
                  <Text>{removalProject.quantityString || '-'}</Text>
                </HStack>
                <HStack
                  p="3px"
                  justify="space-between"
                  data-testid="worksafe-no"
                >
                  <Text>Regulatory Notification</Text>
                  <Text>{removalProject.worksafe_no || '-'}</Text>
                </HStack>
              </Stack>
            </HStack>
          </Box>

          {removalProject.notes && (
            <Box my="10px">
              <Header fontSize="md" fontWeight="bold">
                Notes
              </Header>
              <Text px="20px" py="10px">
                {removalProject.notes}
              </Text>
            </Box>
          )}

          <Box mb="20px">
            <Header mb="10px" fontSize="md" fontWeight="bold">
              Removal Areas
            </Header>
            <RemovalAreaPrintTable removalAreas={removalProject.RemovalAreas} />
          </Box>

          <Box>
            <HStack width="100%" justifyContent="space-between" spacing={5}>
              <Stack flex="1">
                <Header fontSize="md" fontWeight="bold">
                  Licensed Asbestos Removalist
                </Header>
                <HStack p="1px" bg="gray.50" justify="space-between">
                  <Text>Licensed Removalist</Text>
                  <Text>Jesco Asbestos Removal</Text>
                </HStack>
                <HStack p="1px" justify="space-between">
                  <Text>Removalist License #</Text>
                  <Text>ARA00003</Text>
                </HStack>
                <HStack p="1px" bg="gray.50" justify="space-between">
                  <Text>Removalist Phone</Text>
                  <Text>0411 067 700</Text>
                </HStack>
                <HStack
                  p="1px"
                  justify="space-between"
                  data-testid="nominated-supervisor"
                >
                  <Text>Nominated Supervisor / Emergency Contact</Text>
                  <Text>
                    {removalProject.Supervisor
                      ? removalProject.Supervisor.name
                      : '-'}
                  </Text>
                </HStack>
              </Stack>
              <Stack flex="1">
                <Header fontSize="md" fontWeight="bold">
                  Independent Asbestos Assessor
                </Header>
                <HStack
                  p="1px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="assessor"
                >
                  <Text>Licensed Assessor</Text>
                  <Text>
                    {removalProject.Assessor
                      ? removalProject.Assessor.name
                      : '-'}
                  </Text>
                </HStack>
                <HStack
                  p="1px"
                  justify="space-between"
                  data-testid="assessor-company"
                >
                  <Text>Assessor Company</Text>
                  <Text>
                    {removalProject.Assessor
                      ? removalProject.Assessor.company
                      : '-'}
                  </Text>
                </HStack>
                <HStack
                  p="1px"
                  bg="gray.50"
                  justify="space-between"
                  data-testid="assessor-license"
                >
                  <Text>Assessor License #</Text>
                  <Text>
                    {removalProject.Assessor
                      ? removalProject.Assessor.license_no
                      : '-'}
                  </Text>
                </HStack>
                <HStack
                  p="1px"
                  justify="space-between"
                  data-testid="assessor-phone"
                >
                  <Text>Assessor Phone</Text>
                  <Text>
                    {removalProject.Assessor
                      ? removalProject.Assessor.phone
                      : '-'}
                  </Text>
                </HStack>
              </Stack>
            </HStack>
          </Box>

          <Box mt="20px" className="pba">
            <Header my="10px" fontSize="md" fontWeight="bold">
              SWMS Preparation
            </Header>
            <Stack flex="1">
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>Prepared by</Text>
                <Text>Tony Hall</Text>
              </HStack>
              <HStack p="1px" justify="space-between">
                <Text>Position</Text>
                <Text>Supervisor</Text>
              </HStack>
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>
                  Person Responsible for Ensuring Compliance with SWMS
                </Text>
                <Text>
                  {removalProject.Supervisor
                    ? `${removalProject.Supervisor.name} - ${removalProject.Supervisor.phone}`
                    : '-'}
                </Text>
              </HStack>
              <HStack p="1px" justify="space-between">
                <Text>
                  Person Responsible for Reviewing SWMS Control Measures
                </Text>
                <Text>
                  {removalProject.Supervisor
                    ? `${removalProject.Supervisor.name} - ${removalProject.Supervisor.phone}`
                    : '-'}
                </Text>
              </HStack>
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>
                  Person Responsible for Changing/Updating SWMS as Required
                </Text>
                <Text>
                  {removalProject.Supervisor
                    ? `${removalProject.Supervisor.name} - ${removalProject.Supervisor.phone}`
                    : '-'}
                </Text>
              </HStack>
              <HStack p="1px" justify="space-between">
                <Text>
                  (before a change occurs to the work itself, the system of work
                  or the work location, following the introduction of another
                  high-risk activity/trade, following an incident, or when risk
                  controls are not adequate for the works being carried out or
                  the SWMS is not being followed)
                </Text>
              </HStack>
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>Additional Reference for this Project</Text>
                <Text>
                  Asbestos Removal Control Plan prepared by Jesco Asbestos
                  Removal Pty Ltd, Any Site Inductions or Toolbox Talks
                </Text>
              </HStack>
            </Stack>
          </Box>

          <Box mt="20px">
            <Text>
              Have Environmental Considerations been Considered in the
              Development of the SWMS: Yes Risk Consideration: Hierarchy of
              Controls (Elimination, Substitution, Isolation, Engineering,
              Administration &amp; PPE)
            </Text>
          </Box>
          {supervisorSignature ? (
            <Box mt="20px" className="pba">
              <Header
                my="10px"
                fontSize="md"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Nominated Supervisor Sign Off
              </Header>
              <Text>
                I understand and comply with the requirements of this SWMS. I
                will monitor the implementation of the SWMS and take action in
                the event of any non-compliances. I will consult with the work
                crew and amend the SWMS as necessary in the event of changes to
                the work method, equipment, sequence, materials or the like and
                in the event of any safety or environmental breaches, incidents
                or near misses.
              </Text>
              <Table colorScheme="gray" mt={'20px'}>
                <Thead>
                  <Tr>
                    <Th p={0} width="40%">
                      Nominated Supervisor
                    </Th>
                    <Th p={0} width="30%">
                      Signature
                    </Th>
                    <Th p={0} width="30%">
                      Date
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr
                    data-testid="swms-signature-row"
                    border="1px solid"
                    borderColor="gray.300"
                  >
                    <Td height="50px" p={'10px'}>
                      {supervisorSignature.name}
                    </Td>
                    <Td height="50px" p={0}>
                      <Image
                        height="60px"
                        src={supervisorSignature.path}
                        alt={`${supervisorSignature.name} signature`}
                      />
                    </Td>
                    <Td height="50px" p={0}>
                      {supervisorSignature.date}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          ) : null}

          <Box mt="20px">
            <Header
              my="10px"
              fontSize="md"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Training &amp; Competency required to complete the activity
            </Header>

            <UnorderedList pl="15px">
              <ListItem pl="10px" listStyleType="'-'">
                Construction industry white card
              </ListItem>
              <ListItem pl="10px" listStyleType="'-'">
                Onsite induction (if required)
              </ListItem>
              <ListItem pl="10px" listStyleType="'-'">
                Induction into this SWMS
              </ListItem>
              <ListItem pl="10px" listStyleType="'-'">
                Remove non-friable asbestos CPCCDE3014
              </ListItem>
              {containsFriable ? (
                <ListItem pl="10px" listStyleType="'-'">
                  Remove friable asbestos CPCCDE3015
                </ListItem>
              ) : null}
              <ListItem pl="10px" listStyleType="'-'">
                Supervise Asbestos Removal CPCCBC4051A
              </ListItem>
            </UnorderedList>
          </Box>

          <Box mt="20px">
            <Header my="10px" fontSize="md" fontWeight="bold">
              SITE SPECIFIC HIGH RISK CONSTRUCTION WORK
            </Header>

            <List spacing={0} border="1px solid" borderColor="gray.300">
              {highRiskOptions.map((hro, index) => (
                <ListItem
                  data-testid="high-risk-option-row"
                  key={hro.label}
                  p="5px"
                  borderBottom={
                    index !== highRiskOptions.length ? '1px solid' : 'none'
                  }
                  borderColor="gray.300"
                >
                  <ListIcon
                    fontSize="18px"
                    as={FaCheckCircle}
                    data-testid={
                      hro.isActive ? 'checkbox-active' : 'checkbox-inactive'
                    }
                    color={hro.isActive ? 'green.500' : 'transparent'}
                  />
                  {hro.label}
                </ListItem>
              ))}
            </List>
          </Box>

          <Box mt="20px">
            <Header
              my="10px"
              fontSize="md"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Overall Risk Rating Controls
            </Header>

            <HStack
              bg="gray.50"
              spacing={0}
              borderBottom="1px solid"
              fontWeight="bold"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px" textTransform="uppercase">
                  Likelihood
                </Text>
              </Box>
              <Box
                flex="2"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px" textTransform="uppercase">
                  Consequence
                </Text>
              </Box>
            </HStack>
            <HStack
              spacing={0}
              borderBottom="1px solid"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack p="10px">
                  <Text fontWeight="bold">Almost Certain</Text>
                  <Text>(Will probably occur)</Text>
                </Stack>
              </Box>
              <HStack
                flex="2"
                bg="gray.50"
                spacing={0}
                borderBottom="1px solid"
                borderColor="gray.300"
                className="pba"
              >
                <Box
                  flex="1"
                  p="10px"
                  bg="check"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Check
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="stop"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="stop"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
              </HStack>
            </HStack>

            <HStack spacing={0} borderBottom="1px solid" borderColor="gray.300">
              <Box
                flex="1"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack p="10px">
                  <Text fontWeight="bold">Possible</Text>
                  <Text>(May occur, has happened)</Text>
                </Stack>
              </Box>
              <HStack
                flex="2"
                bg="gray.50"
                spacing={0}
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  p="10px"
                  bg="start"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="check"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Check
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="stop"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
              </HStack>
            </HStack>

            <HStack spacing={0} borderBottom="1px solid" borderColor="gray.300">
              <Box
                flex="1"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack p="10px">
                  <Text fontWeight="bold">Unlikey</Text>
                  <Text>(Could occur, known to happen)</Text>
                </Stack>
              </Box>
              <HStack
                flex="2"
                bg="gray.50"
                spacing={0}
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  p="10px"
                  bg="start"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="start"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
                <Box
                  flex="1"
                  p="10px"
                  bg="check"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="black" textTransform="uppercase">
                    Check
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>

          <Box mt="20px">
            <HStack
              bg="gray.50"
              spacing={0}
              borderBottom="1px solid"
              fontWeight="bold"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">Uncontrolled Risk Level</Text>
              </Box>
              <Box
                flex="2"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">What do we need to do?</Text>
              </Box>
              <Box
                flex="1"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">Controlled Risk Level</Text>
              </Box>
              <Box
                flex="2"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">What should happen next?</Text>
              </Box>
            </HStack>
            <HStack
              spacing={0}
              borderBottom="1px solid"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                bg="stop"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text p="10px" color="black" textTransform="uppercase">
                  Stop
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack spacing={0} p="10px">
                  <Text textTransform="uppercase" fontWeight="bold">
                    Task Must Stop
                  </Text>
                  <Text>
                    Hazard must be eliminated, or the uncontrolled risk level
                    reduced through substitution, isolation, engineering or a
                    design change. Requires immediate attention.
                  </Text>
                </Stack>
              </Box>
              <Box
                height="120px"
                flex="1"
                bg="stop"
                borderRight="1px solid"
                borderColor="gray.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text p="10px" color="black" textTransform="uppercase">
                  Stop
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">
                  The task CANNOT proceed until the on-site supervisor has
                  agreed to what controls must be applied.
                </Text>
              </Box>
            </HStack>

            <HStack
              spacing={0}
              borderBottom="1px solid"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                bg="check"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text p="10px" color="black" textTransform="uppercase">
                  Check
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack spacing={0} p="10px">
                  <Text fontWeight="bold" textTransform="uppercase">
                    Task Must Be Checked
                  </Text>
                  <Text>
                    Ensure that the highest possible controls have been applied
                    (e.g., elimination, substitution, isolation, engineering,
                    several administrative controls and PPE).
                  </Text>
                </Stack>
              </Box>
              <Box
                flex="1"
                bg="check"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text p="10px" color="black" textTransform="uppercase">
                  Check
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">
                  On-site supervisor MUST review controls and ensure they are
                  appropriate and effective before the task can start.
                </Text>
              </Box>
            </HStack>

            <HStack
              spacing={0}
              borderBottom="1px solid"
              borderColor="gray.300"
              className="pba"
            >
              <Box
                flex="1"
                bg="start"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text p="10px" color="black" textTransform="uppercase">
                  Start
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Stack spacing={0} p="10px">
                  <Text fontWeight="bold" textTransform="uppercase">
                    Task May Start
                  </Text>
                  <Text>
                    Proceed to start work. Record and review if any
                    equipment/people/materials/work processes or procedure
                    changes.
                  </Text>
                </Stack>
              </Box>
              <Box
                flex="1"
                bg="start"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="black" p="10px" textTransform="uppercase">
                  Start
                </Text>
              </Box>
              <Box
                flex="2"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="10px">
                  Proceed to start work. Record and review if any
                  equipment/people/materials/work processes or procedure
                  changes.
                </Text>
              </Box>
            </HStack>
          </Box>

          <Box mt="20px">
            <Header
              my="10px"
              fontSize="md"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Procedures and Safety Controls
            </Header>
            <HStack
              bg="gray.50"
              spacing={0}
              borderBottom="1px solid"
              fontWeight="bold"
              borderColor="gray.300"
            >
              <Box flex="1" borderRight="1px solid" borderColor="gray.300">
                <Text p="5px" fontSize="10px">
                  Work Activities / Risks
                </Text>
              </Box>
              <Box flex="1" borderRight="1px solid" borderColor="gray.300">
                <Text p="5px" fontSize="10px">
                  Uncontrolled Risk Level
                </Text>
              </Box>
              <Box
                flex="2"
                borderRight="1px solid"
                fontWeight="bold"
                borderColor="gray.300"
              >
                <Stack spacing={0} p="5px">
                  <Text fontSize="10px">Potential Hazards & Risks</Text>
                  <Text fontSize="10px">(What is the problem?)</Text>
                </Stack>
              </Box>
              <Box flex="7" fontWeight="bold" borderColor="gray.300">
                <Stack spacing={0} p="5px">
                  <Text fontSize="10px">What are the control measures?</Text>
                  <Text fontSize="10px">
                    (Describe what will be done to control the risk. What will
                    you do to make the activity as safe as posible?)
                  </Text>
                </Stack>
              </Box>
              <Box
                flex="1"
                borderRight="1px solid"
                borderLeft="1px solid"
                borderColor="gray.300"
              >
                <Text p="5px" fontSize="10px">
                  Controlled Risk Level
                </Text>
              </Box>
            </HStack>

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_20,
            ) && (
              <HStack
                bg="white"
                data-testid="working-off-ladder"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">Working off ladder</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack p="5px">
                    <Text>Falling</Text>
                    <Text>Damage of ladder with potential to fail</Text>
                    <Text>Slips causing injury</Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack spacing={0} p="5px">
                    <Text>Correct manual handling techniques</Text>
                    <Text pt="10px">
                      Complete a visual and physical check of the ladder
                      ensuring:
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem pl="10px" listStyleType="'-'">
                        The feet are in good condition and fitted securely
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        It is free from breaks and bends
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        It is intact with no missing parts
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Free from rust/corrosion
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Maximum load rating in Kg
                      </ListItem>
                    </UnorderedList>
                    <Text pt="10px">
                      Footwear worn should have a non-slip sole, and be free
                      from oil, grease or other matter that may affect grip –
                      make sure that ladder feet never move when ladder is being
                      used
                    </Text>
                    <Text pt="10px">
                      Ladders should always be set up on a firm, clean, level
                      surface – open its legs fully and secure brace
                    </Text>
                    <Text pt="10px">
                      Avoid carrying anything in the hand while climbing or
                      descending a ladder
                    </Text>
                    <Text pt="10px">
                      Place ladder as close as possible to work to avoid
                      over-reaching or leaning from work platform – never lean
                      out or overreach
                    </Text>
                    <Text pt="10px">
                      At any time, only one person is to be on the platform
                      ladder
                    </Text>
                    <Text pt="10px">
                      Whenever climbing or descending a ladder, do it slowly and
                      always face the ladder
                    </Text>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_21,
            ) && (
              <HStack
                bg="white"
                data-testid="use-of-scaffold-mobile-scaffold"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">Use of Scaffold / Mobile Scaffold</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack p="5px">
                    <Text>Fall from height </Text>
                    <Text>Falling objects</Text>
                    <Text>Damage to/inadequate floor support</Text>
                    <Text>Unidentified/unexpected site specific hazards</Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack p="5px" spacing="20px">
                    <Text>
                      We will identify any overhead hazards, openings, objects
                      and other hazards that will impact on the scaffold
                    </Text>
                    <Text>
                      We will inspect the components prior to erecting mobile
                      scaffold; we will not use if damaged and notify Supervisor
                    </Text>
                    <Text>
                      We will inspect floor surface for suitability for mobile
                      scaffold and confirm any multipurpose floor surface can
                      support the weight of scaffold and will not be damaged by
                      scaffold castors and load.
                    </Text>
                    <Text>
                      We will obtain, read, understand and strictly follow the
                      mobile scaffold manufacturer’s assembly instructions and
                      safety requirements
                    </Text>
                    <Text>
                      We will provide safe means of raising, lowering and
                      storing tools, plant, materials and rubbish
                    </Text>
                    <Text>
                      We will wear a hard hat if assisting on the ground and
                      maintain constant awareness of overhead work
                    </Text>
                    <Text>
                      We will assess travel path for safe movement of mobile
                      scaffold. Identify and remove hazards
                    </Text>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_22,
            ) && (
              <HStack
                bg="white"
                data-testid="use-of-mobile-elevated-work-platform"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="10px">Use of Mobile Elevated Work Platform</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack px="5px">
                    <Text>Fall from platform</Text>
                    <Text>Equipment being overturned</Text>
                    <Text>Collision/injury</Text>
                    <Text>
                      Operated by unauthorised / unqualified person(s)
                    </Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack p="5px" spacing={0}>
                    <Text>
                      We will conduct pre-operation checks before lifting any
                      person in the work platform including verification that
                      alarms are operational
                    </Text>
                    <Text pt="20px">
                      We will verify that plant operators are competent and have
                      the relevant licenses
                    </Text>
                    <Text pt="20px">
                      We will consult the manufacturer’s or supplier’s
                      instructions for information on safe operation
                    </Text>
                    <Text pt="20px">
                      We will identify and implement control area of interaction
                      between people and EWP, using control measures such as
                      exclusion zones and spotters, and communicating regularly
                      with site workers of the EWP’s movement plans
                    </Text>
                    <Text pt="20px">
                      We will verify the parts of the EWP are clear of overhead
                      obstructions, especially powerlines and overhead
                      electrical cables
                    </Text>
                    <Text pt="20px">
                      We will confirm the EWP is parked on a solid level surface
                      while workers are on the platform unless designated for
                      rough terrain
                    </Text>
                    <Text pt="20px">
                      We will use platforms as working platforms and not as a
                      means of entering and exiting a work area
                    </Text>
                    <Text pt="20px">
                      We will check the surface area for any penetrations or
                      obstructions that could cause uncontrolled movement or
                      overturning of the platform
                    </Text>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_23,
            ) && (
              <HStack
                bg="white"
                data-testid="use-of-mobile-elevated-work-platform"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">Use of fall-arrest system</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack px="5px">
                    <Text>Fall from height</Text>
                    <Text>
                      Suspension trauma as a result of free fall causing blood
                      flow restriction
                    </Text>
                    <Text>Slips and trips causing injury</Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack p="10px" spacing={0}>
                    <Text>We will not work alone</Text>
                    <Text pt="20px">
                      We will not work at heights in inclement weather
                    </Text>
                    <Text pt="20px">
                      We will barricade or provide suitable overlay on
                      skylights/brittle roofs
                    </Text>
                    <Text pt="20px">
                      We will verify trained/personnel will be using fall-arrest
                      system
                    </Text>
                    <Text pt="20px">
                      We will not use equipment that is out of date or damaged
                      and notify Supervisor
                    </Text>
                    <Text pt="20px">
                      We will work in fall restraint where possible (no access
                      beyond fall edge)
                    </Text>
                    <Text pt="20px">
                      We will keep slack in rope line lanyard to a minimum
                    </Text>
                    <Text pt="20px">
                      We will formulate a rescue plan prior to use
                    </Text>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_12,
            ) && (
              <HStack
                bg="white"
                data-testid="working-near-electrical-installations"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">
                    Working near energised electrical installation or services
                  </Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Text px="5px">
                    Serious injury or death from being electrocuted
                  </Text>
                </Box>
                <Box flex="7">
                  <Stack p="5px" spacing={0}>
                    <Text as="u">
                      Working Near Energised Electrical Installation or Services
                    </Text>

                    <Text pt="20px">
                      We will look for overhead power lines and buried power
                      line indicators. if not disconnected we will
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem>
                        Turn main circuit breaker OFF and isolate access
                      </ListItem>
                      <ListItem>
                        Stay at least 2.5 meters away from overhead power lines
                      </ListItem>
                    </UnorderedList>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_5,
            ) && (
              <HStack
                data-testid="removal-of-asbestos-material"
                bg="white"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">Removal of asbestos material</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack p="5px">
                    <Text>Unautorised persons entering work area</Text>
                    <Text pt="20px">
                      Persons being inadvertently exposed to asbestos
                    </Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack p="5px">
                    <Text pt="0px">
                      Before Commencing asbestos removal work, we will notify
                      the following personnel:
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem pl="10px" listStyleType="'-'">
                        The person who commissioned the asbestos removal work
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Other PCBU’s at the workplace
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Anyone occupying premises in the immediate vicinity
                      </ListItem>
                    </UnorderedList>

                    <Text pt="20px">
                      We will identify the boundaries of the asbestos removal
                      site and work area(s) and establish an exclusion zone by
                      placing barrier tape and / or signage alerting persons to
                      where the asbestos removal work is being carried out
                    </Text>

                    <Text pt="20px">
                      We will establish a decontamination area for personnel and
                      tools to be used prior to exiting the asbestos removal
                      area
                    </Text>

                    <Text pt="20px">
                      We will provide removalists with sufficient sets of
                      Personal Protective Equipment (PPE) which will be disposed
                      of as asbestos waste, unless it can be decontaminated
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem>P2 Respirator (non-friable)</ListItem>
                      <ListItem>P3 Respirator (friable)</ListItem>
                      <ListItem>Type 5, Category 3 disposal coveralls</ListItem>
                      <ListItem>Steel capped footwear</ListItem>
                      <ListItem>Safety googles, if perceived risk</ListItem>
                      <ListItem>Safety gloves, if perceived risk</ListItem>
                      <ListItem>
                        Hard hat, if perceived risk and/or site requirement
                      </ListItem>
                    </UnorderedList>

                    <Text pt="20px">
                      We will use materials and tools that minimise the
                      generation and dispersion of airborne asbestos fibres as
                      much as possible
                    </Text>

                    <Text pt="20px">
                      Following asbestos removal, we will ask an independent
                      asbestos assessor to carry out a visual clearance
                      inspection of the removal area and issue a clearance
                      certificate if no visible asbestos is found as a result of
                      the asbestos removal work carried out
                    </Text>

                    <Text pt="20px">
                      We will track the waste and dispose of at landfill
                      approved to accept asbestos
                    </Text>

                    <Text pt="20px">
                      We will add the following controls if conditions change
                      and the asbestos material needs to be removed under
                      friable conditions:
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem>
                        We will provide removalist with P3 full face supplied
                        air respirator
                      </ListItem>
                      <ListItem>
                        We will build an enclosure around the asbestos, where
                        practicable, so that it creates a separate physical
                        barrier that prevents access to the asbestos
                      </ListItem>
                      <ListItem>
                        We will attach a decontamination facility to the
                        enclosure
                      </ListItem>
                      <ListItem>
                        We will attach an exhaust extraction fan to the
                        enclosure to create 'negative' air pressure
                      </ListItem>
                      <ListItem>
                        We will ask the assessor to inspect the integrity of the
                        enclosure prior to starting asbestos removal
                      </ListItem>
                      <ListItem>
                        We will regularly monitor the effectiveness of the
                        enclosure while asbestos removal work is underway
                      </ListItem>
                      <ListItem>
                        Following asbestos removal, we will ask the assessor to
                        carry out a visual clearance inspection of the removal
                        area
                      </ListItem>
                      <ListItem>
                        We will ask the assessor to place a 'clearance air
                        monitor' within each enclosure following their visual
                        inspection
                      </ListItem>
                      <ListItem>
                        {`We will dismantle the enclosure once the lab results show
                      a clearance reading of <0.01 fibres/ml of air and verbal and/or
                      written okay has been provided by the assessor`}
                      </ListItem>
                    </UnorderedList>
                    <Text pt="20px">
                      Following asbestos removal, we will ask an independent
                      asbestos assessor to carry out a visual clearance
                      inspection of the removal area nad issue a clearance
                      certificate if no visible asbestos is found as aresult of
                      hte asbestos removal work carried out
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem pt="20px">
                        We will add the following clearance measures for the
                        removal of friable asbestos if conditions change:
                      </ListItem>
                      <ListItem>
                        We will ask an independent asbestos assessor to place a
                        'clearance air monitor' within each enclosure
                      </ListItem>
                      <ListItem>
                        We will not dismantle the enclosure until the lab
                        results show a reading of less than 0.01 fibres/ml of
                        air and clearance has ben provided by the asbestos
                        assessor
                      </ListItem>
                    </UnorderedList>
                    <Text pt="20px">
                      We will provide decontamination facilities for personnel
                      and tools / equipment
                    </Text>
                    <Text pt="20px">
                      We will track the waste and dispose of at landfill
                      approved to accept asbestos
                    </Text>
                    <Text pt="20px">
                      We will ask an independent asbestos assessor to place a
                      ‘clearance air monitor’ within each enclosure following
                      their visual inspection
                    </Text>
                    <Text pt="20px">
                      We will not dismantle the enclosure until the lab results
                      show a reading of less than 0.01 fibres/ml of air and
                      clearance has been provided by the asbestos assessor
                    </Text>
                  </Stack>
                </Box>

                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_16,
            ) && (
              <HStack
                data-testid="working-around-powered-mobile-plant"
                bg="white"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">
                    Working around movement of powered mobile plant
                  </Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Text px="5px">
                    Persons being seriously injured by moving powered mobile
                    plant
                  </Text>
                  <Text p="5px">Equipment being overturned</Text>
                </Box>
                <Box flex="7">
                  <Box data-testid="working-around-plant" p="5px">
                    <Text as="u">
                      Working Around Movement of Powered Mobile Plant
                    </Text>

                    <Text pt="20px">
                      We will make positive eye contact with the Operator before
                      approaching, signal our intention to the Operator; and
                      wait for acknowledgement from the Operator before
                      approaching
                    </Text>

                    <Text pt="20px">We will stay away from the swing arm</Text>

                    <Text pt="20px">
                      We will listen for reversing alarms and horns
                    </Text>
                  </Box>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            {Boolean(
              removalProject.SWMSWorkActivityRisk.work_activity_risk_24,
            ) && (
              <HStack
                data-testid="unexpected-finds"
                bg="white"
                spacing={0}
                alignItems="stretch"
                borderBottom="1px solid"
                borderColor="gray.300"
              >
                <Box
                  flex="1"
                  bg="gray.50"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px">Disturbing silica dust</Text>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Stop
                  </Text>
                </Box>
                <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                  <Stack p="5px">
                    <Text>
                      Exposure to operator and others to dust that may contain
                      silica
                    </Text>
                  </Stack>
                </Box>
                <Box flex="7">
                  <Stack p="5px">
                    <Text pt="5px">
                      We will wear appropriate PPE for the task being carried
                      out:
                    </Text>
                    <UnorderedList pl="15px">
                      <ListItem pl="10px" listStyleType="'-'">
                        Safety goggles
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Ear protection
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        Steel capped boots
                      </ListItem>
                      <ListItem pl="10px" listStyleType="'-'">
                        P2 Respirator
                      </ListItem>
                    </UnorderedList>
                    <Text pt="10px">
                      We will provide adequate ventilation and/or dust
                      extraction with HEPA Filters
                    </Text>
                    <Text pt="10px">
                      We will barricade the work area (exclusion zones) to
                      minimize exposure to others
                    </Text>
                    <Text pt="10px">
                      We will use wet cutting options where available
                    </Text>
                    <Text pt="10px">
                      We will clean equipment & tools to remove residual
                      concrete dust while wearing mask and before leaving the
                      work area
                    </Text>
                  </Stack>
                </Box>
                <Box
                  flex="1"
                  alignSelf="stretch"
                  borderRight="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                >
                  <Text p="5px" textTransform="uppercase">
                    Start
                  </Text>
                </Box>
              </HStack>
            )}

            <HStack
              data-testid="unexpected-finds"
              bg="white"
              spacing={0}
              alignItems="stretch"
              borderBottom="1px solid"
              borderColor="gray.300"
            >
              <Box
                flex="1"
                bg="gray.50"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="5px">Unexpected Finds</Text>
              </Box>
              <Box
                flex="1"
                alignSelf="stretch"
                borderRight="1px solid"
                borderColor="gray.300"
              >
                <Text p="5px" textTransform="uppercase">
                  Stop
                </Text>
              </Box>
              <Box flex="2" borderRight="1px solid" borderColor="gray.300">
                <Stack p="5px">
                  <Text>Unautorised persons entering work area</Text>
                  <Text pt="20px">
                    Persons being inadvertently exposed to asbestos
                  </Text>
                </Stack>
              </Box>
              <Box flex="7">
                <Stack p="5px">
                  <Text pt="5px">
                    In the event of unexpected finds, we will stop work in the
                    immediate area where the suspect material is found and
                    restrict access to authorised personnel
                  </Text>
                  <Text pt="20px">
                    Site manager to be notified and involved in the
                    decision-making process
                  </Text>
                  <Text pt="20px">
                    Asbestos assessor to be contacted to inspect the material;
                    the material may require sampling if there is any doubt
                    whether material contains asbestos:
                  </Text>
                  <UnorderedList pl="15px">
                    <ListItem pl="10px" listStyleType="'-'">
                      If the material is found to be non-asbestos, then works to
                      continue
                    </ListItem>
                    <ListItem pl="10px" listStyleType="'-'">
                      If the material is found to be asbestos, then the assessor
                      and removalist shall discuss and agree on the best removal
                      methodology
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </Box>
              <Box
                flex="1"
                alignSelf="stretch"
                borderRight="1px solid"
                borderLeft="1px solid"
                borderColor="gray.300"
              >
                <Text p="5px" textTransform="uppercase">
                  Start
                </Text>
              </Box>
            </HStack>

            <HStack p="20px" my="40px" bg="gray.50" className="pba">
              <HStack p="20px">
                <Text textTransform="uppercase" textAlign="center">
                  Required Personal Protective Equipment (PPE)
                </Text>
              </HStack>
              <HStack
                px="40px"
                spacing="5px"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                {removalProject.SWMSProtection
                  .swms_protection_decontamination && (
                  <Image
                    src={swmsRisksDecontaminationImg}
                    data-testid="swms-risk-swmsRisksDecontaminationImg"
                    alt="swms-risk-swmsRisksDecontaminationImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_ear && (
                  <Image
                    src={swmsRisksEarProtectionImg}
                    data-testid="swms-risk-swmsRisksEarProtectionImg"
                    alt="swms-risk-swmsRisksEarProtectionImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_eye && (
                  <Image
                    src={swmsRisksEyeProtectionImg}
                    data-testid="swms-risk-swmsRisksEyeProtectionImg"
                    alt="swms-risk-swmsRisksEyeProtectionImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_foot && (
                  <Image
                    src={swmsRisksFootProtectionImg}
                    data-testid="swms-risk-swmsRisksFootProtectionImg"
                    alt="swms-risk-swmsRisksFootProtectionImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_hand && (
                  <Image
                    src={swmsRisksHandProtectionImg}
                    data-testid="swms-risk-swmsRisksHandProtectionImg"
                    alt="swms-risk-swmsRisksHandProtectionImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_hard_hat && (
                  <Image
                    src={swmsRisksHardHatImg}
                    data-testid="swms-risk-swmsRisksHardHatImg"
                    alt="swms-risk-swmsRisksHardHatImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection.swms_protection_lung && (
                  <Image
                    src={swmsRisksLungProtectionImg}
                    data-testid="swms-risk-swmsRisksLungProtectionImg"
                    alt="swms-risk-swmsRisksLungProtectionImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection
                  .swms_protection_protective_clothing && (
                  <Image
                    src={swmsRisksProtectiveClothingImg}
                    data-testid="swms-risk-swmsProtectionProtectiveClothingImg"
                    alt="swms-risk-swmsProtectionProtectiveClothingImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection
                  .swms_protection_safety_harness && (
                  <Image
                    src={swmsRisksSafetyHarnessImg}
                    data-testid="swms-risk-swmsRisksSafetyHarnessImg"
                    alt="swms-risk-swmsRisksSafetyHarnessImg"
                    height="80px"
                  />
                )}

                {removalProject.SWMSProtection
                  .swms_protection_visible_clothing && (
                  <Image
                    src={swmsRisksVisibilityImg}
                    data-testid="swms-risk-swmsRisksVisibilityImg"
                    alt="swms-risk-swmsRisksVisibilityImg"
                    height="80px"
                  />
                )}
              </HStack>
            </HStack>
            <Box mt={20} className="pba">
              <Header
                my="10px"
                fontSize="md"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Worker Sign-Off
              </Header>
              <Text>
                The undersigned, employees of Jesco Asbestos Removal Pty Ltd or
                as otherwise identified, declare that I / we have attended "Work
                Activity Training" in the tasks to be performed and or possess
                the required training and licensing required to work on this
                project. I shall ensure that my licenses, training and skills
                are current and relvant for the works to be conducted.
              </Text>

              <Text pt="20px" pb="30px">
                I have had the opportunity to be involved in the development /
                review of the SWMS. All work will be performed in the manner
                described in the Safe Work Method Statements issued by the
                company.
              </Text>
              <SwmsSignaturePrintTable signatures={workerSignatures} />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
