import React from 'react';
import moment from 'moment';
import {
  HStack,
  Box,
  Stack,
  Progress,
  Text,
  Image,
  UnorderedList,
  OrderedList,
  useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Header } from '../containers/Text/Header';
import { GET_REMOVAL_PROJECT_QUERY } from '../services/apollo/queries';
import { RemovalProjectInterface } from 'jesco-web';
import logo from '../lib/images/logo.png';
import { RemovalAreaPrintTable } from '../containers/RemovalProject/RemovalAreaPrintTable';
import { PrintListItem } from 'src/containers/Print/PrintListItem';
import A4 from 'src/containers/Print/A4';

export interface ARCPPrintPageProps {
  removalProjectId: number;
}

export const ARCPPrintPage = ({ removalProjectId }: ARCPPrintPageProps) => {
  const [isPrinting] = useMediaQuery('print');
  const { loading, data } = useQuery<{
    removalProject: RemovalProjectInterface;
  }>(GET_REMOVAL_PROJECT_QUERY, {
    variables: {
      id: removalProjectId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const removalProject = data.removalProject;

  const containsFriable = removalProject.RemovalAreas.find(
    (ra) => ra.Type.name.toLowerCase() === 'friable',
  );

  return (
    <Box
      bg={isPrinting ? '#fff' : '#ccc'}
      width="100%"
      py={isPrinting ? 0 : '20px'}
      color="black"
      borderColor="black"
    >
      <A4>
        <Box borderBottom="1px solid" borderColor="gray.300">
          <HStack mb="5px" justify="space-between">
            <Image height="70px" src={logo} alt="Jesco logo" />
            <Stack align="flex-end" justifyContent="flex-end" spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="right">
                Jesco Asbestos Removal Pty Ltd
              </Header>
              <Text fontSize="sm" as="span">
                17 Capital Terrace
              </Text>
              <Text fontSize="sm" as="span">
                Queanbeyan East, NSW 2620
              </Text>
              <Text fontSize="sm" as="span">
                Tel 0411 067 700
              </Text>
              <Text fontSize="sm" as="span">
                ABN 64660657572
              </Text>
            </Stack>
          </HStack>
        </Box>
        <Header mt="20px" mb="0px" fontSize="xl" textAlign="center">
          Asbestos Removal Control Plan (ARCP)
        </Header>
        <Header mb="10px" fontSize="md" fontWeight="bold">
          Project Details
        </Header>
        <Box>
          <HStack
            width="100%"
            justifyContent="space-between"
            align="flex-start"
          >
            <Stack flex="1" spacing="0">
              <HStack
                p="3px"
                bg="gray.50"
                justify="space-between"
                data-testid="site-address"
              >
                <Text>Site Address</Text>
                <Text>{`${data.removalProject.address}, ${data.removalProject.city}, ${data.removalProject.State?.abbrev}`}</Text>
              </HStack>
              <HStack p="3px" justify="space-between" data-testid="client">
                <Text>Client</Text>
                <Text>{removalProject.Client?.name || '-'}</Text>
              </HStack>
              <HStack
                p="3px"
                bg="gray.50"
                justify="space-between"
                data-testid="client-contact"
              >
                <Text>Client Contact</Text>
                <Text>{removalProject.Client?.client_contact || '-'}</Text>
              </HStack>
              <HStack
                p="3px"
                justify="space-between"
                data-testid="client-phone"
              >
                <Text>Client Phone</Text>
                <Stack>
                  {removalProject.Client && removalProject.Client.mobile && (
                    <Text>{removalProject.Client.mobile}</Text>
                  )}
                  {removalProject.Client && removalProject.Client.phone && (
                    <Text>{removalProject.Client.phone}</Text>
                  )}
                </Stack>
              </HStack>
            </Stack>
            <Stack flex="1" spacing="0">
              <HStack
                p="3px"
                bg="gray.50"
                justify="space-between"
                data-testid="start-date"
              >
                <Text>Expected Commencement Date</Text>
                <Text>
                  {moment(removalProject.start_date, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  ) || '-'}
                </Text>
              </HStack>
              <HStack p="3px" justify="space-between" data-testid="end-date">
                <Text>Expected Completion Date</Text>
                <Text>
                  {moment(removalProject.end_date, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  ) || '-'}
                </Text>
              </HStack>
              <HStack
                p="3px"
                bg="gray.50"
                justify="space-between"
                data-testid="quantity"
              >
                <Text>Quantity</Text>
                <Text>{removalProject.quantityString || '-'}</Text>
              </HStack>
              <HStack p="3px" justify="space-between" data-testid="worksafe-no">
                <Text>Regulatory Notification</Text>
                <Text>{removalProject.worksafe_no || '-'}</Text>
              </HStack>
            </Stack>
          </HStack>
        </Box>
        {removalProject.notes && (
          <Box my="10px">
            <Header fontSize="md" fontWeight="bold">
              Notes
            </Header>
            <Text px="20px" py="10px">
              {removalProject.notes}
            </Text>
          </Box>
        )}

        <Box mb="20px">
          <Header mb="10px" fontSize="md" fontWeight="bold">
            Removal Areas
          </Header>
          <RemovalAreaPrintTable removalAreas={removalProject.RemovalAreas} />
        </Box>

        <Box>
          <HStack width="100%" justifyContent="space-between" spacing={5}>
            <Stack flex="1">
              <Header fontSize="md" fontWeight="bold">
                Licensed Asbestos Removalist
              </Header>
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>Licensed Removalist</Text>
                <Text>Jesco Asbestos Removal</Text>
              </HStack>
              <HStack p="1px" justify="space-between">
                <Text>Removalist License #</Text>
                <Text>ARA00003</Text>
              </HStack>
              <HStack p="1px" bg="gray.50" justify="space-between">
                <Text>Removalist Phone</Text>
                <Text>0411 067 700</Text>
              </HStack>
              <HStack
                p="1px"
                justify="space-between"
                data-testid="nominated-supervisor"
              >
                <Text>Nominated Supervisor / Emergency Contact</Text>
                <Text>
                  {removalProject.Supervisor
                    ? removalProject.Supervisor.name
                    : '-'}
                </Text>
              </HStack>
            </Stack>
            <Stack flex="1">
              <Header fontSize="md" fontWeight="bold">
                Independent Asbestos Assessor
              </Header>
              <HStack
                p="1px"
                bg="gray.50"
                justify="space-between"
                data-testid="assessor"
              >
                <Text>Licensed Assessor</Text>
                <Text>
                  {removalProject.Assessor ? removalProject.Assessor.name : '-'}
                </Text>
              </HStack>
              <HStack
                p="1px"
                justify="space-between"
                data-testid="assessor-company"
              >
                <Text>Assessor Company</Text>
                <Text>
                  {removalProject.Assessor
                    ? removalProject.Assessor.company
                    : '-'}
                </Text>
              </HStack>
              <HStack
                p="1px"
                bg="gray.50"
                justify="space-between"
                data-testid="assessor-license"
              >
                <Text>Assessor License #</Text>
                <Text>
                  {removalProject.Assessor
                    ? removalProject.Assessor.license_no
                    : '-'}
                </Text>
              </HStack>
              <HStack
                p="1px"
                justify="space-between"
                data-testid="assessor-phone"
              >
                <Text>Assessor Phone</Text>
                <Text>
                  {removalProject.Assessor
                    ? removalProject.Assessor.phone
                    : '-'}
                </Text>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            1.0 Preparation
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Notify appropriate regulatory body (e.g. WorkSafe ACT, SafeWork
              NSW, ComCare)
            </PrintListItem>
            <PrintListItem>
              So far as reasonably practicable, consult with the relevant
              parties that asbestos removal work is to be carried out and when
              the work is to commence. This includes:
            </PrintListItem>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                a) The person who commissioned the asbestos removal work
              </PrintListItem>
              <PrintListItem>b) Other PCBU’s at the workplace</PrintListItem>
              <PrintListItem isStriped>
                c) Anyone Occupying premises in the immediate vicinity of the
                workplace
              </PrintListItem>
              <PrintListItem>d) Independent asbestos assessor</PrintListItem>
            </UnorderedList>
            <PrintListItem isStriped>
              Ensure copy of the Safe Work Method Statement and Asbestos Removal
              Control Plan are available on site
            </PrintListItem>
            <PrintListItem>
              Nominated supervisor to determine and identify the boundaries of
              the asbestos removal work area and the boundaries of the asbestos
              removal site on the day of asbestos removal
            </PrintListItem>
            <PrintListItem isStriped>
              Nominated supervisor to establish a designated decontamination
              area for decontamination of tools and equipment, personnel, and
              the decontamination of non-disposable personal protective
              equipment and respiratory protective equipment
            </PrintListItem>
            <PrintListItem>
              Verify windows, doors, and other openings which may be impacted by
              asbestos removal are closed; cover all non-movable items below
              asbestos removal area with plastic sheeting
            </PrintListItem>
            <PrintListItem isStriped>
              Verify with asbestos assessor that control air monitors are in
              place before commencing work (If air monitors are required)
            </PrintListItem>
          </UnorderedList>
          <Header py="10px" fontSize="md" fontWeight="bold">
            Perpare Emergency Plan
          </Header>
          <Header pb="10px" px="5px" fontSize="sm" fontWeight="bold">
            Safely Stop Working
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              On hearing an evacuation alarm, or on instruction of person
              responsible for emergency situation, immediately cease all
              activity, and secure personal valuables if safe to do so.
            </PrintListItem>
            <PrintListItem>
              Assist any person in immediate danger, but only if safe to do so.
            </PrintListItem>
            <PrintListItem isStriped>
              If practical, and only if safe to do so, secure any activity or
              shut down equipment that may become hazardous or suffer damage if
              left unattended as a consequence of evacuation.
            </PrintListItem>
          </UnorderedList>
          <Header py="10px" px="5px" fontSize="sm" fontWeight="bold">
            Safe Means of Egress
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Act in accordance with directions given by person responsible for
              emergency situation and evacuate the building / site immediately.
            </PrintListItem>
            <PrintListItem>
              Assist with the general evacuation if directed to do so by person
              responsible for emergency situation.
            </PrintListItem>
            <PrintListItem isStriped>
              Assist with the evacuation of disabled individuals.
            </PrintListItem>
            <PrintListItem>
              In a fire, do not use a lift to evacuate a building.
            </PrintListItem>
          </UnorderedList>
          <Header py="10px" px="5px" fontSize="sm" fontWeight="bold">
            Reporting your presence at the designated assembly area
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Move calmly to the evacuation assembly area and do not leave the
              evacuation assembly area until the ‘all clear’ has been given.
            </PrintListItem>
          </UnorderedList>
          <Header py="10px" px="5px" fontSize="sm" fontWeight="bold">
            Wait for instructions from the emergency responders
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Follow the instructions of relevant emergency services personnel
              and person responsible for emergency situation.
            </PrintListItem>
          </UnorderedList>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            2.0 Personal Protective Equipment (PPE)
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Workers to be provided with sufficient sets of Personal Protective
              Equipment including:
            </PrintListItem>
            <UnorderedList spacing="5px">
              <PrintListItem pl="10px" listStyleType="'-'">
                Type 5 coveralls
              </PrintListItem>
              <PrintListItem pl="10px" isStriped listStyleType="'-'">
                Safety gloves
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Steel-capped, lace-less safety boots
              </PrintListItem>
              <PrintListItem pl="10px" isStriped listStyleType="'-'">
                Hard hat (if perceived risk of falling debris or site
                requirement)
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Eye protection (if perceived risk or site requirement)
              </PrintListItem>
              <PrintListItem pl="10px" isStriped listStyleType="'-'">
                Long pants (if perceived risk or site requirement)
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Ear plugs
              </PrintListItem>
            </UnorderedList>
            <PrintListItem isStriped>
              Workers to be provided with sufficient Respiratory Protective
              Equipment including:
            </PrintListItem>
            <UnorderedList spacing="5px">
              <PrintListItem pl="10px" listStyleType="'-'">
                P2 half-face respirator for non-friable asbestos
              </PrintListItem>
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                P3 respirator for friable asbestos
              </PrintListItem>
            </UnorderedList>
          </UnorderedList>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            3.0 Signage and Barricades for Asbestos Removal Work
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Asbestos warning signs alerting persons to the presence of
              asbestos to be placed
            </PrintListItem>
            <PrintListItem>
              Asbestos work area(s) to be restricted to authorised, trained and
              properly protected personnel (as far as reasonable practicable)
            </PrintListItem>
          </UnorderedList>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            4.0 Control of Electrical and Lighting Installations
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Address the risk of an electrical injury prior to starting works
            </PrintListItem>
          </UnorderedList>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            5.0 Tools and Equipment
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Use materials and tools that minimise the generation and
              dispersion of airborne asbestos fibres as much as possible during
              removal
            </PrintListItem>

            {removalProject.RemovalAreas.find(
              (ra) => ra.Type.name === 'Non-Friable',
            ) && (
              <PrintListItem data-testid="has-non-friable-asbestos">
                Non Friable: spray equipment, PVA or equivalent, asbestos vacuum
                cleaner, cutting tools
              </PrintListItem>
            )}

            {containsFriable && (
              <PrintListItem data-testid="has-friable-asbestos">
                Friable: spray equipment, PVA or equivalent, asbestos vacuum
                cleaner, cutting tools, negative pressure exhaust unit,
                decontamination facility
              </PrintListItem>
            )}
          </UnorderedList>
        </Box>

        <Header mt="15px" fontSize="md" fontWeight="bold">
          6.0 Removal Methodology
        </Header>

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'fibre cement sheeting',
        ) && (
          <Box className="pba" data-testid="arcp-cement-sheeting">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Cement Sheeting
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Place drop sheets under removal area
              </PrintListItem>
              <PrintListItem>
                Carefully remove nail heads using hammer and chisel and place in
                asbestos waste container. Try to avoid contact with sheeting
              </PrintListItem>
              <PrintListItem isStriped>
                Take sheets off in whole pieces and lower to the ground manually
                without breakage. If breakage is absolutely necessary to remove,
                then dislodge the sheet, dampen, and minimise breakage
              </PrintListItem>
              <PrintListItem>
                Stack sheets on two layers of plastic sheeting and seal with
                tape
              </PrintListItem>
              <PrintListItem isStriped>
                Request visual clearance inspection by independent asbestos
                assessor
              </PrintListItem>
              <PrintListItem>
                Spray rough sawn wood surfaces and any other surface that cannot
                be fully decontaminated with one (1) coat of PVA or equivalent
                following okay from asbestos assessor
              </PrintListItem>
              <PrintListItem isStriped>
                Dispose of plastic drop sheets, associated debris, and asbestos
                waste at approved waste facility
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'super 6 sheeting',
        ) && (
          <Box className="pba" data-testid="arcp-super-6-sheeting">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Corrugated Sheeting
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Place drop sheets under removal area
              </PrintListItem>
              <PrintListItem>
                Spray the sheeting with PVA or equivalent prior to removal and
                let dry so to as avoid a slip hazard
              </PrintListItem>
              <PrintListItem isStriped>
                Carefully remove anchoring screws/bolts using tools that will
                not significantly damage the sheet
              </PrintListItem>
              <PrintListItem>
                Take sheets off in whole pieces and lower to the ground manually
              </PrintListItem>
              <PrintListItem isStriped>
                Wrap sheets in two layers of plastic sheeting
              </PrintListItem>
              <PrintListItem>
                Remove insulation and place in asbestos waste container
              </PrintListItem>
              <PrintListItem isStriped>
                HEPA vacuum roof space of any visible asbestos
              </PrintListItem>
              <PrintListItem>
                Place debris & fasteners in asbestos waste container
              </PrintListItem>
              <PrintListItem isStriped>
                Spray rough sawn wood surfaces and any other surface that cannot
                be fully decontaminated with one (1) coat of PVA or equivalent
                following removal
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {containsFriable && (
          <Box className="pba" data-testid="arcp-friable-asbestos">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Friable Removal
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Enclose asbestos removal area to prevent the escape of airborne
                asbestos fibres
              </PrintListItem>
              <PrintListItem>
                Setup negative air exhaust unit for each friable enclosure
              </PrintListItem>
              <PrintListItem isStriped>
                Arrange independent asbestos assessor to conduct enclosure
                integrity test prior to commencement of the asbestos removal
                work
              </PrintListItem>
              <PrintListItem>
                Saturate asbestos material through its full depth, but not so
                much that run-off or pools of contaminated water will occur.
                Maintain in a wet condition
              </PrintListItem>
              <PrintListItem isStriped>
                After the removal work has been completed HEPA vacuum and/or wet
                wipe removal surfaces (paying particular attention to ledges),
                immediate surrounding areas, equipment within the work area, and
                any non-movable items
              </PrintListItem>
              <PrintListItem>
                Request visual clearance inspection by independent asbestos
                assessor
              </PrintListItem>
              <PrintListItem isStriped>
                After receiving verbal clearance by independent asbestos
                assessor encapsulate removal surfaces and plastic sheeting
                barriers with a PVA solution using airless spray equipment
              </PrintListItem>
              <PrintListItem>
                Request clearance air monitor(s) be placed within each enclosure
                by the independent asbestos assessor
              </PrintListItem>
              <PrintListItem isStriped>
                Do not dismantle enclosure until clearance to reoccupy has been
                granted by the independent asbestos assessor
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'vinyl sheeting',
        ) && (
          <Box className="pba" data-testid="arcp-vinyl-sheeting">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Vinyl Sheeting/Tiles
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Place a tool (such as a scraper or wide blade) under the
                material and lift away from the surface, being careful to
                minimise breakage
              </PrintListItem>
              <PrintListItem>
                A hammer or mallet can be used to tap the tool under
                firmly-adhered material to assist separating the material from
                the surface
              </PrintListItem>
              <PrintListItem isStriped>
                Minimise dust by spraying fine water mist under the material as
                it is lifted, if necessary
              </PrintListItem>
              <PrintListItem>Gently scape up adhering mastic</PrintListItem>
              <PrintListItem isStriped>
                Place material into asbestos waste container (taking care not to
                overfill and rupture)
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find((ra) =>
          [
            'backing board',
            'electrical backing board',
            'switchboard backing panel',
          ].some((keyword) => ra.Material.name.toLowerCase().includes(keyword)),
        ) && (
          <Box className="pba" data-testid="arcp-electrical-backing-board">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Backing Board
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Electricity to be disconnected from the meter panel and wiring
                to back of board terminated
              </PrintListItem>
              <PrintListItem>
                Tilt the board forward and disconnect the cabling from the board
              </PrintListItem>
              <PrintListItem isStriped>
                Remove hinge(s) from metal box
              </PrintListItem>
              <PrintListItem>Place into asbestos waste container</PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'mastic',
        ) && (
          <Box className="pba" data-testid="arcp-mastic-section">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Mastic, Sealant, Beading, Filler, Putty
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Place drop sheets under removal area
              </PrintListItem>
              <PrintListItem>
                Cut and remove managable sections. Place cut pieces in waste
                container
              </PrintListItem>
              <PrintListItem isStriped>
                Remove adhering material by dampening and gently scraping.
                Consider using 'shadow vacuuming' techniques while scraping
              </PrintListItem>
              <PrintListItem>
                Care should be taken not to burn the material if heating is used
                to soften the material
              </PrintListItem>
              <PrintListItem isStriped>
                Spray surface(s) that cannot be fully decontaminated with one
                (1) coat of PVA or equivalent
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'fire door',
        ) && (
          <Box className="pba" data-testid="arcp-fire-door">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Fire Door
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Seal edges and any other openings of door with tape
              </PrintListItem>
              <PrintListItem>
                If door edges cannot be sealed, lay door on plastic sheeting and
                wrap and seal with tape
              </PrintListItem>
              <PrintListItem isStriped>
                Stand on the hinge side of the door and remove the pins from the
                hinges. Start at the bottom hinge so the door doesn't topple
                over
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'gaskets',
        ) && (
          <Box className="pba" data-testid="arcp-gaskets">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Gasket and Rope
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Verify the plant and/or equipment is shut down and isolated
              </PrintListItem>
              <PrintListItem>Dismantle the equipment carefully</PrintListItem>
              <PrintListItem isStriped>
                Dampen the gasket or rope with water. Use a hand scraper to
                slowly remove the gasket or rope. Continue to dampen as dryer
                material is exposed
              </PrintListItem>
              <PrintListItem>
                Collect the removed material and place in asbestos waste
                container
              </PrintListItem>
              <PrintListItem isStriped>
                Remove adhering material by dampening and gently scraping.
                Consider using 'shadow vacuuming' techniques while scraping
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find(
          (ra) => ra.Material.name.toLowerCase() === 'false ceiling tiles',
        ) && (
          <Box className="pba" data-testid="arcp-false-ceiling-tiles">
            <Header my="10px" fontSize="md" fontWeight="bold">
              False Ceiling Tiles
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Cover any surface below the tiles that might be contaminated
                with plastic sheeting
              </PrintListItem>
              <PrintListItem>
                Carefully lift the first tile taking care to minimise the
                disturbance of any asbestos fibres. HEPA vucuum and/or wet wipe,
                where possible, the top of each tile prior to removing
                subsequent tiles
              </PrintListItem>
              <PrintListItem isStriped>
                Wrap tiles in two layers of plastic sheeting
              </PrintListItem>
            </UnorderedList>
          </Box>
        )}

        {removalProject.RemovalAreas.find((ra) =>
          ['conduit', 'inground pipework'].some((keyword) =>
            ra.Material.name.toLowerCase().includes(keyword),
          ),
        ) && (
          <Box className="pba" data-testid="arcp-cement-sheeting">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Inground Pipework or Conduit
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped>
                Facilities to be set up to decontaminate workers and tools
                carrying out the asbestos removal work
              </PrintListItem>
              <PrintListItem>
                Remove exposed pipe in whole sections and wrap in two layers of
                polyethylene sheeting
              </PrintListItem>
              <PrintListItem>
                Tools and equipment include shovels, jimmy bar, rakes, chisel
                and PVA or like
              </PrintListItem>
              <PrintListItem isStriped>If breakage is required</PrintListItem>
              <UnorderedList spacing="5px">
                <PrintListItem>
                  Spray the section to be broke with PVA or like
                </PrintListItem>
                <PrintListItem>
                  Place plastic sheeting around the area to be broken
                </PrintListItem>
                <PrintListItem>
                  Gradually split the pipe lengthwise using a chisel and hammer
                </PrintListItem>
                <PrintListItem>
                  After the top of the pipe has been split, use a crowbar or
                  similar tool as a lever to split the bottom of the pipe
                </PrintListItem>
                <PrintListItem>
                  Carefully remove the plastic sheeting surrounding the pipe and
                  dispose of as asbestos waste
                </PrintListItem>
                <PrintListItem>
                  Spray the broken edges of the pipe with PVA or like to seal
                  the damaged edges
                </PrintListItem>
              </UnorderedList>
            </UnorderedList>
          </Box>
        )}

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            7.0 Decontamination
          </Header>
          <Box className="pba">
            <Header my="10px" fontSize="md" fontWeight="bold">
              Asbestos Removal Work Area
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Surfaces from which asbestos has been removed to be cleaned to
                remove visible material following removal
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Any remaining non-movable items to be vacuumed using H-class
                HEPA vacuum and/ or wet wiped to remove any residual dust; wet
                wipes to be double bagged
              </PrintListItem>
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Plastic sheeting to be carefully rolled or folded up and secured
                with tape or double bagged
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Rough sawn wood surfaces to be sealed with sealant
              </PrintListItem>
            </UnorderedList>
            <Header my="10px" fontSize="md" fontWeight="bold">
              Personal Decontamination
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Decontamination area to be established within the barriers when
                doing non-friable asbestos removal works
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Personal decontamination to the undertaken each time a worker
                leaves the asbestos removal work area
              </PrintListItem>
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Remove visible dust/residue from protective clothing using
                H-class HEPA vacuum and/or wet wipes; wet wipes to be double
                bagged
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                While wearing respirator, carefully remove disposable Protective
                clothing and double bag
              </PrintListItem>
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Use H-class HEPA vacuum and/or wet wipes to clean footwear; wet
                wipes to be double bagged
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Remove respiratory protective equipment
              </PrintListItem>
              <UnorderedList spacing="5px">
                <PrintListItem isStriped pl="10px" listStyleType="'-'">
                  a) Disposable respiratory protective equipment to be double
                  bagged
                </PrintListItem>
                <PrintListItem pl="10px" listStyleType="'-'">
                  b) reusable respiratory protective equipment to be placed in
                  sealed asbestos container and only re-opened when inside
                  contaminated area of next project
                </PrintListItem>
              </UnorderedList>
            </UnorderedList>
            <Header my="10px" fontSize="md" fontWeight="bold">
              Tools and Equipment
            </Header>
            <UnorderedList spacing="5px">
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Fully dismantle tools used during asbestos removal work (where
                appropriate) and clean using H-class HEPA vacuum and/or wet
                wipes (depending on its practicality the level of contamination
                and the presence of any electrical hazards) before they are
                removed from the asbestos removal work area; wet wipes to be
                double bagged
              </PrintListItem>
            </UnorderedList>
          </Box>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            8.0 Transport and Disposal
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Asbestos waste to be disposed of as soon as practicable at an
              approved landfill
            </PrintListItem>
            <PrintListItem>
              Asbestos waste to be transported and disposed of in accordance
              with relevant State or Territory legislation and landfill
              guidelines
            </PrintListItem>
          </UnorderedList>
        </Box>

        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            9.0 Clearance
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem isStriped>
              Arrange with an independent asbestos assesor to conduct clearance
              inspection following asbestos removal
            </PrintListItem>
            {containsFriable && (
              <>
                <PrintListItem>
                  Arrange with an independent asbestos assessor to conduct
                  clearance inspection and air (control) monitoring
                </PrintListItem>
                <PrintListItem>
                  The following actions to be undertaken by the asbestos
                  removalist depending on the following respirable fibre levels
                </PrintListItem>
              </>
            )}
          </UnorderedList>
          {containsFriable && (
            <>
              <Box mt="20px">
                <HStack
                  bg="gray.50"
                  spacing={0}
                  borderBottom="1px solid"
                  fontWeight="bold"
                  borderColor="gray.300"
                  className="pba"
                >
                  <Box
                    flex="1"
                    bg="gray.50"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text p="10px" textTransform="uppercase">
                      Action Level
                    </Text>
                  </Box>
                  <Box
                    flex="1"
                    bg="gray.50"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text p="10px" textTransform="uppercase">
                      Control
                    </Text>
                  </Box>
                  <Box
                    flex="1"
                    bg="gray.50"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text p="10px" textTransform="uppercase">
                      Action
                    </Text>
                  </Box>
                </HStack>
                <HStack
                  spacing={0}
                  borderLeft="1px solid"
                  borderBottom="1px solid"
                  borderColor="gray.300"
                  className="pba"
                >
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text>Less than 0.01 fibres/ml</Text>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <Text color="black">
                      No new control measures are necessary
                    </Text>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <Text color="black">Continue with control measures</Text>
                  </Box>
                </HStack>

                <HStack
                  spacing={0}
                  borderBottom="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                  className="pba"
                >
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text>
                      At 0.01 fibres/mL or more than 0.01 fibres/mL but less
                      than or equal to 0.02 fibres/mL
                    </Text>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <OrderedList>
                      <PrintListItem>Review</PrintListItem>
                      <PrintListItem>Investigate</PrintListItem>
                      <PrintListItem>Implement</PrintListItem>
                    </OrderedList>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <Stack spacing="0">
                      <Text color="black">Review control measures.</Text>
                      <Text color="black">Investigate the cause.</Text>
                      <Text color="black">
                        Implement controls to elmininate or minimise exposure
                        &amp; prevent futher release.
                      </Text>
                    </Stack>
                  </Box>
                </HStack>
                <HStack
                  spacing={0}
                  borderBottom="1px solid"
                  borderLeft="1px solid"
                  borderColor="gray.300"
                  className="pba"
                >
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                  >
                    <Text>More than 0.02 fibres/mL</Text>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <OrderedList>
                      <PrintListItem>Stop removal work</PrintListItem>
                      <PrintListItem>Notify relevant reguator</PrintListItem>
                    </OrderedList>
                  </Box>
                  <Box
                    flex="1"
                    p="10px"
                    alignSelf="stretch"
                    borderRight="1px solid"
                    borderColor="gray.300"
                    display="flex"
                  >
                    <Stack spacing="0">
                      <Text color="black">Stop removal work.</Text>
                      <Text color="black">
                        Notify the relevant regulator by phone followed by a
                        written statement that work has ceased and the results
                        of the air monitoring.
                      </Text>
                    </Stack>
                  </Box>
                </HStack>
              </Box>
              <Box mt="10px">
                <Text>
                  The asbestos assessor should not issue a clearance certificate
                  unless the results of the air monitoring test shows that any
                  identified asbestos fibre levels are below 0.01 fibres/mL
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Box mt="15px" className="pba">
          <Header my="10px" fontSize="md" fontWeight="bold">
            10.0 Consultation
          </Header>
          <UnorderedList spacing="5px">
            <PrintListItem pl="10px" listStyleType="'-'">
              Ensure clearance certificate and/or results of the air (control)
              monitoring are given to the following people:
            </PrintListItem>
            <UnorderedList spacing="0px">
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                Workers at the workplace
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Health and safety representatives for the workplace
              </PrintListItem>
              <PrintListItem isStriped pl="10px" listStyleType="'-'">
                PCBU's at the workplace, and
              </PrintListItem>
              <PrintListItem pl="10px" listStyleType="'-'">
                Other people at the workplace
              </PrintListItem>
            </UnorderedList>
          </UnorderedList>
        </Box>
      </A4>
    </Box>
  );
};
