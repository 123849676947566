import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Button,
  Box,
  Stack,
  IconButton,
  Image,
  Text,
  HStack,
  Divider,
  Drawer,
  DrawerOverlay,
  FormControl,
  FormLabel,
  DrawerContent,
  DrawerHeader,
  Input,
  FormErrorMessage,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  useDisclosure,
  Select,
  Switch,
  DrawerFooter,
} from '@chakra-ui/react';
import { DatePicker } from 'antd';
import { RemovalProjectInterface, EmployeeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import { Header } from '../../containers/Text/Header';
import { Section } from '../../containers/Section';
import {
  UPLOAD_SIGNATURE,
  DELETE_SIGNATURE,
  GET_SIGNED_URL,
} from '../../services/apollo/mutations';
import { FaTimes } from 'react-icons/fa';
import SignatureCanvas from 'react-signature-canvas';
import { useFormik } from 'formik';
import { useAuthContext } from '../../providers/Auth.Provider';
import axios from 'axios';

export interface RemovalProjectSignaturesProps {
  onComplete(): void;
  removalProject: RemovalProjectInterface;
  employees: EmployeeInterface[];
}

export interface SignatureTypeInterface {
  signaturableId: number;
  signaturableType: string;
}

export const RemovalProjectSignatures = ({
  onComplete,
  removalProject,
  employees,
}: RemovalProjectSignaturesProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const { me } = useAuthContext();
  const sigRef = useRef<SignatureCanvas>(null);
  const [signature, setSignature] = useState<string>();
  const [signatures, setSignatures] = useState<SignatureTypeInterface[]>([
    {
      signaturableId: Number(removalProject.id),
      signaturableType: 'SWMS',
    },
  ]);
  const [isSigningAll, setIsSigningAll] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteSignature, { loading: deleteIsLoading }] = useMutation(
    DELETE_SIGNATURE,
    {
      onCompleted(d) {
        if (!d || !d.deleteSignature || !d.deleteSignature.status) return;

        handleClose();
        onComplete();

        toast({
          title: 'Signature removed',
          description: `Signature removed`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [uploadSignature, { loading }] = useMutation(UPLOAD_SIGNATURE, {
    onCompleted(d) {
      if (!d || !d.uploadSignature || !d.uploadSignature.status) return;

      handleClose();
      onComplete();
      onClearSignature();
      setSignature(undefined);

      toast({
        title: 'Signature uploaded',
        description: `Signature uploaded`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const onClearSignature = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  };

  const onSaveSignature = () => {
    if (sigRef.current) {
      setSignature(sigRef.current.getCanvas().toDataURL('image/png'));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: me ? me.name : '',
      date: moment().toDate(),
      organization: 'Jesco',
      supervisor:
        removalProject && removalProject.Supervisor
          ? removalProject.Supervisor.name
          : 'Tony Hall',
      useExistingSignature: false,
      employeeId: undefined,
    },
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      name: Yup.string().nullable(),
      date: Yup.date().required('Date is required'),
      oragnization: Yup.string(),
      supervisor: Yup.string(),
      useExistingSignature: Yup.boolean(),
      employeeId: Yup.number().nullable(),
    }),
    onSubmit: async (values) => {
      if (values.employeeId) {
        const employee = employees.find(
          (e) => e.id === Number(values.employeeId),
        );

        if (employee) {
          const name = `${employee.first} ${employee.last}`;

          uploadSignature({
            variables: {
              name,
              supervisor:
                removalProject && removalProject.Supervisor
                  ? removalProject.Supervisor.name
                  : 'Tony Hall',
              organization: 'Jesco',
              employeeId: Number(values.employeeId),
              date: moment(values.date).format('DD/MM/YYYY'),
              signatures,
            },
          });
        }
      } else {
        const { data } = await getSignedUrl({
          variables: {
            file: `${values.name}.png`,
            type: 'image/png',
            folder: 'employee-signatures',
          },
        });

        if (data.getSignedUrl && signature) {
          const signedUrl = data.getSignedUrl;
          const base64Response = await fetch(signature);
          const blob = await base64Response.blob();
          // signature is a base64 encoded image
          const response = await axios.put(signedUrl, blob, {
            headers: {
              'Content-Type': 'image/png',
            },
          });

          const path = data.getSignedUrl.split('?')[0];

          console.log({
            path,
            response,
            name: values.name,
            supervisor: values.supervisor,
            organization: values.organization,
            employeeId: Number(values.employeeId),
            date: moment(values.date).format('DD/MM/YYYY'),
            signatures,
          });

          uploadSignature({
            variables: {
              path,
              name: values.name,
              supervisor: values.supervisor,
              organization: values.organization,
              employeeId: Number(values.employeeId),
              date: moment(values.date).format('DD/MM/YYYY'),
              signatures,
            },
          });
        }
      }
    },
  });

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onError: (error) => {
      toast({
        title: 'Upload failed',
        description: 'The file could not be uploaded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Failed to upload file:', error);
    },
  });

  return (
    <>
      <Section alignSelf="flex-start" maxWidth={['auto', 'auto', '500px']}>
        <HStack justify="space-between" mb="10px">
          <Header fontSize="lg" color="textSecondary">
            Signatures
          </Header>
          <Button
            data-testid="sign-all-forms-button"
            isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
            onClick={() => {
              onOpen();
              setIsSigningAll(true);
            }}
            variant="link"
          >
            Sign all
          </Button>
        </HStack>
        <Divider />
        <HStack mt="10px" justify="space-between">
          <Text fontSize="md">SWMS</Text>
          <Button
            data-testid="swms-add-signature"
            isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
            onClick={() => {
              onOpen();
              setIsSigningAll(false);
              setSignatures([
                {
                  signaturableId: Number(removalProject.id),
                  signaturableType: 'SWMS',
                },
              ]);
            }}
            variant="link"
          >
            Add signature
          </Button>
        </HStack>

        {removalProject.SWMSSignatures.map((td) => (
          <Box
            key={td.id}
            borderBottom="1px solid"
            borderColor="gray.300"
            pl="10px"
            data-testid="swms-signatures"
          >
            <HStack justify="space-between">
              <Stack spacing={1}>
                <Text fontSize="md">{`${td.name} - ${td.organization}`}</Text>
                <Text>{td.date}</Text>
              </Stack>
              <HStack>
                <IconButton
                  aria-label="delete-signature-button"
                  data-testid="delete-signature-button"
                  variant="outline"
                  isDisabled={
                    !Boolean(me?.Permissions?.can_edit_removal_projects)
                  }
                  icon={<FaTimes />}
                  isLoading={deleteIsLoading}
                  size="sm"
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      deleteSignature({
                        variables: {
                          id: td.id,
                        },
                      });
                    }
                  }}
                />
              </HStack>
            </HStack>
          </Box>
        ))}
        {removalProject.ToolboxTalks.map((tbt) => (
          <Stack key={tbt.id}>
            <HStack mt="10px" justify="space-between">
              <Text fontSize="md">
                Toolbox talk - {moment(tbt.date).format('D MMMM, YYYY')}
              </Text>
              <Button
                data-testid="toolbox-talk-add-signature"
                isDisabled={
                  !Boolean(me?.Permissions?.can_edit_removal_projects)
                }
                onClick={() => {
                  onOpen();
                  setIsSigningAll(false);
                  setSignatures([
                    {
                      signaturableId: Number(tbt.id),
                      signaturableType: 'toolboxTalk',
                    },
                  ]);
                }}
                variant="link"
              >
                Add signature
              </Button>
            </HStack>
            {tbt.Signatures.map((td) => (
              <Box
                key={td.id}
                borderBottom="1px solid"
                borderColor="gray.300"
                pl="10px"
                data-testid="toolbox-talk-signatures"
              >
                <HStack justify="space-between">
                  <Stack spacing={1}>
                    <Text fontSize="md">{`${td.name} - ${td.organization}`}</Text>
                    <Text>{td.date}</Text>
                  </Stack>
                  <HStack>
                    <IconButton
                      isDisabled={
                        !Boolean(me?.Permissions?.can_edit_removal_projects)
                      }
                      aria-label="delete-signature-button"
                      data-testid="delete-signature-button"
                      variant="outline"
                      icon={<FaTimes />}
                      size="sm"
                      isLoading={deleteIsLoading}
                      onClick={() => {
                        if (window.confirm('Are you sure?')) {
                          deleteSignature({
                            variables: {
                              id: td.id,
                            },
                          });
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </Box>
            ))}
          </Stack>
        ))}
      </Section>

      <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <form onSubmit={formik.handleSubmit} noValidate>
          <DrawerContent rounded="md">
            <DrawerHeader
              px="40px"
              display="flex"
              fontSize="md"
              color="white"
              bg="blue.500"
            >
              {isSigningAll ? 'Sign all' : 'Add signature'}
            </DrawerHeader>
            <DrawerCloseButton
              left="0"
              top="5px"
              data-testid="drawer-close-button"
              onClick={handleClose}
              color="white"
            />
            <DrawerBody p="20px" data-testid="drawer">
              <Stack>
                {isSigningAll && (
                  <>
                    <Text fontSize="md">
                      Which forms would like like to sign?
                    </Text>
                    <FormControl
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p="10px"
                    >
                      <FormLabel htmlFor="signature-form-swms" mb="0">
                        SWMS
                      </FormLabel>
                      <Switch
                        colorScheme="green"
                        id="signature-form-swms"
                        name="signature-form-swms"
                        data-testid="signature-form-swms"
                        isChecked={Boolean(
                          signatures.find((s) => s.signaturableType === 'SWMS'),
                        )}
                        onChange={() => {
                          if (
                            !signatures.find(
                              (s) => s.signaturableType === 'SWMS',
                            )
                          ) {
                            setSignatures([
                              ...signatures,
                              {
                                signaturableType: 'SWMS',
                                signaturableId: Number(removalProject.id),
                              },
                            ]);
                          } else {
                            setSignatures(
                              signatures.filter(
                                (s) => s.signaturableType !== 'SWMS',
                              ),
                            );
                          }
                        }}
                      />
                    </FormControl>
                    <Divider />
                    {removalProject.ToolboxTalks.map((tbt) => (
                      <Stack key={`${tbt.date}-${tbt.id}`}>
                        <FormControl
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p="10px"
                        >
                          <FormLabel
                            htmlFor="signature-form-toolbox-talk"
                            mb="0"
                          >
                            Toolbox Talk -{' '}
                            {moment(tbt.date, 'YYYY-MM-DD').format(
                              'D MMMM, YYYY',
                            )}
                          </FormLabel>
                          <Switch
                            colorScheme="green"
                            id="signature-form-toolbox-talk"
                            name="signature-form-toolbox-talk"
                            data-testid="signature-form-toolbox-talk"
                            isChecked={Boolean(
                              signatures.find(
                                (s) =>
                                  s.signaturableType === 'toolboxTalk' &&
                                  s.signaturableId === Number(tbt.id),
                              ),
                            )}
                            onChange={() => {
                              if (
                                !signatures.find(
                                  (s) =>
                                    s.signaturableType === 'toolboxTalk' &&
                                    s.signaturableId === Number(tbt.id),
                                )
                              ) {
                                setSignatures([
                                  ...signatures,
                                  {
                                    signaturableType: 'toolboxTalk',
                                    signaturableId: Number(tbt.id),
                                  },
                                ]);
                              } else {
                                setSignatures(
                                  signatures.filter(
                                    (s) => s.signaturableType !== 'toolboxTalk',
                                  ),
                                );
                              }
                            }}
                          />
                        </FormControl>
                        <Divider />
                      </Stack>
                    ))}
                  </>
                )}

                <FormControl id="useExistingSignature">
                  <HStack justify="space-between">
                    <FormLabel>Use employee signature?</FormLabel>
                    <HStack>
                      <Button
                        colorScheme="green"
                        variant="outline"
                        data-testid="use-employee-signature-yes"
                        isActive={formik.values.useExistingSignature}
                        onClick={() =>
                          formik.setFieldValue('useExistingSignature', true)
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        variant="outline"
                        colorScheme="yellow"
                        data-testid="use-employee-signature-no"
                        isActive={!formik.values.useExistingSignature}
                        onClick={() => {
                          onClearSignature();
                          setSignature(undefined);
                          formik.setFieldValue('useExistingSignature', false);
                          formik.setFieldValue('employeeId', undefined);
                        }}
                      >
                        No
                      </Button>
                    </HStack>
                  </HStack>
                </FormControl>
                <Divider mb="20px" />
                {formik.values.useExistingSignature && (
                  <FormControl id="client">
                    <FormLabel>Employee</FormLabel>
                    <Select
                      data-testid="signature-form-employee-dropdown"
                      name="employeeId"
                      placeholder="Select an employee"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employeeId}
                      autoFocus
                    >
                      {employees.map((c) => (
                        <option key={c.id} value={c.id}>
                          {`${c.first} ${c.last}`}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!formik.values.useExistingSignature && (
                  <>
                    <FormControl
                      id="name"
                      isInvalid={Boolean(formik.errors.name)}
                      isRequired
                    >
                      <FormLabel>Name</FormLabel>
                      <Input
                        data-testid="signature-form-name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        autoFocus
                      />
                      <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="organization"
                      isInvalid={Boolean(formik.errors.organization)}
                    >
                      <FormLabel>Organization</FormLabel>
                      <Input
                        data-testid="signature-form-organization"
                        organization="organization"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organization}
                      />
                      <FormErrorMessage>
                        {formik.errors.organization}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="supervisor"
                      isInvalid={Boolean(formik.errors.supervisor)}
                    >
                      <FormLabel>Supervisor</FormLabel>
                      <Input
                        data-testid="signature-form-supervisor"
                        supervisor="supervisor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.supervisor}
                      />
                      <FormErrorMessage>
                        {formik.errors.supervisor}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
                <FormControl id="date" data-testid="signature-form-date">
                  <FormLabel>
                    <HStack justifyContent="space-between">
                      <Text>Date</Text>
                      <Text>
                        {moment(formik.values.date).format('D MMMM, YYYY')}
                      </Text>
                    </HStack>
                  </FormLabel>

                  <DatePicker
                    format="DD/MM/YYYY"
                    value={moment(formik.values.date).clone()}
                    onChange={(d) => {
                      formik.setFieldValue('date', d?.toDate());
                    }}
                  />
                </FormControl>
                {!formik.values.useExistingSignature && !signature && (
                  <Stack>
                    <Text fontSize="md" fontWeight="bold">
                      Sign below
                    </Text>
                    <Box
                      border="1px solid"
                      borderColor="gray.300"
                      rounded="md"
                      width="400px"
                      data-testid="signature-container"
                    >
                      <SignatureCanvas
                        ref={sigRef}
                        penColor="black"
                        canvasProps={{
                          height: 200,
                          width: 400,
                          className: 'sigCanvas',
                        }}
                      />
                    </Box>
                    <HStack>
                      <Button onClick={onClearSignature}>Clear</Button>
                      <Button onClick={onSaveSignature}>Save</Button>
                    </HStack>
                  </Stack>
                )}

                {!formik.values.useExistingSignature && signature && (
                  <Stack>
                    <Box
                      border="1px solid"
                      borderColor="green.300"
                      rounded="md"
                      width="400px"
                    >
                      <Image width={400} height={200} src={signature} />
                    </Box>

                    <HStack>
                      <Button onClick={() => setSignature(undefined)}>
                        Clear
                      </Button>
                    </HStack>
                  </Stack>
                )}
              </Stack>
            </DrawerBody>
            <DrawerFooter
              justifyContent="space-evenly"
              borderTop="1px solid"
              borderColor="gray.300"
            >
              <Button width="200px" size="md" mr="5px" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                width="200px"
                ml="5px"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isLoading={loading}
                disabled={
                  (!formik.values.useExistingSignature &&
                    !Boolean(signature)) ||
                  (formik.values.useExistingSignature &&
                    !Boolean(formik.values.employeeId)) ||
                  !formik.isValid
                }
              >
                Upload
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </>
  );
};
