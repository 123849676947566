import React from 'react';
import { Text, Flex, IconButton } from '@chakra-ui/react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { PaginatorNumberButton } from './PaginatorNumberButton';
import { getPaginatorPages } from './Paginator/getPaginatorPages';

export interface PaginatorInterface {
  currentPage: number;
  perPage: number;
  totalResults: number;
  onChangePage(pageNumber: number): void;
}

export const Paginator = ({
  currentPage,
  perPage,
  totalResults,
  onChangePage,
}: PaginatorInterface) => {
  const pages = getPaginatorPages({
    totalResults,
    currentPage,
    perPage,
  });

  const isNextPageDisabled =
    !pages.length ||
    (pages[pages.length - 1] && pages[pages.length - 1].number === currentPage);

  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      data-testid="paginator"
    >
      <IconButton
        aria-label="pagination-previous"
        p={0}
        ml={2}
        color="blue"
        colorScheme="gray"
        variant="ghost"
        size="xs"
        icon={<FaChevronLeft />}
        disabled={!pages.length || currentPage === 1}
        onClick={() => onChangePage(currentPage - 1)}
      />

      {pages.map((page) => {
        if (page.type === 'page') {
          let dataTestId = `paginator-page-${page.number}`;

          const maxPageNumber = Math.ceil(totalResults / perPage);
          if (page.number === maxPageNumber) {
            dataTestId = 'paginator-page-last';
          }

          if (page.number === currentPage) {
            dataTestId = 'paginator-page-current';
          }

          return (
            <PaginatorNumberButton
              key={dataTestId}
              data-testid={dataTestId}
              isActive={currentPage === page.number}
              onClick={onChangePage}
              number={page.number}
            />
          );
        }

        if (page.type === 'elipsis') {
          return (
            <Text key={`elipsis-${page.number}`} color="green">
              ...
            </Text>
          );
        }

        return null;
      })}

      <IconButton
        disabled={isNextPageDisabled}
        aria-label="pagination-next"
        p={0}
        ml={2}
        color="green"
        colorScheme="gray"
        variant="ghost"
        size="xs"
        icon={<FaChevronRight />}
        onClick={() => onChangePage(currentPage + 1)}
      />
    </Flex>
  );
};
