import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Stack, Button, Flex, FlexProps, Image, Text } from '@chakra-ui/react';

import { useAuthContext } from '../providers';
import logo from '../lib/images/logo.png';

export interface FooterPropsInterface extends RouteComponentProps, FlexProps {}

export const Footer = withRouter(
  ({ history: { push }, ...props }: RouteComponentProps) => {
    const { me, logout } = useAuthContext();

    return (
      <Flex
        as="nav"
        justify="center"
        p="1rem"
        width="100%"
        position="relative"
        zIndex={3}
        paddingX="2rem"
        paddingY="3rem"
        bg="white"
        {...props}
      >
        <Stack width="100%" maxWidth="1200px" spacing="50px" align="center">
          <Button
            variant="solid"
            height="auto"
            colorScheme="white"
            onClick={() => push('/home')}
          >
            <Image height="70px" src={logo} />
          </Button>

          <Flex>
            <Flex>
              {me && (
                <Button
                  variant="link"
                  size="md"
                  colorScheme="green"
                  onClick={() => push('/dashboard')}
                  bg="transparent"
                  mr="20px"
                >
                  Dashboard
                </Button>
              )}

              {me && (
                <Button
                  variant="outline"
                  size="sm"
                  colorScheme="green"
                  onClick={logout}
                  bg="transparent"
                >
                  Sign out
                </Button>
              )}
            </Flex>
          </Flex>
          <Stack align="center">
            <Text fontWeight={300} color="blue.500">
              17 Capital Terrace Queanbeyan NSW, 2620
            </Text>
            <Text fontWeight={300} color="blue.500">
              ABN: 64660657572
            </Text>
          </Stack>
        </Stack>
      </Flex>
    );
  },
);
