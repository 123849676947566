import React, { useState } from 'react';
import { CalendarEventTypeInterface, CalendarEventInterface } from 'jesco-web';
import { Event, Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
  Box,
  Button,
  HStack,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Portal,
  IconButton,
  Progress,
} from '@chakra-ui/react';

import { Header } from '../containers/Text/Header';
import { CalendarEventDrawer } from 'src/containers/Calendar/CalendarEventDrawer';
import {
  FaChevronRight,
  FaEllipsisH,
  FaPen,
  FaChevronLeft,
} from 'react-icons/fa';
import { useQuery } from '@apollo/client';
import {
  GET_CALENDAR_EVENT_TYPES,
  GET_CALENDAR_EVENTS_FOR_DATE,
  GET_CALENDAR_EVENT,
} from '../services/apollo/queries';
import { CalendarEvent } from '../containers/Calendar/CalendarEvent';
import { CalendarEventPopover } from 'src/containers/Calendar/CalendarEventPopover';
import { CalendarEventTypeDrawer } from 'src/containers/Calendar/CalendarEventTypeDrawer';
import { withRouter, RouteComponentProps } from 'react-router-dom';

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);

export const CalendarPage = withRouter(
  ({
    match,
  }: RouteComponentProps<{
    eventId?: string;
  }>) => {
    const [view, setView] = useState<
      'day' | 'month' | 'week' | 'work_week' | 'agenda'
    >('month');

    const [date, setDate] = useState<Date>(moment().toDate());
    const [newEventDate, setNewEventDate] = useState<Date>(moment().toDate());
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
      isOpen: isPopoverOpen,
      onOpen: onPopoverOpen,
      onClose: onPopoverClose,
    } = useDisclosure();
    const {
      isOpen: isEventTypeDrawerOpen,
      onOpen: onEventTypeDrawerOpen,
      onClose: onEventTypeDrawerClose,
    } = useDisclosure();
    const [event, setEvent] = useState<CalendarEventInterface>();

    const {
      data: calendarEventTypesData,
      refetch: refetchEventTypes,
      loading: isGetEventTypesLoading,
    } = useQuery<{
      getCalendarEventTypes: CalendarEventTypeInterface[];
    }>(GET_CALENDAR_EVENT_TYPES);

    let calendarEventTypes: CalendarEventTypeInterface[] = [];

    if (calendarEventTypesData) {
      calendarEventTypes = calendarEventTypesData.getCalendarEventTypes;
    }

    const { loading: isEventLoading } = useQuery<{
      getCalendarEvent: CalendarEventInterface;
    }>(GET_CALENDAR_EVENT, {
      variables: {
        id: match.params.eventId,
      },
      skip: !Boolean(match.params.eventId),
      onCompleted: (d) => {
        if (d && d.getCalendarEvent) {
          const returnedEvent = d?.getCalendarEvent;
          onPopoverOpen();
          setView('month');
          setEvent(returnedEvent);
          setDate(moment(returnedEvent.start_date_formatted, 'LLL').toDate());
        }
      },
    });

    const { data, refetch, loading } = useQuery<{
      getCalendarEventsForDate: CalendarEventInterface[];
    }>(GET_CALENDAR_EVENTS_FOR_DATE, {
      variables: {
        date: moment(date).format('MM/DD/YYYY'),
      },
      nextFetchPolicy: 'network-only',
    });

    let myEventsList: Event[] = [];
    let calendarEvents: CalendarEventInterface[] = [];

    if (data) {
      calendarEvents = data.getCalendarEventsForDate;
      myEventsList = data.getCalendarEventsForDate.reduce<Event[]>(
        (state, ce) => {
          return [
            ...state,
            {
              start: moment(ce?.start_date_formatted, 'LLL').toDate(),
              end: moment(ce?.end_date_formatted, 'LLL').toDate(),
              title: ce.title,
              allDay: ce.is_all_day,
              resource: {
                id: ce.id,
                eventTypeId: ce.calendar_event_type_id,
              },
            },
          ];
        },
        [],
      );
    }

    const handleEventClick = (e: Event) => {
      onPopoverOpen();
      const calendarEvent = calendarEvents.find(
        (ce) => ce.id === e.resource.id,
      );
      if (calendarEvent) {
        setEvent(calendarEvent);
      }
    };

    const handlePrev = () => {
      if (view === 'month') {
        setDate(moment(date).subtract(1, 'month').toDate());
      }

      if (view === 'week') {
        setDate(moment(date).subtract(1, 'week').toDate());
      }

      if (view === 'day') {
        setDate(moment(date).subtract(1, 'day').toDate());
      }
    };

    const handleToday = () => {
      setDate(moment().toDate());
    };

    const handleNext = () => {
      if (view === 'month') {
        setDate(moment(date).add(1, 'month').toDate());
      }

      if (view === 'week') {
        setDate(moment(date).add(1, 'week').toDate());
      }

      if (view === 'day') {
        setDate(moment(date).add(1, 'day').toDate());
      }
    };

    let title = moment(date).format('MMMM, YYYY');

    if (view === 'week') {
      const startOfWeek = moment(date).startOf('week').clone();
      const endOfWeek = moment(date).endOf('week').clone();
      title = `${moment(date).format('MMM')} ${startOfWeek.format(
        'D',
      )} - ${endOfWeek.format('D')}, ${moment(date).format('YYYY')}`;
    }

    if (view === 'day') {
      title = moment(date).format('D MMMM, YYYY');
    }

    const isLoading = loading || isGetEventTypesLoading || isEventLoading;

    return (
      <>
        {isLoading && (
          <Box width="100%" position="relative">
            <Progress
              position="absolute"
              top="0"
              left="0"
              right="0"
              height=".15rem"
              isIndeterminate
            />
          </Box>
        )}
        <Box p={['5px', '10px', '20px']} maxWidth="1400px">
          <HStack justify="space-between">
            <Header fontSize="3xl" color="textSecondary" mb="10px">
              {title}
            </Header>

            <HStack>
              <Button variant="link" colorScheme="green" onClick={onOpen}>
                Add Event
              </Button>

              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  data-testid="calendar-settings-button"
                >
                  <FaEllipsisH />
                </MenuButton>
                <Portal>
                  <MenuList data-testid="calendar-settings-menu">
                    <MenuItem onClick={onEventTypeDrawerOpen}>
                      <HStack justify="space-between">
                        <Text>Edit Types</Text>
                        <FaPen />
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </HStack>
          </HStack>

          <Box>
            <Calendar
              onShowMore={() => {
                setView('day');
              }}
              date={date}
              view={view}
              localizer={localizer}
              events={myEventsList}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 1200 }}
              onSelectEvent={handleEventClick}
              eventPropGetter={(e, start, end, isSelected) => {
                const activeEventType = calendarEventTypes.find(
                  (cet) => cet.id === Number(e.resource.eventTypeId),
                );

                let newStyle = {};
                if (view === 'month') {
                  newStyle = {
                    backgroundColor: 'transparent',
                    color: 'black',
                  };
                } else {
                  newStyle = {
                    backgroundColor: String(activeEventType?.color),
                    color: 'white',
                  };
                }

                if (e.allDay) {
                  newStyle = {
                    backgroundColor: String(activeEventType?.color),
                    color: 'white',
                  };
                }

                return {
                  style: newStyle,
                };
              }}
              onDrillDown={(d, v) => {
                setNewEventDate(d);
                setEvent(undefined);
                onOpen();
              }}
              components={{
                event: (props) => (
                  <CalendarEvent
                    calendarEventTypes={calendarEventTypes}
                    {...props}
                  />
                ),
                toolbar: () => (
                  <HStack py="10px" justify="space-between">
                    <HStack spacing={0}>
                      <IconButton
                        aria-label="calendar-previous"
                        size="sm"
                        icon={<FaChevronLeft />}
                        rounded="none"
                        onClick={handlePrev}
                      />
                      <Button
                        size="sm"
                        rounded="none"
                        colorScheme="green"
                        isActive={moment(date).isSame(moment(), 'day')}
                        onClick={handleToday}
                      >
                        Today
                      </Button>
                      <IconButton
                        size="sm"
                        aria-label="calendar-previous"
                        icon={<FaChevronRight />}
                        rounded="none"
                        onClick={handleNext}
                      />
                    </HStack>

                    <HStack spacing={0}>
                      <Button
                        size="sm"
                        rounded="none"
                        colorScheme="green"
                        isActive={view === 'month'}
                        onClick={() => setView('month')}
                      >
                        Month
                      </Button>
                      <Button
                        size="sm"
                        rounded="none"
                        colorScheme="green"
                        isActive={view === 'week'}
                        onClick={() => setView('week')}
                      >
                        Week
                      </Button>
                      <Button
                        size="sm"
                        rounded="none"
                        colorScheme="green"
                        isActive={view === 'day'}
                        onClick={() => setView('day')}
                      >
                        Day
                      </Button>
                    </HStack>
                  </HStack>
                ),
              }}
            />
          </Box>
          <CalendarEventPopover
            isOpen={isPopoverOpen}
            onClose={onPopoverClose}
            event={event}
            onEditClick={() => {
              onPopoverClose();
              onOpen();
            }}
            calendarEventTypes={calendarEventTypes}
          />
        </Box>

        <CalendarEventDrawer
          date={newEventDate}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setEvent(undefined);
          }}
          onComplete={() => {
            onClose();
            refetch();
          }}
          calendarEvent={event}
          calendarEventTypes={calendarEventTypes}
        />

        <Box>
          <CalendarEventTypeDrawer
            isOpen={isEventTypeDrawerOpen}
            onClose={onEventTypeDrawerClose}
            onComplete={() => {
              refetchEventTypes();
            }}
            calendarEventTypes={calendarEventTypes}
            isGetEventTypesLoading={isGetEventTypesLoading}
          />
        </Box>
      </>
    );
  },
);
