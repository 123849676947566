import React from 'react';
import { Text, BoxProps } from '@chakra-ui/react';

export type ErrorMessagePropsInterface = BoxProps;

export const ErrorMessage = ({
  children,
  ...props
}: ErrorMessagePropsInterface) => (
  <Text color="red.50" fontSize="md" data-testid="signin-message" {...props}>
    {children}
  </Text>
);
