import React, { useState } from 'react';
import numeral from 'numeral';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Stack,
  useDisclosure,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FileInterface } from 'jesco-web';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import { FileTableRow } from '../containers/File/FileTableRow';
import { FileDrawer } from '../containers/File/FileDrawer';
import { GET_FILES_QUERY } from 'src/services/apollo';
import { DELETE_FILE } from '../services/apollo/mutations';
import { useAuthContext } from '../providers/Auth.Provider';

export const FilesPage = () => {
  const { me } = useAuthContext();
  const toast = useToast({ position: 'bottom-left' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch, error } = useQuery<{
    files: {
      items: FileInterface[];
      count: number;
    };
  }>(GET_FILES_QUERY, {
    variables: filter,
    nextFetchPolicy: 'network-only',
  });

  const [deleteFile, { loading }] = useMutation(DELETE_FILE, {
    onCompleted(d) {
      if (!d || !d.deleteFile || !d.deleteFile.id) return;

      onClose();
      refetch();

      toast({
        title: 'File deleted',
        description: `${d.deleteFile.name} deleted`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  if (error) {
    return null;
  }

  let files: FileInterface[] = [];
  let count = 0;

  if (data) {
    const {
      files: { count: total, items },
    } = data;

    count = total;
    files = items;
  }

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  const renderRow = ({
    item,
    index,
  }: {
    item: FileInterface;
    index: number;
  }) => {
    return (
      <FileTableRow
        isLoading={loading}
        canClick={Boolean(me?.Permissions?.can_view_company_files)}
        canDelete={Boolean(me?.Permissions?.can_edit_company_files)}
        key={String(item.id)}
        isStriped={index % 2 === 1}
        file={item}
        onDeleteFileClick={(f) => {
          if (window.confirm(`Are you you want to delete ${f.name}?`)) {
            deleteFile({
              variables: {
                id: f.id,
              },
            });
          }
        }}
        onRowClick={() => {
          window.open(item.path, '_blank');
        }}
      />
    );
  };

  return (
    <>
      <Box p={['5px', '10px', '20px']} maxWidth="1400px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary" mb="10px">
            Files
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
            align="center"
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>

            {Boolean(me?.Permissions?.can_edit_company_files) && (
              <Button
                data-testid="add-file-button"
                colorScheme="green"
                variant="ghost"
                color="green.500"
                size="md"
                onClick={() => {
                  onOpen();
                }}
              >
                Upload file
              </Button>
            )}
          </Stack>
        </Stack>
        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>
        <Table
          dataTestId="files-table"
          onChangeFilter={(newFilter) =>
            setFilter({
              ...filter,
              ...newFilter,
            })
          }
          headers={[
            {
              slug: 'name',
              title: 'Name',
              width: '80%',
              'data-testid': 'files-name-header',
            },
            {
              title: 'File type',
              slug: 'type',
              width: '15%',
            },
            {
              title: '',
              width: '5%',
            },
          ]}
          data={files || []}
          renderItem={renderRow}
          filter={filter}
        />
      </Box>

      <FileDrawer isOpen={isOpen} onClose={onClose} onComplete={refetch} />
    </>
  );
};
