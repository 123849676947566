import React, { useState } from 'react';
import { SignInFormValuesInterface } from 'jesco-web';
import { Flex, Box, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SIGN_IN_MUTATION } from '../services/apollo';
import { SignInForm } from '../containers/Form/SignInForm';
import { useAuthContext } from '../providers/Auth.Provider';

export const SignInPage = withRouter(
  ({ history: { push } }: RouteComponentProps) => {
    const toast = useToast({ position: 'bottom-left' });
    const { setAuthToken, setAuthUser, logout } = useAuthContext();
    const [errorMessage, setErrorMessage] = useState('');

    const [signIn, { loading }] = useMutation(SIGN_IN_MUTATION, {
      onCompleted(data) {
        logout();
        setAuthToken(data.signIn.token);
        push('/dashboard');
        setAuthUser(data.signIn.me);

        toast({
          title: `Welcome ${data.signIn.me.name}`,
          description: `Sign in success`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError(e) {
        if (
          e.graphQLErrors.length &&
          e.graphQLErrors[0].extensions &&
          e.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
        ) {
          setErrorMessage(e.graphQLErrors[0].message);
        } else {
          setErrorMessage('Oops! Something went wrong, please try again');
        }
      },
    });

    const handleSubmit = async (form: SignInFormValuesInterface) => {
      setErrorMessage('');

      logout();

      signIn({
        variables: form,
      });
    };

    return (
      <Flex
        justifyContent="center"
        data-testid="page-sign-in"
        bg="base"
        py={['30px', '150px', '200px']}
        width="100%"
        align="center"
      >
        <Box
          rounded="sm"
          width="100%"
          p="20px"
          maxWidth={['450px', '550px']}
          bg="white"
        >
          <SignInForm
            isLoading={loading}
            errorMessage={errorMessage}
            onSubmit={handleSubmit}
            initialValues={{
              email: '',
              password: '',
            }}
          />
        </Box>
      </Flex>
    );
  },
);
