import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

export interface DetailsPropsInterface extends FlexProps {
  label: string;
  body: string;
}

export const Details = ({ label, body, ...props }: DetailsPropsInterface) => (
  <Flex
    align="center"
    justify="space-between"
    borderBottom="1px"
    borderColor="lightGray"
    py="8px"
    {...props}
  >
    <Text color="textSecondary">{label}</Text>

    <Text>{body}</Text>
  </Flex>
);
