import { gql } from '@apollo/client';

export const GET_REMOVAL_PROJECTS_QUERY = gql`
  query getRemovalProjects(
    $clientIds: [ID!]
    $status: String
    $type: String
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    removalProjects(
      clientIds: $clientIds
      status: $status
      type: $type
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        client_id
        client_contact
        assessor_id
        supervisor_id
        address
        city
        state_id
        worksafe_no
        start_date
        end_date
        active
        status
        statusString
        type
        typeString
        quantity
        notes
        created_at
        updated_at
        Client {
          id
          name
          address
          city
          state_id
          post_code
          email
          client_contact
          phone
          mobile
          active
          created_at
          updated_at
        }
        State {
          id
          name
          abbrev
        }
      }
    }
  }
`;

export const GET_PEOPLE_QUERY = gql`
  {
    allAssessors {
      id
      name
      company
      phone
      state_id
      license_no
      license_expires
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
    allClients {
      id
      name
      address
      city
      state_id
      post_code
      email
      client_contact
      phone
      mobile
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
    allSupervisors {
      id
      name
      phone
      active
      created_at
      updated_at
    }
    allEmployees {
      id
      first
      last
      active
      start_date
      termination_date
    }
  }
`;

export const GET_REMOVAL_PROJECT_QUERY = gql`
  query getRemovalProject($id: ID!) {
    removalProject(id: $id) {
      id
      client_id
      client_contact
      assessor_id
      supervisor_id
      address
      city
      state_id
      worksafe_no
      start_date
      end_date
      active
      status
      statusString
      type
      typeString
      quantity
      quantityString
      notes
      created_at
      updated_at
      Client {
        id
        name
        address
        city
        state_id
        post_code
        email
        client_contact
        phone
        mobile
        active
        created_at
        updated_at
      }
      Assessor {
        id
        name
        company
        phone
        state_id
        license_no
        license_expires
        active
        created_at
        updated_at
      }
      Supervisor {
        id
        name
        phone
        active
        created_at
        updated_at
      }
      ToolboxTalks {
        id
        removal_project_id
        date
        weather_id
        planned_activities
        q_1
        q_2
        q_3
        q_4
        q_5
        q_6
        q_7
        topics_to_cover
        notes
        other_items
        active
        created_at
        updated_at
        Signatures {
          id
          signaturable_id
          type
          name
          path
          date
          supervisor
          organization
          created_at
          updated_at
        }
      }
      RemovalAreas {
        id
        has_asbestos
        Area {
          id
          name
        }
        Location {
          id
          name
        }
        Material {
          id
          name
        }
        Type {
          id
          name
        }
        Condition {
          id
          name
        }
      }
      State {
        id
        name
        abbrev
      }
      SWMSProtection {
        id
        swms_protection_hard_hat
        swms_protection_eye
        swms_protection_visible_clothing
        swms_protection_foot
        swms_protection_lung
        swms_protection_hand
        swms_protection_ear
        swms_protection_decontamination
        swms_protection_protective_clothing
        swms_protection_safety_harness
        created_at
        updated_at
      }
      SWMSWorkActivityRisk {
        id
        removal_project_id
        work_activity_risk_1
        work_activity_risk_2
        work_activity_risk_3
        work_activity_risk_4
        work_activity_risk_5
        work_activity_risk_6
        work_activity_risk_7
        work_activity_risk_8
        work_activity_risk_9
        work_activity_risk_10
        work_activity_risk_11
        work_activity_risk_12
        work_activity_risk_13
        work_activity_risk_14
        work_activity_risk_15
        work_activity_risk_16
        work_activity_risk_17
        work_activity_risk_18
        work_activity_risk_19
        work_activity_risk_20
        work_activity_risk_21
        work_activity_risk_22
        work_activity_risk_23
        work_activity_risk_24
        created_at
        updated_at
      }
      Uploads {
        id
        name
        path
        size
        type
        uploadable_type
        uploadable_id
        created_at
        updated_at
      }
      SWMSSignatures {
        id
        signaturable_id
        type
        name
        path
        date
        supervisor
        organization
        created_at
        updated_at
      }
    }
  }
`;

export const GET_CLIENT_QUERY = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      name
      address
      city
      state_id
      post_code
      email
      client_contact
      phone
      mobile
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const GET_ASSESSOR_QUERY = gql`
  query getAssessor($id: ID!) {
    assessor(id: $id) {
      id
      name
      company
      phone
      state_id
      license_no
      license_expires
      active
      created_at
      updated_at
      State {
        id
        name
        abbrev
      }
    }
  }
`;

export const GET_ASSESSORS_QUERY = gql`
  query getAssessor(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    assessors(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        name
        company
        phone
        state_id
        license_no
        license_expires
        active
        created_at
        updated_at
        State {
          id
          name
          abbrev
        }
      }
    }
  }
`;

export const GET_CLIENTS_QUERY = gql`
  query getClients(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    clients(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        name
        address
        city
        state_id
        post_code
        email
        client_contact
        phone
        mobile
        active
        created_at
        updated_at
        State {
          id
          name
          abbrev
        }
      }
    }
  }
`;

export const GET_SUPERVISORS_QUERY = gql`
  query getSupervisors(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    supervisors(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        name
        phone
        active
        created_at
        updated_at
      }
    }
  }
`;

export const GET_EMPLOYEES_QUERY = gql`
  query getEmployees(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    employees(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        first
        last
        active
        start_date
        termination_date
        created_at
        updated_at
        Uploads {
          id
          name
          path
          size
          type
          uploadable_type
          uploadable_id
          created_at
          updated_at
        }
        Signature {
          id
          name
          path
          size
          type
          uploadable_type
          uploadable_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query getUsers(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    users(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        name
        username
        email
        created_at
        updated_at
        Permissions {
          id
          can_edit_clients
          can_edit_users
          can_edit_supervisors
          can_edit_employees
          can_edit_company_files
          can_view_company_files
          can_edit_removal_projects
          can_edit_waste_disposals
          can_edit_quotes
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_APPLICATION_DETAILS_QUERY = gql`
  query applicationDetails {
    applicationDetails {
      states {
        id
        name
        abbrev
      }
      employees {
        id
        first
        last
        active
        start_date
        termination_date
        created_at
        updated_at
      }
    }
  }
`;

export const GET_ME_QUERY = gql`
  {
    me {
      id
      email
      name
      status
      username
      Permissions {
        can_edit_clients
        can_edit_users
        can_edit_supervisors
        can_edit_employees
        can_edit_company_files
        can_view_company_files
        can_edit_removal_projects
        can_edit_waste_disposals
        can_edit_quotes
        created_at
        updated_at
      }
    }
  }
`;

export const GET_WASTE_DISPOSALS_QUERY = gql`
  query getWasteDisposals(
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    wasteDisposals(
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        signature_date
        date
        active
        created_at
        updated_at
        RemovalProjects {
          id
          client_id
          client_contact
          assessor_id
          supervisor_id
          address
          city
          state_id
          worksafe_no
          start_date
          end_date
          active
          status
          type
          quantity
          notes
          created_at
          updated_at
          State {
            id
            name
            abbrev
          }
        }
      }
    }
  }
`;

export const GET_WASTE_DISPOSAL_QUERY = gql`
  query getWasteDisposal($id: ID!) {
    wasteDisposal(id: $id) {
      id
      signature_date
      date
      active
      created_at
      updated_at
      TipDockets {
        id
        name
        path
        size
        type
        uploadable_type
        uploadable_id
        created_at
        updated_at
      }
      RemovalProjects {
        id
        client_id
        client_contact
        assessor_id
        supervisor_id
        address
        city
        state_id
        worksafe_no
        start_date
        end_date
        active
        status
        type
        quantity
        notes
        created_at
        updated_at
        Client {
          id
          name
          address
          city
          state_id
          post_code
          email
          client_contact
          phone
          mobile
          active
          created_at
          updated_at
        }
        State {
          id
          name
          abbrev
        }
        RemovalAreas {
          id
          has_asbestos
          Type {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TOOLBOX_TALK_QUERY = gql`
  query getToolboxTalk($id: ID!) {
    toolboxTalk(id: $id) {
      id
      removal_project_id
      date
      weather_id
      planned_activities
      q_1
      q_2
      q_3
      q_4
      q_5
      q_6
      q_7
      topics_to_cover
      notes
      other_items
      active
      created_at
      updated_at
      RemovalProject {
        id
        client_id
        client_contact
        assessor_id
        supervisor_id
        address
        city
        state_id
        worksafe_no
        start_date
        end_date
        active
        status
        type
        quantity
        notes
        created_at
        updated_at
        State {
          id
          name
          abbrev
        }
      }
      Signatures {
        id
        signaturable_id
        type
        name
        path
        date
        supervisor
        organization
        created_at
        updated_at
      }
    }
  }
`;

export const GET_FILES_QUERY = gql`
  query getFiles(
    $type: String
    $search: String
    $orderBy: String!
    $orderByDirection: String!
    $page: Int!
    $perPage: Int!
  ) {
    files(
      type: $type
      search: $search
      orderBy: $orderBy
      orderByDirection: $orderByDirection
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        name
        path
        size
        type
        uploadable_type
        uploadable_id
        created_at
        updated_at
      }
    }
  }
`;

export const GET_REMOVAL_OPTIONS_QUERY = gql`
  query removalOptions($type: String!, $search: String) {
    removalOptions(type: $type, search: $search) {
      id
      name
    }
  }
`;

export const GET_ALL_REMOVAL_OPTIONS = gql`
  query allRemovalOptions {
    allRemovalOptions {
      areas {
        id
        name
      }
      locations {
        id
        name
      }
      materials {
        id
        name
      }
      types {
        id
        name
      }
      conditions {
        id
        name
      }
    }
  }
`;

export const GET_CALENDAR_EVENTS_FOR_DATE = gql`
  query getCalendarEventsForDate($date: String!) {
    getCalendarEventsForDate(date: $date) {
      id
      user_id
      calendar_event_type_id
      start_date
      end_date
      start_date_formatted
      end_date_formatted
      is_all_day
      title
      location
      description
      Employees {
        id
        first
        last
        active
        start_date
        termination_date
      }
    }
  }
`;

export const GET_CALENDAR_EVENT = gql`
  query getCalendarEvent($id: ID!) {
    getCalendarEvent(id: $id) {
      id
      user_id
      calendar_event_type_id
      start_date
      end_date
      start_date_formatted
      end_date_formatted
      is_all_day
      title
      location
      description
      Employees {
        id
        first
        last
        active
        start_date
        termination_date
      }
    }
  }
`;

export const GET_CALENDAR_EVENT_TYPES = gql`
  query getCalendarEventTypes {
    getCalendarEventTypes {
      id
      title
      color
    }
  }
`;

export const SEARCH_ALL_QUERY = gql`
  query searchAll($search: String!) {
    searchAll(search: $search) {
      Clients {
        id
        name
        address
        city
        state_id
        post_code
        email
        client_contact
        phone
        mobile
        active
        created_at
        updated_at
        State {
          id
          name
          abbrev
        }
      }
      RemovalProjects {
        id
        client_id
        client_contact
        assessor_id
        supervisor_id
        address
        city
        state_id
        worksafe_no
        start_date
        end_date
        active
        status
        statusString
        type
        typeString
        quantity
        quantityString
        notes
        Client {
          id
          name
        }
        Assessor {
          id
          name
        }
        Supervisor {
          id
          name
        }
      }
      CalendarEvents {
        id
        user_id
        calendar_event_type_id
        start_date
        end_date
        start_date_formatted
        end_date_formatted
        is_all_day
        title
        location
        description
        CalendarEventType {
          title
          color
        }
        Employees {
          id
          first
          last
          active
          start_date
          termination_date
        }
      }
      Quotes {
        id
        date
        end_date
        street
        city
        zip
        name
        state_id
        status
        statusString
        State {
          id
          name
          abbrev
        }
      }
    }
  }
`;

export const GET_QUOTES_QUERY = gql`
  query getQuotes(
    $status: String
    $search: String
    $page: Int!
    $perPage: Int!
  ) {
    getQuotes(
      status: $status
      search: $search
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        id
        date
        date_formatted
        end_date
        end_date_formatted
        street
        city
        zip
        name
        state_id
        status
        statusString
        body
        notes
        CalendarEvent {
          id
          user_id
          calendar_event_type_id
          start_date
          end_date
          start_date_formatted
          end_date_formatted
          is_all_day
          title
          location
          description
        }
        State {
          id
          name
          abbrev
        }
        QuoteItems {
          id
          description
          price
        }
        RemovalAreas {
          id
          has_asbestos
          quote_price
          Area {
            id
            name
          }
          Location {
            id
            name
          }
          Material {
            id
            name
          }
          Type {
            id
            name
          }
          Condition {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_QUOTE_QUERY = gql`
  query getQuote($id: ID!) {
    getQuote(id: $id) {
      id
      date
      date_formatted
      end_date
      end_date_formatted
      street
      city
      zip
      name
      state_id
      status
      statusString
      body
      notes
      CalendarEvent {
        id
        user_id
        calendar_event_type_id
        start_date
        end_date
        start_date_formatted
        end_date_formatted
        is_all_day
        title
        location
        description
      }
      State {
        id
        name
        abbrev
      }
      QuoteItems {
        id
        description
        price
      }
      RemovalAreas {
        id
        has_asbestos
        area_id
        location_id
        material_id
        type_id
        condition_id
        quote_price
        Area {
          id
          name
        }
        Location {
          id
          name
        }
        Material {
          id
          name
        }
        Type {
          id
          name
        }
        Condition {
          id
          name
        }
      }
    }
  }
`;
