import { ListItem, ListItemProps } from '@chakra-ui/react';
import React from 'react';

export interface PrintListItemProps extends ListItemProps {
  isStriped?: boolean;
}
export const PrintListItem = ({ isStriped, ...props }: PrintListItemProps) => {
  return (
    <ListItem
      className="pba"
      px="3px"
      py="0px"
      bg={isStriped ? 'gray.50' : props.bg}
      {...props}
    />
  );
};
