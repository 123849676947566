import React from 'react';
import Headroom from 'react-headroom';
import { Redirect, Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { Navbar } from '../containers/Navbar';
import { Footer } from '../containers/Footer';
import { SignInPage } from './SignInPage';

export const HomeLandingPage = ({ match: { url } }: RouteComponentProps) => (
  <Box bg="base" width="100%" height="100%">
    <Headroom>
      <Navbar />
    </Headroom>

    <Switch>
      <Route
        exact
        path={`${url}`}
        render={routerProps => <SignInPage {...routerProps} />}
      />

      <Redirect to={`${url}`} />
    </Switch>

    <Footer />
  </Box>
);
