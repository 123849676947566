import React, { useEffect, useState } from 'react';
import { Switch, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { Box, Tabs, TabList, Tab } from '@chakra-ui/react';
import { StateInterface } from 'jesco-web';
import { Header } from '../containers/Text/Header';
import { ClientsPage } from './ClientsPage';
import { AssessorsPage } from './AssessorsPage';
import { SupervisorsPage } from './SupervisorsPage';
import { EmployeesPage } from './EmployeesPage';
import { UsersPage } from './UsersPage';
import { useAuthContext } from '../providers/Auth.Provider';

export interface PeoplePageProps extends RouteComponentProps {
  states: StateInterface[];
}

export const PeoplePage = ({
  history: { push, location },
  match: { url },
  states,
}: PeoplePageProps) => {
  const { me } = useAuthContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    let newIndex = 0;

    if (location.pathname.includes('/dashboard/people/assessors')) {
      newIndex = 1;
    }

    if (location.pathname.includes('/dashboard/people/supervisors')) {
      newIndex = 2;
    }

    if (location.pathname.includes('/dashboard/people/employees')) {
      newIndex = 3;
    }

    if (location.pathname.includes('/dashboard/people/users')) {
      newIndex = 4;
    }

    setCurrentTabIndex(newIndex);
  }, [location]);

  return (
    <Box p={['5px', '10px', '20px']} maxWidth="1400px">
      <Header fontSize="3xl" color="textSecondary" mb="10px">
        People
      </Header>

      <Tabs
        size="md"
        index={currentTabIndex}
        onChange={(index) => setCurrentTabIndex(index)}
      >
        <TabList maxWidth="100%" overflowX={['scroll', 'initial', 'initial']}>
          <Tab onClick={() => push('/dashboard/people/clients')}>Clients</Tab>
          <Tab onClick={() => push('/dashboard/people/assessors')}>
            Assessors
          </Tab>
          <Tab onClick={() => push('/dashboard/people/supervisors')}>
            Supervisors
          </Tab>
          {Boolean(me?.Permissions?.can_edit_employees) && (
            <Tab onClick={() => push('/dashboard/people/employees')}>
              Employees
            </Tab>
          )}
          {Boolean(me?.Permissions?.can_edit_users) && (
            <Tab onClick={() => push('/dashboard/people/users')}>Users</Tab>
          )}
        </TabList>

        <Switch>
          <Route
            path={`${url}/clients/:clientId?`}
            render={() => <ClientsPage states={states} />}
          />

          <Route
            path={`${url}/assessors`}
            render={() => <AssessorsPage states={states} />}
          />

          <Route
            path={`${url}/supervisors`}
            render={() => <SupervisorsPage />}
          />

          <Route path={`${url}/employees`} render={() => <EmployeesPage />} />

          <Route path={`${url}/users`} render={() => <UsersPage />} />

          <Redirect to={`${url}/clients`} />
        </Switch>
      </Tabs>
    </Box>
  );
};
