import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import pluralize from 'pluralize';
import { RemovalOptionInterface } from 'jesco-web';
import {
  Button,
  Box,
  Stack,
  Text,
  HStack,
  IconButton,
  useToast,
  Divider,
  FormControl,
  Input,
  BoxProps,
  Skeleton,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { SearchInput } from '../Form/SearchInput';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { GET_REMOVAL_OPTIONS_QUERY } from '../../services/apollo/queries';
import {
  DELETE_REMOVAL_OPTION,
  CREATE_REMOVAL_OPTION,
} from '../../services/apollo/mutations';

export interface RemovalOptionPanelProps extends BoxProps {
  type: string;
}
export const RemovalOptionPanel = ({
  type,
  ...props
}: RemovalOptionPanelProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [search, setSearch] = useState<string>('');
  const [isAddingNew, setIsAddingNew] = useState(false);

  const { data, refetch, loading: getOptionsLoading } = useQuery<{
    removalOptions: RemovalOptionInterface[];
  }>(GET_REMOVAL_OPTIONS_QUERY, {
    variables: {
      type,
      search,
    },
  });

  const [deleteRemovalOption, { loading }] = useMutation(
    DELETE_REMOVAL_OPTION,
    {
      onCompleted(d) {
        if (!d || !d.deleteRemovalOption || !d.deleteRemovalOption.status)
          return;
        refetch();
        toast({
          title: 'Removal option deleted',
          description: `Removal option deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [
    createRemovalOption,
    { loading: createRemovalOptionLoading },
  ] = useMutation(CREATE_REMOVAL_OPTION, {
    onCompleted(d) {
      if (!d || !d.createRemovalOption || !d.createRemovalOption.id) return;
      setIsAddingNew(false);
      setSearch('');
      refetch();
      formik.resetForm();
      toast({
        title: 'Removal option created',
        description: `Removal option created`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  let removalOptions: RemovalOptionInterface[] = [];
  if (data) {
    removalOptions = data.removalOptions;
  }

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(1).required(),
    }),
    onSubmit: (values) => {
      createRemovalOption({
        variables: {
          type,
          name: values.name,
        },
      });
    },
  });

  return (
    <Stack data-testid={`removal-${type}-section`} height="300px" {...props}>
      <Stack>
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Removal {pluralize(type)}
          </Text>
          <Box>
            <SearchInput
              placeholder={`Search removal ${pluralize(type)}`}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onClear={() => {
                setSearch('');
              }}
              value={search}
            />
          </Box>
        </HStack>
        <Divider />
        {getOptionsLoading && (
          <Stack>
            <Skeleton height="35px" />
            <Skeleton height="35px" />
            <Skeleton height="35px" />
            <Skeleton height="35px" />
            <Skeleton height="35px" />
          </Stack>
        )}
        {!getOptionsLoading && (
          <>
            {removalOptions.slice(0, 5).map((ra) => (
              <Stack key={ra.id} data-testid={`removal-${type}-row`}>
                <HStack key={ra.id} justify="space-between">
                  <Text>{ra.name}</Text>
                  <IconButton
                    isLoading={loading}
                    size="sm"
                    variant="outline"
                    data-testid={`delete-removal-${type}`}
                    aria-label={`delete-removal-${type}`}
                    onClick={() => {
                      if (window.confirm('Are you sure you?')) {
                        deleteRemovalOption({
                          variables: {
                            id: ra.id,
                          },
                        });
                      }
                    }}
                    icon={<FaTimes />}
                  />
                </HStack>
                <Divider />
              </Stack>
            ))}

            {isAddingNew && (
              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  id="name"
                  isInvalid={Boolean(formik.errors.name)}
                  isRequired
                >
                  <HStack>
                    <Input
                      data-testid="removal-option-form-name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      autoFocus
                    />

                    <HStack>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        data-testid="removal-option-ok"
                        aria-label="removal-option-ok"
                        type="submit"
                        disabled={!formik.values.name}
                        isLoading={createRemovalOptionLoading}
                        icon={<FaCheck />}
                      />
                      <IconButton
                        variant="outline"
                        colorScheme="gray"
                        data-testid="removal-option-cancel"
                        aria-label="removal-option-cancel"
                        onClick={() => setIsAddingNew(false)}
                        isLoading={createRemovalOptionLoading}
                        icon={<FaTimes />}
                      />
                    </HStack>
                  </HStack>
                </FormControl>
              </form>
            )}
            {!isAddingNew && (
              <Button
                data-testid="add-new-button"
                variant="link"
                onClick={() => setIsAddingNew(true)}
              >
                Add new {type}
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
