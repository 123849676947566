import React, { ReactElement } from 'react';
import { v4 } from 'uuid';
import {
  Table as CTable,
  Thead,
  Tr,
  Tbody,
  Box,
  Flex,
  BoxProps,
  Spinner,
} from '@chakra-ui/react';
import { TableHeader } from './TableHeader';
import { TableMessage } from './TableMessage';

export interface TableFilterInterface {
  orderBy: string;
  orderByDirection: string;
  page: number;
  perPage: number;
  search: string;
}

export interface TableHeaderOption extends BoxProps {
  slug?: string;
  orderBy?: string;
  title: string;
  tooltip?: string;
  'data-testid'?: string;
}

export interface TablePropsInterface {
  containerProps?: BoxProps;
  data: object[];
  dataTestId?: string;
  emptyMessage?: string;
  filter?: TableFilterInterface;
  headers: TableHeaderOption[];
  isLoading?: boolean;
  onHeaderClick?(slug: string): void;
  renderItem({
    item,
    index,
  }: {
    item: any;
    index: number;
  }): ReactElement | ReactElement[] | null;
  onChangeFilter?(filter: TableFilterInterface): void;
}

export const Table = ({
  containerProps,
  data,
  dataTestId,
  emptyMessage,
  headers,
  isLoading,
  renderItem,
  onChangeFilter,
  filter,
}: TablePropsInterface) => {
  const handleHeaderClick = (slug: string) => {
    if (!filter || !onChangeFilter) return;
    let orderByDirection = '';
    if (filter.orderBy === slug) {
      if (filter.orderByDirection === 'desc') {
        orderByDirection = 'asc';
      }
      if (filter.orderByDirection === 'asc') {
        orderByDirection = 'desc';
      }
    } else {
      orderByDirection = 'desc';
    }

    onChangeFilter({
      ...filter,
      orderByDirection,
      orderBy: slug,
    });
  };

  return (
    <Box width="100%" {...containerProps}>
      <CTable width="100%" data-testid={dataTestId}>
        <Thead>
          <Tr>
            {headers.map((header) => (
              <TableHeader
                isActive={filter ? filter.orderBy === header.slug : false}
                direction={filter ? filter.orderByDirection : null}
                onHeaderClick={handleHeaderClick}
                key={v4()}
                slug={header.slug}
                width={header.width || 'auto'}
                {...header}
              />
            ))}
          </Tr>
        </Thead>

        {!isLoading && data.length >= 0 && (
          <Tbody>
            {data.map((item, index) =>
              renderItem({
                item,
                index,
              }),
            )}
          </Tbody>
        )}
      </CTable>

      {isLoading && (
        <Flex width="100%" p="50px" justifyContent="center">
          <Spinner color="green.500" />
        </Flex>
      )}

      {!isLoading && !data.length && (
        <TableMessage>{emptyMessage || 'No results'}</TableMessage>
      )}
    </Box>
  );
};
