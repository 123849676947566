import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  Input,
  IconButton,
  InputProps,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

export interface SearchInputPropsInterface extends InputProps {
  onClear(): void;
  value: string;
  autoFocus?: boolean;
  placeholder?: string;
}

export const SearchInput = ({
  onClear,
  value,
  onChange,
  autoFocus,
  placeholder,
  ...props
}: SearchInputPropsInterface) => {
  const [localValue, setLocalValue] = React.useState(value);

  const debounced = useDebouncedCallback(
    // function
    (v) => {
      if (onChange) {
        onChange(v);
      }
    },
    // delay in ms
    500,
  );

  return (
    <InputGroup size="md">
      <Input
        data-testid="search-input"
        autoFocus={autoFocus}
        onChange={(e) => {
          setLocalValue(e.target.value);
          debounced.callback(e);
        }}
        placeholder={placeholder || 'Search'}
        value={localValue}
        {...props}
      />

      {Boolean(localValue.length) && (
        <InputRightElement>
          <IconButton
            data-testid="clear-search"
            aria-label="clear-input"
            position="absolute"
            variant="ghost"
            colorScheme="gray"
            size="sm"
            onClick={() => {
              setLocalValue('');
              onClear();
            }}
            icon={<FaTimes />}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
