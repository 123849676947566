import React from 'react';
import moment from 'moment';
import { CalendarEventInterface, CalendarEventTypeInterface } from 'jesco-web';
import {
  Button,
  ButtonGroup,
  Stack,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  Tag,
  Text,
  AlertDialogFooter,
  HStack,
} from '@chakra-ui/react';
import { FaCalendar } from 'react-icons/fa';
import { CalendarBubble } from './CalendarBubble';

export interface CalendarEventPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  event?: CalendarEventInterface;
  calendarEventTypes: CalendarEventTypeInterface[];
  onEditClick: () => void;
}

export const CalendarEventPopover = ({
  isOpen,
  onClose,
  event,
  onEditClick,
  calendarEventTypes,
}: CalendarEventPopoverProps) => {
  const initialFocusRef = React.useRef();

  const activeEventType = calendarEventTypes.find(
    (cet) => cet.id === Number(event?.calendar_event_type_id),
  );

  const start = moment(event?.start_date_formatted, 'LLL');
  const end = moment(event?.end_date_formatted, 'LLL');

  const eventStartTime = start.format('hh:mm A');
  const eventEndTime = end.format('hh:mm A');
  const eventStartDate = start.format('DD/MM/YYYY');
  const eventEndDate = end.format('DD/MM/YYYY');

  let dateString = `${eventStartDate} - ${eventEndDate}`;
  if (start.isSame(end, 'day')) {
    dateString = `${eventStartDate}`;
  }

  return (
    <AlertDialog
      leastDestructiveRef={initialFocusRef.current}
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogContent
        data-testid="calendar-event-dialog"
        color="white"
        bg="blue.500"
        borderColor="blue.500"
        position="absolute"
        bottom={0}
        left={0}
      >
        <AlertDialogHeader pt={4} fontWeight="bold" border="0">
          <HStack>
            <Text>{event?.title}</Text>
          </HStack>
          <HStack my="10px">
            <FaCalendar />
            <Text color="gray.300" fontSize="sm" mt="15px">
              {dateString}
            </Text>
          </HStack>

          {!Boolean(event?.is_all_day) && (
            <Text color="gray.300" fontSize="sm">
              {eventStartTime} - {eventEndTime}
            </Text>
          )}
        </AlertDialogHeader>

        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Stack>
            {!Boolean(event?.description) && (
              <Text fontSize="md" minHeight="50px" fontWeight="bold">
                No description
              </Text>
            )}

            {Boolean(event?.description) &&
              event?.description.split('\n').map((line) => (
                <Text key={line} fontSize="md" fontWeight="bold">
                  {line}
                </Text>
              ))}

            {Boolean(event && event.location) && (
              <Text color="gray.300">
                Where:{' '}
                <Text as="span" color="white">
                  {event?.location}
                </Text>
              </Text>
            )}
          </Stack>
        </AlertDialogBody>

        <AlertDialogFooter
          border="0"
          alignItems="center"
          pb={4}
          justifyContent="space-between"
        >
          {Boolean(activeEventType) && (
            <Tag bg="white" alignSelf="flex-start" size="md">
              <HStack>
                <CalendarBubble color={String(activeEventType?.color)} />
                <Text>{String(activeEventType?.title)}</Text>
              </HStack>
            </Tag>
          )}

          <ButtonGroup size="sm" alignSelf="flex-end">
            <Button colorScheme="green" onClick={onEditClick}>
              Edit
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
