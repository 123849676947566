import React from 'react';
import { RemovalAreaInterface } from 'jesco-web';
import { Table, TableFilterInterface, TablePropsInterface } from '../Table';
import { RemovalAreaTableRow } from './RemovalAreaTableRow';
import { useAuthContext } from '../../providers/Auth.Provider';

const sort = (
  removalAreas: RemovalAreaInterface[] = [],
  filter?: TableFilterInterface,
) => {
  if (!filter) return removalAreas;

  return removalAreas
    .slice()
    .sort((a: RemovalAreaInterface, b: RemovalAreaInterface) => {
      let asort = a.Area;
      let bsort = b.Area;

      if (filter.orderBy === 'area') {
        asort = a.Area;
        bsort = b.Area;
      }

      if (filter.orderBy === 'location') {
        asort = a.Location;
        bsort = b.Location;
      }

      if (filter.orderBy === 'material') {
        asort = a.Material;
        bsort = b.Material;
      }

      if (filter.orderBy === 'type') {
        asort = a.Type;
        bsort = b.Type;
      }

      if (filter.orderBy === 'condition') {
        asort = a.Condition;
        bsort = b.Condition;
      }

      if (filter.orderByDirection === 'asc') {
        return asort.name > bsort.name ? -1 : 1;
      }

      if (filter.orderByDirection === 'desc') {
        return asort.name < bsort.name ? -1 : 1;
      }

      return 1;
    });
};

interface RemovalAreaTableProps extends Partial<TablePropsInterface> {
  removalAreas: RemovalAreaInterface[];
  isLoading: boolean;
  clickable?: boolean;
  onDeleteRemovalAreaClick?(removalArea: RemovalAreaInterface): void;
}

export const RemovalAreaTable = ({
  removalAreas,
  onDeleteRemovalAreaClick,
  isLoading,
  filter,
  clickable,
  ...props
}: RemovalAreaTableProps) => {
  const { me } = useAuthContext();

  const filteredRemovalAreas = sort(removalAreas, filter);

  const renderRow = ({
    item,
    index,
  }: {
    item: RemovalAreaInterface;
    index: number;
  }) => {
    return (
      <RemovalAreaTableRow
        canDelete={Boolean(me?.Permissions?.can_edit_removal_projects)}
        key={String(item.id)}
        isStriped={index % 2 === 1}
        removalArea={item}
        isLoading={isLoading}
        onRemoveClick={onDeleteRemovalAreaClick}
      />
    );
  };

  const headers = [
    {
      slug: clickable ? 'area' : '',
      title: 'Area',
      width: '22%',
    },
    {
      slug: clickable ? 'location' : '',
      title: 'Location',
      width: '25%',
    },
    {
      slug: clickable ? 'material' : '',
      title: 'Material',
      width: '22%',
    },
    {
      slug: clickable ? 'type' : '',
      title: 'Type',
      width: '22%',
    },
    {
      slug: clickable ? 'condition' : '',
      title: 'Condition',
      width: '22%',
    },
  ];

  if (onDeleteRemovalAreaClick) {
    headers.push({
      slug: '',
      title: '',
      width: '10%',
    });
  }

  return (
    <Table
      dataTestId="removal-area-table"
      headers={headers}
      data={filteredRemovalAreas || []}
      renderItem={renderRow}
      filter={filter}
      {...props}
    />
  );
};
