import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { StateInterface } from 'jesco-web';
import moment from 'moment';
import { DatePicker } from 'antd';
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  HStack,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
  DrawerFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import {
  CREATE_ASSESSOR,
  UPDATE_ASSESSOR,
  DELETE_ASSESSOR,
} from '../../services/apollo/mutations';
import { useMutation } from '@apollo/client';
import { FaTrash } from 'react-icons/fa';

export interface AssessorDrawerProps {
  initialValues: AssessorFormValues;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  states: StateInterface[];
}

export interface AssessorFormValues {
  id: number | null;
  name: string;
  company: string;
  licenseNo: string;
  phone: string;
  licenseExpires: Date;
  stateId: number;
}

export const AssessorDrawer = ({
  initialValues,
  isOpen,
  onClose,
  onComplete,
  states,
}: AssessorDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [addAssessor, { loading: addAssessorLoading }] = useMutation(
    CREATE_ASSESSOR,
    {
      onCompleted(d) {
        if (!d || !d.createAssessor || !d.createAssessor.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }
        toast({
          title: 'Assessor created',
          description: `${d.createAssessor.name} created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateAssessor, { loading: updateAssessorLoading }] = useMutation(
    UPDATE_ASSESSOR,
    {
      onCompleted(d) {
        if (!d || !d.updateAssessor || !d.updateAssessor.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Assessor updated',
          description: `${d.updateAssessor.name} updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteAssessor, { loading: deleteAssessorLoading }] = useMutation(
    DELETE_ASSESSOR,
    {
      onCompleted(d) {
        if (!d || !d.deleteAssessor || !d.deleteAssessor.id) return;

        onClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Assessor deleted',
          description: `${d.deleteAssessor.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      name: Yup.string().required('Name is required'),
      company: Yup.string(),
      licenseNo: Yup.string(),
      phone: Yup.string(),
      licenseExpires: Yup.date(),
      stateId: Yup.number().required(),
    }),
    onSubmit: (values) => {
      if (!values.id) {
        addAssessor({
          variables: {
            ...values,
            licenseExpires: moment(values.licenseExpires).format('YYYY-MM-DD'),
            stateId: Number(values.stateId),
          },
        });
      } else {
        updateAssessor({
          variables: {
            ...values,
            licenseExpires: moment(values.licenseExpires).format('YYYY-MM-DD'),
            stateId: Number(values.stateId),
          },
        });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const isLoading =
    addAssessorLoading || updateAssessorLoading || deleteAssessorLoading;

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            pl="40px"
            pr="20px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">
              {formik.values.id ? `${formik.values.name}` : 'New assessor'}
            </Text>

            {formik.values.id && (
              <IconButton
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete ${formik.values.name}?`,
                    )
                  ) {
                    deleteAssessor({
                      variables: {
                        id: formik.values.id,
                      },
                    });
                  }
                }}
                data-testid="delete-assessor-button"
                aria-label="delete-assessor"
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody>
            <FormControl
              id="name"
              isInvalid={Boolean(formik.errors.name)}
              isRequired
            >
              <FormLabel>Name</FormLabel>
              <Input
                data-testid="assessor-form-name"
                autoFocus
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl id="company">
              <FormLabel>Company</FormLabel>
              <Input
                data-testid="assessor-form-company"
                name="company"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company}
              />
            </FormControl>

            <FormControl id="licenseNo">
              <FormLabel>License no.</FormLabel>
              <Input
                data-testid="assessor-form-license-no"
                name="licenseNo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.licenseNo}
              />
            </FormControl>

            <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <NumberInput value={formik.values.phone}>
                <NumberInputField
                  data-testid="assessor-form-phone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </NumberInput>
            </FormControl>

            <FormControl
              id="licenseExp"
              data-testid="assessor-form-license-exp"
            >
              <FormLabel>
                <HStack justifyContent="space-between">
                  <Text>License exp.</Text>
                  <Text>
                    {moment(formik.values.licenseExpires).format(
                      'D MMMM, YYYY',
                    )}
                  </Text>
                </HStack>
              </FormLabel>

              <DatePicker
                format="DD/MM/YYYY"
                value={moment(formik.values.licenseExpires).clone()}
                onChange={(d) => {
                  formik.setFieldValue('licenseExpires', d?.toDate());
                }}
              />
            </FormControl>

            <FormControl id="state">
              <FormLabel>State</FormLabel>
              <Select
                data-testid="assessor-form-state"
                name="stateId"
                placeholder="States"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stateId}
              >
                {states.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            {formik.values.id && (
              <Input
                data-testid="assessor-form-id"
                name="id"
                value={formik.values.id}
                type="hidden"
              />
            )}
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-evenly"
            bg="white"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid || formik.isSubmitting}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Save' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
