import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  Image,
  Box,
  Tag,
  TagLeftIcon,
  TagLabel,
  Button,
  Flex,
  Stack,
  useDisclosure,
  useToast,
  HStack,
  Text,
  IconButton,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { Section } from '../containers/Section';
import { useMutation, useQuery } from '@apollo/client';
import { FaTimes, FaEye, FaPlus, FaExclamationTriangle } from 'react-icons/fa';
import { EmployeeInterface } from 'jesco-web';
import { TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import { DELETE_FILE } from '../services/apollo/mutations';
import { GET_EMPLOYEES_QUERY } from '../services/apollo/queries';

import { EmployeeUploadsDrawer } from '../containers/Employee/EmployeeUploadsDrawer';
import { EmployeeSignatureDrawer } from 'src/containers/Employee/EmployeeSignatureDrawer';
import { useAuthContext } from '../providers/Auth.Provider';
import { Redirect } from 'react-router-dom';
import { EmployeeDrawer } from '../containers/Employee/EmployeeDrawer';

export const EmployeesPage = () => {
  const { me } = useAuthContext();

  const toast = useToast({ position: 'bottom-left' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUploadDrawerOpen,
    onOpen: onOpenUploadDrawer,
    onClose: onCloseUploadDrawer,
  } = useDisclosure();
  const {
    isOpen: isSignatureDrawerOpen,
    onOpen: onOpenSignatureDrawer,
    onClose: onCloseSignatureDrawer,
  } = useDisclosure();
  const [employee, setEmployee] = useState<EmployeeInterface>();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch } = useQuery<{
    employees: {
      items: EmployeeInterface[];
      count: number;
    };
  }>(GET_EMPLOYEES_QUERY, {
    variables: filter,
  });

  let employees: EmployeeInterface[] = [];
  let count = 0;

  if (data) {
    const {
      employees: { count: total, items },
    } = data;

    count = total;
    employees = items;
  }

  const [deleteFile, { loading: deleteFileLoading }] = useMutation(
    DELETE_FILE,
    {
      onCompleted(d) {
        if (!d || !d.deleteFile || !d.deleteFile.id) return;

        onClose();
        refetch();

        toast({
          title: 'Tip docket deleted',
          description: `${d.deleteFile.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  const isLoading = deleteFileLoading;

  if (!Boolean(me?.Permissions?.can_edit_employees)) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <>
      <Box width="100%" pt="10px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary">
            Employees
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>

            <Button
              colorScheme="green"
              variant="ghost"
              color="green.500"
              data-testid="add-employee-button"
              onClick={() => {
                setEmployee(undefined);
                onOpen();
              }}
              size="md"
            >
              Add employee
            </Button>
          </Stack>
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <SimpleGrid columns={[1, 2, 3]} spacing="20px">
          {employees.map((e) => (
            <Flex
              key={e.id}
              width="100%"
              justify="center"
              data-testid="employee-section"
            >
              <Section maxWidth="400px" width="100%" key={e.id}>
                <HStack py="10px" justify="space-between">
                  <Stack align="flex-start">
                    <Button
                      data-testid="edit-employee-button"
                      variant="link"
                      fontSize="xl"
                      fontWeight="bold"
                      color="green.500"
                      isLoading={isLoading}
                      onClick={() => {
                        setEmployee(e);
                        onOpen();
                      }}
                    >
                      {e.first} {e.last}
                    </Button>
                    <Text>
                      Started on {moment(e.start_date).format('D MMMM, YYYY')}
                    </Text>
                  </Stack>
                </HStack>

                <Divider />

                <HStack justify="space-between" my="10px">
                  <Header fontSize="lg" color="textSecondary">
                    Uploads
                  </Header>
                  <IconButton
                    data-testid="employee-add-upload"
                    aria-label="add-upload"
                    size="sm"
                    variant="outline"
                    isLoading={isLoading}
                    icon={<FaPlus />}
                    onClick={() => {
                      setEmployee(e);
                      onOpenUploadDrawer();
                    }}
                  />
                </HStack>

                {!e.Uploads.length && (
                  <Section>
                    <Text fontSize="md" textAlign="center">
                      No uploads
                    </Text>
                  </Section>
                )}

                {e.Uploads.map((td) => (
                  <React.Fragment key={td.id}>
                    <Box pl="10px" py="5px" data-testid="employee-upload-row">
                      <HStack justify="space-between">
                        <Stack spacing={1}>
                          <Text fontSize="sm">{`${td.name}`}</Text>
                        </Stack>
                        <HStack>
                          <IconButton
                            aria-label="remove-employee-upload"
                            data-testid="remove-employee-upload-button"
                            variant="outline"
                            icon={<FaTimes />}
                            size="sm"
                            isLoading={isLoading}
                            onClick={() => {
                              if (window.confirm('Are you sure?')) {
                                deleteFile({
                                  variables: {
                                    id: td.id,
                                  },
                                });
                              }
                            }}
                          />
                          <IconButton
                            data-testid="employee-view-upload-button"
                            aria-label="employee-view-upload-button"
                            variant="outline"
                            icon={<FaEye />}
                            size="sm"
                            isLoading={isLoading}
                            onClick={() => {
                              window.open(td.path, '_blank');
                            }}
                          />
                        </HStack>
                      </HStack>
                    </Box>
                    <Divider />
                  </React.Fragment>
                ))}

                <HStack justify="space-between" my="10px" mt="30px">
                  <Header fontSize="lg" color="textSecondary">
                    Signature
                  </Header>
                  {!e.Signature && (
                    <IconButton
                      data-testid="add-employee-signature-button"
                      aria-label="add-employee-signature"
                      size="sm"
                      variant="outline"
                      isLoading={isLoading}
                      onClick={() => {
                        setEmployee(e);
                        onOpenSignatureDrawer();
                      }}
                      icon={<FaPlus />}
                    />
                  )}
                </HStack>
                <Divider />
                {!e.Signature && (
                  <Flex justify="center" py="10px">
                    <Tag variant="subtle" colorScheme="yellow">
                      <TagLeftIcon boxSize="12px" as={FaExclamationTriangle} />
                      <TagLabel>Employee signature missing!</TagLabel>
                    </Tag>
                  </Flex>
                )}
                {e.Signature && (
                  <React.Fragment key={e.Signature.id}>
                    <HStack justify="space-between">
                      <Stack spacing={1}>
                        <Text fontSize="md">{`${e.Signature.name}`}</Text>
                        <Text>{e.Signature.type}</Text>
                      </Stack>
                      <HStack>
                        <Image path={e.Signature.path} height={50} />
                        <IconButton
                          data-testid="remove-signature-button"
                          aria-label="remove-signature"
                          isLoading={isLoading}
                          variant="outline"
                          icon={<FaTimes />}
                          size="sm"
                          onClick={() => {
                            if (window.confirm('Are you sure?')) {
                              deleteFile({
                                variables: {
                                  id: e.Signature.id,
                                },
                              });
                            }
                          }}
                        />
                      </HStack>
                    </HStack>
                    <Divider />
                  </React.Fragment>
                )}
              </Section>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>

      <EmployeeUploadsDrawer
        onClose={onCloseUploadDrawer}
        isOpen={isUploadDrawerOpen}
        onComplete={refetch}
        employee={employee}
      />

      <EmployeeSignatureDrawer
        onClose={onCloseSignatureDrawer}
        onComplete={refetch}
        isOpen={isSignatureDrawerOpen}
        employee={employee}
      />

      <EmployeeDrawer
        isOpen={isOpen}
        onClose={onClose}
        onComplete={refetch}
        initialValues={{
          id: employee ? Number(employee.id) : null,
          first: employee ? String(employee.first) : '',
          last: employee ? String(employee.last) : '',
          start_date: employee
            ? moment(employee.start_date, 'YYYY-MM-DD').toDate()
            : moment().toDate(),
        }}
      />
    </>
  );
};
