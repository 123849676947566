import React from 'react';
import { Tr, TableRowProps } from '@chakra-ui/react';

export interface TableRowPropsInterface extends TableRowProps {
  isStriped: boolean;
}

export const TableRow = ({
  isStriped,
  onClick,
  ...props
}: TableRowPropsInterface) => (
  <Tr
    _hover={{
      bg: 'gray.100',
      cursor: onClick ? 'pointer' : 'auto',
    }}
    bg={isStriped ? 'gray.50' : 'white'}
    as="tr"
    onClick={onClick}
    {...props}
  />
);
