import React, { useState } from 'react';
import numeral from 'numeral';
import {
  Box,
  Button,
  Flex,
  Stack,
  useDisclosure,
  HStack,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { UserInterface } from 'jesco-web';
import { TableFilterInterface, Paginator } from '../containers/Table';
import { Header } from '../containers/Text/Header';
import { GET_USERS_QUERY } from '../services/apollo/queries';
import { UserPanel } from 'src/containers/User/UserPanel';
import { useAuthContext } from '../providers/Auth.Provider';
import { Redirect } from 'react-router-dom';
import { UserDrawer } from '../containers/User/UserDrawer';

export const UsersPage = () => {
  const { me } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState<UserInterface>();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch } = useQuery<{
    users: {
      items: UserInterface[];
      count: number;
    };
  }>(GET_USERS_QUERY, {
    variables: filter,
  });

  let users: UserInterface[] = [];
  let count = 0;

  if (data) {
    const {
      users: { count: total, items },
    } = data;

    count = total;
    users = items;
  }

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  if (!Boolean(me?.Permissions?.can_edit_users)) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Box width="100%' pt='10px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary" mb="10px">
            Users
          </Header>
          <Button
            colorScheme="green"
            variant="ghost"
            color="green.500"
            data-testid="add-user-button"
            onClick={() => {
              setUser(undefined);
              onOpen();
            }}
            size="md"
          >
            Add user
          </Button>
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <SimpleGrid columns={[1, 1, 2, 3]} spacing="20px">
          {users.map((u) => (
            <Flex
              key={u.id}
              width="100%"
              justify="center"
              data-testid="user-section"
            >
              <UserPanel
                user={u}
                onEdit={() => {
                  setUser(u);
                  onOpen();
                }}
                onComplete={refetch}
              />
            </Flex>
          ))}
        </SimpleGrid>
      </Box>

      <UserDrawer
        onComplete={refetch}
        onClose={onClose}
        isOpen={isOpen}
        initialValues={{
          id: user ? Number(user.id) : undefined,
          name: user ? user.name : '',
          username: user ? user.username : '',
          email: user ? user.email : '',
          password: '',
        }}
      />
    </>
  );
};
