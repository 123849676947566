import React, { MouseEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Text, Button, Stack } from '@chakra-ui/react';

export const Page404 = withRouter(
  ({ history: { push } }: RouteComponentProps) => {
    const handleGoHomeClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      push('/home');
    };

    return (
      <Stack data-testid="404-page" align="center" justify="center">
        <Text fontSize="30px" textAlign="center">
          404 - Item not found
        </Text>

        <Text mt="30px" textAlign="center">
          It looks like the item you are looking for could not be found or you
          do not have permission to access it.
        </Text> 

        <Button
          variant="solid"
          mt="30px"
          width="300px"
          onClick={handleGoHomeClick}
        >
          Go Home
        </Button>
      </Stack>
    );
  },
);
