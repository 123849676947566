import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { DashboardLandingPage, HomeLandingPage, Page404 } from './pages';
import { AuthRoute } from './containers/Auth/AuthRoute';
import { SWMSPrintPage } from './pages/SWMSPrintPage';
import { ARCPPrintPage } from './pages/ARCPPrintPage';
import { ToolboxTalkPrintPage } from './pages/ToolboxTalkPrintPage';
import { WasteDisposalPrintPage } from './pages/WasteDisposalPrintPage';
import { QuotePrintPage } from './pages/QuotePrintPage';

export const App = () => {
  return (
    <Router>
      <>
        <Switch>
          <Route path="/home" component={HomeLandingPage} />

          <AuthRoute
            path="/dashboard"
            render={() => <DashboardLandingPage />}
          />

          <Route
            path={`/swms/:removalProjectId`}
            render={({ match: { params } }) => (
              <SWMSPrintPage
                removalProjectId={Number(params.removalProjectId)}
              />
            )}
          />

          <Route
            path={`/arcp/:removalProjectId`}
            render={({ match: { params } }) => (
              <ARCPPrintPage
                removalProjectId={Number(params.removalProjectId)}
              />
            )}
          />

          <Route
            path={`/toolbox-talk/:toolboxTalkId`}
            render={({ match: { params } }) => (
              <ToolboxTalkPrintPage
                toolboxTalkId={Number(params.toolboxTalkId)}
              />
            )}
          />

          <Route
            path={`/waste-disposal/:wasteDisposalId`}
            render={({ match: { params } }) => (
              <WasteDisposalPrintPage
                wasteDisposalId={Number(params.wasteDisposalId)}
              />
            )}
          />

          <Route
            path={`/quote/:quoteId`}
            render={({ match: { params } }) => (
              <QuotePrintPage quoteId={Number(params.quoteId)} />
            )}
          />

          <Route path="/not-found" component={Page404} />

          <Redirect to="/home" />
        </Switch>
      </>
    </Router>
  );
};
