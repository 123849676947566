import React from 'react';
import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react';

export default function A4(props: BoxProps) {
  const [isPrinting] = useMediaQuery('print');

  return (
    <Box
      className="pba"
      width="225mm"
      bg="white"
      rounded="md"
      p={isPrinting ? 0 : '40px'}
      margin="0 auto"
      {...props}
    >
      {props.children}
    </Box>
  );
}
