import React from 'react';
import moment from 'moment';
import { RemovalProjectInterface } from 'jesco-web';
import { Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';

export interface RemovalProjectRowInterface extends TableRowPropsInterface {
  removalProject: RemovalProjectInterface;
  onRowClick(removalProject: RemovalProjectInterface): void;
}

export const RemovalProjectTableRow = ({
  removalProject,
  onRowClick,
  ...props
}: RemovalProjectRowInterface) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(removalProject);
    }
  };

  return (
    <TableRow
      data-testid="removal-project-table-row"
      onClick={() => handleClick()}
      {...props}
    >
      <TableDiv px="5px" py="10px" data-testid="address-td">
        <Text wordBreak="break-word">
          {`${removalProject.address}, ${removalProject.city}, ${removalProject.State?.abbrev}`}
        </Text>
      </TableDiv>

      <TableDiv p="5px" py="10px" data-testid="client-name-td">
        <Text wordBreak="break-word">
          {removalProject.Client ? removalProject.Client.name : '-'}
        </Text>
      </TableDiv>

      <TableDiv p="5px" py="10px" data-testid="commencement-td">
        <Text wordBreak="break-word">
          {moment(removalProject.start_date, 'YYYY-MM-DD').format(
            'D MMMM, YYYY',
          )}
        </Text>
      </TableDiv>

      <TableDiv p="5px" py="10px" data-testid="completion-td">
        <Text wordBreak="break-word">
          {moment(removalProject.end_date, 'YYYY-MM-DD').isSame(
            moment(removalProject.start_date, 'YYYY-MM-DD'),
          )
            ? 'Same day'
            : moment(removalProject.end_date, 'YYYY-MM-DD').format(
                'D MMMM, YYYY',
              )}
        </Text>
      </TableDiv>
    </TableRow>
  );
};
