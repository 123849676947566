import React from 'react';
import { BoxProps, Box, Button, Text } from '@chakra-ui/react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

export interface TableHeaderPropsInterface extends BoxProps {
  direction: string | null;
  isActive?: boolean;
  onHeaderClick?(slug: string): void;
  render?(): React.ReactElement;
  slug?: string;
  title?: string;
  tooltip?: string;
}

export const TableHeader = ({
  direction,
  flexDirection,
  isActive,
  onHeaderClick,
  render,
  slug,
  title,
  tooltip,
  ...props
}: TableHeaderPropsInterface) => {
  return (
    <Box as="th" data-tip={tooltip} data-for="table-header-tooltip" {...props}>
      {render && render()}
      {!render && !slug && title && (
        <Text
          fontSize="12px"
          color="textPrimary"
          textAlign="left"
          fontWeight="bold"
        >
          {title}
        </Text>
      )}

      {!render && slug && title && (
        <Button
          justifyContent="flex-start"
          onClick={() => onHeaderClick && onHeaderClick(slug || '')}
          p="3px"
          rounded="sm"
          width="100%"
          fontWeight="normal"
          variant="link"
          colorScheme="green"
          textDecoration={isActive ? 'underline' : 'none'}
          m={0}
          textAlign="left"
          wordBreak="break-word"
        >
          {title}
          {isActive && direction === 'desc' && (
            <Box data-testid="sort-desc" ml="10px" as={FaChevronDown} />
          )}

          {isActive && direction === 'asc' && (
            <Box data-testid="sort-asc" ml="10px" as={FaChevronUp} />
          )}
        </Button>
      )}
    </Box>
  );
};
