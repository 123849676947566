import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UserInterface } from 'jesco-web';
import {
  Box,
  Button,
  Stack,
  HStack,
  Text,
  IconButton,
  Divider,
  FormControl,
  Input,
  useToast,
} from '@chakra-ui/react';
import { UserPermissionsForm } from './UserPermissionsForm';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Details } from '../Details';
import { Section } from '../Section';
import { UPDATE_PASSWORD } from '../../services/apollo';
import { useMutation } from '@apollo/client';

export interface UserPanelProps {
  user: UserInterface;
  onEdit(user: UserInterface): void;
  onComplete(): void;
}
export const UserPanel = ({ user, onEdit, onComplete }: UserPanelProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [updatePassword, { loading: updatePasswordLoading }] = useMutation(
    UPDATE_PASSWORD,
    {
      onCompleted(d) {
        if (!d || !d.updatePassword || !d.updatePassword.status) return;
        setShowResetPassword(false);
        formik.resetForm();
        onComplete();

        toast({
          title: 'Password changed',
          description: `Password changed`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      password: 'jesco',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      updatePassword({
        variables: {
          userId: Number(user.id),
          password: values.password,
        },
      });
    },
  });

  return (
    <Section maxWidth="500px" width="100%">
      <HStack py="10px" justify="space-between">
        <Stack alignItems="flex-start">
          <Button
            variant="link"
            colorScheme="green"
            data-testid="edit-user-button"
            fontSize="xl"
            onClick={() => {
              onEdit(user);
            }}
          >
            {user.name}
          </Button>
        </Stack>
      </HStack>
      <Divider />

      <Details label="Email" body={user.email || '-'} />
      <Details label="Username" body={user.username || '-'} />
      <HStack justify="flex-end" my="15px">
        {!showResetPassword && (
          <HStack justify="flex-end">
            <Button
              variant="link"
              colorScheme="brand"
              onClick={() => setShowResetPassword(true)}
            >
              Reset password
            </Button>
          </HStack>
        )}

        {showResetPassword && (
          <Box w="100%">
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                id="password"
                isInvalid={Boolean(formik.errors.password)}
                isRequired
              >
                <HStack>
                  <Input
                    data-testid="reset-password-form-password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    autoFocus
                  />

                  <HStack>
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      data-testid="submit-password-ok"
                      aria-label="submit-password-ok"
                      type="submit"
                      disabled={!formik.isValid}
                      isLoading={updatePasswordLoading}
                      icon={<FaCheck />}
                    />
                    <IconButton
                      variant="outline"
                      colorScheme="gray"
                      data-testid="submit-password-cancel"
                      aria-label="submit-password-cancel"
                      onClick={() => setShowResetPassword(false)}
                      isLoading={updatePasswordLoading}
                      icon={<FaTimes />}
                    />
                  </HStack>
                </HStack>
              </FormControl>
            </form>
          </Box>
        )}
      </HStack>

      <Stack mt="10px">
        <Text fontSize="lg">Permissions</Text>
        <Divider />
        <UserPermissionsForm user={user} onComplete={onComplete} />
      </Stack>
    </Section>
  );
};
