import React from 'react';
import { Button } from '@chakra-ui/react';

export interface PaginatorNumberButtonInterface {
  isActive: boolean;
  number: number;
  onClick(pageNumber: number): void;
}

export const PaginatorNumberButton = ({
  isActive,
  number,
  onClick,
  ...props
}: PaginatorNumberButtonInterface) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    onClick(number);
  };

  return (
    <Button
      onClick={handleClick}
      variant="link"
      colorScheme="green"
      color="blue"
      fontWeight="normal"
      minWidth="auto"
      textDecoration={isActive ? 'none' : 'underline'}
      mx="5px"
      {...props}
    >
      {`${number}`}
    </Button>
  );
};
