import React, { useState } from 'react';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import Highlight from 'react-highlighter';
import {
  ClientInterface,
  CalendarEventInterface,
  RemovalProjectInterface,
  CalendarEventTypeInterface,
  QuoteInterface,
} from 'jesco-web';
import {
  Icon,
  Box,
  Flex,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Skeleton,
  Stack,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Tag,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_CALENDAR_EVENT_TYPES,
  SEARCH_ALL_QUERY,
} from '../services/apollo/queries';
import {
  FaCalendar,
  FaEnvelope,
  FaMobile,
  FaPhone,
  FaRoad,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CalendarBubble } from './Calendar/CalendarBubble';

export interface SearchDrawerProps extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SearchDrawer = withRouter(
  ({ history: { push }, isOpen, onClose }: SearchDrawerProps) => {
    const [search, setSearch] = useState<string>('');
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [removalProjects, setRemovalProjects] = useState<
      RemovalProjectInterface[]
    >([]);
    const [quotes, setQuotes] = useState<QuoteInterface[]>([]);
    const [calendarEvents, setCalendarEvents] = useState<
      CalendarEventInterface[]
    >([]);
    const firstField = React.useRef<HTMLInputElement>(null);
    const { data: calendarEventTypesData } = useQuery<{
      getCalendarEventTypes: CalendarEventTypeInterface[];
    }>(GET_CALENDAR_EVENT_TYPES);

    let calendarEventTypes: CalendarEventTypeInterface[] = [];

    if (calendarEventTypesData) {
      calendarEventTypes = calendarEventTypesData.getCalendarEventTypes;
    }

    const [searchAll, { loading }] = useLazyQuery<{
      searchAll: {
        Clients: ClientInterface[];
        RemovalProjects: RemovalProjectInterface[];
        CalendarEvents: CalendarEventInterface[];
        Quotes: QuoteInterface[];
      };
    }>(SEARCH_ALL_QUERY, {
      onCompleted(d) {
        if (d.searchAll.Clients) {
          setClients(d.searchAll.Clients);
        }

        if (d.searchAll.RemovalProjects) {
          setRemovalProjects(d.searchAll.RemovalProjects);
        }

        if (d.searchAll.CalendarEvents) {
          setCalendarEvents(d.searchAll.CalendarEvents);
        }

        if (d.searchAll.Quotes) {
          setQuotes(d.searchAll.Quotes);
        }
      },
    });

    const debounced = useDebouncedCallback(
      // function
      (v) => {
        if (v.target.value) {
          searchAll({
            variables: {
              search: v.target.value,
            },
          });
        }
      },
      // delay in ms
      500,
    );

    const handleClose = () => {
      onClose();
      setSearch('');
      setClients([]);
      setRemovalProjects([]);
      setCalendarEvents([]);
    };

    return (
      <Drawer
        size="lg"
        isOpen={isOpen}
        placement="right"
        onClose={handleClose}
        initialFocusRef={firstField}
      >
        <DrawerOverlay />
        <DrawerContent rounded="md" data-testid="search-drawer">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">What are you looking for?</Text>
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody data-testid="drawer" p={0} pt="30px" overflow="hidden">
            <Stack p="20px">
              <InputGroup size="lg">
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaSearch color="gray" />}
                />

                <Input
                  ref={firstField}
                  placeholder="Search"
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                    debounced.callback(e);
                  }}
                  value={search}
                />

                {Boolean(search.length) && (
                  <InputRightElement>
                    <IconButton
                      data-testid="clear-search"
                      aria-label="clear-input"
                      position="absolute"
                      variant="ghost"
                      colorScheme="blue"
                      size="lg"
                      onClick={() => {
                        setSearch('');
                        firstField.current?.focus();
                      }}
                      icon={<FaTimes />}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Stack>
            <Flex
              flex={1}
              width="100%"
              overflow="hidden"
              height="100%"
              borderTop="1px solid"
              borderColor="gray.300"
              pt="20px"
            >
              <Stack
                width="100%"
                height="100%"
                overflow="auto"
                px="20px"
                pb="200px"
              >
                {loading && (
                  <Stack>
                    <Skeleton height="35px" />
                    <Skeleton height="35px" />
                    <Skeleton height="35px" />
                    <Skeleton height="35px" />
                    <Skeleton height="35px" />
                  </Stack>
                )}
                {!loading &&
                  (clients.length ||
                    removalProjects.length ||
                    calendarEvents.length) && (
                    <Stack spacing="50px">
                      {calendarEvents.length && (
                        <Box>
                          <Text fontSize="xl" color="green.500">
                            Calendar events
                          </Text>

                          {calendarEvents.map((event) => {
                            const activeEventType = calendarEventTypes.find(
                              (cet) =>
                                cet.id ===
                                Number(event?.calendar_event_type_id),
                            );

                            const start = moment(
                              event?.start_date_formatted,
                              'LLL',
                            );
                            const end = moment(
                              event?.end_date_formatted,
                              'LLL',
                            );

                            const eventStartTime = start.format('hh:mm A');
                            const eventEndTime = end.format('hh:mm A');
                            const eventStartDate = start.format('D MMMM, YYYY');
                            const eventEndDate = end.format('D MMMM, YYYY');

                            let dateString = `${eventStartDate} - ${eventEndDate}`;
                            if (start.isSame(end, 'day')) {
                              dateString = `${eventStartDate}`;
                            }

                            return (
                              <Box
                                key={event.id}
                                spacing="10px"
                                borderTop="1px solid"
                                borderColor="gray.300"
                                py="10px"
                              >
                                <Box pt={4} fontWeight="bold" border="0">
                                  <HStack justify="space-between">
                                    <HStack maxWidth="450px">
                                      <Icon as={FaCalendar} color="gray.500" />
                                      <Text>
                                        <Highlight search={search}>
                                          {event?.title}
                                        </Highlight>
                                      </Text>
                                    </HStack>
                                    <Button
                                      size="sm"
                                      colorScheme="green"
                                      variant="link"
                                      onClick={() => {
                                        handleClose();
                                        push(`/dashboard/calendar/${event.id}`);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </HStack>
                                  <HStack justify="space-between" mt="10px">
                                    <Text fontSize="sm" color="blue.500">
                                      {dateString}
                                    </Text>
                                    {Boolean(activeEventType) && (
                                      <Tag
                                        bg={String(activeEventType?.color)}
                                        alignSelf="flex-start"
                                        size="md"
                                      >
                                        <HStack>
                                          <CalendarBubble color="white" />
                                          <Text color="white">
                                            {String(activeEventType?.title)}
                                          </Text>
                                        </HStack>
                                      </Tag>
                                    )}
                                  </HStack>
                                  {!Boolean(event?.is_all_day) && (
                                    <Text fontSize="sm" color="green.500">
                                      ({eventStartTime} - {eventEndTime})
                                    </Text>
                                  )}
                                  {Boolean(event?.is_all_day) && (
                                    <Text fontSize="sm" color="green.500">
                                      All day
                                    </Text>
                                  )}
                                </Box>
                                <Box>
                                  <Stack>
                                    <Text
                                      fontSize="md"
                                      minHeight="50px"
                                      my="10px"
                                    >
                                      <Highlight search={search}>
                                        {event && event.description
                                          ? event.description
                                          : 'No description'}
                                      </Highlight>
                                    </Text>

                                    {Boolean(event && event.location) && (
                                      <HStack justify="space-between">
                                        {Boolean(event?.Employees.length) && (
                                          <HStack justify="space-between">
                                            {event?.Employees.map((e) => (
                                              <HStack key={e.id}>
                                                <Text>
                                                  {e?.first} {e?.last}
                                                </Text>
                                              </HStack>
                                            ))}
                                          </HStack>
                                        )}
                                        <Text>
                                          <Highlight search={search}>
                                            {event && event.location
                                              ? event.location
                                              : 'No location'}
                                          </Highlight>
                                        </Text>
                                      </HStack>
                                    )}
                                  </Stack>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      {removalProjects.length && (
                        <Box>
                          <Text fontSize="xl" color="green.500">
                            Removal projects
                          </Text>

                          {removalProjects.map((removalProject) => {
                            const startDate = moment(
                              removalProject.start_date,
                              'YYYY-MM-DD',
                            );
                            const endDate = moment(
                              removalProject.end_date,
                              'YYYY-MM-DD',
                            );
                            const isSameDate = startDate.isSame(endDate, 'day');
                            return (
                              <Stack
                                key={removalProject.id}
                                spacing="10px"
                                borderTop="1px solid"
                                borderColor="gray.300"
                                pt="10px"
                              >
                                <HStack justify="space-between">
                                  <Text fontSize="lg">
                                    <Highlight search={search}>
                                      {removalProject.Client?.name}
                                    </Highlight>
                                  </Text>
                                  <ButtonGroup>
                                    <Button
                                      size="sm"
                                      colorScheme="green"
                                      variant="link"
                                      onClick={() => {
                                        handleClose();
                                        push(
                                          `/dashboard/removal-project/${removalProject.id}`,
                                        );
                                      }}
                                    >
                                      View
                                    </Button>
                                  </ButtonGroup>
                                </HStack>
                                <HStack>
                                  <Icon as={FaRoad} color="gray.500" />
                                  <Text>
                                    <Highlight search={search}>
                                      {`${removalProject.address}, ${removalProject.city}, ${removalProject.State?.abbrev}`}
                                    </Highlight>
                                  </Text>
                                </HStack>
                                <HStack>
                                  <HStack>
                                    <Icon as={FaCalendar} color="gray.500" />
                                    {isSameDate && (
                                      <Text color="blue.500">
                                        {startDate.format('D MMMM, YYYY')}
                                      </Text>
                                    )}

                                    {!isSameDate && (
                                      <Text color="blue.500">
                                        {startDate.format('D MMMM, YYYY')} -{' '}
                                        {endDate.format('D MMMM, YYYY')}
                                      </Text>
                                    )}
                                  </HStack>
                                </HStack>
                                <HStack justify="space-between">
                                  <Text>Assessor</Text>
                                  <Text>
                                    <Highlight search={search}>
                                      {removalProject.Assessor?.name || 'None'}
                                    </Highlight>
                                  </Text>
                                </HStack>
                                <HStack justify="space-between">
                                  <Text>Supervisor</Text>
                                  <Text>
                                    <Highlight search={search}>
                                      {removalProject.Supervisor?.name ||
                                        'None'}
                                    </Highlight>
                                  </Text>
                                </HStack>
                              </Stack>
                            );
                          })}
                        </Box>
                      )}
                      {clients.length && (
                        <Box>
                          <Text fontSize="xl" color="green.500">
                            Clients
                          </Text>

                          {clients.map((client) => (
                            <Stack
                              key={client.id}
                              spacing="10px"
                              borderTop="1px solid"
                              borderColor="gray.300"
                              pt="10px"
                            >
                              <HStack justify="space-between">
                                <Text fontSize="lg">
                                  <Highlight search={search}>
                                    {client.name}
                                  </Highlight>
                                </Text>
                                <ButtonGroup>
                                  <Button
                                    size="sm"
                                    colorScheme="green"
                                    variant="link"
                                    onClick={() => {
                                      handleClose();
                                      push(
                                        `/dashboard/people/clients/${client.id}`,
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                </ButtonGroup>
                              </HStack>

                              <HStack>
                                <Icon as={FaEnvelope} color="green.500" />
                                <Text>
                                  <Highlight search={search}>
                                    {client.email}
                                  </Highlight>
                                </Text>
                              </HStack>
                              <HStack>
                                <Icon as={FaRoad} color="gray.500" />
                                <Text>
                                  <Highlight search={search}>
                                    {`${client.address}, ${client.city}, ${client.State?.abbrev}`}
                                  </Highlight>
                                </Text>
                              </HStack>

                              {client.phone ||
                                (client.mobile && (
                                  <HStack>
                                    {client.phone && (
                                      <HStack>
                                        <Icon as={FaPhone} color="gray.500" />
                                        <Text>
                                          <Highlight search={search}>
                                            {client.phone}
                                          </Highlight>
                                        </Text>
                                      </HStack>
                                    )}

                                    {client.mobile && (
                                      <HStack>
                                        <Icon as={FaMobile} color="gray.500" />
                                        <Text>
                                          <Highlight search={search}>
                                            {client.mobile}
                                          </Highlight>
                                        </Text>
                                      </HStack>
                                    )}
                                  </HStack>
                                ))}
                              <HStack>
                                <Text>Client Contact:</Text>
                                <Text>
                                  <Highlight search={search}>
                                    {client.client_contact || 'None'}
                                  </Highlight>
                                </Text>
                              </HStack>
                            </Stack>
                          ))}
                        </Box>
                      )}
                    </Stack>
                  )}
                {quotes.length && (
                  <Box>
                    <Text fontSize="xl" color="green.500">
                      Quotes
                    </Text>

                    {quotes.map((quote) => (
                      <Stack
                        key={quote.id}
                        spacing="10px"
                        borderTop="1px solid"
                        borderColor="gray.300"
                        pt="10px"
                      >
                        <HStack justify="space-between">
                          <Text fontSize="lg">
                            <Highlight search={search}>{quote.name}</Highlight>
                          </Text>
                          <ButtonGroup>
                            <Button
                              size="sm"
                              colorScheme="green"
                              variant="link"
                              onClick={() => {
                                handleClose();
                                push(`/dashboard/quote/${quote.id}`);
                              }}
                            >
                              View
                            </Button>
                          </ButtonGroup>
                        </HStack>

                        <HStack>
                          <Icon as={FaRoad} color="gray.500" />
                          <Text>
                            <Highlight search={search}>
                              {`${quote.street}, ${quote.city}, ${quote.State?.abbrev}`}
                            </Highlight>
                          </Text>
                        </HStack>
                        <Stack>
                          <Text>
                            {moment(quote.date_formatted, 'LLL').format(
                              'D MMMM, YYYY',
                            )}
                          </Text>
                          <Text>
                            {moment(quote.date_formatted, 'LLL').format(
                              'hh:mm A',
                            )}{' '}
                            -{' '}
                            {moment(quote.end_date_formatted, 'LLL').format(
                              'hh:mm A',
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                    ))}
                  </Box>
                )}

                {!loading &&
                  search &&
                  !clients.length &&
                  !removalProjects.length &&
                  !quotes.length &&
                  !calendarEvents.length && (
                    <Box rounded="sm" bg="gray.100" p="20px" align="center">
                      <Text fontSize="md">
                        No matching results could be found
                      </Text>
                    </Box>
                  )}
              </Stack>
            </Flex>
          </DrawerBody>
          <DrawerFooter borderTop="1px solid" borderColor="gray.300">
            <Button
              onClick={handleClose}
              width="200px"
              ml="5px"
              variant="solid"
              colorScheme="blue"
            >
              Finished
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  },
);
