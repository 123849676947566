import React, { useState } from 'react';
import {
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { RemovalAreaTable } from './RemovalAreaTable';
import { Section } from '../Section';
import { RemovalProjectInterface } from 'jesco-web';
import { TableFilterInterface } from '../Table/Table';
import { RemovalAreaDrawer } from './RemovalAreaDrawer';
import { useMutation } from '@apollo/client';
import { REMOVE_AREA_FROM_REMOVAL_PROJECT } from '../../services/apollo/mutations';
import { useAuthContext } from '../../providers/Auth.Provider';
import { RemovalOptionEditButton } from './RemovalOptionEditButton';

export interface RemovalProjectRemovalAreasProps {
  removalProject: RemovalProjectInterface;
  onComplete(): void;
}
export const RemovalProjectRemovalAreas = ({
  removalProject,
  onComplete,
}: RemovalProjectRemovalAreasProps) => {
  const { me } = useAuthContext();
  const toast = useToast({ position: 'bottom-left' });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const [removeAreaFromRemovalProject, { loading }] = useMutation(
    REMOVE_AREA_FROM_REMOVAL_PROJECT,
    {
      onCompleted(d) {
        if (
          !d ||
          !d.removeAreaFromRemovalProject ||
          !d.removeAreaFromRemovalProject.status
        )
          return;
        onComplete();
        toast({
          title: 'Removal area removed',
          description: `Removal area removed`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  return (
    <>
      <Section width="100%" maxWidth="700px">
        <Stack height="100%">
          <Flex align="center" mb="40px" justifyContent="space-between">
            <Text
              fontSize="lg"
              color="textSecondary"
            >{`Removal areas (${removalProject.RemovalAreas.length})`}</Text>
            <Button
              isDisabled={!Boolean(me?.Permissions?.can_edit_removal_projects)}
              variant="link"
              data-testid="add-removal-area-button"
              size="sm"
              onClick={onOpen}
            >
              Add removal area
            </Button>
          </Flex>
          <Flex flex={1}>
            <RemovalAreaTable
              isLoading={loading}
              filter={filter}
              onChangeFilter={setFilter}
              onDeleteRemovalAreaClick={(ra) => {
                removeAreaFromRemovalProject({
                  variables: {
                    id: Number(ra.id),
                  },
                });
              }}
              clickable
              removalAreas={removalProject.RemovalAreas}
              emptyMessage="No removal areas yet"
            />
          </Flex>
          <HStack justify="flex-end">
            {me && <RemovalOptionEditButton me={me} />}
          </HStack>
        </Stack>
      </Section>

      <RemovalAreaDrawer
        isOpen={isOpen}
        onClose={onClose}
        onComplete={onComplete}
        removalProject={removalProject}
      />
    </>
  );
};
