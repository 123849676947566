import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker } from 'antd';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { GET_PEOPLE_QUERY } from '../../services/apollo/queries';
import {
  AssessorInterface,
  ClientInterface,
  SupervisorInterface,
} from 'jesco-web';
import { useQuery, useMutation } from '@apollo/client';
import {
  CREATE_REMOVAL_PROJECT,
  UPDATE_REMOVAL_PROJECT,
  DELETE_REMOVAL_PROJECT,
} from '../../services/apollo/mutations';
import { RemovalProjectInterface, StateInterface } from 'jesco-web';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

export interface RemovalProjectFormValues {
  id?: number;
  address: string;
  assessorId?: number;
  city: string;
  clientId?: number;
  endDate: Date;
  notes?: string;
  quantity: number;
  startDate: Date;
  stateId?: number;
  supervisorId?: number;
  worksafeNo: string;
}

export interface RemovalProjectDrawerProps extends RouteComponentProps {
  initialValues: RemovalProjectFormValues;
  isOpen: boolean;
  onClose(): void;
  states: StateInterface[];
  removalProject?: RemovalProjectInterface;
  onComplete?(): void;
}

export const RemovalProjectDrawer = withRouter(
  ({
    history: { push },
    initialValues,
    isOpen,
    onClose,
    states,
    removalProject,
    onComplete,
  }: RemovalProjectDrawerProps) => {
    const toast = useToast({ position: 'bottom-left' });
    const { data: peopleData } = useQuery<{
      allAssessors: AssessorInterface[];
      allClients: ClientInterface[];
      allSupervisors: SupervisorInterface[];
    }>(GET_PEOPLE_QUERY);

    let assessors: AssessorInterface[] = [];
    let clients: ClientInterface[] = [];
    let supervisors: SupervisorInterface[] = [];

    if (peopleData) {
      assessors = peopleData.allAssessors;
      clients = peopleData.allClients;
      supervisors = peopleData.allSupervisors;
    }

    const [createRemovalProject, { loading: isCreateLoading }] = useMutation(
      CREATE_REMOVAL_PROJECT,
      {
        onCompleted(d) {
          if (!d || !d.createRemovalProject || !d.createRemovalProject.id)
            return;

          onClose();
          push(`/dashboard/removal-project/${d.createRemovalProject.id}`);
        },
      },
    );

    const [updateRemovalProject, { loading: isUpdateLoading }] = useMutation(
      UPDATE_REMOVAL_PROJECT,
      {
        onCompleted(d) {
          if (!d || !d.updateRemovalProject || !d.updateRemovalProject.id)
            return;

          onClose();
          if (onComplete) {
            onComplete();
          }

          formik.resetForm();

          toast({
            title: 'Removal project updated',
            description: `Removal project updated`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

    const [deleteRemovalProject, { loading: isDeleteLoading }] = useMutation(
      DELETE_REMOVAL_PROJECT,
      {
        onCompleted(d) {
          if (!d || !d.deleteRemovalProject || !d.deleteRemovalProject.id)
            return;

          onClose();

          toast({
            title: 'Removal project deleted',
            description: `Removal project deleted`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          push(`/dashboard/removal-projects`);
        },
      },
    );

    const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

    const formik = useFormik({
      isInitialValid: false,
      enableReinitialize: true,
      initialValues,
      validationSchema: Yup.object().shape({
        assessorId: Yup.string().required(),
        address: Yup.string().min(1).required('Site address is required'),
        city: Yup.string().min(1).required('City is required'),
        clientId: Yup.string().required('Client is required'),
        stateId: Yup.string().required(),
        endDate: Yup.date().required(),
        quantity: Yup.string().required('Quantity is required'),
        startDate: Yup.date().required(),
        supervisorId: Yup.string().required(),
        worksafeNo: Yup.string().nullable(),
      }),
      onSubmit: (values) => {
        if (!values.id) {
          createRemovalProject({
            variables: {
              ...values,
              startDate: moment(values.startDate).format('YYYY-MM-DD'),
              endDate: moment(values.endDate).format('YYYY-MM-DD'),
              assessorId: Number(values.assessorId),
              clientId: Number(values.clientId),
              supervisorId: Number(values.supervisorId),
              stateId: Number(values.stateId),
              quantity: Number(values.quantity),
            },
          });
        } else {
          updateRemovalProject({
            variables: {
              ...values,
              startDate: moment(values.startDate).format('YYYY-MM-DD'),
              endDate: moment(values.endDate).format('YYYY-MM-DD'),
              assessorId: Number(values.assessorId),
              clientId: Number(values.clientId),
              supervisorId: Number(values.supervisorId),
              stateId: Number(values.stateId),
              quantity: Number(values.quantity),
            },
          });
        }
      },
    });

    const handleClose = () => {
      onClose();
      formik.resetForm();
    };

    return (
      <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <form onSubmit={formik.handleSubmit} noValidate>
          <DrawerContent rounded="md">
            <DrawerHeader
              pl="40px"
              pr="20px"
              display="flex"
              fontSize="md"
              color="white"
              bg="blue.500"
              justifyContent="space-between"
              alignItems="center"
            >
              <DrawerCloseButton
                left="0"
                top="5px"
                data-testid="drawer-close-button"
                onClick={handleClose}
                color="white"
              />
              <Text fontSize="xl">
                {formik.values.id
                  ? `Edit Removal Project`
                  : 'New Removal Project'}
              </Text>

              {formik.values.id && formik.values.id && (
                <IconButton
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      deleteRemovalProject({
                        variables: {
                          id: formik.values.id,
                        },
                      });
                    }
                  }}
                  data-testid="delete-removal-project-button"
                  aria-label="delete-removal-project"
                  colorScheme="red"
                  size="sm"
                  icon={<FaTrash />}
                />
              )}
            </DrawerHeader>

            <DrawerBody bg="white" p="20px">
              <Stack spacing={15}>
                <FormControl
                  id="client"
                  isInvalid={Boolean(formik.errors.clientId)}
                  isRequired
                >
                  <FormLabel>Client</FormLabel>
                  <Select
                    data-testid="removal-project-form-client"
                    name="clientId"
                    placeholder="Select a client"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.clientId}
                  >
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  id="address"
                  isInvalid={Boolean(formik.errors.address)}
                  isRequired
                >
                  <FormLabel>Site address</FormLabel>
                  <Input
                    data-testid="removal-project-form-address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                </FormControl>

                <Stack
                  justify="space-between"
                  direction={['column', 'column', 'row']}
                >
                  <FormControl
                    id="city"
                    isInvalid={Boolean(formik.errors.city)}
                    isRequired
                  >
                    <FormLabel>City</FormLabel>
                    <Input
                      data-testid="removal-project-form-city"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                    <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    id="state"
                    isInvalid={Boolean(formik.errors.stateId)}
                    isRequired
                  >
                    <FormLabel>State</FormLabel>
                    <Select
                      data-testid="removal-project-form-state"
                      name="stateId"
                      placeholder="Select a state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.stateId}
                    >
                      {states.map((s) => (
                        <option key={s.id} value={s.id}>
                          {s.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Box>
                  <Stack
                    direction={['column', 'column', 'row']}
                    justify="space-between"
                    mt={5}
                    mb={3}
                  >
                    <FormControl
                      id="startDate"
                      data-testid="removal-project-form-commencement"
                    >
                      <FormLabel>
                        <HStack justifyContent="space-between">
                          <Text fontSize="lg">Commencement</Text>
                          <Text fontWeight="bold">
                            {moment(formik.values.startDate).format(
                              'D MMMM, YYYY',
                            )}
                          </Text>
                        </HStack>
                      </FormLabel>

                      <DatePicker
                        format="DD/MM/YYYY"
                        value={moment(formik.values.startDate).clone()}
                        onChange={(d) => {
                          formik.setFieldValue('startDate', d?.toDate());
                        }}
                      />
                    </FormControl>

                    <FormControl
                      id="endDate"
                      data-testid="removal-project-form-completion"
                    >
                      <FormLabel>
                        <HStack justifyContent="space-between">
                          <Text fontSize="lg">Completion</Text>
                          <Text fontWeight="bold">
                            {moment(formik.values.endDate).format(
                              'D MMMM, YYYY',
                            )}
                          </Text>
                        </HStack>
                      </FormLabel>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={moment(formik.values.endDate).clone()}
                        onChange={(d) => {
                          formik.setFieldValue('endDate', d?.toDate());
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Box>

                <Stack
                  justify="space-between"
                  direction={['column', 'column', 'row']}
                >
                  <FormControl
                    id="assessor"
                    isInvalid={Boolean(formik.errors.assessorId)}
                    isRequired
                  >
                    <FormLabel>Assessor</FormLabel>
                    <Select
                      data-testid="removal-project-form-assessor"
                      name="assessorId"
                      placeholder="Select an assessor"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.assessorId}
                    >
                      {assessors.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    id="supervisor"
                    isInvalid={Boolean(formik.errors.supervisorId)}
                    isRequired
                  >
                    <FormLabel>Supervisor</FormLabel>
                    <Select
                      data-testid="removal-project-form-supervisor"
                      name="supervisorId"
                      placeholder="Select a supervisor"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.supervisorId}
                    >
                      {supervisors.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack
                  justify="space-between"
                  direction={['column', 'column', 'row']}
                >
                  <FormControl id="worksafeNo">
                    <FormLabel>Regulatory Notification</FormLabel>
                    <Input
                      data-testid="removal-project-form-worksafe-notification"
                      name="worksafeNo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.worksafeNo}
                    />
                  </FormControl>

                  <FormControl id="quantity">
                    <FormLabel>Quantity</FormLabel>
                    <Select
                      data-testid="removal-project-form-quantity"
                      name="quantity"
                      placeholder="Select a quantity"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                    >
                      <option value="1">Less than 10 SQM</option>
                      <option value="2">Greater than 10 SQM</option>
                    </Select>
                  </FormControl>
                </Stack>

                <FormControl id="worksafeNo">
                  <FormLabel>Notes</FormLabel>
                  <Input
                    minHeight="150px"
                    as="textarea"
                    data-testid="removal-project-form-notes"
                    name="notes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                </FormControl>

                {formik.values.id && (
                  <Input
                    data-testid="removal-project-form-id"
                    name="id"
                    value={formik.values.id}
                    type="hidden"
                  />
                )}
              </Stack>
            </DrawerBody>

            <DrawerFooter
              justifyContent="space-evenly"
              borderTop="1px solid"
              borderColor="gray.300"
            >
              <Button width="200px" size="md" mr="5px" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                width="200px"
                ml="5px"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={!formik.isValid || formik.isSubmitting}
                isLoading={isLoading}
              >
                {formik.values.id ? 'Update' : 'Create'}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    );
  },
);
