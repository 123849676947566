import React, { useState } from 'react';
import {
  Button,
  Stack,
  IconButton,
  Text,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  DrawerFooter,
} from '@chakra-ui/react';
import { EmployeeInterface } from 'jesco-web';
import { useMutation } from '@apollo/client';
import {
  GET_SIGNED_URL,
  UPLOAD_EMPLOYEE_FILE,
} from '../../services/apollo/mutations';
import { FaTimes } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

export interface EmployeeUploadsProps {
  onComplete(): void;
  employee?: EmployeeInterface;
  isOpen: boolean;
  onClose(): void;
}

export const EmployeeUploadsDrawer = ({
  onComplete,
  employee,
  isOpen,
  onClose,
}: EmployeeUploadsProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const [file, setFile] = useState<File>();

  const [uploadEmployeeFile, { loading }] = useMutation(UPLOAD_EMPLOYEE_FILE, {
    onCompleted(d) {
      if (!d || !d.uploadEmployeeFile || !d.uploadEmployeeFile.id) return;

      onClose();
      onComplete();
      setFile(undefined);

      toast({
        title: 'File uploaded',
        description: `File uploaded`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = () => {
    if (!employee) return;

    getSignedUrl({
      variables: {
        file: file?.name,
        type: file?.type,
        folder: 'uploads',
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setFile(acceptedFiles[0]);
      }
    },
    maxFiles: 1,
  });

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onCompleted: async (data) => {
      if (data.getSignedUrl && file && employee) {
        const signedUrl = data.getSignedUrl;
        await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        toast({
          title: 'File uploaded',
          description: 'File successfully uploaded',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // drop the query params from the signed URL
        const path = signedUrl.split('?')[0];

        await uploadEmployeeFile({
          variables: {
            path,
            name: file.name,
            mimetype: file.type,
            employeeId: Number(employee.id),
          },
        });
      }
    },
    onError: (error) => {
      toast({
        title: 'Upload failed',
        description: 'The file could not be uploaded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Failed to upload file:', error);
    },
  });

  const handleClose = () => {
    onClose();
    setFile(undefined);
  };

  if (!employee) return null;

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent rounded="md">
        <DrawerHeader
          px="40px"
          display="flex"
          fontSize="md"
          color="white"
          bg="blue.500"
        >
          Upload file
        </DrawerHeader>
        <DrawerCloseButton
          left="0"
          top="5px"
          data-testid="drawer-close-button"
          onClick={handleClose}
          color="white"
        />
        <DrawerBody data-testid="drawer">
          <Stack>
            {!file && (
              <Stack
                {...getRootProps()}
                bg="#eee"
                height="150px"
                rounded="md"
                alignItems="center"
                justifyContent="center"
              >
                <input
                  data-testid="employee-upload-input"
                  {...getInputProps()}
                />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag and drop a file here, or click to select files</p>
                )}
              </Stack>
            )}

            {file && (
              <HStack bg="gray.50" p="10px" justify="space-between">
                <Text>
                  <Text fontWeight="bold" as="span">
                    {file.name}
                  </Text>
                  <Text as="span" ml="10px">
                    {file.type}
                  </Text>
                </Text>
                <IconButton
                  aria-label="cancel-upload"
                  icon={<FaTimes />}
                  size="sm"
                  onClick={() => setFile(undefined)}
                  variant="outline"
                  colorScheme="gray"
                />
              </HStack>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter
          justifyContent="space-evenly"
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <Button width="200px" size="md" mr="5px" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            width="200px"
            ml="5px"
            onClick={handleSubmit}
            variant="solid"
            colorScheme="blue"
            isLoading={loading}
            disabled={!Boolean(file)}
          >
            Upload
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
