import React from 'react';
import moment from 'moment';
import {
  HStack,
  Flex,
  Box,
  Stack,
  Progress,
  Image,
  useMediaQuery,
  Text,
  UnorderedList,
  ListItem,
  OrderedList,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Header } from '../containers/Text/Header';
import { GET_WASTE_DISPOSAL_QUERY } from '../services/apollo/queries';
import { WasteDisposalInterface } from 'jesco-web';
import tonyHallSignature from '../lib/images/tony-hall-signature.png';
import A4 from '../containers/Print/A4';

export interface WasteDisposalPrintPageProps {
  wasteDisposalId: number;
}

export const WasteDisposalPrintPage = ({
  wasteDisposalId,
}: WasteDisposalPrintPageProps) => {
  const [isPrinting] = useMediaQuery('print');
  const { loading, data } = useQuery<{
    wasteDisposal: WasteDisposalInterface;
  }>(GET_WASTE_DISPOSAL_QUERY, {
    variables: {
      id: wasteDisposalId,
    },
  });

  if (loading) {
    return (
      <Box width="100%" position="relative">
        <Progress
          position="absolute"
          top="0"
          left="0"
          right="0"
          height=".15rem"
          isIndeterminate
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const wasteDispoal = data.wasteDisposal;

  const WasteDisposalFooter = ({ page }: { page: number }) => (
    <Box border="1px solid">
      <HStack borderBottom="1px solid" px="10px">
        <Box borderRight="1px solid" width="350px" p="3px">
          <Text fontSize="10px">Application to dispose of Asbestos. ACT</Text>
        </Box>
        <Flex flex={1} justify="flex-end">
          <Text textAlign="right" fontSize="10px">{`page ${page} of 5`}</Text>
        </Flex>
      </HStack>
      <HStack borderBottom="1px solid" px="10px">
        <Box borderRight="1px solid" width="350px" p="3px">
          <Text fontSize="10px">Revision: Initial 02.11.2015 byJD</Text>
        </Box>
        <Flex flex={1} justify="flex-end">
          <Text textAlign="right" fontSize="10px">
            Reviewed R ILESS.Crowe: 041.046.20175
          </Text>
        </Flex>
      </HStack>
      <HStack px="10px">
        <Box borderRight="1px solid" width="350px" p="3px">
          <Text fontSize="10px">Approved by: R IlesS.Crowe</Text>
        </Box>
        <Flex flex={1} justify="flex-end">
          <Text textAlign="right" fontSize="10px">
            © Copyright Remondis
          </Text>
        </Flex>
      </HStack>
    </Box>
  );

  let hasFriable = false;
  let hasNonFriable = false;

  wasteDispoal.RemovalProjects.forEach((rp) => {
    if (rp.RemovalAreas.find((ra) => ra.Type.name === 'Friable')) {
      hasFriable = true;
    }

    if (rp.RemovalAreas.find((ra) => ra.Type.name === 'Non-Friable')) {
      hasNonFriable = true;
    }
  });

  return (
    <Box
      bg={isPrinting ? '#fff' : '#ccc'}
      width="100%"
      py={isPrinting ? 0 : '20px'}
      color="black"
      borderColor="black"
    >
      <A4
        minHeight={isPrinting ? 'initial' : '300mm'}
        height={isPrinting ? '320mm' : '300mm'}
        mb={isPrinting ? 0 : '20px'}
      >
        <Flex height="100%" flexDirection="column">
          <HStack mb="5px" justify="space-between">
            <Stack spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Header>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                MUGGA LANE WASTE MANAGEMENT FACILITY
              </Header>
            </Stack>
            <Image
              height="60px"
              src="https://jesco-files.s3-us-west-1.amazonaws.com/remondis.png"
              alt="Remondis logo"
            />
          </HStack>
          <Flex direction="column" flex="1">
            <Box border="1px solid">
              <Text textAlign="center" fontSize="xl" fontWeight="bold">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Text>
            </Box>
            <Stack mt="10px">
              <Text>
                Asbestos can only be disposed of at the Mugga Lane Waste
                Management Facility. At least two working days notice must be
                given in writing (submission of application) or 24 hours in
                emergency situations before 12PM the day before to Remondis
                Australia to ensure that space is available.
              </Text>
              <Text>
                Mugga Lane Waste Management Facility is only for disposal
                strictly by appointment and only between the hours of 7.30 am to
                12.30 pm, Monday to Friday. Weather and site conditions may
                cause arrangements to be cancelled at short notice.
              </Text>
              <Text>
                All asbestos waste{' '}
                <Text as="span" fontWeight="bold">
                  must
                </Text>{' '}
                be packaged in accordance with the attached instructions, based
                upon ACTPLA and WorkSafe ACT requirements.
              </Text>

              <Text>
                The transporter is responsible for lifting and placing all
                packages into the designated disposal area.
              </Text>
              <Text>
                Enquiries may be directed to Remondis Australia on (02) 6270
                7700
              </Text>
              <Text>
                Please complete and return this form by facsimile to Remondis
                Australia on (02) 6232 7733 or email to Canberra@remondis.com.au
              </Text>
            </Stack>
            <Box border="1px solid" px="5px">
              <Text textAlign="center" fontWeight="bold">
                Name and Contact Details of Applicant
              </Text>
              <Stack>
                <HStack>
                  <Text>Name (Company or Individual):</Text>
                  <Box width="500px" borderBottom="1px solid">
                    <Text>Jesco Asbestos Removal Pty Ltd</Text>
                  </Box>
                </HStack>

                <HStack>
                  <Text>Address:</Text>
                  <Box width="500px" borderBottom="1px solid">
                    <Text>17 Capital Terrace, Queanbeyan East, NSW</Text>
                  </Box>
                </HStack>

                <HStack>
                  <Text>Phone Number:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>0411 067 700</Text>
                  </Box>
                  border
                  <Text>Mobile:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>0411 067 700</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Fax Number:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text color="white">a</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>ACT Builders License Number:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>ARA00003</Text>
                  </Box>
                  <Text>Class:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>A</Text>
                  </Box>
                </HStack>
                <Box>
                  <Text mt="-5px">
                    (PLEASE ATTACH COPY OF LICENCE WITH ENDORSEMENT TO REMOVE
                    ASBESTOS)
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Box borderBottom="1px solid" pb="15px">
              <Text>
                If other than a Licenced Builder, please indicate the nature of
                the material being disposed of:
              </Text>
            </Box>

            <Box border="1px solid" px="5px" mt="10px" pb="5px">
              <Text textAlign="center" fontWeight="bold">
                Transporter
              </Text>
              <Stack>
                <HStack>
                  <Text>Name of Transporter:</Text>
                  <Box width="500px" borderBottom="1px solid">
                    <Text>Jesco Asbestos Removal Pty Ltd</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Phone Number:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>0411 067 700</Text>
                  </Box>
                  border
                  <Text>Mobile:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>0411 067 700</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Vehicle Registration:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>CK84AG, CG10BH, CP18UH, DYX10N</Text>
                  </Box>
                  <Text>Type of Asbestos:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <HStack>
                      {Boolean(hasFriable) && <Text>Friable</Text>}
                      {Boolean(hasNonFriable) && <Text>Non-Friable</Text>}
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Packaging Details:</Text>
                  <Box width="450px" borderBotBoxtom="1px solid">
                    <Text>
                      Covered leak-proof truck, double wrapped and/or double
                      bagged
                    </Text>
                  </Box>
                </HStack>
                <Text fontWeight="bold">
                  (eg: sealed drums, palletised, double bagged & approximate
                  amount, i.e. Kg)
                </Text>
                <HStack alignItems="flex-start">
                  <Text minWidth="250px">
                    Site from which the asbestos is being moved:
                  </Text>

                  <Stack spacing={0}>
                    <HStack flexDirection="row" flexWrap="wrap" spacing="15px">
                      {wasteDispoal.RemovalProjects.map((rp) => (
                        <Text
                          key={rp.id}
                        >{`${rp.address}, ${rp.city}, ${rp.State?.abbrev}`}</Text>
                      ))}
                    </HStack>
                    <Box width="350px" borderBottom="1px solid"></Box>
                  </Stack>
                </HStack>
                <HStack>
                  <Text>Block:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text color="white">-</Text>
                  </Box>
                  border
                  <Text>Section:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text color="white">-</Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>

            <Box border="1px solid" px="5px" mt="10px" pb="5px">
              <Text textAlign="center" fontWeight="bold">
                Payment Details and Certification
              </Text>
              <Stack>
                <HStack>
                  <Text>Method of Payment:</Text>
                  <Text>Credit Card</Text>
                  <Box>
                    <Box
                      mt="-5px"
                      border="1px solid"
                      height="15px"
                      width="15px"
                    />
                  </Box>
                  <Text>EFTPOS</Text>
                  <Box>
                    <Box
                      mt="-5px"
                      border="1px solid"
                      height="15px"
                      width="15px"
                    />
                  </Box>
                  <Text>Account No:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text>216519</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Planned date of delivery:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text>
                      {moment(data.wasteDisposal.date, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY',
                      )}
                    </Text>
                  </Box>
                </HStack>
                <Text>
                  I certify that the asbestos waste will be packaged and
                  transported in accordance with the attached instructions, as
                  per ACTPLA and Worksafe ACT requirements
                </Text>
                <HStack>
                  <Text>Name of Licence holder: (Please Print):</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text>Tony Hall</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>Signature of License holder:</Text>
                  <Image
                    src={tonyHallSignature}
                    height="40px"
                    alt="jill-krogh-signature"
                  />
                  <Text>Date:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text>
                      {moment(
                        data.wasteDisposal.signature_date,
                        'YYYY-MM-DD',
                      ).format('DD/MM/YYYY')}
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>
          </Flex>

          <WasteDisposalFooter page={1} />
        </Flex>
      </A4>

      <A4
        minHeight={isPrinting ? 'initial' : '300mm'}
        height={isPrinting ? '320mm' : '300mm'}
        mb={isPrinting ? 0 : '20px'}
      >
        <Flex height="100%" flexDirection="column">
          <HStack mb="5px" justify="space-between">
            <Stack spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Header>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                MUGGA LANE WASTE MANAGEMENT FACILITY
              </Header>
            </Stack>
            <Image
              height="60px"
              src="https://jesco-files.s3-us-west-1.amazonaws.com/remondis.png"
              alt="Remondis logo"
            />
          </HStack>
          <Flex direction="column" flex="1">
            <Box border="1px solid" px="5px" mt="10px" pb="5px">
              <Text textAlign="center" fontWeight="bold">
                Indemnity
              </Text>
              <Stack>
                <Text>
                  The licence holder shall indemnify and keep indemnified
                  Remondis from and against any claim, demand, action, suit or
                  proceeding that may be made or brought by any person against
                  Remondis or the employees or agents of Remondis or any of them
                  in respect of personal injury to or death of any person
                  whatsoever or loss of or damage to any property or any other
                  loss or damage whatsoever arising out of or as a consequence
                  of an unlawful act or a negligent act or omission by the
                  licence holder in the execution of the work undertaken in this
                  application by the licence holder, or its employees, agents or
                  sub-constultants and also from any costs and expenses that may
                  be incurred with any such claim, demand, action, suit or
                  proceeding.
                </Text>
                <HStack>
                  <Text>Signature of License holder:</Text>
                  <Box width="450px" borderBotBoxtom="1px solid">
                    <Image
                      src={tonyHallSignature}
                      height="40px"
                      alt="jill-krogh-signature"
                    />
                  </Box>
                </HStack>
                <HStack>
                  <Text>Date:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text>
                      {moment(
                        data.wasteDisposal.signature_date,
                        'YYYY-MM-DD',
                      ).format('DD/MM/YYYY')}
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>
            <Box border="1px solid" px="5px" mt="10px" pb="5px">
              <Text textAlign="center" fontWeight="bold">
                For Office Use Only
              </Text>
              <Stack>
                <HStack>
                  <Text>Signature of Remondis Project Manager:</Text>
                  <Box width="250px" borderBottom="1px solid">
                    <Text color="white">-</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text width="200px">Application:</Text>
                  <Text>Approved</Text>
                  <Box>
                    <Box
                      mt="-5px"
                      border="1px solid"
                      height="15px"
                      width="15px"
                    />
                  </Box>
                  <Text pl="100px">Rejected</Text>
                  <Box>
                    <Box
                      mt="-5px"
                      border="1px solid"
                      height="15px"
                      width="15px"
                    />
                  </Box>
                </HStack>
                <HStack>
                  <Text>Date:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text color="white">-</Text>
                  </Box>
                  <Text pl="150px">Appointment Time:</Text>
                  <Box width="150px" borderBottom="1px solid">
                    <Text color="white">-</Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>
            <Text mt="30px" fontSize="xl">
              Enquiries may be directed to Remondis Australia on (02) 6270 7700
            </Text>
            <Text mt="30px" fontSize="xl">
              Please complete and return this form by facsimile to Remondis
              Australia on (02) 6232 7733 or email to Canberra@remondis.com.au
            </Text>
          </Flex>
          <WasteDisposalFooter page={2} />
        </Flex>
      </A4>

      <A4
        minHeight={isPrinting ? 'initial' : '300mm'}
        height={isPrinting ? '320mm' : '300mm'}
        mb={isPrinting ? 0 : '20px'}
      >
        <Flex height="100%" flexDirection="column">
          <HStack mb="5px" justify="space-between">
            <Stack spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Header>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                MUGGA LANE WASTE MANAGEMENT FACILITY
              </Header>
            </Stack>
            <Image
              height="60px"
              src="https://jesco-files.s3-us-west-1.amazonaws.com/remondis.png"
              alt="Remondis logo"
            />
          </HStack>
          <Flex direction="column" flex="1">
            <Text fontWeight="bold" fontSize="lg" textAlign="center">
              Disposal of asbestos will only be permitted if the delivery
              complies with the following disposal instructions
            </Text>
            <Text fontWeight="bold" mt="10px">
              Definitions
            </Text>
            <Text mt="5px">
              <Text as="span" fontWeight="bold">
                ‘Non-friable’
              </Text>{' '}
              asbestos is any material that, when dry,{' '}
              <Text as="span" fontWeight="bold">
                cannot
              </Text>{' '}
              be crumbled, pulverized or reduced to a powder by hand pressure.
              This is the most common form of waste and is usually found as
              cement sheeting (either flat or corrugated), vinyl floor tiles,
              water or flue pipes, or other asbestos bonded products produced
              prior to 1980. If left undisturbed, non friable asbestos presents
              no known health risks.
            </Text>
            <Text mt="10px">
              <Text as="span" fontWeight="bold">
                ‘friable’
              </Text>{' '}
              asbestos is any material that, when dry, can be crumbled,
              pulverized or reduced to a powder by hand pressure. The most
              common form was used in pipe lagging, insulation and asbestos
              backed vinyl floor tiles. It presents significant health risks if
              disturbed.
            </Text>

            <Text fontWeight="bold" mt="10px">
              Containers for Asbestos Waste
            </Text>
            <Text>
              All asbestos waste must be enclosed in suitable containers. This
              may include wrapping, double bagging (putting it in a plastic bag
              inside a second bag), or where appropriate, sealing it in a drum,
              timber crate or shipping container.
            </Text>
            <Text mt="15px">
              All friable asbestos waste must be either double bagged, or sealed
              in a suitable container. If double-bagged:
            </Text>

            <Text my="10px">Small Volumes to Asbestos Yard</Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">
                each bag must be impervious to asbestos dust and be made of 200
                Micron (.02mm) thick polyethylene
              </ListItem>
              <ListItem pl="10px">
                the maximum size of a bag is to be able to fit into a 200 litre
                container
              </ListItem>
              <ListItem pl="10px">
                bags should be filled to no more than 50 per cent capacity
              </ListItem>
              <ListItem pl="10px">each bag must not exceed 30kg</ListItem>
              <ListItem pl="10px">
                bags are to be folded over and sealed with adhesive tape after
                excluding all surplus air. (Note that no wire is permitted.)
              </ListItem>
            </UnorderedList>

            <Text my="10px">
              Medium to Large Volumes to be deposited in the Landfill
            </Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">
                each bag must be impervious to asbestos dust and be made of 200
                Micron (.02mm) thick polyethylene
              </ListItem>
              <ListItem pl="10px">
                the maximum size of a parcel must not be greater than 15 cubic
                metres
              </ListItem>
              <ListItem pl="10px">
                parcels are to be double wrapped and sealed as per the code
                (Note that no wire is permitted.)
              </ListItem>
            </UnorderedList>

            <Text mt="20px">
              Non-friable asbestos waste must be double bagged, or sealed in a
              suitable container.
            </Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">
                All wrapping material must be impervious to asbestos dust and be
                made of .02mm thick polyethylene.
              </ListItem>
            </UnorderedList>

            <Text mt="20px">Drums used for containment must:</Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">Be of metal or plastic construction</ListItem>
              <ListItem pl="10px">
                Have a mechanical seal plus a tape seal
              </ListItem>
              <ListItem pl="10px">Be suitably labelled</ListItem>
              <ListItem pl="10px">Have a maximum capacity of 200L</ListItem>
            </UnorderedList>

            <Text mt="20px">Timber crates used for containment:</Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">
                Must be banded with metal straps before they are transported
              </ListItem>
              <ListItem pl="10px">
                That are larger than the standard size (3m x 2m x 1.5m) must be
                placed on a suitable pallet for safe lifting.
              </ListItem>
            </UnorderedList>
          </Flex>
          <WasteDisposalFooter page={3} />
        </Flex>
      </A4>

      <A4
        minHeight={isPrinting ? 'initial' : '300mm'}
        height={isPrinting ? '320mm' : '300mm'}
        mb={isPrinting ? 0 : '20px'}
      >
        <Flex height="100%" flexDirection="column">
          <HStack mb="5px" justify="space-between">
            <Stack spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Header>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                MUGGA LANE WASTE MANAGEMENT FACILITY
              </Header>
            </Stack>
            <Image
              height="60px"
              src="https://jesco-files.s3-us-west-1.amazonaws.com/remondis.png"
              alt="Remondis logo"
            />
          </HStack>
          <Flex direction="column" flex="1">
            <Text fontWeight="bold">Transportation and Disposal</Text>
            <Stack>
              <Text>
                Asbestos may only be packaged in a manner described in this
                application as per ACTPLA and ACT WorkSafe requirements.
              </Text>
              <Text>
                Packaging material must be protected and remain intact during
                transport and unloading.
              </Text>
              <Text>
                Damaged packaging must be replaced or repaired prior to disposal
                of the consignment.
              </Text>
              <Text>
                All consignments must be lifted and placed within the designated
                disposal area. No tipping, where parcel may fall from vehicle to
                ground, is permitted as this may split the packaging and expose
                the asbestos material.
              </Text>
            </Stack>
            <Text fontWeight="bold" mt="20px">
              Labelling of Containers
            </Text>
            <Text>
              All asbestos waste material must be labelled before it is stored
              or transported. The label should contain the following
              information.
            </Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">Type of asbestos:</ListItem>
              <UnorderedList pl="30px">
                <ListItem pl="10px">
                  <HStack>
                    <Text>white asbestos</Text>
                    <Text pl="88px">UN no. 2590</Text>
                    <Text pl="92px">Class 9 p.g. iii</Text>
                  </HStack>
                </ListItem>
                <ListItem pl="10px">
                  <HStack spacing="100px">
                    <Text>blue asbestos</Text>
                    <Text>UN no. 2212</Text>
                    <Text>Class 9 p.g. ii</Text>
                  </HStack>
                </ListItem>
                <ListItem pl="10px">
                  <HStack spacing="100px">
                    <Text>blue asbestos</Text>
                    <Text>UN no. 2212</Text>
                    <Text>Class 9 p.g. ii</Text>
                  </HStack>
                </ListItem>
              </UnorderedList>
              <ListItem pl="10px">
                The location from which it was removed;
              </ListItem>
              <ListItem pl="10px">
                Name and contact details of the ACT builder’s licence holder;
              </ListItem>
              <ListItem pl="10px">
                Name and contact details of the licensed transporter (if
                different); and
              </ListItem>
              <ListItem pl="10px">A sign that reads:</ListItem>
            </UnorderedList>
            <Stack align="center" justify="center" spacing={0}>
              <Text fontSize="lg" fontWeight="bold">
                CAUTION – ASBESTOS
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                AVOID CREATING DUST
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                SERIOUS INHALATION HEALTH HAZARD
              </Text>
            </Stack>
            <Text mt="20px">
              All drums must be labelled on the lid and twice on the side, with
              the labels spaced evenly around them. Timber crates larger than
              the average size (3m x 2m x 1.5m) must have labels spaced no more
              than two metres apart. Labelling must be in a permanent form.
              Stencilling should be standard but adhesive labels are preferable
              for use on plastic wrapping.
            </Text>
            <Text mt="10px" fontWeight="bold">
              Signs on Vehicles
            </Text>
            <Text>
              All vehicles used to transport asbestos waste materials must
              display signs in accordance with the Australian Code for the
              Transport of Dangerous Goods by Road and Rail.
            </Text>
            <Text mt="10px">Where transporting asbestos is loaded with:</Text>
            <UnorderedList pl="30px">
              <ListItem pl="10px">
                a single package that exceeds 400kg, or
              </ListItem>
              <ListItem pl="10px">
                multiple packages each of which is more than 400kg, or
              </ListItem>
              <ListItem pl="10px">
                any number of packages where the total load reaches 1,000kg
              </ListItem>
            </UnorderedList>
            <Text mt="20px">
              The vehicle is required to display at the front and rear of the
              vehicle a Class 9 dangerous goods label with sides at least 250mm
              x 250mm and in addition the relevant shipping documentation and
              emergency procedure guides must be carried in the driver’s cabin.
            </Text>
          </Flex>
          <WasteDisposalFooter page={4} />
        </Flex>
      </A4>

      <A4
        minHeight={isPrinting ? 'initial' : '300mm'}
        height={isPrinting ? '320mm' : '300mm'}
        mb={isPrinting ? 0 : '20px'}
      >
        <Flex height="100%" flexDirection="column">
          <HStack mb="5px" justify="space-between">
            <Stack spacing={0}>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                APPLICATION TO DISPOSE OF ASBESTOS
              </Header>
              <Header fontWeight="bold" fontSize="lg" textAlign="left">
                MUGGA LANE WASTE MANAGEMENT FACILITY
              </Header>
            </Stack>
            <Image
              height="60px"
              src="https://jesco-files.s3-us-west-1.amazonaws.com/remondis.png"
              alt="Remondis logo"
            />
          </HStack>
          <Flex direction="column" flex="1">
            <Text fontWeight="bold">Disposal of Waste</Text>
            <Text mt="20px" fontWeight="bold">
              Small volumes to Asbestos Disposal Yard
            </Text>
            <Stack>
              <Text>
                When the material is delivered, it will be weighed at the
                landfill. A copy of the manifest for the material must be handed
                to the weighbridge operator.
              </Text>
              <Text>
                The Landfill Supervisor will direct the transporter to where the
                material is to be unloaded and carefully placed. Unloading of
                the vehicle is the responsibility of the transporter. All timber
                crates must be lifted as no tipping is permitted. Remondis
                Australia will undertake final disposal and coverage of the
                material in the asbestos disposal yard site.
              </Text>
            </Stack>
            <Text mt="20px" fontWeight="bold">
              Medium to large quantities to be disposed of in the landfill cell
              area.
            </Text>
            <OrderedList pl="20px" mt="10px">
              <ListItem pl="10px">
                Medium to large loads are to be notified to REMONDIS at the time
                of submission of the “Application to Dispose of Asbestos” forms.
              </ListItem>
              <ListItem pl="10px">
                Transporter attends site at the scheduled time.
              </ListItem>
              <ListItem pl="10px">
                REMONDIS representative meets transporter at the weighbridge to
                check paperwork and inspect the load.
              </ListItem>
              <ListItem pl="10px">
                REMONDIS representative will advise transporter where the
                material will be disposed and escorts transporter to disposal
                point.
              </ListItem>
              <ListItem pl="10px">
                REMONDIS representative will advise transporter where to place
                and how to place the parcel at the point of disposal. Unloading
                of the vehicle is the responsibility of the transporter.
              </ListItem>
              <ListItem pl="10px">
                Transporter carries outplacement of parcel under guidance of
                REMONDIS and exits the site.
              </ListItem>
              <ListItem pl="10px">
                REMONDIS will then cover the parcel as per the required
                procedure.
              </ListItem>
            </OrderedList>

            <Text mt="20px" fontWeight="bold">
              References
            </Text>
            <UnorderedList pl="20px" mt="10px">
              <ListItem pl="10px">ACT Work Health & Safety Act 2011</ListItem>
              <ListItem pl="10px">
                ACT Work Health & Safety Regulations 2011
              </ListItem>
              <ListItem pl="10px">
                ACT Environmental Protection Act 1997
              </ListItem>
              <ListItem pl="10px">ACT Dangerous Substances Act 2004</ListItem>
              <ListItem pl="10px">
                ACT Dangerous Goods (Road Transport) Act 2009
              </ListItem>
              <ListItem pl="10px">
                ACT Construction Occupations (Licensing) Act 2004
              </ListItem>
              <ListItem pl="10px">
                ACT Construction Occupations (Licensing) Regulation 2004
              </ListItem>
              <ListItem pl="10px">ACT Building Act 2004</ListItem>
              <ListItem pl="10px">
                ACT Building (General) Regulations 2008
              </ListItem>
              <ListItem pl="10px">
                Worksafe ACT – Codes of Practise: How To Safely Remove Asbestos,
                How to Manage and Control Asbestos in the Workplace.
              </ListItem>
              <ListItem pl="10px">
                Australian Code for the Transport of Dangerous Goods by Road and
                Rail
              </ListItem>
              <ListItem pl="10px">
                ACT Govt, Contaminated Sites: Information Sheet 5 – Requirements
                for the Transport and Disposal of Asbestos Contaminated Wastes.
              </ListItem>
            </UnorderedList>
          </Flex>
          <WasteDisposalFooter page={5} />
        </Flex>
      </A4>
    </Box>
  );
};
