import * as React from 'react';
import { UserInterface } from 'jesco-web';
import * as Sentry from '@sentry/browser';
import { appEnvironments } from '../enums';
import { ErrorBoundary } from '../containers/ErrorBoundary';

export interface ErrorLoggingProviderContextInterface {
  logError(error: any, info: any): void;
  setLoggingUser(user: UserInterface): void;
}

export interface ErrorMeta {
  name: string;
  email: string;
}

const ErrorLoggingContext = React.createContext<
  ErrorLoggingProviderContextInterface
>({
  logError: () => {},
  setLoggingUser: () => {},
});

export const useErrorLoggingContext = () =>
  React.useContext(ErrorLoggingContext);

export const ErrorLoggingProvider = ({ children }: any) => {
  const [errorMeta, setErrorMeta] = React.useState<ErrorMeta | null>(null);

  React.useEffect(() => {
    // Only set up a rollbar in production builds
    if (
      process.env.REACT_APP_SENTRY_DSN &&
      process.env.REACT_APP_ENV === appEnvironments.production
    ) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
      });
    }
  }, []);

  /**
   * Logs a pretty severe error to 3rd party services
   *
   * @param {any} error
   */
  const logError = (error: any, info: any) => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
    });

    Sentry.configureScope((scope) => {
      if (errorMeta) {
        scope.setUser({
          email: errorMeta.email,
          name: errorMeta.name,
        });
      }
    });

    // Only log errors to the console in development
    // if (process.env.REACT_APP_ENV !== appEnvironments.production) {
    //   console.log(error);
    // } else {

    // }
    console.log('logging error');
    console.log('Sentry', Sentry);

    Sentry.captureException(error);
  };

  const setLoggingUser = (user: UserInterface) => {
    setErrorMeta({
      name: user.name,
      email: user.email,
    });
  };

  return (
    <ErrorLoggingContext.Provider
      value={{
        logError,
        setLoggingUser,
      }}
    >
      <ErrorBoundary logError={logError}>{children}</ErrorBoundary>
    </ErrorLoggingContext.Provider>
  );
};
