import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  Button,
  Stack,
  useDisclosure,
  HStack,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { Section } from '../containers/Section';
import { useQuery } from '@apollo/client';
import { StateInterface, QuoteInterface } from 'jesco-web';
import { Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import { GET_QUOTES_QUERY } from '../services/apollo/queries';
import { useAuthContext } from '../providers/Auth.Provider';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { QuoteDrawer } from '../containers/Quote/QuoteDrawer';
import { QuoteNotes } from 'src/containers/Quote/QuoteNotes';

export interface QuotesPageProps extends RouteComponentProps {
  states: StateInterface[];
}
export interface QuotesTableFilter {
  status: string;
  search: string;
  page: number;
  perPage: number;
}

export const QuotesPage = ({ states, history: { push } }: QuotesPageProps) => {
  const { me } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState<QuotesTableFilter>({
    status: 'pre',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { loading, data, refetch } = useQuery<{
    getQuotes: {
      items: QuoteInterface[];
      count: number;
    };
  }>(GET_QUOTES_QUERY, {
    variables: filter,
    fetchPolicy: 'network-only',
  });

  let quotes: QuoteInterface[] = [];
  let count = 0;

  if (data) {
    const {
      getQuotes: { count: total, items },
    } = data;

    count = total;
    quotes = items;
  }

  const handleRefetch = (newFilter: QuotesTableFilter) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  if (!Boolean(me?.Permissions?.can_edit_quotes)) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Box p={['5px', '10px', '20px']} maxWidth="1400px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary">
            Quotes
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>

            <Button
              colorScheme="green"
              variant="ghost"
              color="green.500"
              data-testid="add-quote-button"
              onClick={() => {
                onOpen();
              }}
              size="md"
            >
              Add quote
            </Button>
          </Stack>
        </Stack>

        <Tabs size="md" pb="20px">
          <TabList maxWidth="100%" overflowX={['scroll', 'initial', 'initial']}>
            <Tab
              onClick={() =>
                handleRefetch({
                  ...filter,
                  status: 'pre',
                })
              }
            >
              Pre-Approved
            </Tab>
            <Tab
              onClick={() =>
                handleRefetch({
                  ...filter,
                  status: 'approved',
                })
              }
            >
              Approved
            </Tab>
            <Tab
              onClick={() =>
                handleRefetch({
                  ...filter,
                  status: 'all',
                })
              }
            >
              All
            </Tab>
          </TabList>
        </Tabs>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {loading ? 'Loading...' : `${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <SimpleGrid columns={[1, 2, 3]} spacing="20px">
          {quotes.map((q) => (
            <Section
              alignSelf="flex-start"
              maxWidth="400px"
              width="100%"
              key={q.id}
              px="15px"
              py="10px"
            >
              <Button
                data-testid="edit-quote-button"
                variant="link"
                fontSize="xl"
                fontWeight="bold"
                color="green.500"
                onClick={() => {
                  push(`/dashboard/quote/${q.id}`);
                }}
              >
                <Text isTruncated maxWidth="250px">
                  {q.name}
                </Text>
              </Button>
              <Stack align="flex-start" mt="5px" spacing={0}>
                <Text fontWeight="bold" fontSize="sm">{`${q.street}`}</Text>
                <Text fontWeight="bold" fontSize="sm">
                  {q.city} {q.State.abbrev}
                </Text>
              </Stack>

              <Stack mt="5px" spacing={0}>
                <Text>
                  {moment(q.date_formatted, 'LLL').format('D MMMM, YYYY')}
                </Text>
                <Text>
                  {moment(q.date_formatted, 'LLL').format('hh:mm A')} -{' '}
                  {moment(q.end_date_formatted, 'LLL').format('hh:mm A')}
                </Text>
              </Stack>

              <Box mt="10px">
                <QuoteNotes quote={q} onComplete={refetch} />
              </Box>
            </Section>
          ))}
        </SimpleGrid>
      </Box>

      <QuoteDrawer
        isOpen={isOpen}
        onClose={onClose}
        onComplete={refetch}
        states={states}
        initialValues={{
          id: undefined,
          street: '',
          city: '',
          zip: '',
          stateId: undefined,
          name: '',
          notes: '',
          date: moment().endOf('hour').set('minutes', 0).toDate(),
          endDate: moment()
            .endOf('hour')
            .add('1', 'hour')
            .set('minutes', 0)
            .toDate(),
        }}
      />
    </>
  );
};
