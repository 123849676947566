import { extendTheme } from '@chakra-ui/react';

// Let's say you want to add custom colors
export const theme = extendTheme({
  styles: {
    global: (props: any) => ({
      'html, body': {
        fontSize: 'sm',
        color: props.colorMode === 'dark' ? 'white' : 'gray.600',
        lineHeight: 'tall',
      },
      'html, body, #root': {
        height: '100%',
      },
      '.pba': {
        pageBreakInside: 'avoid',
      },
      a: {
        color: props.colorMode === 'dark' ? 'teal.300' : 'teal.500',
      },
      '@media print': {
        body: {
          '-webkit-print-color-adjust': 'exact',
        },
        '.pba': {
          pageBreakInside: 'avoid',
        },
      },
      '.react-datepicker': {
        border: 'none',
        borderRadius: 'none',
        w: '100%',
      },
      '.react-datepicker__header': {
        border: 'none',
        backgroundColor: 'white',
      },
      '.react-datepicker__month-container': {
        w: '100%',
      },
      '.rbc-header': {
        border: 'none',
        borderBottom: '1px solid #DDD',
      },

      '.rbc-date-cell': {
        color: '#000',
      },
      '.rbc-event': {
        padding: 0,
      },

      '.ant-picker-dropdown': {
        zIndex: 2000,
      },

      /**
       * WYSIWYG Editor
       */
      '.DraftEditor-editorContainer': {
        backgroundColor: 'rgba(250, 250, 250, 1)',
        padding: '10px',
        minHeight: '150px',
      },
      '.quote-body p': {
        mb: '.5em',
      },
    }),
  },
  fonts: {
    body: '"OpenSans", Helvetica, Arial, sans-serif',
    mono: '"OpenSans", Helvetica, Arial, sans-serif',
    heading: '"OpenSans", Helvetica, Arial, sans-serif',
  },
  colors: {
    stop: '#FF0102',
    start: '#02B050',
    check: '#FEFF02',
    blue: {
      900: '#091a26',
      800: '#11344c',
      700: '#1a4e72',
      600: '#226898',
      500: '#2b82be',
      400: '#559bcb',
      300: '#80b4d8',
      200: '#aacde5',
      100: '#d5e6f2',
    },
    green: {
      900: '#0a2023',
      800: '#154146',
      700: '#1f6168',
      600: '#2a828b',
      500: '#34a2ae',
      400: '#5db5be',
      300: '#85c7ce',
      200: '#aedadf',
      100: '#d6ecef',
    },
  },
});
