import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useToast,
  Text,
  IconButton,
  Stack,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  DrawerFooter,
} from '@chakra-ui/react';
import { DatePicker } from 'antd';
import { useMutation } from '@apollo/client';
import {
  CREATE_WASTE_DISPOSAL,
  UPDATE_WASTE_DISPOSAL,
  DELETE_WASTE_DISPOSAL,
} from '../../services/apollo/mutations';
import { WasteDisposalInterface } from 'jesco-web';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

export interface WasteDisposalFormValues {
  id: number | undefined;
  date: Date;
  signatureDate: Date;
}

export interface WasteDisposalDrawerProps extends RouteComponentProps {
  isOpen: boolean;
  onClose(): void;
  wasteDisposal?: WasteDisposalInterface;
  onComplete?(): void;
  initialValues: WasteDisposalFormValues;
}

export const WasteDisposalDrawer = withRouter(
  ({
    history: { push },
    isOpen,
    onClose,
    wasteDisposal,
    onComplete,
    initialValues,
  }: WasteDisposalDrawerProps) => {
    const toast = useToast({ position: 'bottom-left' });

    const [createWasteDisposal, { loading: isCreateLoading }] = useMutation(
      CREATE_WASTE_DISPOSAL,
      {
        onCompleted(d) {
          if (!d || !d.createWasteDisposal || !d.createWasteDisposal.id) return;

          onClose();
          push(`/dashboard/waste-disposal/${d.createWasteDisposal.id}`);
        },
      },
    );

    const [updateWasteDisposal, { loading: isUpdateLoading }] = useMutation(
      UPDATE_WASTE_DISPOSAL,
      {
        onCompleted(d) {
          if (!d || !d.updateWasteDisposal || !d.updateWasteDisposal.id) return;

          onClose();
          if (onComplete) {
            onComplete();
          }

          toast({
            title: 'Waste disposal updated',
            description: `Waste disposal updated`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

    const [deleteWasteDisposal, { loading: isDeleteLoading }] = useMutation(
      DELETE_WASTE_DISPOSAL,
      {
        onCompleted(d) {
          if (!d || !d.deleteWasteDisposal || !d.deleteWasteDisposal.id) return;

          onClose();

          toast({
            title: 'Waste disposal deleted',
            description: `Toolbox talk deleted`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          push(`/dashboard/waste-disposals`);
        },
      },
    );

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: Yup.object().shape({
        id: Yup.number().nullable(),
        date: Yup.date().required(),
        signatureDate: Yup.date().nullable(),
      }),
      onSubmit: (values: WasteDisposalFormValues) => {
        if (!values.id) {
          createWasteDisposal({
            variables: {
              ...values,
              date: moment(values.date).format('YYYY-MM-DD'),
              signatureDate: moment(values.signatureDate).format('YYYY-MM-DD'),
            },
          });
        } else {
          updateWasteDisposal({
            variables: {
              ...values,
              date: moment(values.date).format('YYYY-MM-DD'),
              signatureDate: moment(values.signatureDate).format('YYYY-MM-DD'),
            },
          });
        }
      },
    });

    const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

    const handleClose = () => {
      onClose();
      formik.resetForm();
    };

    return (
      <Drawer size="lg" isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <form onSubmit={formik.handleSubmit} noValidate>
          <DrawerContent rounded="md">
            <DrawerHeader
              pl="40px"
              pr="20px"
              display="flex"
              fontSize="md"
              color="white"
              bg="blue.500"
              justifyContent="space-between"
              alignItems="center"
            >
              <DrawerCloseButton
                left="0"
                top="5px"
                data-testid="drawer-close-button"
                onClick={handleClose}
                color="white"
              />
              <Text fontSize="xl">
                {wasteDisposal ? `Edit Waste Disposal` : 'New Waste Disposal'}
              </Text>

              {wasteDisposal && wasteDisposal.id && (
                <IconButton
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      deleteWasteDisposal({
                        variables: {
                          id: wasteDisposal.id,
                        },
                      });
                    }
                  }}
                  data-testid="delete-assessor-button"
                  aria-label="delete-assessor"
                  colorScheme="red"
                  icon={<FaTrash />}
                  size="sm"
                />
              )}
            </DrawerHeader>
            <DrawerBody p="20px">
              <Stack spacing="50px" bg="white">
                <FormControl
                  id="signatureDate"
                  data-testid="waste-disposal-form-signature-date"
                >
                  <FormLabel>
                    <HStack justifyContent="space-between">
                      <Text fontSize="lg" fontWeight="bold">
                        Signature date
                      </Text>
                      <Text color="red.500">
                        {moment(formik.values.signatureDate).format(
                          'D MMMM, YYYY',
                        )}
                      </Text>
                    </HStack>
                  </FormLabel>

                  <DatePicker
                    format="DD/MM/YYYY"
                    value={moment(formik.values.signatureDate).clone()}
                    onChange={(d) => {
                      formik.setFieldValue('signatureDate', d?.toDate());
                    }}
                  />
                </FormControl>

                <FormControl id="date" data-testid="waste-disposal-form-date">
                  <FormLabel>
                    <HStack justifyContent="space-between">
                      <Text fontSize="lg" fontWeight="bold">
                        Dropoff date
                      </Text>
                      <Text color="red.500">
                        {moment(formik.values.date).format('D MMMM, YYYY')}
                      </Text>
                    </HStack>
                  </FormLabel>

                  <DatePicker
                    format="DD/MM/YYYY"
                    value={moment(formik.values.date).clone()}
                    onChange={(d) => {
                      formik.setFieldValue('date', d?.toDate());
                    }}
                  />
                </FormControl>

                {formik.values.id && (
                  <Input
                    data-testid="waste-disposal-form-id"
                    name="id"
                    value={formik.values.id}
                    type="hidden"
                  />
                )}
              </Stack>
            </DrawerBody>
            <DrawerFooter
              justifyContent="space-evenly"
              borderTop="1px solid"
              borderColor="gray.300"
            >
              <Button width="200px" size="md" mr="5px" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                width="200px"
                ml="5px"
                type="submit"
                variant="solid"
                colorScheme="blue"
                disabled={!formik.isValid || formik.isSubmitting}
                isLoading={isLoading}
              >
                {formik.values.id ? 'Update' : 'Create'}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    );
  },
);
