import React, { useState } from 'react';
import numeral from 'numeral';
import {
  Text,
  HStack,
  Box,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ClientInterface, StateInterface } from 'jesco-web';
import { useQuery } from '@apollo/client';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import {
  GET_CLIENTS_QUERY,
  GET_CLIENT_QUERY,
} from '../services/apollo/queries';

import { ClientTableRow } from '../containers/Client/ClientTableRow';
import { useAuthContext } from '../providers/Auth.Provider';
import { ClientDrawer } from 'src/containers/Client/ClientDrawer';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface ClientsPageProps
  extends RouteComponentProps<{
    clientId?: string;
  }> {
  states: StateInterface[];
}

export const ClientsPage = withRouter(({ states, match }: ClientsPageProps) => {
  const { me } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [client, setClient] = useState<ClientInterface>();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'asc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch, loading } = useQuery<{
    clients: {
      items: ClientInterface[];
      count: number;
    };
  }>(GET_CLIENTS_QUERY, {
    variables: filter,
    fetchPolicy: 'network-only',
  });

  useQuery<{
    client: ClientInterface;
  }>(GET_CLIENT_QUERY, {
    variables: {
      id: match.params.clientId,
    },
    skip: !Boolean(match.params.clientId),
    onCompleted: (d) => {
      if (d && d.client) {
        onOpen();
        setClient(d?.client);
      }
    },
  });

  let clients: ClientInterface[] = [];
  let count = 0;

  if (data) {
    const {
      clients: { count: total, items },
    } = data;

    count = total;
    clients = items;
  }

  const renderRow = ({
    item,
    index,
  }: {
    item: ClientInterface;
    index: number;
  }) => {
    return (
      <ClientTableRow
        canClick={Boolean(me?.Permissions?.can_edit_clients)}
        key={String(item.id)}
        isStriped={index % 2 === 1}
        client={item}
        onRowClick={() => {
          onOpen();
          setClient(item);
        }}
      />
    );
  };

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  return (
    <>
      <Box width="100%" pt="10px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary">
            Clients
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>

            {Boolean(me?.Permissions?.can_edit_clients) && (
              <Button
                colorScheme="green"
                variant="ghost"
                color="green.500"
                data-testid="add-client-button"
                onClick={() => {
                  setClient(undefined);
                  onOpen();
                }}
                size="md"
              >
                Add client
              </Button>
            )}
          </Stack>
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <Table
          isLoading={loading}
          dataTestId="clients-table"
          onChangeFilter={(newFilter) =>
            setFilter({
              ...filter,
              ...newFilter,
            })
          }
          headers={[
            {
              slug: 'name',
              title: 'Name',
              width: '35%',
              'data-testid': 'client-name-header',
            },
            {
              slug: 'email',
              title: 'Email',
              width: '35%',
              'data-testid': 'client-email-header',
            },
            {
              title: 'Phone',
              width: '20%',
            },
          ]}
          data={clients || []}
          renderItem={renderRow}
          filter={filter}
        />
      </Box>

      <ClientDrawer
        states={states}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setClient(undefined);
        }}
        initialValues={{
          id: client ? Number(client.id) : null,
          address: client ? String(client.address) : '',
          city: client ? String(client.city) : '',
          clientContact: client ? String(client.client_contact) : '',
          email: client ? String(client.email) : '',
          mobile: client ? String(client.mobile) : '',
          name: client ? String(client.name) : '',
          phone: client ? String(client.phone) : '',
          postCode: client ? String(client.post_code) : '',
          stateId: client ? Number(client.state_id) : 1,
        }}
        onComplete={() => {
          refetch();
        }}
      />
    </>
  );
});
