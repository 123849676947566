import React from 'react';
import { RemovalOptionInterface, RemovalProjectInterface } from 'jesco-web';
import { useQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Select,
  HStack,
  Input,
  useToast,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { GET_ALL_REMOVAL_OPTIONS } from '../../services/apollo/queries';
import { ADD_AREA_TO_REMOVAL_PROJECT } from '../../services/apollo/mutations';

export interface RemovalAreaFormValues {
  id?: number;
  removalProjectId: string;
  areaId: string;
  locationId: string;
  materialId: string;
  typeId: string;
  conditionId: string;
}

export interface RemovalAreaDrawerProps {
  removalProject: RemovalProjectInterface;

  onComplete(): void;
  isOpen: boolean;
  onClose(): void;
}

export const RemovalAreaDrawer = ({
  removalProject,
  onComplete,
  isOpen,
  onClose,
}: RemovalAreaDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });
  const { loading, data } = useQuery<{
    allRemovalOptions: {
      areas: RemovalOptionInterface[];
      locations: RemovalOptionInterface[];
      materials: RemovalOptionInterface[];
      types: RemovalOptionInterface[];
      conditions: RemovalOptionInterface[];
    };
  }>(GET_ALL_REMOVAL_OPTIONS);

  const [addAreaToRemovalProject, { loading: addAreaLoading }] = useMutation(
    ADD_AREA_TO_REMOVAL_PROJECT,
    {
      onCompleted(d) {
        if (
          !d ||
          !d.addAreaToRemovalProject ||
          !d.addAreaToRemovalProject.status
        )
          return;
        onComplete();
        onClose();
        formik.resetForm();

        toast({
          title: 'Removal area added',
          description: `Removal area added`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {
      removalProjectId: removalProject.id,
      areaId: undefined,
      locationId: undefined,
      materialId: undefined,
      typeId: undefined,
      conditionId: undefined,
    },
    validationSchema: Yup.object().shape({
      removalProjectId: Yup.string().required(),
      areaId: Yup.string().required('Area is required'),
      locationId: Yup.string().required('Location is required'),
      materialId: Yup.string().required('Material is required'),
      typeId: Yup.string().required('Type is required'),
      conditionId: Yup.string().required('Condition is requried'),
    }),
    onSubmit: (values) => {
      addAreaToRemovalProject({
        variables: {
          removalProjectId: Number(removalProject.id),
          areaId: Number(values.areaId),
          locationId: Number(values.locationId),
          materialId: Number(values.materialId),
          typeId: Number(values.typeId),
          conditionId: Number(values.conditionId),
        },
      });
    },
  });

  let areas: RemovalOptionInterface[] = [];
  let locations: RemovalOptionInterface[] = [];
  let materials: RemovalOptionInterface[] = [];
  let types: RemovalOptionInterface[] = [];
  let conditions: RemovalOptionInterface[] = [];

  if (data) {
    areas = data.allRemovalOptions.areas;
    locations = data.allRemovalOptions.locations;
    materials = data.allRemovalOptions.materials;
    types = data.allRemovalOptions.types;
    conditions = data.allRemovalOptions.conditions;
  }

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
          >
            Add removal area
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody p="20px">
            <Stack spacing={5}>
              <FormControl id="area">
                <FormLabel>Area</FormLabel>
                <Select
                  data-testid="removal-area-form-area"
                  name="areaId"
                  placeholder="Select an area"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.areaId}
                >
                  {areas.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="location">
                <FormLabel>Location</FormLabel>
                <Select
                  data-testid="removal-area-form-location"
                  name="locationId"
                  placeholder="Select a location"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.locationId}
                >
                  {locations.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="material">
                <FormLabel>Material</FormLabel>
                <Select
                  data-testid="removal-area-form-material"
                  name="materialId"
                  placeholder="Select a material"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.materialId}
                >
                  {materials.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="type" s>
                <FormLabel>Type</FormLabel>
                <Select
                  data-testid="removal-area-form-type"
                  name="typeId"
                  placeholder="Select a type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.typeId}
                >
                  {types.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="condition">
                <FormLabel>Condition</FormLabel>
                <Select
                  data-testid="removal-area-form-condition"
                  name="conditionId"
                  placeholder="Select a condition"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.conditionId}
                >
                  {conditions.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Input
                data-testid="removal-project-form-id"
                name="id"
                value={formik.values.removalProjectId}
                type="hidden"
              />
            </Stack>
          </DrawerBody>

          <DrawerFooter
            justifyContent="space-evenly"
            borderTop="1px solid"
            borderColor="gray.300"
          >
            <HStack spacing={5} mt={5} justify="center">
              <Button
                width="200px"
                size="md"
                mr="5px"
                onClick={handleClose}
                isLoading={loading || addAreaLoading}
              >
                Cancel
              </Button>

              <Button
                width="200px"
                ml="5px"
                type="submit"
                variant="solid"
                colorScheme="blue"
                disabled={!formik.isValid || formik.isSubmitting}
                isLoading={loading || addAreaLoading}
              >
                Add
              </Button>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
