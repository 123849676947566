import React from 'react';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  CREATE_SUPERVISOR,
  UPDATE_SUPERVISOR,
  DELETE_SUPERVISOR,
} from '../../services/apollo/mutations';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

export interface SupervisorFormValues {
  id: number | null;
  name: string;
  phone: string;
}

export interface SupervisorDrawerProps {
  initialValues: SupervisorFormValues;
  isOpen: boolean;
  onComplete: () => void;
  onClose: () => void;
}

export const SupervisorDrawer = ({
  initialValues,
  isOpen,
  onComplete,
  onClose,
}: SupervisorDrawerProps) => {
  const toast = useToast({ position: 'bottom-left' });

  const [addSupervisor, { loading: addSupervisorLoading }] = useMutation(
    CREATE_SUPERVISOR,
    {
      onCompleted(d) {
        if (!d || !d.createSupervisor || !d.createSupervisor.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Supervisor created',
          description: `${d.createSupervisor.name} created`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [updateSupervisor, { loading: updateSupervisorLoading }] = useMutation(
    UPDATE_SUPERVISOR,
    {
      onCompleted(d) {
        if (!d || !d.updateSupervisor || !d.updateSupervisor.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Supervisor updated',
          description: `${d.updateSupervisor.name} updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [deleteSupervisor, { loading: deleteSupervisorLoading }] = useMutation(
    DELETE_SUPERVISOR,
    {
      onCompleted(d) {
        if (!d || !d.deleteSupervisor || !d.deleteSupervisor.id) return;

        handleClose();
        if (onComplete) {
          onComplete();
        }

        toast({
          title: 'Supervisor deleted',
          description: `${d.deleteSupervisor.name} deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      name: Yup.string().required(),
      phone: Yup.string().required(),
    }),
    onSubmit: (values: SupervisorFormValues) => {
      if (!values.id) {
        addSupervisor({
          variables: values,
        });
      } else {
        updateSupervisor({
          variables: values,
        });
      }
    },
  });

  const isLoading =
    addSupervisorLoading || updateSupervisorLoading || deleteSupervisorLoading;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerContent rounded="md" data-testid="supervisor-drawer">
          <DrawerHeader
            px="40px"
            display="flex"
            fontSize="md"
            color="white"
            bg="blue.500"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="xl">
              {formik.values.name ? `${formik.values.name}` : 'New supervisor'}
            </Text>
            {formik.values.id && (
              <IconButton
                onClick={() =>
                  deleteSupervisor({
                    variables: {
                      id: formik.values.id,
                    },
                  })
                }
                data-testid="delete-supervisor-button"
                aria-label="delete-supervisor"
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            left="0"
            top="5px"
            data-testid="drawer-close-button"
            onClick={handleClose}
            color="white"
          />
          <DrawerBody data-testid="drawer">
            <Stack spacing={5}>
              <FormControl
                id="name"
                isInvalid={Boolean(formik.errors.name)}
                isRequired
              >
                <FormLabel>Name</FormLabel>
                <Input
                  data-testid="supervisor-form-name"
                  autoFocus
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>

              <FormControl id="phone">
                <FormLabel>Phone</FormLabel>
                <NumberInput value={formik.values.phone}>
                  <NumberInputField
                    data-testid="supervisor-form-phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </NumberInput>
              </FormControl>

              {formik.values.id && (
                <Input
                  data-testid="supervisor-form-id"
                  name="id"
                  value={formik.values.id}
                  type="hidden"
                />
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTop="1px solid" borderColor="gray.300">
            <Button width="200px" size="md" mr="5px" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              width="200px"
              ml="5px"
              type="submit"
              variant="solid"
              colorScheme="blue"
              disabled={!formik.isValid}
              isLoading={isLoading}
            >
              {formik.values.id ? 'Update' : 'Create'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
