import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  Stack,
  Text,
  useToast,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { FaTimes } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { GET_SIGNED_URL, UPLOAD_FILE } from 'src/services/apollo';
import axios from 'axios';

export const FileDrawer = ({
  onComplete,
  onClose,
  isOpen,
}: {
  onComplete?: () => void;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const toast = useToast({ position: 'bottom-left' });
  const types = [
    { label: 'Staff Documentation', value: 'staff_documentation' },
    { label: 'Company Document', value: 'company' },
    { label: 'DOP Certificate', value: 'dop_certificate' },
    { label: 'Safety Data Sheet', value: 'safety_data_sheets' },
  ];
  const [type, setType] = useState(types[0].value);
  const [file, setFile] = useState<{
    name: string;
    type: string;
  }>();

  const onDrop = async (
    acceptedFiles: {
      name: string;
      type: string;
    }[],
  ) => {
    if (acceptedFiles[0]) {
      setFile(acceptedFiles[0]);
    }
  };

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: () => {
      if (onComplete) onComplete();
      onClose(); // Close the drawer after upload
    },
  });

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onCompleted: async (data) => {
      if (data.getSignedUrl && file) {
        const signedUrl = data.getSignedUrl;
        await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        toast({
          title: 'File uploaded',
          description: 'File successfully uploaded',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // drop the query params from the signed URL
        const path = signedUrl.split('?')[0];

        await uploadFile({
          variables: {
            path,
            name: file.name,
            mimetype: file.type,
            type,
          },
        });
      }
    },
    onError: (error) => {
      toast({
        title: 'Upload failed',
        description: 'The file could not be uploaded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Failed to upload file:', error);
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const handleUpload = async () => {
    if (!file) return;

    getSignedUrl({
      variables: {
        file: file.name,
        type: file.type,
        folder: 'uploads',
      },
    });
  };

  const handleClose = () => {
    setFile(undefined);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>{file ? file.name : 'New file'}</DrawerHeader>
        <DrawerCloseButton onClick={handleClose} />
        <DrawerBody>
          <Stack spacing={4}>
            {!file && (
              <Stack
                {...getRootProps()}
                padding={4}
                border="2px dashed gray"
                cursor="pointer"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here...</p>
                ) : (
                  <p>Drag 'n' drop a file here, or click to select a file</p>
                )}
              </Stack>
            )}
            {file && (
              <Stack direction="row" spacing={4} align="center">
                <Text>{file.name}</Text>
                <IconButton
                  aria-label="Remove file"
                  icon={<FaTimes />}
                  onClick={() => setFile(undefined)}
                />
              </Stack>
            )}
            <FormControl>
              <FormLabel>File Type</FormLabel>
              <Select value={type} onChange={(e) => setType(e.target.value)}>
                {types.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleUpload} disabled={!file}>
            Upload
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
