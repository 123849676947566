import React from 'react';
import { ClientInterface } from 'jesco-web';
import { Text } from '@chakra-ui/react';
import { TableDiv, TableRow, TableRowPropsInterface } from '../Table';

export interface ClientRowInterface extends TableRowPropsInterface {
  client: ClientInterface;
  onRowClick(client: ClientInterface): void;
  canClick: boolean;
}

export const ClientTableRow = ({
  client,
  onRowClick,
  canClick,
  ...props
}: ClientRowInterface) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(client);
    }
  };

  return (
    <TableRow
      data-testid="client-table-row"
      onClick={canClick ? handleClick : undefined}
      {...props}
    >
      <TableDiv px="5px" py="10px" data-testid="client-name-td">
        <Text wordBreak="break-word">{client.name}</Text>
      </TableDiv>

      <TableDiv px="5px" py="10px" data-testid="client-email-td">
        <Text wordBreak="break-word">{client.email}</Text>
      </TableDiv>

      <TableDiv px="5px" py="10px">
        {client.phone && <Text>{client.phone}</Text>}
        {client.mobile && <Text>{client.mobile}</Text>}
        {!client.mobile && !client.phone && <Text>-</Text>}
      </TableDiv>
    </TableRow>
  );
};
