import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { useQuery } from '@apollo/client';
import {
  Box,
  Text,
  Button,
  Stack,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { AssessorInterface, StateInterface } from 'jesco-web';
import { Table, TableFilterInterface, Paginator } from '../containers/Table';
import { SearchInput } from '../containers/Form/SearchInput';
import { Header } from '../containers/Text/Header';
import { GET_ASSESSORS_QUERY } from '../services/apollo/queries';
import { AssessorTableRow } from '../containers/Assessor/AssessorTableRow';
import { AssessorDrawer } from 'src/containers/Assessor/AssessorDrawer';

export interface AssessorsPageProps {
  states: StateInterface[];
}

export const AssessorsPage = ({ states }: AssessorsPageProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [assessor, setAssessor] = useState<AssessorInterface>();
  const [filter, setFilter] = useState<TableFilterInterface>({
    orderBy: 'id',
    orderByDirection: 'desc',
    search: '',
    page: 1,
    perPage: 40,
  });

  const { data, refetch, loading } = useQuery<{
    assessors: {
      items: AssessorInterface[];
      count: number;
    };
  }>(GET_ASSESSORS_QUERY, {
    variables: filter,
  });

  let assessors: AssessorInterface[] = [];
  let count = 0;

  if (data) {
    const {
      assessors: { count: total, items },
    } = data;

    count = total;
    assessors = items;
  }

  const renderRow = ({
    item,
    index,
  }: {
    item: AssessorInterface;
    index: number;
  }) => {
    return (
      <AssessorTableRow
        key={String(item.id)}
        isStriped={index % 2 === 1}
        assessor={item}
        onRowClick={() => {
          onOpen();
          setAssessor(item);
        }}
      />
    );
  };

  const handleRefetch = (newFilter: TableFilterInterface) => {
    setFilter(newFilter);
    refetch(newFilter);
  };

  return (
    <>
      <Box width="100%" pt="10px">
        <Stack
          mb="20px"
          direction={['column', 'row', 'row']}
          justify={['center', 'space-between', 'space-between']}
          align="center"
        >
          <Header fontSize="3xl" color="textSecondary">
            Assessors
          </Header>

          <Stack
            direction={['column-reverse', 'row', 'row']}
            justify="center"
            width={['100%', 'initial', 'initial']}
          >
            <Box width={['100%', '200px', '250px']}>
              <SearchInput
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: e.target.value || '',
                  });
                }}
                onClear={() => {
                  setFilter({
                    ...filter,
                    page: 1,
                    search: '',
                  });
                }}
                value={filter.search}
              />
            </Box>

            <Button
              colorScheme="green"
              variant="ghots"
              color="green.500"
              data-testid="add-assessor-button"
              onClick={() => {
                setAssessor(undefined);
                onOpen();
              }}
              size="md"
            >
              Add assessor
            </Button>
          </Stack>
        </Stack>

        <HStack mb="20px" justify="space-between">
          <Text fontSize="lg" whiteSpace="nowrap" data-testid="result-count">
            {`${numeral(count).format('0,0')} results`}
          </Text>

          {count > filter.perPage && (
            <Paginator
              onChangePage={(pageNumber) =>
                handleRefetch({
                  ...filter,
                  page: pageNumber,
                })
              }
              perPage={filter.perPage || 20}
              currentPage={filter.page || 1}
              totalResults={count}
            />
          )}
        </HStack>

        <Table
          isLoading={loading}
          dataTestId="assessors-table"
          onChangeFilter={(newFilter) =>
            setFilter({
              ...filter,
              ...newFilter,
            })
          }
          headers={[
            {
              slug: 'name',
              title: 'Name',
              width: '35%',
              'data-testid': 'assessor-name-header',
            },
            {
              slug: 'company',
              title: 'Company',
              width: '35%',
              'data-testid': 'assessor-company-header',
            },
            {
              title: 'Phone',
              width: '20%',
            },
          ]}
          data={assessors || []}
          renderItem={renderRow}
          filter={filter}
        />
      </Box>

      <AssessorDrawer
        states={states}
        isOpen={isOpen}
        initialValues={{
          id: assessor ? Number(assessor.id) : null,
          name: assessor ? assessor.name : '',
          company: assessor ? String(assessor.company) : '',
          licenseNo: assessor ? String(assessor.license_no) : '',
          phone: assessor ? String(assessor.phone) : '',
          licenseExpires: assessor
            ? moment(assessor.license_expires || '', 'YYYY-MM-DD').toDate()
            : moment().toDate(),
          stateId: assessor ? Number(assessor.state_id) : 1,
        }}
        onClose={() => {
          onClose();
          setAssessor(undefined);
        }}
        onComplete={() => {
          refetch();
        }}
      />
    </>
  );
};
