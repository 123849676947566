import React from 'react';
import { CalendarEventTypeInterface } from 'jesco-web';
import moment from 'moment';
import { EventProps, Event } from 'react-big-calendar';
import { Text, Box, Button, HStack } from '@chakra-ui/react';
import { CalendarBubble } from './CalendarBubble';

export const CalendarEvent = (
  props: React.PropsWithChildren<EventProps<Event>> & {
    calendarEventTypes: CalendarEventTypeInterface[];
  },
) => {
  const activeEventType = props.calendarEventTypes.find(
    (cet) => cet.id === Number(props.event.resource.eventTypeId),
  );

  if (props.event.allDay) {
    return (
      <Box px="4px">
        <Button
          width="100%"
          bg={activeEventType?.color}
          justifyContent="flex-start"
          color="white"
          variant="link"
          spacing="1px"
          fontWeight="normal"
        >
          <HStack spacing="3px" align="center">
            <Text fontSize="sm">{props.event.title}</Text>
          </HStack>
        </Button>
      </Box>
    );
  }

  return (
    <Box px="4px">
      <Button
        variant="link"
        spacing="1px"
        fontWeight="normal"
        color="black.500"
      >
        <HStack spacing="3px" align="center">
          {Boolean(activeEventType) && (
            <CalendarBubble color={String(activeEventType?.color)} />
          )}

          <Text fontSize="xs">
            {moment(props.event.start).format('hh:mm A')}
          </Text>

          <Text fontSize="sm" color="black.500">
            {props.event.title}
          </Text>
        </HStack>
      </Button>
    </Box>
  );
};
